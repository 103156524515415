import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { Howl } from "howler";
import jacksrbetterJson from "../jrbjson.json";
import resultCards from "../resultCards";

import reaveldealer_cards_sound from "../../../../../../assets/sounds/dealercards_reavel.mp3";
// import emtyCard from "../../../../../../assets/games/jacksorbetter/Asset 1.svg";
import emtyCard from "../../../../../../assets/games/jacksorbetter/emtyCard.svg";
import tensorbetterEmtyCard from "../../../../../../assets/games/jacksorbetter/tensrbetter2.svg";
import deucesWildEmtyCard from "../../../../../../assets/games/jacksorbetter/deucesEmetyCard.svg";
import jokerPokerEmtyCard from "../../../../../../assets/games/jacksorbetter/jokerPokerEmetyCard.svg";

import deucesWildHeart from '../../../../../../assets/games/jacksorbetter/dw2h.svg';
import deucesWildDiamond from '../../../../../../assets/games/jacksorbetter/dw2d.svg';
import deucesWildSpade from '../../../../../../assets/games/jacksorbetter/dw2s.svg';
import deucesWildClub from '../../../../../../assets/games/jacksorbetter/dw2c.svg';

import jokerpokerHeart from '../../../../../../assets/games/jacksorbetter/jpjh.svg';
import jokerpokerDiamond from '../../../../../../assets/games/jacksorbetter/jpjd.svg';
import jokerpokerSpade from '../../../../../../assets/games/jacksorbetter/jpjs.svg';
import jokerpokerClub from '../../../../../../assets/games/jacksorbetter/jpjc.svg';

import "./index.css"



const deucesWildCardsArray = [
    { src: deucesWildHeart },
    { src: deucesWildDiamond },
    { src: deucesWildSpade },
    { src: deucesWildClub },
]
const jokerpokerCardsArray = [
    { src: jokerpokerHeart },
    { src: jokerpokerDiamond },
    { src: jokerpokerSpade },
    { src: jokerpokerClub },
]
const CardsContainer = forwardRef((props, ref) => {

    const [classVaribles, setclassVaribles] = useState({});
    const [flipSound, setFlipSound] = useState({ betSound: null, isPlayingFlipSound: null });

    const reaveldealer_cards = useRef(null);
    const backFaceCard = useRef(null);

    const { contextSafe } = useGSAP()

    let initializeCardFlipSound = () => {
        try {
            let betSound = new Howl({
                src: [reaveldealer_cards_sound],
                html5: true,
                preload: true,
                onplayerror: () => {     // Handle play errors
                    betSound.once('unlock', () => betSound.play());
                }
            });

            setFlipSound((prev) => ({ ...prev, betSound, isPlayingFlipSound: false }));
            return betSound;
        } catch (error) {
            console.error("Error initializing bet sound:", error);
            return null;
        }
    };


    useEffect(() => {
        switch (Number(props.gameId)) {
            case 30:
                backFaceCard.current = emtyCard;
                break;
            case 35:
                backFaceCard.current = deucesWildEmtyCard;
                break;
            case 36:
                backFaceCard.current = jokerPokerEmtyCard;
                break;
            case 40:
                backFaceCard.current = tensorbetterEmtyCard;
                break;
            default:
                return null

        }
        gsap.ticker.lagSmoothing(0);
        reaveldealer_cards.current = initializeCardFlipSound();
        const interval = setInterval(() => gsap.ticker.tick(), 500);
        // console.log(flipSound.betSound)

        return () => {
            reaveldealer_cards.current.pause();
            clearInterval(interval);
        }
        // }, [flipSound.isPlayingFlipSound])
    }, [])

    const { cardDetails, clickOncardToHoldorUnhold, isSoundOn } = props;
    // console.log(cardDetails);
    const { id, src, value, hold } = cardDetails

    useEffect(() => {
        const isActiveWindowsMode =
            (navigator.userAgentData && navigator.userAgentData.platform && navigator.userAgentData.platform === "Windows") ||
            navigator.userAgent.indexOf("Windows") !== -1;

        // Determine if the device is in portrait or landscape mode
        const isActivePortraitMode = window.innerHeight > window.innerWidth;
        const isActiveLandscapeMode = window.innerWidth > window.innerHeight;
        if (isActiveWindowsMode) {
            setclassVaribles({
                liContainer: jacksrbetterJson.liContainer,
                cardImage: jacksrbetterJson.cardImage,
                holdSpan: jacksrbetterJson.holdSpan

            })

        } else if (isActivePortraitMode) {
            setclassVaribles({
                liContainer: jacksrbetterJson.liContainerPrt,
                cardImage: jacksrbetterJson.cardImage,
                holdSpan: jacksrbetterJson.holdSpan

            })

        } else {
            setclassVaribles({
                liContainer: jacksrbetterJson.liContainerPrt,
                cardImage: jacksrbetterJson.cardImage,
                holdSpan: jacksrbetterJson.holdSpan

            })

        }



    }, []);




    let playRemainingSounds = (chipSound) => {
        let currentSound = chipSound;
        if (isSoundOn) {
            // console.log(chipSound)

            // if (chipSound && flipSound.isPlayingFlipSound) {
            //     chipSound.stop();
            // }
            if (!chipSound || flipSound.isPlayingFlipSound) {
                currentSound = initializeCardFlipSound();
            }

            currentSound.play();

            setFlipSound({
                currentSound,
                isPlayingFlipSound: true,
            });

        }

    };


    // useImperativeHandle(ref, contextSafe(() => ({
    useImperativeHandle(ref, () => ({

        resetCardsOnReload(res) {


            let cardLength = res.cards.length;

            let t1 = gsap.timeline();
            t1.clear(); // Clears all previous animations

            switch (Number(props.gameId)) {
                case 35:
                    // '2H', '2D', '2C', '2S', 'JC'
                    for (let i = 0; i < cardLength; i++) {
                        const cardElement = document.getElementById(`jrb${i}`);
                        const cardValue = res.cards[i];
                        const wildCardIndex = ["2H", "2D", "2S", "2C"].indexOf(cardValue);
                        if (wildCardIndex !== -1) {
                            if (cardElement && deucesWildCardsArray[wildCardIndex]) {
                                cardElement.src = deucesWildCardsArray[wildCardIndex].src;

                            }
                        } else {
                            for (let j = 0; j < resultCards.length; j++) {
                                if (resultCards[j].rsc_val === res.cards[i]) {
                                    if (cardElement && resultCards[j].src) {
                                        cardElement.src = resultCards[j].src;

                                    }
                                }
                            }
                        }
                    }
                    break;
                case 36:
                    // '2H', '2D', '2C', '2S', 'JC'
                    for (let i = 0; i < cardLength; i++) {
                        const cardElement = document.getElementById(`jrb${i}`);
                        const cardValue = res.cards[i];
                        // const jokerpokerCardIndex = ["JH", "JD", "JS", "JC"].indexOf(cardValue);
                        const jokerpokerCardIndex = ["JK"].indexOf(cardValue);
                        if (jokerpokerCardIndex !== -1) {
                            if (cardElement && jokerpokerCardsArray[jokerpokerCardIndex]) {
                                cardElement.src = jokerpokerCardsArray[jokerpokerCardIndex].src;

                            }
                        } else {
                            for (let j = 0; j < resultCards.length; j++) {
                                if (resultCards[j].rsc_val === res.cards[i]) {
                                    if (cardElement && resultCards[j].src) {
                                        cardElement.src = resultCards[j].src;

                                    }
                                }
                            }
                        }
                    }
                    break;
                default:
                    for (let i = 0; i < cardLength; i++) {
                        for (let j = 0; j < resultCards.length; j++) {
                            if (resultCards[j].rsc_val === res.cards[i]) {
                                const cardElement = document.getElementById(`jrb${i}`);
                                if (cardElement && resultCards[j].src) {
                                    cardElement.src = resultCards[j].src;

                                }
                            }
                        }
                    }

                    break;
            }


        },



        dealBetResultRes(res) {
            // console.log(res);
            let cardLength = res.cards.length;
            // console.log("cardLength", cardLength);
            // console.log("resultCards.length", resultCards, resultCards.length);


            let dealerFirstCards = []
            switch (Number(props.gameId)) {
                case 35:
                    // '2H', '2D', '2C', '2S', 'JC'
                    for (let i = 0; i < cardLength; i++) {
                        const cardValue = res.cards[i];
                        const wildCardIndex = ["2H", "2D", "2S", "2C"].indexOf(cardValue);
                        if (wildCardIndex !== -1) {
                            dealerFirstCards.push(deucesWildCardsArray[wildCardIndex]);
                        } else {
                            const matchedCard = resultCards.find(card => card.rsc_val === cardValue);
                            dealerFirstCards.push(matchedCard);
                        }
                    }

                    break;
                case 36:
                    // '2H', '2D', '2C', '2S', 'JC'
                    for (let i = 0; i < cardLength; i++) {
                        const cardValue = res.cards[i];
                        // const jokerpokerCardIndex = ["JH", "JD", "JS", "JC"].indexOf(cardValue);
                        const jokerpokerCardIndex = ["JK"].indexOf(cardValue);
                        if (jokerpokerCardIndex !== -1) {
                            dealerFirstCards.push(jokerpokerCardsArray[jokerpokerCardIndex]);
                        } else {
                            const matchedCard = resultCards.find(card => card.rsc_val === cardValue);
                            dealerFirstCards.push(matchedCard);
                        }
                    }

                    break;
                default:
                    for (let i = 0; i < cardLength; i++) {
                        const cardValue = res.cards[i];
                        const matchedCard = resultCards.find(card => card.rsc_val === cardValue);
                        dealerFirstCards.push(matchedCard);
                    }
                    break;
            }

            let t1 = gsap.timeline();
            t1.clear(); // Clear previous animations
            setTimeout(() => {
                for (let i = 0; i < cardLength; i++) {
                    const cardElement = document.getElementById(`jrb${i}`);
                    if (cardElement && dealerFirstCards[i] && dealerFirstCards[i].src) {
                        t1.to(cardElement, {
                            rotateY: 90,
                            ease: "power3.inOut",
                            duration: 0.15,
                            autoAlpha: 1,
                            // delay: i * 0.05, // Stagger flips by index
                            // delay: 2 * 0.05, // Stagger flips by index
                            onStart: () => {
                                playRemainingSounds(flipSound.betSound);
                                cardElement.src = dealerFirstCards[i].src;
                            }
                        })
                            .set(cardElement, { attr: { src: dealerFirstCards[i].src } }) // Set the new card image mid-flip
                            .to(cardElement, {
                                rotateY: 0,
                                ease: "power3.inOut",
                                // duration: 0.15,
                                duration: 0.1,
                                autoAlpha: 1,
                                onComplete: () => {
                                    if (res.autoHoldCards && res.autoHoldCards[i]) {
                                        setTimeout(() => {
                                            clickOncardToHoldorUnhold(i);
                                        }, 200);
                                        // console.log(res.autoHoldCards[i], i);
                                    }
                                }
                            }, `+=0.1`);
                    }

                }

                // }, 1000);
            }, 500);
        }
        ,

        drawUnselectedCards(drawResponse, dealerResponse, etyCard) {

            let dealerCards = dealerResponse.cards;
            let drawCards = drawResponse.cards;

            console.log("dealResponse ", dealerResponse, "drawResponse ", drawResponse);

            let cardLength = dealerResponse.cards.length;





            let dealerFirstCards = []
            switch (Number(props.gameId)) {
                case 35:
                    // '2H', '2D', '2C', '2S', 'JC'
                    for (let i = 0; i < cardLength; i++) {
                        if (dealerCards[i] !== drawCards[i]) {
                            const cardElement = document.getElementById(`jrb${i}`);
                            const cardValue = drawCards[i];
                            const wildCardIndex = ["2H", "2D", "2S", "2C"].indexOf(cardValue);
                            if (wildCardIndex !== -1) {
                                dealerFirstCards[i] = deucesWildCardsArray[wildCardIndex];
                            } else {
                                const matchedCard = resultCards.find(card => card.rsc_val === cardValue);
                                dealerFirstCards[i] = matchedCard;
                            }
                            cardElement.src = backFaceCard.current;
                        }
                    }

                    break;
                case 36:
                    // '2H', '2D', '2C', '2S', 'JC'
                    for (let i = 0; i < cardLength; i++) {
                        if (dealerCards[i] !== drawCards[i]) {
                            const cardElement = document.getElementById(`jrb${i}`);
                            const cardValue = drawCards[i];
                            // const jokerpokerCardIndex = ["JH", "JD", "JS", "JC"].indexOf(cardValue);
                            const jokerpokerCardIndex = ["JK"].indexOf(cardValue);
                            if (jokerpokerCardIndex !== -1) {
                                dealerFirstCards[i] = jokerpokerCardsArray[jokerpokerCardIndex];
                            } else {
                                const matchedCard = resultCards.find(card => card.rsc_val === cardValue);
                                dealerFirstCards[i] = matchedCard;
                            }
                            cardElement.src = backFaceCard.current;
                        }
                    }

                    break;
                default:
                    for (let i = 0; i < cardLength; i++) {
                        if (dealerCards[i] !== drawCards[i]) {

                            const cardValue = drawCards[i];
                            const matchedCard = resultCards.find(card => card.rsc_val === cardValue);
                            dealerFirstCards[i] = matchedCard;
                            const cardElement = document.getElementById(`jrb${i}`);
                            cardElement.src = backFaceCard.current;
                        }
                    }

                    break;
            }

            let t1 = gsap.timeline();
            t1.clear(); // Clears all previous animations
            setTimeout(() => {
                console.log(dealerFirstCards)
                if (dealerResponse && drawResponse) {
                    for (let i = 0; i < cardLength; i++) {
                        if (dealerCards[i] !== drawCards[i]) {
                            const cardElement = document.getElementById(`jrb${i}`);
                            // cardElement.src = backFaceCard.current;
                            if (cardElement && dealerFirstCards[i] && dealerFirstCards[i].src) {
                                t1.to(cardElement, {
                                    rotateY: 90,
                                    ease: "power3.inOut",
                                    duration: 0.15,
                                    autoAlpha: 1,
                                    // delay: i * 0.05, // Stagger flips by index
                                    // delay: 2 * 0.05, // Stagger flips by index
                                    onStart: () => {
                                        playRemainingSounds(flipSound.betSound);
                                        cardElement.src = dealerFirstCards[i].src;
                                    }
                                })
                                    .set(cardElement, { attr: { src: dealerFirstCards[i].src } }) // Set the new card image mid-flip
                                    .to(cardElement, {
                                        rotateY: 0,
                                        ease: "power3.inOut",
                                        // duration: 0.15,
                                        duration: 0.1,
                                        autoAlpha: 1,

                                    }, `+=0.1`);
                            }


                        }

                    }

                }
                // },1000)
            }, 500)

        },



        restartTheGame(intialCards) {

            let cardLength = intialCards.length;
            let t2 = gsap.timeline();
            // console.log("cardLength", cardLength)
            for (let i = 0; i < cardLength; i++) {
                const cardElement = document.getElementById(`jrb${i}`);
                cardElement.src = backFaceCard.current;
            }

        },



    }))
    // )



    const clickOncardToHoldorUnholdcard = () => {
        clickOncardToHoldorUnhold(id)
    }



    return (
        <li className="jacksCardContainer" style={classVaribles.liContainer}>
            <button onClick={clickOncardToHoldorUnholdcard}>
                {/* <img id={`jrb${id}`} style={classVaribles.cardImage} src={src} /> */}
                <img id={`jrb${id}`} style={classVaribles.cardImage} src={backFaceCard.current} />
            </button>
            {hold && <span style={classVaribles.holdSpan}>Hold</span>}
        </li>
    )

})


export default CardsContainer