import React, { Component, Fragment } from "react";

import { Stage, Layer, Group, Rect, Arc, Text } from "react-konva";
import { Howl } from "howler";

import betchipsnd from "././../../../../../assets/sounds/betoncard.mp3";
import betrussiansnd from "././../../../../../assets/sounds/bet.mp3";
import pokerchipsnd from "././../../../../../assets/sounds/pokerchip_snd.mp3";
// import plyerwin_snd from "././../../../../../assets/sounds/playerwin.mp3";
import plyerwin_snd from "././../../../../../assets/sounds/successNew.mp3";
import cardsdropping_snd from "././../../../../../assets/sounds/carddroping.mp3";
import cardsshuffle from "././../../../../../assets/sounds/shuffle-cards.mp3";
import reaveldealer_cards from "././../../../../../assets/sounds/dealercards_reavel.mp3";
import clear_snd from "././../../../../../assets/sounds/clear.mp3";
import selectCard from "../../../../../assets/sounds/selectcard.mp3";
import unselectCard from "../../../../../assets/sounds/unselectcard.mp3";
import playerWin from "../../../../../assets/sounds/successNew.mp3"
import playerLose from "../../../../../assets/sounds/successNew.mp3"
// import playerWin from "../../../../../assets/sounds/win.mp3"
// import playerLose from "../../../../../assets/sounds/duplose.mp3"
import Cards from "../RussianPoker/UI/cards";
import Rutablewindows from "../../../../../assets/games/russianPoker/russiantblewindows.png";

import chip_10 from "../../../../../assets/games/russianPoker/black.png";
import chip_25 from "../../../../../assets/games/russianPoker/Purple chip.svg";
import chip_50 from "../../../../../assets/games/russianPoker/blue.png";
import chip_100 from "../../../../../assets/games/russianPoker/red.png";
import chip_500 from "../../../../../assets/games/russianPoker/yellow.png";
import chip_1000 from "../../../../../assets/games/russianPoker/Whitechip.svg"
import "./Russianpoker.css";
import russianbg from "../../../../../assets/games/russianPoker/BG.png";
import mutesnds from "../../../../../assets/games/russianPoker/mute_snds.png"
// import pokertableruss from "../../../../../assets/games/russianPoker/AssetTable.png";
// import pokertablelandsp from "../../../../../assets/games/russianPoker/landscapetable.png";
import landscapebg from "../../../../../assets/games/russianPoker/landspbg.png";
import porttraitejson from "../portraite&landscape.json";
import balanceicon from "../../../../../assets/games/russianPoker/Balance icon.png";
import balanceiconwindows from "../../../../../assets/games/russianPoker/balancewindows.png";
import exitrussian_popup from "../../../../../assets/games/russianPoker/logOut.png";
// import exitrussian from "../../../../../assets/games/russianPoker/logOut.png";
import exitrussian from "../../../../../assets/games/russianPoker/logOut.png";
import popupExit from "../../../../../assets/common_imgs/exit_btn.png"
// import exitrussian from "../../../../../assets/games/russianPoker/Exit.png";
import beticon from "../../../../../assets/games/russianPoker/Bet icon.png";
import beticonwindows from "../../../../../assets/games/russianPoker/betwindows.png";
import winnericn from "../../../../../assets/games/russianPoker/winnericon.png";
import winnericnwindows from "../../../../../assets/games/russianPoker/winnericonwindows.png";
import informicon from "../../../../../assets/games/russianPoker/i.png";
import russiantitle from "../../../../../assets/games/russianPoker/russiantitle.png";
import russianlabelimg from "../../../../../assets/games/russianPoker/russianlabel.png";
import optionbtn from "../../../../../assets/games/russianPoker/Option.png";
import optionbtnwindows from "../../../../../assets/games/russianPoker/optionbtnwindows.png";
import volumeimg from "../../../../../assets/games/russianPoker/Valume.png";
import russianbetbtnup from "../../../../../assets/games/russianPoker/up.png";
import russianbetbtright from "../../../../../assets/games/russianPoker/right.png";
import russianbetbtnleft from "../../../../../assets/games/russianPoker/left.png";
import russianbetbtndown from "../../../../../assets/games/russianPoker/down.png";
import russianbetcle from "../../../../../assets/games/russianPoker/betcircle.png";
import betincerementdecrement from "../../../../../assets/games/russianPoker/betincreament&decreament.png";
// import betincerementdecrement1 from "../../../../../assets/games/russianPoker/Balance icon.png"
import betchipsRussian from "../../../../../assets/games/russianPoker/black.png";
import Russianbgwindows from "../../../../../assets/games/russianPoker/russianbgwindows.png";

import Drawbtn from "../../../../../assets/games/russianPoker/drawbtn.png";
import Drawbtnwindows from "../../../../../assets/games/russianPoker/drawbtnwindows.png";
import bnsactive from "../../../../../assets/games/russianPoker/bnsactive.png";
import Buybtn from "../../../../../assets/games/russianPoker/buybtn.png";
import Buybtnwindows from "../../../../../assets/games/russianPoker/buybtnwindows.png";
import Betbtn from "../../../../../assets/games/russianPoker/betbtn.png";
// import Betbtnwindows from "../../../../../assets/games/russianPoker/betbtnwindows.png"
import Foldbn from "../../../../../assets/games/russianPoker/foldbtn.png";
import Foldbnwindows from "../../../../../assets/games/russianPoker/foldbtnwindows.png";
import useridlmg from "../../../../../assets/games/russianPoker/userid.png";
import Russiandeck from "../../../../../assets/games/russianPoker/RussianDeck.png";
import gsap from "gsap";
import DealerCardsContainer from "./UI/DealerCardsContainer";
import resultCards from "../RussianPoker/UI/resultCards";
import Title from "../RussianPoker/Russiantitle";
import { connect } from "react-redux";

const Russianpokerassets = [

  chip_10,
  chip_25,
  chip_50,
  chip_100,
  chip_500,
  chip_1000,
  russianbg,
  mutesnds,

  landscapebg,
  balanceicon,
  balanceiconwindows,
  exitrussian_popup,
  popupExit,
  beticon,
  beticonwindows,
  winnericn,
  winnericnwindows,
  informicon,
  russiantitle,
  russianlabelimg,
  optionbtn,
  optionbtnwindows,
  volumeimg,
  russianbetbtnup,
  russianbetbtright,
  russianbetbtnleft,
  russianbetbtndown,
  russianbetcle,
  betincerementdecrement,
  betchipsRussian,
  Russianbgwindows,
  Drawbtn,
  Drawbtnwindows,
  bnsactive,
  Buybtn,
  Buybtnwindows,
  Betbtn,
  Foldbn,
  Foldbnwindows,
  useridlmg,
];

// npm i react-konva@17.0.1-3
class RussianPoker extends Component {
  constructor(props) {
    super(props);
    this.cardsRef = React.createRef();
    this.dealerCardsContainer = React.createRef();

    // this.pokerchipsnd = new Audio(pokerchipsnd);

    // antebetresult and shuffled result : ---------
    this.randomPlayerCards = [];
    this.shuffledPlayerCards = [];
    this.randomDealerCards = [];
    // replaceCards (userSelectedCards) : ---------
    this.replaceCards = [];
    // serverResponse (after draw cards) : ---------
    this.unsequenceDrawCards = [];
    this.sequenceDrawCards = [];
    // clickOnBuyCard : ---------
    this.buyCardUnsequenceArray = [];
    this.buyCardsequenceArray = [];
    // DEALER CARDS
    this.dealerCardsRandom = [];
    this.dealerShuffledCards = [];
    // CONFIRM_DEALER_CARD : -----------------
    this.dealerUnsequenceDrawCards = [];
    this.dealerSequenceDrawFinalCards = [];
    this.insuranceActive = false;
    this.balnce = this.props.balance;
    this.antebet = 0;
    this.betsamount = 0;
    this.roundNumber = 0;
    this.betAmount = 0;


    this.state = {
      isPlayingChip: false,
      chipSound: null,
      isPlayingBet: false,
      betSound: null,
      insurenceMinbetAmt: 0,
      isplayerinfoupdate: false,
      bonusactive: 0,
      insurenceMaxbetAmt: 0,
      playerrankinghand: [],
      myOrient: "land",
      betshigh_balnce: null,
      keyboardhandle: false,
      dealenable: false,
      disClearBtn: 1,
      disDrawbtn: 1,
      disBuybtn: 1,
      cmfrmdealeraction: 0,
      cmfrmdealeractionNO: 0,
      Foldbtn: 1,
      handId: 0,
      Yesbtn: 1,
      Nobtn: 1,
      betBtnValue: 0,
      disBetBtn: 1,
      playerhand_status: "",
      isoptionmenu: false,
      ShowGamerules: false,
      buttontext: "",
      drawbuttonhandle: false,
      isbonuspattable: false,
      ShowLimits: false,
      isactiveoptionsmenu: false,
      ishistorytable: false,
      balance_rsp: 0,
      updateBet: 0,
      betdeductions: 0,
      antebetcircle: false,
      betchipincrementbtn: false,
      multibuttonsebledsble: false,
      mutesounds: false,
      totalbet: 0,
      bonusbetamount: 0,
      previousBonusbetamount: 0,
      count: 1,
      insurencetbet: 0,
      pokerranking: "",
      pokerrankingdeler: "",
      storebetchips: [],
      storeantebetchips: [],
      storebonuschips: [],
      storeinsurancechips: [],
      prevStatestoreantebetchips: [],
      prevStatestorebonuschips: [],
      totalwinRussian: 0,
      betdecreamentbtn: false,
      idincrement: 0,
      bsbtn: true,
      anteBetAmount: 0,
      previousAnteBetAmount: 0,
      disablemultibutton: false,
      insurencebtn: false,
      isMobilePortraitLndsp: null,
      betbutton: "DEAL",
      activeScreen: null,
      // //GroupProperties
      heightOfLayer: null,
      widthOfLayer: null,
      dealerDistanceFromX: null,
      playerDistanceFromX: null,
      dealerDistanceFromY: null,
      PlayerDistanceFromY: null,
      // SpriteSheetProps
      revealCard: false,
      infoText: this.props.infoText,
      buydrawCheck: false,
      insurencetbet: 0,
      lowBalaceErrorInfo: "",
      ShowHistory: false,
      disableBuyButton: false,

      bnsactvglwcss: null,
      betactvglwcss: null,
      betbuttoncss: null,
      betbtntextcss: null,
      foldbuttoncss: null,
      foldbtntextcss: null,
      buybtncss: null,
      buybtntxtcss: null,
      drawbtncss: null,
      drawbtntxtcss: null,
      betchipstoantebetcss: null,
      betchipstobonusbetcss: null,
      chipsstoredcontainercss: null,
      antebetpdvcss: null,
      antechpimgcss: null,
      antespntxtcss: null,
      bonusdivcss: null,
      bonusimgcss: null,
      bonuspntxtcss: null,
      betbtntxtcss: null,
      betchiptobetbtncss: null,
      betchiptobetbtncss: null,
      betbtntxtcss: null,
      buydrawcontainer: null,
      drawchipcss: null,
      drawchiptextcss: null,
      insurancedivcss: null,
      insurancechipcss: null,
      insurancetxtcss: null,
      dealerhandcss: null,
      playerhandcss: null,
      mobileAntiMaskCovercss: null,
      betpannelrussiancovercss: null,
      insurenceactivecss: null,
      rankinginfodelercss: null,

      inforussiancss: null,
      betChipstoInsurance: null,


    };

    this.chipref = React.createRef();
    this.chiprefee = React.createRef();
    this.chipdrawref = React.createRef();
    this.chipbnsref = React.createRef();
    this.chipvalue = React.createRef();
    this.chipsarray = React.createRef();
    this.chipbetref = React.createRef();
    this.chipinsurenceref = React.createRef();
    this.no = React.createRef();
    this.cardsRef = React.createRef();
    // this.dealerCardsContainer = React.createRef();
    this.buyDealerCardDrawn = React.createRef();
    this.handleResize = this.handleResize.bind(this);
    this.isMobile = false;
    this.dealenable = false;
    this.resetAnimationChips = false;
    this.playerWinBonus = false;
    this.wanttobuydelercrd = null;

    this.drawReplaceCards = false;

    this.betchipactivated = false;
    this.buydrawchipactivated = false;
    this.ConfirmbuydealercardactivePopup = false;
    this.resetPyrWnCpsAmn = false;

    this.clearTimingLeft = [
      this.clertime1, this.clertime2, this.clertime3, this.clertime4, this.clertime5, this.clertime6, this.clertime7, this.clertime8, this.clertime9,
      this.clertime11, this.clertime12, this.clertime13, this.clertime14, this.clertime15, this.clertime16, this.clertime17, this.clertime18, this.clertime19,
      this.clertime21, this.clertime22, this.clertime23, this.clertime24, this.clertime25, this.clertime26, this.clertime27, this.clertime28, this.clertime29,
      this.clertime31, this.clertime32, this.clertime33, this.clertime34, this.clertime35, this.clertime36, this.clertime37, this.clertime38, this.clertime39,
      this.clertime41, this.clertime42, this.clertime43, this.clertime44, this.clertime45, this.clertime46, this.clertime47, this.clertime48, this.clertime49,
      this.clertime50, this.clertime51, this.clertime52, this.clertime53, this.clertime54, this.clertime55, this.clertime56
    ]
    this.GameRules = [
      { "name": "The game is played using one 52-card deck. The game uses standard poker rules for ranking and comparing hands." },
      { "name": "The player places his mandatory Ante wager, plus an optional Bonus bet." },
      { "name": "The player and the dealer are dealt five cards, with the dealers last (5th) card turned face up." },
      { "name": "The player must make a decision to either Fold, Bet, Buy, or Draw." },
      { "name": "FOLD: The players five cards are discarded. The dealer will collect the losing Ante." },
      { "name": "BET: The player places a wager of 2x the Ante in the BET box." },
      { "name": "BUY: The player places 1x Ante to buy a 6th Card." },
      { "name": "DRAW: The player places 1x Ante to exchange 1-5 cards." },
      { "name": "If the player invoked either the Buy or the Exchange option, then the Fold or Bet decision will be made based on the new hand." },
      { "name": "If the player has a hand of Three of a Kind or better, an Insurance Bet amounting to at least the players Ante bet and no more than half of the payout in paytable for the players Raise bet can be placed." },
      { "name": "In case the player has two combinations at the same time, the insurance will be bought for both combinations. For that, the first combination needs to be at least a Three of a Kind or higher." },
      { "name": "The dealers cards are then opened and the poker value of the hand declared." },
      { "name": "If the dealer qualifies with a hand of Ace-King or higher, the players hand will be settled as follows:" },

      // { "name": "Player wins: Ante pushes and Bet is paid according to paytable." },
      // { "name": "Tie: Both Ante and Bet push." },
      // { "name": "Dealer wins: All wagers will lose, except possibly the Bonus bet which is settled separately." },
      { "name": "When Dealer Not Qualified (NQ)" },
      { "name": "If the player has a Natural Hand (high card) and the dealer is not qualified, the player receives Ante × 2, and the Bet amount is returned." },
      { "name": "If the player has a Natural Hand (any hand) and the dealer is not qualified, the player receives Ante × 2, and the Bet amount is returned." },
      { "name": "If the player has a non-Natural Hand and the dealer is not qualified, the player receives Ante × 2, and the Bet amount is returned." },
      { "name": "If the player has a hand and the dealer is not qualified, with a buy card used, the Ante, Buy amount, and Bet amount are all returned." },
      { "name": "If the dealer has a better hand than the player, the dealer wins, and no payout is made." },
      { "name": "When Dealer Qualified (Q)" },
      { "name": "If the player has a Natural Hand, the dealer is qualified, and the player has a better hand, the player receives Ante × 2, and Bet winnings are paid according to the payout table." },
      { "name": "If the player has a hand with a buy card, the dealer is qualified, and the player has a better hand, the player receives the Ante, and Bet winnings are paid according to the payout table." },
      { "name": "If the player has a hand with replaced cards, the dealer is qualified, and the player has a better hand, the player receives the Ante, and Bet winnings are paid according to the payout table." },
      { "name": "If the player has a Natural Hand, the dealer is qualified with a buy card, and the player has a better hand, the player receives the Ante, and Bet winnings are paid according to the payout table." },

      
      { "name": "If the players winning hand also contains a second poker combination, then the player will be paid for both combinations, even if the second combination doesn't beat the dealers hand." },
      { "name": "For the second combination to qualify, it must contain at least one card that wasnt included in the first winning combination." },
      { "name": "Should there be more than one way to make a second combination, the highest paying combination will be used." },
      { "name": "The Insurance Bet will be settled as follows" },
      { "name": "If the dealers hand is lower than Ace-King, the Insurance Bet pays 1:1." },
      { "name": "If the dealers hand is equal to or better than the players hand, the Insurance Bet is returned to the player." },
      { "name": "If the dealers hand is Ace-King or better, but worse than the players hand, the Insurance Bet is lost." },
      { "name": "If the dealer doesn't qualify, the player has an option to buy a card for the dealer at the cost equal to the Ante bet." },
      { "name": "If the player opts against this option, Bet is returned and Ante pays 1:1. Any amount paid to buy a 6th card or pay to Exchange cards is lost." },
      { "name": "If the player exercises the option to buy a card for the dealer, the highest card in dealers hand will be replaced with the new card." },
      { "name": "If the new card is of the same rank as the card it was replacing, then the card will be drawn again until the rank is different." },
      { "name": "If, as a result of the replacement, the dealer qualifies and the players hand is still higher than the dealers hand, the players Ante pushes and the Bet is paid according to the paytable." },
      { "name": "If, following the replacement of the card, the dealers hand is higher than the players hand, the player loses all wagers, except possibly the Bonus bet which is settled separately." },
      { "name": "The Bonus bet will pay based on the players initial cards only and according to the bonus paytable." }
    ]
    // console.log(this.GameRules)

    this.bonuspaytable = [
      { k: "Royal Flush", val: "1500 to 1" },
      { k: "Straight Flush", val: "800 to 1" },
      { k: "Four of a Kind", val: "250 to 1" },
      { k: "Full House", val: "150 to 1" },
      { k: "Flush", val: "100 to 1" },
      { k: "Straight", val: "50 to 1" },
      { k: "Three of a Kind", val: "5 to 1" },
    ];
    this.chipVal = [
      { src: chip_10, key: 1, label: "10", val: 10 },
      { src: chip_25, key: 1, label: "20", val: 20 },
      { src: chip_50, key: 1, label: "50", val: 50 },
      { src: chip_100, key: 1, label: "100", val: 100 },
      { src: chip_500, key: 1, label: "500", val: 500 },
      { src: chip_1000, key: 1, label: "1000", val: 1000 },
      // { src: chip_25, key: 1, val: 1000 },
      // { src: chip_25, key: 1, val: 5000 },
    ];
    this.updatePlayerInfo = this.updatePlayerInfo.bind(this);
    // this.handleOrientationChange = this.handleOrientationChange.bind(this)

  }
  clearallsettimeouts = () => {
    for (let i = 1; i <= this.clearTimingLeft.length; i++) {
      clearTimeout(this.clearTimingLeft[i]);
      // console.log(this.clearTimingLeft[i]);
    }
  }




  componentDidMount() {


    // Add event listener to disable Tab globally
    document.addEventListener('keydown', this.handleKeyDown);

    const { childRef } = this.props;
    childRef(this);

    this.preloadassetsRussianpoker(Russianpokerassets)
    // document.addEventListener("keydown", this.Russian_handleKeyboardEvent);
    this.setState({ buttontext: "clear", insurencebtn: true, betdeductions: 0 });

    // console.log(this.props.rp_chipValues)
    this.setState({ buttontext: "clear", insurencebtn: true })
    if (this.props.rp_chipValues) {
      let newChips = this.props.rp_chipValues.split(",");
      // console.log(newChips);
      // console.log(newChips.length)
      let lblTxt = "";
      // for (let i = 0; i < newChips.slice(0,6).length; i++) {
      for (let i = 0; i < newChips.length; i++) {
        if (Number(newChips[i]) >= 1000 && Number(newChips[i]) <= 999999) {
          lblTxt = "K"
        } else if (Number(newChips[i]) >= 1000000 && Number(newChips[i]) <= 999999999) {
          lblTxt = "M"
        } else if (Number(newChips[i]) > 999999999) {
          lblTxt = "B"
        }
        this.chipVal[i].val = newChips[i]
        // console.log(this.chipVal[i].val, lblTxt);
        this.checkChipValuerussian(Number(this.chipVal[i].val), lblTxt, i);
      }

    }

    this.betrussiansnd = new Audio(betrussiansnd);
    // this.reaveldealer_cards = new Audio(reaveldealer_cards);
    this.reaveldealer_cards = new Howl({ src: [reaveldealer_cards], html5: true, });

    this.selectCard = new Audio(selectCard);
    this.unselectCard = new Audio(unselectCard);
    // this.playerWin = new Audio(playerWin);
    // this.playerLose = new Audio(playerLose);
    this.cardsshuffle = new Audio(cardsshuffle);

    this.pokerchipsnd = this.initializeChipSound();
    this.betchipsnd = this.initializeBetSound();
    this.playerWin = this.initializePlayerWinSound();
    this.playerLose = this.initializePlayerLoseSound();


    document.getElementById("russiangamecover").style.display = "none";
    // document.getElementById("russiangamecover").classList.add("gamecover_enbledisable")


    // console.log(porttraitejson.pokertable.height);
    window.addEventListener("resize", this.handleResize);

    // window.addEventListener("resize", this.handleOrientationChange);

    this.handleOrientationChange();

    // console.log("Width: " + window.screen.width);
    // console.log("height: " + window.screen.height);
    const betchipsRussianpoker = document.getElementById("betchips");
    this.setState({ infoText: this.props.language.Place_antebet })
    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {
      document.getElementById("landscapeInfo").style.display = "none";
      document.getElementById("infocontainer").classList.add("infotext_windows")



      // this.ShowBonuspayouttable();


      document.getElementById("paytable").classList.add("paytable")
      document.getElementById("waviy").classList.add("waviy")
      document.getElementById("infrmicon").classList.add("addpointer")
      document.getElementById("optionrussian").classList.add("addpointer")
      const buybutton = document.getElementById("buybtn");
      buybutton.classList.add("addpointer")

      document.getElementById("minmaxbets").classList.add("minmaxbets_windows");
      this.setState({
        activeScreen: 0
      })

      document.getElementById("container_playerdealer").classList.add("container_playerdealer");
      document.getElementById("Dealer").classList.add("Dealer_windows")
      document.getElementById("Player").classList.add("Player_windows")
      // document.getElementById("optionrussian").style.display = "none"

      document.getElementById("insurenceRussian").classList.add("insurencenodrop")
      document.getElementById("buydrawRussian").classList.add("insurencenodrop")
      document.getElementById("betRussian").classList.add("insurencenodrop")
      document.getElementById('volumeRussian').classList.add("addpointer")
      document.getElementById("drawtext").classList.add("drawtextwindows");
      document.getElementById("betpannelrussiancover").style.display = "none";
      document.getElementById("betpannelrussiancover").classList.add("pannelcoverrussian");

      document.getElementById("betcontainer").classList.add("betpannelrussianwindows");
      document.getElementById("inforussian").classList.add("windowsinfo");




      // document.getElementById("anitecircleactive").classList.add("antecircleactivewindows")
      document.getElementById("betcircle").classList.add("addpointer");
      document.getElementById("betbtn").classList.add("addpointer");
      document.getElementById("Foldbtn").classList.add("addpointer");

      document.getElementById("bonusRussian").classList.add("bnspoiner");
      // document.getElementById("bonusRussian").classList.add("addpointer")

      switch (this.state.count) {
        case 1:
          betchipsRussianpoker.src = chip_10;
          document.getElementById("val0").classList.remove("valtext_windows");
          document.getElementById("val0").textContent = this.chipVal[0].label;
          document.getElementById("val0").classList.add("valtext_windows_chip10", "chip_10text");


          break;
        case 2:
          betchipsRussianpoker.src = chip_25;
          break;
        case 3:
          betchipsRussianpoker.src = chip_50;
          break;
        case 4:
          betchipsRussianpoker.src = chip_100;
          break;
        case 5:
          betchipsRussianpoker.src = chip_500;
          break;
        case 6:
          betchipsRussianpoker.src = chip_1000;
          break;
        default:
          break;
      }
      Object.assign(betchipsRussianpoker.style, porttraitejson.betchpwindows);


    } else {
      document.getElementById("waviy").classList.remove("waviy")
      document.getElementById("waviy").style.display = "none"
      document.getElementById("paytable").style.display = "none"
      this.setState({
        activeScreen: 1
      })
      const confirmbuydealerElement = document.getElementById("confirmbuydealer_popup");
      const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnce");
      popupbetGreaterthanBalnceElement.classList.add("popupbetshighMobileIntial");
      const containerPlayerdealerElement = document.getElementById("container_playerdealer");
      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");
      const drawbutton = document.getElementById("drawbtn");
      drawbutton.classList.add("drwnodrop")
      const buybutton = document.getElementById("buybtn");
      // buybutton.classList.add("addpointer")


      containerPlayerdealerElement.className = "";
      containerPlayerdealerElement.style.cssText = "";




      drawbutton.src = "";
      drawbutton.style.cssText = "";
      buybutton.src = "";
      buybutton.style.cssText = "";
      betbutton.src = "";
      betbutton.style.cssText = "";
      foldbutton.src = "";
      foldbutton.style.cssText = "";

      switch (this.state.count) {
        case 1:
          betchipsRussianpoker.src = chip_10;
          document.getElementById("val0").textContent = this.chipVal[0].label;

          break;
        case 2:
          betchipsRussianpoker.src = chip_25;
          break;
        case 3:
          betchipsRussianpoker.src = chip_50;
          break;
        case 4:
          betchipsRussianpoker.src = chip_100;
          break;
        case 5:
          betchipsRussianpoker.src = chip_500;
          break;
        case 6:
          betchipsRussianpoker.src = chip_1000;
          break;

        default:
          break;

      }





      document.getElementById("val0").style.cssText = "";
      document.getElementById("inforussian").className = "";
      document.getElementById("inforussianland").className = "";
      confirmbuydealerElement.classList.add('confirmbuy-dealerpopup');
      if (window.innerWidth < window.innerHeight) {
        document.getElementById("infocontainer").style.display = "block"
        document.getElementById("landscapeInfo").style.display = "none"

        document.getElementById("val0").classList.add("valtextPortrait", "valtextPortrait1");


        document.getElementById("betpannelrussiancover").style.display = "none";
        document.getElementById("betpannelrussiancover").classList.add("PlayerCardpannelcoverPortrait");
        document.getElementById("mobileAntiMaskCover").style.display = "none";
        document.getElementById("mobileAntiMaskCover").classList.add("mobileAntiMaskCover");
        document.getElementById("val0").classList.remove('valtext0');
        // confirmbuydealerElement.classList.add('confirmbuy-dealerpopup');

        document.getElementById("inforussian").classList.add("portraitinfo");
        // document.getElementById('volumeRussian').classList.add("addpointer")
        // betChipText.style.cssText = "";

        containerPlayerdealerElement.classList.add("cntr_pyrdlr_prt");
        document.getElementById("Player").style.fontSize = "80px";

      } else {
        document.getElementById("landscapeInfo").style.display = "block";
        document.getElementById("infocontainer").style.display = "none"

        document.getElementById("betpannelrussiancover").style.display = "none";
        document.getElementById("betpannelrussiancover").classList.add("PlayerCardpannelcoverLndsp");

        document.getElementById("val0").classList.add("valtextLandscape", "valtextPortrait1");
        containerPlayerdealerElement.classList.add("cntr_pyrdlr_lnsp");
        document.getElementById("Player").style.fontSize = "80px";

        document.getElementById("mobileAntiMaskCover").style.display = "none";
        document.getElementById("mobileAntiMaskCover").classList.add("mobileLndspAntiMaskCover");

        document.getElementById("inforussianland").classList.add("lndspinfo");


      }
      // document.addEventListener("visibilitychange", this.handleVisibilityChange);
    }
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
    this.showPreviousBetsStatus();
  }

  initializeChipSound = () => {
    let chipSound = new Howl({
      src: [pokerchipsnd],
      html5: true, // Enable HTML5 Audio for mobile compatibility
    });
    this.setState({
      chipSound,
      isPlayingChip: false
    })
    return chipSound
  }

  // initializeBetSound = () => {
  //   let betSound = new Howl({
  //     src: [betchipsnd],
  //     html5: true, // Enable HTML5 Audio for mobile compatibility
  //   });
  //   this.setState({
  //     betSound,
  //     isPlayingBet: false
  //   })
  //   return betSound

  // }

  playPokerBetSound = (chipSound) => {

    const { isPlayingBet, mutesounds } = this.state;
    let currentSound = chipSound;

    if (!mutesounds) {

      if (isPlayingBet && chipSound && typeof chipSound.stop === "function") {
        chipSound.stop();
      }


      if (!chipSound || typeof chipSound.play !== "function") {
        currentSound = this.initializeBetSound();
      }


      if (currentSound && typeof currentSound.play === "function") {

        currentSound.play();
        this.setState({
          isPlayingBet: true,
        });
      } else {
        console.error("Invalid sound object. Unable to play sound.");
      }
    }
  };

  initializeBetSound = () => {
    try {
      const betSound = new Howl({
        src: [betchipsnd],
        html5: true, // Enable HTML5 Audio for mobile compatibility
      });
      this.setState({
        betSound,
        isPlayingBet: false,
      });
      return betSound;
    } catch (error) {
      console.error("Error initializing bet sound:", error);
      return null;
    }
  };


  initializePlayerWinSound = () => {

    let winSound = new Howl({
      src: [playerWin],
      html5: true,
    });

    return winSound


  }
  initializePlayerLoseSound = () => {
    let winSound = new Howl({
      src: [playerLose],
      html5: true,
    });

    return winSound

  }

  handleKeyDown = (event) => {
    console.log(event.key)
    //   if (event.key === 'Tab' || "Enter") {

    event.preventDefault();  // Disable the Tab key globally
    console.log('Tab key is disabled globally!');
    // }

  };




  preloadassetsRussianpoker = (Russainassets) => {

    Russainassets.forEach((loadedimages) => {
      const img = new Image();
      img.src = loadedimages;
    });

  }




  //   Russian_handleKeyboardEvent=(evt)=>{
  //   console.log(evt.code)
  //   switch(evt.code){

  //     case "Enter":
  //     case "NumpadEnter":

  //       console.log(this.state.betBtnValue)
  //       console.log(this.state.keyboardhandle)
  //       console.log(this.state.disBetBtn)
  //       if (this.state.betBtnValue == 0 && this.state.disBetBtn == 0 && this.state.keyboardhandle) {
  //       this.anteBet();

  //       }else if (this.state.betBtnValue== 1 && this.state.disBetBtn == 0 && this.state.keyboardhandle) {
  //         this.RusssianpokerBet();
  //       }
  //       else if(this.state.betbutton === "REBET"&& this.state.disBetBtn == 0 && this.state.keyboardhandle){
  //         this.anteclick(this.state.betbutton);

  //       }
  //       break;
  //       case "Digit2":
  //         console.log(this.state.betbutton)
  //         console.log(this.state.disBetBtn)
  //         if(this.state.buttontext === "REBETX2"&& this.state.disBetBtn == 0 && this.state.keyboardhandle){
  //           this.anteclick(this.state.buttontext);

  //         }
  //         break;   

  //     case "KeyC": 
  //     // console.log(this.state.disClearBtn)     
  //       if(this.state.disClearBtn == 0 && this.state.keyboardhandle){
  //         this.clearbets();


  //       }
  //       break;   
  //       case "KeyD": 
  //       // console.log(this.state.disClearBtn)     
  //         if(this.state.disDrawbtn == 0 && this.state.keyboardhandle) {

  //           this.drawCard();

  //         }
  //         break;   
  //         case "KeyB": 
  //          console.log(this.state.disBuybtn)     
  //           if(this.state.disBuybtn == 0 && this.state.keyboardhandle){
  //             this.BuyplayerCard();

  //           }
  //           break;   
  //           case "KeyF": 
  //             if(this.state.Foldbtn == 0){
  //               this.FoldCards();

  //             }
  //         break;   
  //         case "KeyY":  
  //         console.log(this.state.betbutton +""+this.state.keyboardhandle)
  //           if(this.state.Yesbtn == 0 && this.state.keyboardhandle){
  //             this.buyDealerCard(this.state.betbutton);

  //           }
  //           else if(this.state.betbutton=="YES" && this.state.keyboardhandle){
  //             this.Confirmbuydealercard(true);

  //           }
  //           else if(this.state.buttontext=="insurence"){
  //             this.InsurenceClaim(this.state.buttontext);

  //           }

  //       break;   
  //       case "KeyN": 
  //       console.log(this.state.Nobtn)
  //       console.log(this.state.Nobtn)
  //       console.log(this.state.betbutton)
  //         if(this.state.Nobtn == 0 && this.state.keyboardhandle){
  //           this.buyDealerCard(this.state.buttontext);

  //         }
  //         else if(this.state.buttontext=="NO" && this.state.keyboardhandle){
  //           this.Confirmbuydealercard(false);

  //         }
  //         else if( this.state.betbutton=="noinsurence"){
  //           this.InsurenceClaim(this.state.betbutton);

  //         }
  //     break;   
  //       default:
  //         break;   
  //   }


  // }


  checkChipValuerussian(chipValue, chpTxt, i) {

    if (chipValue >= 1000) {
      chipValue = chipValue / 1000;
      this.checkChipValuerussian(chipValue, chpTxt, i)
    } else {
      // console.log(chpTxt);
      this.showResultChipValuerussian(chipValue, chpTxt, i);
      return chipValue
    }
  }
  showResultChipValuerussian(chVal, chipTxt, index) {
    // console.log(chVal);
    // console.log(chVal + "" + chipTxt)
    this.chipVal[index].label = chVal + "" + chipTxt;
    // console.log(this.chipVal)
  }
  componentWillUnmount() {

    document.removeEventListener('keydown', this.handleKeyDown);
    // document.removeEventListener("keydown", this.Russian_handleKeyboardEvent);
    window.removeEventListener("resize", this.handleResize);
    this.betchipsnd.pause();
    this.betrussiansnd.pause();
    // this.reaveldealer_cards.pause();

    this.selectCard.pause();
    this.unselectCard.pause();
    this.playerWin.pause();
    this.playerLose.pause();
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
  }

  handleVisibilityChange = () => {


    if (document.visibilityState === "hidden") {
      console.log("The game is in the background or the user switched tabs.");
      // Pause the game, animations, or sound

    } else if (document.visibilityState === "visible") {
      console.log("The user returned to the game.");

      // Resume the game, animations, or sound
      const body = {
        sessionId: sessionStorage.getItem("sessionId"),
        CN: "GET_GAME_STATE",
        object: {
          // gameId: this.state.gameId,
          gameId: this.props.gameId,
          playerId: Number(this.props.playerId),
          // handId:this.handId,

        },
      };
      this.props.network.sendof(body);
      console.log(body);
      this.body = {};
    }

  };


  clearActivesWeDid = () => {

    this.setState({
      bsbtn: true,
      pokerranking: "",
      pokerrankingdeler: "",
    });

    this.randomPlayerCards = [];
    this.shuffledPlayerCards = [];
    this.randomDealerCards = [];
    this.replaceCards = [];
    this.unsequenceDrawCards = [];
    this.sequenceDrawCards = [];
    this.buyCardUnsequenceArray = [];
    this.buyCardsequenceArray = [];
    this.dealerCardsRandom = [];
    this.dealerShuffledCards = [];
    this.dealerUnsequenceDrawCards = [];
    this.dealerSequenceDrawFinalCards = [];
    this.insuranceActive = false;

    if (document.getElementById('rankinginfodeler')) {
      document.getElementById('rankinginfodeler').className = "";
      document.getElementById('rankinginfodeler').textContent = "";
      document.getElementById("insurencetext").style.color = "";
      document.getElementById("bonustext").style.color = "";
      document.getElementById("bettext").style.color = "";
    }

    if (document.getElementById('rankinginfo')) {

      document.getElementById('rankinginfo').className = "";
      document.getElementById('rankinginfo').textContent = "";
    }



    this.cardsRef.current.ReBet();
    this.dealerCardsContainer.current.ReBet();

    this.betchipactivated = false;
    this.buydrawchipactivated = false;
    this.ConfirmbuydealercardactivePopup = false;
    this.resetPyrWnCpsAmn = false;


  }

  handleResize() {
    // console.log("calling resize");
    // document.getElementById("bacaratbgim");
    this.handleOrientationChange();
  }

  handleOrientationChange = () => {



    // if (this.state.isactiveoptionsmenu) {
    //   this.Showoptionsmenu();

    // }
    // if(this.state.ShowLimits){
    //   this.ShowLimits();
    // }
    // if (this.state.ishistorytable) {
    //   // document.getElementById("prevhistory")
    //   this.ShowHistory();

    // }

    document.getElementById("buybtn").style.opacity = "1"
    document.getElementById("buytext").style.opacity = "1"
    this.setState({ disableBuyButton: false })

    if (document.getElementById("optionsmenubutton")) {
      gsap.to('#optionsmenubutton', { autoAlpha: 0 })
    }
    this.setState({ isoptionmenu: false, ShowLimits: false, ShowGamerules: false });

    if (document.getElementById("histry_russian")) {
      document.getElementById("histry_russian").className = "";

    }
    if (document.getElementById("exithistory")) {
      document.getElementById("exithistory").className = "";

    }
    if (document.getElementById("prevhistory")) {
      document.getElementById("prevhistory").className = "";

    }
    if (document.getElementById("histry_body")) {
      document.getElementById("histry_body").className = "";
    }
    if (document.getElementById("heading_hstiry")) {
      document.getElementById("heading_hstiry").className = "";

    }





    if (this.state.isbonuspattable) {
      this.ShowBonuspayouttable();


    }
    // console.log(window.innerHeight);
    // console.log(window.innerWidth);
    const isPortrait = window.innerHeight > window.innerWidth;
    if (isPortrait) {
      // console.log(" -----------  PORT     -------------------");
      this.setState({
        myOrient: "port",
      });
    } else {
      // console.log(" -----------  LAND     -------------------");
      this.setState({
        myOrient: "land",
      });
    }


    const betactiveElement = document.getElementById("betactive");
    const russiandeck = document.getElementById("russiandeck");

    const bacaratbgim = document.getElementById("bacaratbgim");
    const pokertable = document.getElementById("pokertableim");
    const pokertablelnspe = document.getElementById("pokertablelandscape");
    const useriddetl = document.getElementById("userid");
    const russiantiname = document.getElementById("russiantitle");
    const russianlabel = document.getElementById("russiantitle");
    const russianbncicon = document.getElementById("balnceicon");
    const exitbtn = document.getElementById("exitbtn");
    const beticonrussian = document.getElementById("beticon");
    const winnericonrussian = document.getElementById("winnericon");
    const informi = document.getElementById("infrmicon");
    const optionrussianbtn = document.getElementById("optionrussian");
    const volumeRussianimg = document.getElementById("volumeRussian");
    const bonusRussiangme = document.getElementById("bonusRussian");
    const insurenceRussiangme = document.getElementById("insurenceRussian");
    const buydrawRussiangme = document.getElementById("buydrawRussian");
    const betRussiangme = document.getElementById("betRussian");
    const betcirclerussian = document.getElementById("betcircle");
    const betchipincrementbtn = document.getElementById("betincrenet");
    const betchipdecrementbtn = document.getElementById("betdecrement");
    const betchipsRussianpoker = document.getElementById("betchips");
    const pokerrankingtwo = document.getElementById("Straightflush");
    const pokerrankingone = document.getElementById("royalflush");
    const pokerrankingthree = document.getElementById("frofkind");
    const pokerrankingfour = document.getElementById("fullhse");
    const pokerrankingfive = document.getElementById("flush");
    const pokerrankingsix = document.getElementById("stright");
    const pokerrankingseven = document.getElementById("Threekind");
    const pokerrankingeight = document.getElementById("twopair");
    const pokerrankingnine = document.getElementById("onepair");
    const pokerrankingten = document.getElementById("aceking");
    const insurencepays = document.getElementById("insurencepays");
    const Russianbgwindowsimg = document.getElementById("Russianbgwindows");
    const Russiantblewindows = document.getElementById("rsntblewindows");
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    const drawbutton = document.getElementById("drawbtn");
    const buybutton = document.getElementById("buybtn");
    const drwtext = document.getElementById("drawtext");
    const buybtntext = document.getElementById("buytext");
    const betbtntext = document.getElementById("betbtntext");
    const foldbtntext = document.getElementById("foldtext");
    const dealerBuyCardElement = document.getElementById("dealerBuyCard");
    const dealerChipsAddToBuyElement = document.getElementById("dealerChipsAddToBuyButton");

    const bonusInfoTheadElement = document.getElementById('bonusInfoThead');
    const bonusInfoTbodyElement = document.getElementById("bonusInfoTbody");

    const winpopuprspelement = document.getElementById("winpopup_rsp");
    const playerDetailsElement = document.getElementById("playerDetails");
    const containerPlayerdealerElement = document.getElementById("container_playerdealer");
    const chipsstoredcontainerElement = document.getElementById('chipsstoredcontainer');
    const minmaxbetsElement = document.getElementById("minmaxbets")

    const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnce");
    const cardDeckDivElment = document.getElementById("russianPokerDeck")



    containerPlayerdealerElement.className = "";
    playerDetailsElement.className = "";
    minmaxbetsElement.className = "";



    bacaratbgim.style.cssText = "";

    optionrussianbtn.src = "";
    optionrussianbtn.style.cssText = "";


    bonusRussiangme.src = "";
    bonusRussiangme.style.cssText = "";


    betcirclerussian.src = "";
    betcirclerussian.style.cssText = "";

    russianbncicon.src = "";
    russianbncicon.style.cssText = "";

    beticonrussian.src = "";
    beticonrussian.style.cssText = "";

    exitbtn.src = "";
    exitbtn.style.cssText = "";


    winnericonrussian.src = "";
    winnericonrussian.style.cssText = "";

    informi.src = "";
    informi.style.cssText = "";


    volumeRussianimg.src = "";
    volumeRussianimg.style.cssText = "";

    betchipincrementbtn.src = "";
    betchipincrementbtn.style.cssText = "";

    // betchipsRussianpoker.src = "";
    // betchipsRussianpoker.style.cssText="";

    betchipdecrementbtn.src = "";
    betchipdecrementbtn.style.cssText = "";


    useriddetl.src = "";
    useriddetl.style.cssText = "";
    // betactiveElement.className = ""

    dealerBuyCardElement.className = "";
    dealerChipsAddToBuyElement.className = "";
    winpopuprspelement.className = "";
    if (chipsstoredcontainerElement !== null) {
      chipsstoredcontainerElement.className = "";
    }


    if (bonusInfoTheadElement && bonusInfoTbodyElement) {
      bonusInfoTheadElement.className = "";
      bonusInfoTbodyElement.className = "";

    }





    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      this.setState({
        heightOfLayer: 1105,
        // widthOfLayer: 950,
        widthOfLayer: 2200,
        // dealerDistanceFromX: 250,
        // playerDistanceFromX: 1020,    
        dealerDistanceFromX: 590,
        playerDistanceFromX: 580,
        dealerDistanceFromY: -195,
        PlayerDistanceFromY: 450,
        isMobilePortraitLndsp: false,
        scaleX: 1.8,
        scaleY: 1.8,
        activeScreen: 0
      });

      this.isMobile = false;
      this.systemOS = "Windows";
      document.getElementById("topcardscontainer").classList.remove("topcards");
      document.getElementById("betcontainer").classList.remove("betpannelrussian");
      document.getElementById("betcontainer").classList.add("betpannelrussianwindows");
      playerDetailsElement.classList.add("playerIdDetailswindows");
      document.getElementById("infocontainer").style.display = "block"
      document.getElementById("landscapeInfo").style.display = "none";

      containerPlayerdealerElement.classList.add("container_playerdealer");


      if (russianlabel) {
        russianlabel.src = russianlabelimg;
        russianlabel.style.cssText = "";

      }


      // if (russiantiname) {
      //   russiantiname.src = russiantitle;
      //   russiantiname.style.cssText = "";

      // }
      russiandeck.src = Russiandeck
      Object.assign(russiandeck.style, porttraitejson.russiandeck)




      drwtext.classList.remove("drawtextportraite");
      buybtntext.classList.remove("buytextportraite");
      betbtntext.classList.remove("betportraite");
      foldbtntext.classList.remove("foldtextportraite");

      drwtext.classList.add("drawtextwindows");
      buybtntext.classList.add("buytextwindows");
      betbtntext.classList.add("betwindows");
      foldbtntext.classList.add("foldtextwindows");
      document.getElementById("antetext").classList.remove("antetextlnspe,antetextportraite");
      // document.getElementById("antetext").classList.remove("antetextportraite");
      document.getElementById("antetext").classList.add("antetextwindows");
      drwtext.classList.remove("drawtextlandspe");
      buybtntext.classList.remove("buytextlndspe");
      betbtntext.classList.remove("betLandscape");
      foldbtntext.classList.remove("foldtextLandscape");

      Russianbgwindowsimg.style.background = `url(${Russianbgwindows})`;
      //  alert(".............."+ Russianbgwindowsimg.style.background)

      Object.assign(Russianbgwindowsimg.style, porttraitejson.russianbgwindows)



      // Russiantblewindows.src = Rutablewindows;
      // Object.assign(Russiantblewindows.style, porttraitejson.russtablewindows)


      optionrussianbtn.src = optionbtnwindows;
      Object.assign(optionrussianbtn.style, porttraitejson.optionbtnwindows)

      russianbncicon.src = balanceiconwindows;
      Object.assign(russianbncicon.style, porttraitejson.balanceiconwindows)

      document.getElementById("balance").classList.remove("balanceportraite,balancelndscape");
      document.getElementById("balance").classList.add("balancewindows");

      useriddetl.src = useridlmg;
      Object.assign(useriddetl.style, porttraitejson.useridwindows)



      document.getElementById("useridtext").classList.remove("useridportraite,useridlandscape");
      document.getElementById("useridtext").classList.add("useridwindows");

      beticonrussian.src = beticonwindows;

      Object.assign(beticonrussian.style, porttraitejson.beticonwindows);
      document.getElementById("bets").classList.remove("betslandspe,betsportraite");
      document.getElementById("bets").classList.add("betswindows");



      // if (russiantiname) {
      //   russiantiname.src = russiantitle;
      //   Object.assign(russiantiname.style, porttraitejson.russiantitlewindows);

      // }

      if (russianlabel) {
        russianlabel.src = russianlabelimg;
        Object.assign(russiantiname.style, porttraitejson.russiantitlewindows);

      }

      winnericonrussian.src = winnericnwindows;
      Object.assign(winnericonrussian.style, porttraitejson.winnericonwindows);

      document.getElementById("wins").classList.remove("winslandspe,winsportraite");
      document.getElementById("wins").classList.add("winswindows");

      // informi.src = informicon;
      // Object.assign(informi.style, porttraitejson.infrmicnlnspewindows);

      volumeRussianimg.src = volumeimg;
      Object.assign(volumeRussianimg.style, porttraitejson.volumewindows);


      betchipincrementbtn.src = betincerementdecrement;
      Object.assign(betchipincrementbtn.style, porttraitejson.beincrementwindows);


      betchipdecrementbtn.src = betincerementdecrement;
      Object.assign(betchipdecrementbtn.style, porttraitejson.bedecrementwindows);

      betcirclerussian.src = russianbetcle;
      Object.assign(betcirclerussian.style, porttraitejson.becirclewindows);


      bonusRussiangme.src = russianbetbtright;
      Object.assign(bonusRussiangme.style, porttraitejson.bonusbtnwindows);

      document.getElementById("bonustext").classList.remove("bonustextportraite,bonustextlndspe");
      document.getElementById("bonustext").classList.add("bonustext");

      insurenceRussiangme.src = russianbetbtnup;
      Object.assign(insurenceRussiangme.style, porttraitejson.insurencebtnwindows);
      document.getElementById("insurencetext").classList.remove("insurencetextportraite,insurencetextlndspe");
      document.getElementById("insurencetext").classList.add("insurencetext");

      buydrawRussiangme.src = russianbetbtnleft;
      Object.assign(buydrawRussiangme.style, porttraitejson.drawbuybtnwindows);

      document.getElementById("buydrawtext").classList.add("buydrawtext");

      betRussiangme.src = russianbetbtndown;
      Object.assign(betRussiangme.style, porttraitejson.betbtnwindows);

      document.getElementById("bettext").classList.remove("bettextlndspe,bettextportraite");
      document.getElementById("bettext").classList.add("bettext");

      pokerrankingone.classList.remove("royalflshportraite");
      document.getElementById("royalto");

      pokerrankingtwo.classList.remove("strightportraite");
      document.getElementById("strghtto").classList.remove("royalflshportraiteto");

      pokerrankingthree.classList.remove("fourofkindportraite");
      document.getElementById("frkndto").classList.remove("royalflshportraiteto");

      pokerrankingfour.classList.remove("fullhouseportraite");
      document.getElementById("fullhseto").classList.remove("royalflshportraiteto");

      pokerrankingfive.classList.remove("flushportraite");
      document
        .getElementById("flushto")
        .classList.remove("royalflshportraiteto");

      pokerrankingsix.classList.remove("stright");
      document
        .getElementById("strightto")
        .classList.remove("royalflshportraiteto");

      pokerrankingseven.classList.remove("threekindportraite");
      document.getElementById("Threekindto").classList.remove("royaltocolor");

      pokerrankingeight.classList.remove("twopairportraite");
      document.getElementById("twopairto").classList.remove("royaltocolor");

      pokerrankingnine.classList.remove("onepairportraite");
      document.getElementById("onepairto").classList.remove("royaltocolor");

      pokerrankingten.classList.remove("acekingportraite");
      document.getElementById("acekingto").classList.remove("royaltocolor");

      insurencepays.classList.remove("insurencepaysportraite");
      document
        .getElementById("insurencepaysto")
        .classList.remove("insurenceratio");

      pokerrankingone.classList.remove("royalflshlndspe");
      document.getElementById("royalto").classList.remove("royaltocolor");

      pokerrankingtwo.classList.remove("strightflushlndspe");
      document.getElementById("strghtto").classList.remove("royaltocolor");

      pokerrankingthree.classList.remove("fourofkindlndspe");
      document.getElementById("frkndto").classList.remove("royaltocolor");

      pokerrankingfour.classList.remove("fullhouselndspe");
      document.getElementById("fullhseto").classList.remove("royaltocolor");

      pokerrankingfive.classList.remove("flushlndspe");
      document.getElementById("flushto").classList.remove("royaltocolor");

      pokerrankingsix.classList.remove("strightlndspe");
      document.getElementById("strightto").classList.remove("royaltocolor");

      pokerrankingseven.classList.remove("threekindlndspe");
      document.getElementById("Threekindto").classList.remove("royaltocolor");

      pokerrankingeight.classList.remove("twopairlndspe");
      document.getElementById("twopairto").classList.remove("royaltocolor");

      pokerrankingnine.classList.remove("onepairlndspe");
      document.getElementById("onepairto").classList.remove("royaltocolor");

      pokerrankingten.classList.remove("acekinglndspe");
      document.getElementById("acekingto").classList.remove("royaltocolor");

      insurencepays.classList.remove("insurencepayslndspe");
      document
        .getElementById("insurencepaysto")
        .classList.remove("insurenceratio");

      pokerrankingone.classList.add("royalflshwindows");
      document.getElementById("royalto").classList.add("royaltocolor");

      pokerrankingtwo.classList.add("strightflushwindows");
      document.getElementById("strghtto").classList.add("royaltocolor");

      pokerrankingthree.classList.add("fourofkindwindows");
      document.getElementById("frkndto").classList.add("royaltocolor");

      pokerrankingfour.classList.add("fullhouse");
      document.getElementById("fullhseto").classList.add("royaltocolor");

      pokerrankingfive.classList.add("flush");
      document.getElementById("flushto").classList.add("royaltocolor");

      pokerrankingsix.classList.add("strightwindows");
      document.getElementById("strightto").classList.add("royaltocolor");

      pokerrankingseven.classList.add("threekind");
      document.getElementById("Threekindto").classList.add("royaltocolor");

      pokerrankingeight.classList.add("twopair");
      document.getElementById("twopairto").classList.add("royaltocolor");

      pokerrankingnine.classList.add("onepair");
      document.getElementById("onepairto").classList.add("royaltocolor");

      pokerrankingten.classList.add("aceking");
      document.getElementById("acekingto").classList.add("royaltocolor");

      insurencepays.classList.add("insurencepays");
      document
        .getElementById("insurencepaysto").classList.add("insurenceratio");
      document.getElementById("playerDealerCardContainer").classList.remove("stage-top-mobile-portrait-rp");
    } else {
      russiandeck.src = Russiandeck
      cardDeckDivElment.className = ""
      document.getElementById("inforussian").className = "";
      document.getElementById("inforussianland").className = "";
      pokerrankingone.classList.remove("royalflshwindows");
      document.getElementById("royalto").classList.remove("royaltocolor");

      pokerrankingtwo.classList.remove("strightflushwindows");
      document.getElementById("strghtto").classList.remove("royaltocolor");

      pokerrankingthree.classList.remove("fourofkindwindows");
      document.getElementById("frkndto").classList.remove("royaltocolor");

      pokerrankingfour.classList.remove("fullhouse");
      document.getElementById("fullhseto").classList.remove("royaltocolor");

      pokerrankingfive.classList.remove("flush");
      document.getElementById("flushto").classList.remove("royaltocolor");

      pokerrankingsix.classList.remove("strightwindows");
      document.getElementById("strightto").classList.remove("royaltocolor");

      pokerrankingseven.classList.remove("threekind");
      document.getElementById("Threekindto").classList.remove("royaltocolor");

      pokerrankingeight.classList.remove("twopair");
      document.getElementById("twopairto").classList.remove("royaltocolor");

      pokerrankingnine.classList.remove("onepair");
      document.getElementById("onepairto").classList.remove("royaltocolor");

      pokerrankingten.classList.remove("aceking");
      document.getElementById("acekingto").classList.remove("royaltocolor");

      insurencepays.classList.remove("insurencepays");
      document.getElementById("insurencepaysto").classList.remove("insurenceratio");

      pokerrankingone.classList.remove("royalflshlndspe");
      document.getElementById("royalto").classList.remove("royaltocolor");

      pokerrankingtwo.classList.remove("strightflushlndspe");
      document.getElementById("strghtto").classList.remove("royaltocolor");

      pokerrankingthree.classList.remove("fourofkindlndspe");
      document.getElementById("frkndto").classList.remove("royaltocolor");

      pokerrankingfour.classList.remove("fullhouselndspe");
      document.getElementById("fullhseto").classList.remove("royaltocolor");

      pokerrankingfive.classList.remove("flushlndspe");
      document.getElementById("flushto").classList.remove("royaltocolor");

      pokerrankingsix.classList.remove("strightlndspe");
      document.getElementById("strightto").classList.remove("royaltocolor");

      pokerrankingseven.classList.remove("threekindlndspe");
      document.getElementById("Threekindto").classList.remove("royaltocolor");

      pokerrankingeight.classList.remove("twopairlndspe");
      document.getElementById("twopairto").classList.remove("royaltocolor");

      pokerrankingnine.classList.remove("onepairlndspe");
      document.getElementById("onepairto").classList.remove("royaltocolor");

      pokerrankingten.classList.remove("acekinglndspe");
      document.getElementById("acekingto").classList.remove("royaltocolor");

      insurencepays.classList.remove("insurencepayslndspe");
      document.getElementById("insurencepaysto").classList.remove("insurenceratio");
      document.getElementById("crdscover").className = "";
      document.getElementById("crdscover").cssText = "";

      pokerrankingone.className = "";
      pokerrankingtwo.className = "";
      pokerrankingthree.className = "";
      pokerrankingfour.className = "";
      pokerrankingfive.className = "";
      pokerrankingsix.className = "";
      pokerrankingseven.className = "";
      pokerrankingeight.className = "";
      pokerrankingnine.className = "";
      pokerrankingten.className = "";
      insurencepays.className = "";
      popupbetGreaterthanBalnceElement.className = "";

      document.getElementById("royalto").classList.add("royalflshportraiteto");
      document.getElementById("strghtto").classList.add("royalflshportraiteto");
      document.getElementById("frkndto").classList.add("royalflshportraiteto");
      document.getElementById("fullhseto").classList.add("royalflshportraiteto");
      document.getElementById("flushto").classList.add("royalflshportraiteto");
      document.getElementById("strightto").classList.add("royalflshportraiteto");
      document.getElementById("Threekindto").classList.add("royaltocolor");
      document.getElementById("twopairto").classList.add("royaltocolor");
      document.getElementById("onepairto").classList.add("royaltocolor");
      document.getElementById("acekingto").classList.add("royaltocolor");
      document.getElementById("insurencepaysto").classList.add("insurenceratio");

      // console.log(isPortrait);
      document.getElementById("playerDealerCardContainer").classList.remove("stage-top-mobile-portrait-rp");
      document.getElementById("playerDealerCardContainer").classList.remove("stage-top-mobile-landscape-rp");
      let betcontainerElement = document.getElementById("betcontainer");

      Russianbgwindowsimg.style.cssText = "";



      useriddetl.src = "";
      useriddetl.style.cssText = "";


      // Russiantblewindows.src = "";
      // Russiantblewindows.style.cssText = "";

      bacaratbgim.style.cssText = "";


      optionrussianbtn.src = "";
      optionrussianbtn.style.cssText = "";


      russianbncicon.src = "";
      russianbncicon.style.cssText = "";

      beticonrussian.src = "";
      beticonrussian.style.cssText = "";


      exitbtn.src = "";
      exitbtn.style.cssText = "";

      russiantiname.className = "";
      if (russiantiname) {
        russiantiname.src = russiantitle;
        russiantiname.style.cssText = "";

      }


      winnericonrussian.src = "";
      winnericonrussian.style.cssText = "";


      informi.src = "";
      informi.style.cssText = "";




      volumeRussianimg.src = "";
      volumeRussianimg.style.cssText = "";




      betchipsRussianpoker.src = "";
      betchipsRussianpoker.style.cssText = "";




      betchipincrementbtn.src = "";
      betchipincrementbtn.style.cssText = "";



      betchipdecrementbtn.src = "";
      betchipdecrementbtn.style.cssText = "";




      let chipsElementText = document.getElementById("val0");
      betcontainerElement.style.cssText = "";
      chipsElementText.className = "";
      let chipsElmtextclass = "";

      const betTextaddtoBetElement = document.getElementById("betTextaddtoBet");


      if (this.playerWinBonus === true) {
        if (isPortrait) {
          if (this.state.bonusbetamount) {
            if (document.getElementById("chip10centerbns")) {
              gsap.to("#chip10centerbns", {
                x: -630, y: 925, duration: 0.00001, opacity: 1

              });
            }

          }

        } else {

          if (this.state.bonusbetamount) {
            if (document.getElementById('chip10centerbns')) {

              gsap.to("#chip10centerbns", {
                x: 550, y: -130, duration: 0.00001, autoAlpha: 1,
              });

            }

          }

        }
      } else {
        this.playerWinBonus = false
      }



      if (this.betchipactivated === true) {
        if (isPortrait) {
          gsap.to(this.chipbetref.current, {
            x: -27,
            y: -925,
            duration: 0.000001
          });


          gsap.to(betTextaddtoBetElement, {
            x: -3,
            y: -1710,
            duration: 0.000001
          });

        } else {
          gsap.to(this.chipbetref.current, {
            x: -1060,
            y: 170,
            duration: 0.000001
          });


          gsap.to(betTextaddtoBetElement, {
            x: -1060,
            y: 170,
            duration: 0.000001

          });


        }

      } else {
        this.betchipactivated = false;
      }


      if (this.buydrawchipactivated === true) {
        if (isPortrait) {
          gsap.to(this.chipdrawref.current, {
            x: 322,
            y: -1230,
            duration: 0.000001,
          });
        } else {
          gsap.to(this.chipdrawref.current, {
            x: -1230,
            y: 0,
            duration: 0.000001,
          });
        }
      } else {
        this.buydrawchipactivated = false
      }

      const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popup");
      if (this.ConfirmbuydealercardactivePopup === true) {
        confirmbuydealerPopupElement.display = "block"
        confirmbuydealerPopupElement.className = "";
        if (isPortrait) {
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardPortrait");
          // confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardLndsp");
        } else {
          // confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardPortrait");
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardLndsp");
        }

      } else {
        this.ConfirmbuydealercardactivePopup = false;
      }


      switch (this.state.count) {
        case 1:
          betchipsRussianpoker.src = chip_10;
          chipsElementText.textContent = this.chipVal[0].label;
          chipsElmtextclass = "valtextPortrait1";

          break;
        case 2:
          betchipsRussianpoker.src = chip_25;
          chipsElementText.textContent = this.chipVal[1].label;
          chipsElmtextclass = "bet-text-addtoBet20";
          break;
        case 3:
          betchipsRussianpoker.src = chip_50;
          chipsElementText.textContent = this.chipVal[2].label;
          chipsElmtextclass = "bet-text-addtoBet20";
          break;
        case 4:
          betchipsRussianpoker.src = chip_100;
          chipsElementText.textContent = this.chipVal[3].label;
          chipsElmtextclass = "bet-text-addtoBet20";
          break;
        case 5:
          betchipsRussianpoker.src = chip_500;
          chipsElementText.textContent = this.chipVal[4].label;
          chipsElmtextclass = "bet-text-addtoBet20";
          break;
        case 6:
          betchipsRussianpoker.src = chip_1000;
          chipsElementText.textContent = this.chipVal[5].label;
          chipsElmtextclass = "bet-text-addtoBet20";
          break;

        default:
          break;

      }


      const rankinginfodelerElement = document.getElementById("rankinginfodeler");
      const rankinginfoElement = document.getElementById("rankinginfo");
      rankinginfodelerElement.className = "";
      rankinginfoElement.className = "";
      rankinginfoElement.style.cssText = "";


      if (bonusInfoTheadElement && bonusInfoTbodyElement) {
        Object.assign(bonusInfoTheadElement.style, porttraitejson.bonusTheadPortrait);
        Object.assign(bonusInfoTbodyElement.style, porttraitejson.bonusInfoTbodyPortrait);
      }
      betbtntext.className = "";
      foldbtntext.className = "";



      winpopuprspelement.classList.add("winPopupForMptIntl");
      if (isPortrait) {
        document.getElementById("infocontainer").style.display = "block"
        document.getElementById("landscapeInfo").style.display = "none";
        if (this.state.betshigh_balnce !== null) {
          popupbetGreaterthanBalnceElement.classList.add("popupbetshighPortrait")
        }

        document.getElementById("inforussian").classList.add("portraitinfo");
        minmaxbetsElement.classList.add("minmaxbets_portrait");

        drwtext.classList.remove("drawtextlandspe");
        buybtntext.classList.remove("buytextlndspe");


        drwtext.classList.remove("drawtextwindows");
        buybtntext.classList.remove("buytextwindows");




        // document.getElementById("antetext").classList.remove("antetextwindows");
        document.getElementById("antetext").classList.remove("antetextlnspe,antetextwindows");


        containerPlayerdealerElement.classList.add("cntr_pyrdlr_prt");
        document.getElementById("Player").style.fontSize = "80px"
        // pokertablelnspe.src = "";
        // pokertablelnspe.style.cssText = "";

        playerDetailsElement.classList.add("playerIdDetailsPortrait");
        document.getElementById("playerDealerCardContainer").classList.add("stage-top-mobile-portrait-rp");
        document.getElementById("topcardscontainer").classList.add("topcards");
        pokerrankingone.classList.add("royalflshportraite");
        pokerrankingtwo.classList.add("strightportraite");
        pokerrankingthree.classList.add("fourofkindportraite");
        pokerrankingfour.classList.add("fullhouseportraite");
        pokerrankingfive.classList.add("flushportraite");
        pokerrankingsix.classList.add("stright");
        pokerrankingseven.classList.add("threekindportraite");
        pokerrankingeight.classList.add("twopairportraite");
        pokerrankingnine.classList.add("onepairportraite");
        pokerrankingten.classList.add("acekingportraite");
        insurencepays.classList.add("insurencepaysportraite");
        Object.assign(betcontainerElement.style, porttraitejson.betpannelrussianPortrait);

        drwtext.classList.add("drawtextportraite");
        buybtntext.classList.add("buytextportraite");
        betbtntext.classList.add("betportraite");
        foldbtntext.classList.add("foldtextportraite");
        document.getElementById("antetext").className = "";
        document.getElementById("antetext").classList.add("antetextportraite");
        if (document.getElementById("val0") && chipsElmtextclass !== "") {
          document.getElementById("val0").classList.add("valtextPortrait", chipsElmtextclass);
        } else {
          document.getElementById("val0").classList.add("valtextPortrait");
        }

        bacaratbgim.style.background = `url(${russianbg})`;
        bacaratbgim.style.backgroundSize = "cover";
        bacaratbgim.style.width = porttraitejson.bacaratbg.width;
        bacaratbgim.style.height = porttraitejson.bacaratbg.height;
        bacaratbgim.style.backgroundSize = porttraitejson.bacaratbg.background;
        bacaratbgim.style.left = 0;




        // betchipsRussianpoker.src = betchipsRussian;
        Object.assign(betchipsRussianpoker.style, porttraitejson.betchp);


        betchipincrementbtn.src = betincerementdecrement;
        Object.assign(betchipincrementbtn.style, porttraitejson.beincrementportraite);



        betchipdecrementbtn.src = betincerementdecrement;
        Object.assign(betchipdecrementbtn.style, porttraitejson.bedecrement)


        volumeRussianimg.src = volumeimg;
        Object.assign(volumeRussianimg.style, porttraitejson.volume)

        bonusRussiangme.src = russianbetbtright;

        Object.assign(bonusRussiangme.style, porttraitejson.bonusbtn);


        document.getElementById("bonustext").className = "";
        document.getElementById("bonustext").classList.add("bonustextportraite");

        insurenceRussiangme.src = russianbetbtnup;
        Object.assign(insurenceRussiangme.style, porttraitejson.insurencebtn);

        document.getElementById("insurencetext").className = "";
        document.getElementById("insurencetext").classList.add("insurencetextportraite");

        buydrawRussiangme.src = russianbetbtnleft;
        buydrawRussiangme.style.position = porttraitejson.drawbuybtn.position;
        buydrawRussiangme.style.width = porttraitejson.drawbuybtn.width;
        buydrawRussiangme.style.height = porttraitejson.drawbuybtn.height;
        buydrawRussiangme.style.top = porttraitejson.drawbuybtn.top;
        buydrawRussiangme.style.left = porttraitejson.drawbuybtn.left;
        document.getElementById("buydrawtext").className = "";

        document.getElementById("buydrawtext").classList.add("buydrawtextportraite");

        betRussiangme.src = russianbetbtndown;

        Object.assign(betRussiangme.style, porttraitejson.betbtn);


        document.getElementById("bettext").className = "";

        document.getElementById("bettext").classList.add("bettextportraite");

        betcirclerussian.src = russianbetcle;
        Object.assign(betcirclerussian.style, porttraitejson.becircle);

        // optionrussianbtn.style.display = "none";
        optionrussianbtn.src = optionbtn;
        Object.assign(optionrussianbtn.style, porttraitejson.optionbtn);

        dealerBuyCardElement.classList.add('dealerBuyCard');
        dealerChipsAddToBuyElement.classList.add('dealerBuyCardDrawButtonPortrait');


        if (pokertable) {
          // pokertable.src = pokertableruss;
          // pokertable.style.backgroundSize = "cover";

          // Object.assign(pokertable.style, porttraitejson.pokertable);


        }

        // russianbncicon.src = balanceicon;
        russianbncicon.src = balanceiconwindows;
        Object.assign(russianbncicon.style, porttraitejson.balanceiconportraite);


        document.getElementById("balance").className = "";
        document.getElementById("balance").classList.add("balanceportraite");
        // document.getElementById("balance").classList.remove("balancelndscape");

        // beticonrussian.src = beticon;
        beticonrussian.src = beticonwindows;

        Object.assign(beticonrussian.style, porttraitejson.beticon);


        document.getElementById("bets").className = "";
        // document.getElementById("bets").classList.remove("betslandspe");
        document.getElementById("bets").classList.add("betsportraite");

        // exitbtn.src = exitrussian;
        Object.assign(exitbtn.style, porttraitejson.exit);



        if (russiantiname) {
          russiantiname.src = russiantitle;

          Object.assign(russiantiname.style, porttraitejson.russiantitle);


        }

        winnericonrussian.src = winnericn;
        Object.assign(winnericonrussian.style, porttraitejson.winnericon);



        document.getElementById("wins").className = "";
        document.getElementById("wins").classList.add("winsportraite");

        informi.src = informicon;
        Object.assign(informi.style, porttraitejson.infrmicn);


        useriddetl.src = useridlmg;
        Object.assign(useriddetl.style, porttraitejson.useridportraite);

        document.getElementById("useridtext").className = "";
        document.getElementById("useridtext").classList.add("useridportraite");










        if (this.state.pokerrankingdeler !== "") {
          document.getElementById('rankinginfodeler').className = "";
          rankinginfodelerElement.classList.add("pokerrankingdelerPortrait")
        } else {
          document.getElementById('rankinginfodeler').className = "";

        }

        if (this.state.pokerranking !== "") {
          rankinginfoElement.classList.add("pokerrankingPortait")
        } else {
          document.getElementById('rankinginfo').className = "";
        }

        // console.log(this.props.gameState,
        // this.props.gameState?.response?.action === "BUY_PLAYER_CARD",
        // this.props.gameState?.response?.playerFinalCards.length === 6,
        // this.buyCardUnsequenceArray.length);

        if (((this.props.gameState?.response?.action === "BUY_PLAYER_CARD" ||
          this.props.gameState?.response?.playerFinalCards.length === 6) &&
          this.buyCardUnsequenceArray.length === 6) || this.buyCardUnsequenceArray.length === 6
        ) {
          rankinginfoElement.style.left = "33%";
        } else {
          if (this.state.isMobilePortraitLndsp) {
            rankinginfoElement.style.left = "35%";
          }

        }

        cardDeckDivElment.classList.add("cardDeckPortrait")
        this.setState({

          heightOfLayer: 1500,
          widthOfLayer: 600,
          // widthOfLayer: 550,
          dealerDistanceFromX: -280,
          playerDistanceFromX: -150,


          // heightOfLayer: 1600,
          // widthOfLayer: 900,
          // dealerDistanceFromX: -130,
          // playerDistanceFromX: 2,
          // dealerDistanceFromY: -20,
          // PlayerDistanceFromY: 1030,
          dealerDistanceFromY: -90,
          PlayerDistanceFromY: 940,
          isMobilePortraitLndsp: true,
          scaleX: 2.8,
          scaleY: 2.8,
          activeScreen: 1,


          bnsactvglwcss: "bnsactivePortrait",
          betactvglwcss: "betactivePortrait",
          betbuttoncss: porttraitejson.Betbtnportraite,
          betbtntextcss: "betportrait",
          foldbuttoncss: porttraitejson.foldbtnportraite,
          foldbtntextcss: "rebettextportrait",
          buybtncss: porttraitejson.Buybtnportraite,
          buybtntxtcss: "buytextPortrait",
          drawbtncss: porttraitejson.Drawbtnportraite,
          drawbtntxtcss: "drawtextPortrait",
          betchipstoantebetcss: porttraitejson.Betchiptoanteprotrait,
          betchipstobonusbetcss: porttraitejson.betchipstobnsPortrait,
          chipsstoredcontainercss: "",
          antebetpdvcss: "chip-center-position",
          antechpimgcss: "chip20centerpositionPortrait",
          antespntxtcss: "antebetChipPortrait20",
          bonusdivcss: "bonusChipsContainerForPortrait",
          bonusimgcss: "bonusChipsForPortrait",
          bonuspntxtcss: "bonusChipsTextForPortrait",
          betchiptobetbtncss: porttraitejson.BetchiptobetPortrait,
          // betbtntxtcss:"bet-text-addtoBet-portrait",
          betbtntxtcss: `bet-text-addtoBet-portrait ${chipsElmtextclass}`,
          buydrawcontainer: "drawChipDiv",
          drawchipcss: "betchipsaddtodraw",
          // drawchiptextcss:"betTextaddtodraw" ,
          drawchiptextcss: `betTextaddtodraw ${chipsElmtextclass}`,
          insurancedivcss: "",
          insurancechipcss: "chiptoinsurencePortrait",
          insurancetxtcss: "insuranceTextPortrait",
          dealerhandcss: "pokerrankingdelerPortrait",
          playerhandcss: "pokerrankingPortait",
          mobileAntiMaskCovercss: "mobileAntiMaskCover",
          betpannelrussiancovercss: "PlayerCardpannelcoverPortrait",
          insurenceactivecss: "insurenceactivePortrait",
          rankinginfodelercss: "pokerrankingdelerPortrait",
          inforussiancss: "portraitinfo",
          betChipstoInsurance: porttraitejson.betchipstobnsPortrait,





        })

      } else {
        document.getElementById("playerDealerCardContainer").classList.add("stage-top-mobile-landscape-rp");
        document.getElementById("landscapeInfo").style.display = "block";
        document.getElementById("infocontainer").style.display = "none"
        this.setState({

          // heightOfLayer: 800,
          // widthOfLayer: 1300,
          // dealerDistanceFromX: -385,
          // playerDistanceFromX: 615,
          // dealerDistanceFromY: 200,
          // PlayerDistanceFromY: 220,

          heightOfLayer: 1050,
          widthOfLayer: 530,
          // widthOfLayer: 1000,
          dealerDistanceFromX: -300,
          playerDistanceFromX: -150,
          // dealerDistanceFromX: -270,
          // playerDistanceFromX: -130,
          dealerDistanceFromY: -330,
          // dealerDistanceFromY: -200,
          PlayerDistanceFromY: 480,
          isMobilePortraitLndsp: true,
          scaleX: 2.8,
          scaleY: 2.8,
          activeScreen: 1,

          bnsactvglwcss: "bnsactiveLandscape",
          betactvglwcss: "betactiveLndsp",
          betbuttoncss: porttraitejson.Betbtnlndspe,
          betbtntextcss: "betLandscape",
          foldbuttoncss: porttraitejson.foldbtnlndspe,
          foldbtntextcss: "foldtextLandscape",
          buybtncss: porttraitejson.Buybtnlndspe,
          buybtntxtcss: "buytextLndsp",
          drawbtncss: porttraitejson.Drawbtnlndspe,
          drawbtntxtcss: "drawtextLndsp",
          betchipstoantebetcss: porttraitejson.betchiplndspe,
          betchipstobonusbetcss: porttraitejson.betchipstobnsLndsp,
          chipsstoredcontainercss: "chipsstoredcontainerLndsp",
          antebetpdvcss: "chip-center-position-lndsp",
          antechpimgcss: "chip20centerpositionPortrait",
          antespntxtcss: "antebetChipPortrait20",
          bonusdivcss: "bonusChipsCntrFrLndsp",
          bonusimgcss: "bonusChipsForPortrait",
          bonuspntxtcss: "bonusChipsTextfrLndsp",
          betchiptobetbtncss: porttraitejson.BetchiptobetLndsp,
          // betbtntxtcss:"bet-text-addtoBet-lndsp",
          betbtntxtcss: `bet-text-addtoBet-lndsp ${chipsElmtextclass}`,
          buydrawcontainer: "drawChipDivLndsp",
          drawchipcss: "betchipsaddtodraw",
          // drawchiptextcss:"betTextaddwLndsp",
          drawchiptextcss: `betTextaddwLndsp ${chipsElmtextclass}`,
          insurancedivcss: "chip-to-insurence-lndsp",
          insurancechipcss: "chiptoinsurenceLndSpe",
          insurancetxtcss: "insuranceTextLndspe",
          dealerhandcss: "pokerrankingdelerLndSpe",
          playerhandcss: "pokerrankingPlayerLndsp",
          mobileAntiMaskCovercss: "mobileLndspAntiMaskCover",
          betpannelrussiancovercss: "PlayerCardpannelcoverLndsp",
          insurenceactivecss: "insurenceactiveLndsp",
          rankinginfodelercss: "pokerrankingdelerLndSpe",
          inforussiancss: "lndspinfo",
          betChipstoInsurance: porttraitejson.betchipstobnsLndsp,




        });
        cardDeckDivElment.classList.add("cardDeckLandscape")
        if (this.state.pokerrankingdeler !== "") {
          document.getElementById('rankinginfodeler').className = "";
          rankinginfodelerElement.classList.add("pokerrankingdelerLndSpe")
        } else {
          document.getElementById('rankinginfodeler').className = "";
        }


        if (this.state.pokerranking !== "") {
          document.getElementById('rankinginfo').className = "";
          rankinginfoElement.classList.add("pokerrankingPlayerLndsp")
        } else {
          document.getElementById('rankinginfo').className = "";
        }

        if (((this.props.gameState?.response?.action === "BUY_PLAYER_CARD" ||
          this.props.gameState?.response?.playerFinalCards.length === 6) &&
          this.buyCardUnsequenceArray.length === 6) || this.buyCardUnsequenceArray.length === 6
        ) {
          // rankinginfoElement.style.left = "61.5%";
          rankinginfoElement.style.left = "42.5%";
        } else {
          if (this.state.isMobilePortraitLndsp) {
            // rankinginfoElement.style.left = "62.5%";
            rankinginfoElement.style.left = "43.5%";
          }

        }
        if (this.state.betshigh_balnce !== null) {
          popupbetGreaterthanBalnceElement.classList.add("popupbetshighLndsp")
        }
        document.getElementById("inforussianland").classList.add("lndspinfo");
        minmaxbetsElement.classList.add("minmaxbets_lndsp");

        containerPlayerdealerElement.classList.add("cntr_pyrdlr_lnsp");
        if (chipsstoredcontainerElement) {
          chipsstoredcontainerElement.classList.add("chipsstoredcontainerLndsp");
        }
        document.getElementById("Player").style.fontSize = "80px";

        document.getElementById("inforussianland").classList.add("lndspinfo");


        document.getElementById("topcardscontainer").classList.remove("topcards");
        document.getElementById("betcontainer").classList.remove("betpannelrussian");

        if (document.getElementById("val0") && chipsElmtextclass !== "") {
          document.getElementById("val0").classList.add("valtextLandscape", chipsElmtextclass);
        } else {
          document.getElementById("val0").classList.add("valtextLandscape");
        }
        Object.assign(betcontainerElement.style, porttraitejson.betpannelrussianIandscape);

        drwtext.classList.remove("drawtextportraite");
        buybtntext.classList.remove("buytextportraite");

        drwtext.classList.remove("drawtextwindows");
        buybtntext.classList.remove("buytextwindows");
        // foldbtntext.className = "";
        // betbtntext.className = "";

        drwtext.classList.add("drawtextlandspe");
        buybtntext.classList.add("buytextlndspe");
        betbtntext.classList.add("betLandscape");
        foldbtntext.classList.add("foldtextLandscape");

        pokerrankingone.classList.add("royalflshlndspe");
        document.getElementById("royalto").classList.add("royaltocolor");

        pokerrankingtwo.classList.add("strightflushlndspe");
        document.getElementById("strghtto").classList.add("royaltocolor");

        pokerrankingthree.classList.add("fourofkindlndspe");
        document.getElementById("frkndto").classList.add("royaltocolor");

        pokerrankingfour.classList.add("fullhouselndspe");
        document.getElementById("fullhseto").classList.add("royaltocolor");

        pokerrankingfive.classList.add("flushlndspe");
        document.getElementById("flushto").classList.add("royaltocolor");

        pokerrankingsix.classList.add("strightlndspe");
        document.getElementById("strightto").classList.add("royaltocolor");

        pokerrankingseven.classList.add("threekindlndspe");
        document.getElementById("Threekindto").classList.add("royaltocolor");

        pokerrankingeight.classList.add("twopairlndspe");
        document.getElementById("twopairto").classList.add("royaltocolor");

        pokerrankingnine.classList.add("onepairlndspe");
        document.getElementById("onepairto").classList.add("royaltocolor");

        pokerrankingten.classList.add("acekinglndspe");
        document.getElementById("acekingto").classList.add("royaltocolor");

        insurencepays.classList.add("insurencepayslndspe");
        document.getElementById("insurencepaysto").classList.add("insurenceratio");

        // pokertable.src = "";
        // pokertable.style.cssText = "";



        betcirclerussian.src = "";
        betcirclerussian.style.cssText = "";



        insurenceRussiangme.src = "";
        insurenceRussiangme.style.cssText = "";


        betRussiangme.src = "";
        betRussiangme.style.cssText = "";


        buydrawRussiangme.src = "";
        buydrawRussiangme.style.cssText = "";


        bonusRussiangme.src = "";
        bonusRussiangme.style.cssText = "";





        playerDetailsElement.classList.add("playerIdDetailsLandscape");
        bacaratbgim.style.background = `url(${landscapebg})`;
        bacaratbgim.style.backgroundSize = "cover";
        bacaratbgim.style.width = porttraitejson.bacaratbg.width;
        bacaratbgim.style.height = porttraitejson.bacaratbg.height;
        bacaratbgim.style.left = 0;



        dealerBuyCardElement.classList.add('dealerBuyCardLndsp');
        dealerChipsAddToBuyElement.classList.add('dealerBuyCardDrawButtonPortrait');



        // pokertablelnspe.src = pokertablelandsp;
        // Object.assign(pokertablelnspe.style, porttraitejson.pokertableland);


        // betchipsRussianpoker.src = betchipsRussian;
        Object.assign(betchipsRussianpoker.style, porttraitejson.betchiplndspe);


        bonusRussiangme.src = russianbetbtright;
        Object.assign(bonusRussiangme.style, porttraitejson.bonusbtnlndspe);
        document.getElementById("bonustext").className = "";
        document.getElementById("bonustext").classList.add("bonustextlndspe");

        insurenceRussiangme.src = russianbetbtnup;
        Object.assign(insurenceRussiangme.style, porttraitejson.insurencebtnlndspe);
        document.getElementById("insurencetext").className = "";
        document.getElementById("insurencetext").classList.add("insurencetextlndspe");

        russiantiname.src = russiantitle;
        Object.assign(russiantiname.style, porttraitejson.russiantitlelndscape);


        buydrawRussiangme.src = russianbetbtnleft;
        Object.assign(buydrawRussiangme.style, porttraitejson.drawbuybtnlndspe);


        document.getElementById("buydrawtext").className = "";

        document.getElementById("buydrawtext").classList.add("buydrawtextlndspe");

        document.getElementById("antetext").className = "";
        document.getElementById("antetext").classList.add("antetextlnspe");

        betRussiangme.src = russianbetbtndown;
        Object.assign(betRussiangme.style, porttraitejson.betbtnlndspe);


        document.getElementById("bettext").className = "";
        document.getElementById("bettext").classList.add("bettextlndspe");

        betcirclerussian.src = russianbetcle;
        Object.assign(betcirclerussian.style, porttraitejson.becirclelndspe);


        volumeRussianimg.src = volumeimg;
        Object.assign(volumeRussianimg.style, porttraitejson.volumelnspe);


        useriddetl.src = useridlmg;
        Object.assign(useriddetl.style, porttraitejson.useridlndspe);


        document.getElementById("useridtext").className = "";
        document.getElementById("useridtext").classList.add("useridlandscape");

        // optionrussianbtn.style.display = "none";
        optionrussianbtn.src = optionbtn;
        Object.assign(optionrussianbtn.style, porttraitejson.optionbtnlandspe);

        beticonrussian.src = beticonwindows;
        // beticonrussian.src = beticon;
        Object.assign(beticonrussian.style, porttraitejson.beticonlandspe);


        document.getElementById("bets").className = "";
        document.getElementById("bets").classList.add("betslandspe");
        // beticonrussian.style.transform =porttraitejson.beticonlandspe.transform;

        // russianbncicon.src = balanceicon;
        russianbncicon.src = balanceiconwindows;
        Object.assign(russianbncicon.style, porttraitejson.balanceiconlndspe);


        document.getElementById("balance").className = "";
        document.getElementById("balance").classList.add("balancelndscape");
        // russianbncicon.style.transform = porttraitejson.balanceicon.transform;

        // exitbtn.src = exitrussian;
        Object.assign(exitbtn.style, porttraitejson.exitlanspe);


        winnericonrussian.src = winnericn;
        Object.assign(winnericonrussian.style, porttraitejson.winnericonlndspe);


        document.getElementById("wins").className = "";
        document.getElementById("wins").classList.add("winslandspe");

        informi.src = informicon;
        Object.assign(informi.style, porttraitejson.infrmicnlnspe);

        betchipincrementbtn.src = betincerementdecrement;
        Object.assign(betchipincrementbtn.style, porttraitejson.betincreamentlndspe);



        betchipdecrementbtn.src = betincerementdecrement;
        Object.assign(betchipdecrementbtn.style, porttraitejson.bedecrementlndspe);

      }

      const drawtextElement = document.getElementById("drawtext");
      if (drawtextElement) {
        drawtextElement.classList.add("drawtextopacity");
      }

      // this.setState({
      //   // mobileLandScape,
      //   resetAnteChip:{x: 0, y: 0, duration: 0.0001 ,zIndex: -2,opacity: 0}, //"#chipcenterposition"
      //   resetBetChip:{x: 0, y: 0, duration: 0.0001 ,zIndex: -2,opacity: 0},//#betchipsaddtobet"
      //   resetBetText:{x: 0, y: 0, duration: 0.0001 ,zIndex: -2,opacity: 0},//"#betTextaddtoBet"
      //   resetInsuranceChip:{x: 0, y: 0, duration: 0.0001 ,zIndex: -2,opacity: 0},//#chiptoinsurence"
      //   resetPyrDrwByChip:{x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0},//this.chipdrawref.current,
      //   resetDlrBycardChip:{x: 0, y: 0,duration:0.0001,zIndex: -2,opacity: 0 },// this.buyDealerCardDrawn.current,
      //   resetDlrLoseChip:{x:0,y:0, opacity: 0, zIndex: -10 ,duration:0.00001},//"#dealerchip",
      //   resetwinpopuprspelement:{autoAlpha: 0, scale: 0, duration: 0.00001}// const winpopuprspelement = document.getElementById("winpopup_rsp");  this.resetPyrWnCpsAmn = true;
      // })


      // this.setState({
      //   // mobilePortrait
      //   resetAnteChip:{x: 13, y: 1200, duration: 0.00001, zIndex: -2, opacity: 0},
      //   resetBetChip:{x: 1, y: 76, duration: 0.00001, zIndex: -2, opacity: 0},
      //   resetBetText:{x: 153, y: 185, duration: 0.00001, zIndex: -2, opacity: 0},
      //   resetInsuranceChip:{x: 15, y: 1376, duration: 0.00001, zIndex: -2, opacity: 0},
      //   resetPyrDrwByChip:{x: 548, y: -36, duration:0.00001, zIndex: -5, opacity: 0},
      //   resetDlrBycardChip:{x: 0, y: 0, duration: 0.00001, zIndex: -2, opacity: 0},
      //   resetDlrLoseChip:{ x: -45,y: 20, opacity: 0, zIndex: -10 ,duration:0.00001},
      //   resetwinpopuprspelement:{autoAlpha: 0, scale: 0, duration: 0.00001}
      // })


      // if(this.resetPyrWnCpsAmn === true){
      //   if(isPortrait){


      //     if(document.getElementById("chipcenterposition")&&document.getElementById("betchipsaddtobet")&&document.getElementById("betTextaddtoBet")&&
      //       document.getElementById("chiptoinsurence")&&this.chipdrawref.current&&this.buyDealerCardDrawn.current&&document.getElementById("dealerchip")&&winpopuprspelement){
      //       gsap.to("#chipcenterposition",{x: 13, y: 1200, duration: 0.0000001, zIndex: -2, opacity: 0})
      //       gsap.to("#betchipsaddtobet",{x: 1, y: 76, duration: 0.0000001, zIndex: -2, opacity: 0})
      //       gsap.to("#betTextaddtoBet",{x: 153, y: 185, duration: 0.00001, zIndex: -2, opacity: 0})
      //       gsap.to("#chiptoinsurence",{x: 15, y: 1376, duration: 0.00001, zIndex: -2, opacity: 0})
      //       gsap.to(this.chipdrawref.current,{x: 548, y: -36, duration:0.00001, zIndex: -5, opacity: 0})
      //       gsap.to( this.buyDealerCardDrawn.current,{x: 0, y: 0, duration: 0.00001, zIndex: -2, opacity: 0})
      //       gsap.to("#dealerchip",{ x: -45,y: 20, opacity: 0, zIndex: -10 ,duration:0.00001})
      //       gsap.to(winpopuprspelement,{autoAlpha: 0, scale: 0, duration: 0.00001})

      //   }

      //   }else{
      //     if(document.getElementById("chipcenterposition")&&document.getElementById("betchipsaddtobet")&&document.getElementById("betTextaddtoBet")&&
      //     document.getElementById("chiptoinsurence")&&this.chipdrawref.current&&this.buyDealerCardDrawn.current&&document.getElementById("dealerchip")&&winpopuprspelement){
      //     gsap.to("#chipcenterposition",{x: 0, y: 0, duration: 0.0001 ,zIndex: -2,opacity: 0})
      //     gsap.to("#betchipsaddtobet",  {x: 0, y: 0, duration: 0.000001 ,zIndex: -2,opacity: 0})
      //     gsap.to("#betTextaddtoBet",   {x: 0, y: 0, duration: 0.000001 ,zIndex: -2,opacity: 0})
      //     gsap.to("#chiptoinsurence",   {x: 0, y: 0, duration: 0.0001 ,zIndex: -2,opacity: 0})
      //     gsap.to(this.chipdrawref.current,        {x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0})
      //     gsap.to( this.buyDealerCardDrawn.current,{x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0})
      //     gsap.to("#dealerchip",{ x:0,y:0, opacity: 0, zIndex: -10 ,duration:0.00001})
      //     gsap.to(winpopuprspelement,{autoAlpha: 0, scale: 0, duration: 0.00001})

      // }

      //   }
      // }







    }
  };

  showPreviousBetsStatus = () => {


    // console.log(this.props.gameState);
    const { response } = this.props.gameState;

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {
      if (response) {
        document.getElementById("betpannelrussiancover").style.display = "block";
        document.getElementById("betpannelrussiancover").classList.add("bet_pannelcoverrussian");
      }
      else {
        document.getElementById("betpannelrussiancover").style.display = "none";
        document.getElementById("betpannelrussiancover").classList.remove("bet_pannelcoverrussian");

      }
    }

    if (response) {
      this.buyCardUnsequenceArray = [...this.props?.gameState?.response?.playerFinalCards];

      this.highlightPlayerCardsName(response.playerHand);
      this.setState({
        anteBetAmount: response.anteBetAmount,
        bonusbetamount: response.bonusBetAmount,
        // playerrankinghand: handrank

      });

      switch (response.action) {
        case "PARAM_BET":

          this.showcardsAdBetsParamState(response, response.playerFinalCards, response.dealerCards);
          this.showParamBetStatus(false);
          this.ShowbonusstatusTousr(response);

          this.setState({
            infoText: response.message
          })


          break;
        case "BET":
          this.showrussianPokerresp(response);
          break;
        case "FOLD":
          // this.Show_FoldHandresp(response);
          if (
            navigator.userAgentData &&
            navigator.userAgentData.platform &&
            navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
          ) {
            this.Show_FoldHandresp(response);
            const drawbutton = document.getElementById("drawbtn");
            const buybutton = document.getElementById("buybtn");
            const betbutton = document.getElementById("betbtn");
            const foldbutton = document.getElementById("Foldbtn");

            const drawtextElement = document.getElementById("drawtext");
            const buytextElement = document.getElementById("buytext");
            const betbtntextElement = document.getElementById("betbtntext");
            const foldtextElement = document.getElementById("foldtext");

            betbtntextElement.textContent = "";
            foldtextElement.textContent = "";
            drawtextElement.textContent = "";
            buytextElement.textContent = "";

            drawbutton.src = "";
            drawbutton.style.cssText = "";


            buybutton.src = "";
            buybutton.style.cssText = "";

            betbutton.src = "";
            betbutton.style.cssText = "";


            foldbutton.src = "";
            foldbutton.style.cssText = "";

          } else {
            this.showPlayerorDealerorFold(response);
          }

          break;
        case "CONFIRM_FOLD":
          // this.Show_FoldHandresp(response);
          if (
            navigator.userAgentData &&
            navigator.userAgentData.platform &&
            navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
          ) {
            this.Show_FoldHandresp(response);

          } else {
            this.showPlayerorDealerorFold(response);
          }


          break;
        case "REPLACE_CARDS":
          this.replaceCards = []
          // this.ShowrpReplaceCards(response);
          this.drawReplaceCards = true;
          if (
            navigator.userAgentData &&
            navigator.userAgentData.platform &&
            navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
          ) {
            //  this.showcardsAdBetsParamState(response,response.dealerCards,response.playerFinalCards);
            this.ShowBuyplayercardresp(response);
            //  this.ShowrpReplaceCards(response);

          } else {

            this.ShowBuyplayercardresp(response);
          }






          break;
        case "BUY_PLAYER_CARD":
          this.drawReplaceCards = true;
          if (
            navigator.userAgentData &&
            navigator.userAgentData.platform &&
            navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
          ) {

            this.ShowBuyplayercardresp(response);
            // this.showcardsAdBetsParamState(response,response.dealerCards,response.playerFinalCards)


          } else {
            this.ShowBuyplayercardresp(response);

          }




          break;
        case "BUY_DEALER_CARD":
          this.setState({ cmfrmdealeraction: 1, cmfrmdealeractionNO: 1 })
          this.ShowbuydealercardResponse(response);
          // this.showrussianPokerresp(response);  

          break;
        case "CONFIRM_DEALER_CARD":
          this.Showbuyconfirmdealercardresp(response);

          break;
        case "INSURANCE":
          // this.Showclaiminsurenceresp(response);


          if (
            navigator.userAgentData &&
            navigator.userAgentData.platform &&
            navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
          ) {
            this.Showclaiminsurenceresp(response);

          } else {
            this.showrussianPokerresp(response);
          }
          break;

        default:
          return []
      }

    }




  }


  showcardsAdBetsParamState = (response, playerFinalCards, dealerCards) => {
    setTimeout(() => {
      this.setState({
        totalbet: response.totalBetAmount,
        totalwinRussian: (response.bonus && Number(response.bonusBetAmount)) ? response.bonusWinAmount : 0,
        // betdeductions:response.totalBetAmount....................commmented on 18-10-24.....................
      })
    }, 250)
    // this.onDrawingCards(response.playerFinalCards, false);
    // console.log(dealerCards)
    // console.log(playerFinalCards)




    let convrAntebt = this.topLable_rsp(response.anteBetAmount);

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      if (Number(response.anteBetAmount) > 0) {
        let antebetstatebetamount = this.topLable_rsp(response.anteBetAmount)
        this.setState((prevState) => ({
          storebetchips: [
            ...prevState.storebetchips,
            <div id="chipcenterposition">
              <img id="centerchips" className="chip10centerposition"
                src={this.ReturnChipBasedAmount(response.anteBetAmount)}
                alt="Chip 10"
              />
              <span id="antebetval_10" className="antebet_textval10">{antebetstatebetamount}</span>
            </div>

          ],
        }));
      }
      if ((response.action === "PARAM_BET") ||
        (response.message === `Attention! You have a ${response.playerHand}. Are you sure you want to fold?` && response.action === "FOLD") ||
        (response.action === "BUY_PLAYER_CARD" || response.action === "REPLACE_CARDS")
      ) {
        if (response.bonus) {

          if (Number(response.bonusBetAmount) > 0) {
            let bonusbetstateamnt = response.bonusBetAmount
            this.setState((prevState) => ({
              storebetchips: [
                ...prevState.storebetchips,
                <div id="chip10centerbns">
                  <img className="chip10centerbns"
                    src={this.ReturnChipBasedAmount(response.bonusBetAmount)}
                    alt="Chip 10"
                  />
                  <span id="antebetval_10" className="bonusChipsForwindows_val10">{bonusbetstateamnt}</span>
                </div>

              ],
              totalwinRussian: response.bonusWinAmount,
            }));

            setTimeout(() => {
              this.ShowBonuspayouttable();

              this.bonuspaytable.map((obj, val) => {
                const bonushandplayer = document.getElementById(`numrussian${val}`);
                const bonushandplayerval = document.getElementById(`numbersvalrussian${val}`);



                let playerbonuhand = response.playerHand.split(" , ");

                if (bonushandplayer && playerbonuhand.includes(obj.k)) {
                  if (bonushandplayer.classList.contains("num")) {
                    bonushandplayer.classList.remove("num")

                    bonushandplayer.classList.add("bonuswinhglt")

                  }
                  bonushandplayerval.classList.add("bonuspayouthglt")
                }
              });
            }, 1000)
            this.ShowbonusstatusTousr(response);
          }

        }
      }
      // if (response.playerBuyCardAmount > 0 || response.playerReplaceCardAmount > 0) {

      //   gsap.set("#betchipsaddtodraw", { clearProps: "transform" });
      //   setTimeout(() => {
      //     gsap.to("#betchipsaddtodraw", {
      //       x: -1084,
      //       y: -598,
      //       duration: 0.1
      //     });
      //   }, 10);

      //   let betexttodraw = document.getElementById("betchipsaddtodrawText");
      //   betexttodraw.classList.add("bet-text-addtodraw", "bet-text-addtoinsurence10")
      //   gsap.to("#betTextaddtodraw", { opacity: 2, zIndex: 15 })
      //   let betchipsdraw = document.getElementById("betchipsaddtodraw");


      //   // console.log(betexttodraw)

      //   let betTextaddtoBetClass = "bet-text-addtodraw20";
      //   // this.setState((prevState) => ({
      //   //   totalbet: prevState.totalbet + prevState.anteBetAmount

      //   // }));
      //   let buyreplacestatebet
      //   // Object.assign(betchipsdraw.style,porttraitejson.drawchpgamestatewindows)
      //   Object.assign(betchipsdraw.style, porttraitejson.betchipsdrawwindows)

      //   if (response.playerBuyCardAmount > 0) {

      //     betchipsdraw.src = chip_10;
      //     buyreplacestatebet = this.topLable_rsp(response.playerBuyCardAmount)
      //     betexttodraw.textContent = buyreplacestatebet
      //     gsap.to("#betchipsaddtodraw", { x: -4, y: -2, duration: 0.1 })
      //     document.getElementById("betchipsaddtodrawText").classList.add("bet-text-addtodraw,bet-text-addtodraw10")
      //     // console.log(betexttodraw)
      //   }
      //   else {
      //     betchipsdraw.src = chip_10;
      //     buyreplacestatebet = this.topLable_rsp(response.playerReplaceCardAmount)
      //     document.getElementById("betchipsaddtodrawText").classList.add("bet-text-addtodraw,bet-text-addtodraw10")
      //     betexttodraw.textContent = buyreplacestatebet
      //     // console.log(betexttodraw)
      //   }

      // }



    }
    else {

      if (Number(response.anteBetAmount) > 0) {
        document.getElementById("mobileAntiMaskCover").style.display = "block";
        this.setState((prevState) => ({
          storeantebetchips: [
            ...prevState.storeantebetchips,
            { chip: this.ReturnChipBasedAmount(response.anteBetAmount), chipvalue: convrAntebt, chipcolor1: "valtextPortrait1" },

          ],
          bsbtn: true
        }));

      }


      // switch(response.action){
      //   case "PARAM_BET":


      if ((response.action === "PARAM_BET") ||
        (response.message === `Attention! You have a ${response.playerHand}. Are you sure you want to fold?` && response.action === "FOLD") ||
        (response.action === "BUY_PLAYER_CARD" || response.action === "REPLACE_CARDS")
      ) {
        if (response.bonus) {
          if (Number(response.bonusBetAmount) > 0) {
            // if(true){
            let convrBonusbet = this.topLable_rsp(response.bonusBetAmount);
            this.setState((prevState) => ({
              storebonuschips: [
                ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(response.bonusBetAmount), chipvalue: convrBonusbet, chipcolor1: "insuranceTextPortraitChip10" },

              ],
              totalwinRussian: response.bonusWinAmount,
              bsbtn: true
            }));
            setTimeout(() => {
              this.ShowBonuspayouttable();

              this.bonuspaytable.map((obj, val) => {
                const bonushandplayer = document.getElementById(`numrussian${val}`);
                const bonushandplayerval = document.getElementById(`numbersvalrussian${val}`);



                let playerbonuhand = response.playerHand.split(" , ");

                if (bonushandplayer && playerbonuhand.includes(obj.k)) {
                  const isPortrait = window.innerHeight > window.innerWidth;

                  if (isPortrait) {
                    if (bonushandplayer.classList.contains("numPortrait")) {
                      bonushandplayer.classList.remove("numPortrait")
                      bonushandplayerval.classList.add("bonuspayouthglt_portraite")
                      bonushandplayer.classList.add("bonuswinhglt_portrite")

                    }

                  }
                  else {
                    if (bonushandplayer.classList.contains("numPortrait")) {
                      bonushandplayer.classList.remove("numPortrait")
                      bonushandplayerval.classList.add("bonuspayouthglt_portraite")
                      bonushandplayer.classList.add("bonuswinhglt_portrite")

                    }

                  }


                }
              });

            }, 1000)

            setTimeout(() => {
              this.ShowBonuspayouttable()
            }, 2500)
            this.ShowbonusstatusTousr(response);
          }
        }

      }




    }



    this.shuffledPlayerCards = [...playerFinalCards]
    this.randomDealerCards = []
    for (let m = 0; m < dealerCards.length; m++) {
      if (dealerCards[m] === '-1') {
        this.randomDealerCards.push('xx')

      } else {
        this.randomDealerCards.push(dealerCards[m]);


      }
    }

    // console.log("this.randomDealerCards", this.randomDealerCards);
    // console.log("this.randomPlayerCards", this.shuffledPlayerCards);

    let cnt = this.shuffledPlayerCards.length;
    let cnt2 = this.randomDealerCards.length;
    for (let i = 0; i < cnt2; i++) {
      let b1 = this.randomDealerCards[i];
      switch (i) {
        case 0:
          this.dealerCardsContainer.current.showPreviousDealercards("cardZero", b1, cnt);
          break;
        case 1:
          this.dealerCardsContainer.current.showPreviousDealercards("cardOne", b1, cnt);
          break;
        case 2:
          this.dealerCardsContainer.current.showPreviousDealercards("cardTwo", b1, cnt);
          break;
        case 3:
          this.dealerCardsContainer.current.showPreviousDealercards("cardThree", b1, cnt);
          break;
        case 4:
          this.dealerCardsContainer.current.showPreviousDealercards("cardFour", b1, cnt);
          break;
        case 5:
          this.dealerCardsContainer.current.showPreviousDealercards("cardFive", b1, cnt);
          break;
        default:
          break;
      }
    }

    for (let i = 0; i < cnt; i++) {
      let b2 = this.shuffledPlayerCards[i];
      switch (i) {
        case 0:
          this.cardsRef.current.showPreviousPlayercards("cardZero", b2, cnt);
          break;
        case 1:
          this.cardsRef.current.showPreviousPlayercards("cardOne", b2, cnt);
          break;
        case 2:
          this.cardsRef.current.showPreviousPlayercards("cardTwo", b2, cnt);
          break;
        case 3:
          this.cardsRef.current.showPreviousPlayercards("cardThree", b2, cnt);
          break;
        case 4:
          this.cardsRef.current.showPreviousPlayercards("cardFour", b2, cnt);
          break;
        case 5:
          this.cardsRef.current.showPreviousPlayercards("cardFive", b2, cnt);
          // this.cardsRef.current.addCards("cardFive", b2, cnt);
          break;
        default:
          break;
      }

    }



  }

  ShowbonusstatusTousr = (res) => {

    let info = "";
    let spokerranking = "";
    let betactive = ""

    setTimeout(() => {
      this.setState({ drawbuttonhandle: true, disDrawbtn: 1 });
      document.getElementById("drawtext").classList.add("drawtextopacity");
      document.getElementById("drawbtn").classList.add("drwnodrop");

    }, 500)

    let mode = ""
    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)

    ) {
      document.getElementById("playerDealerCardContainer").classList.add("addpointer")
      info = "windowsinfo";
      spokerranking = "windowspokerranking";
      betactive = "betactive"
      mode = "Windows"

    } else {
      //  this.setState({ isbonuspattable: false });
      document.getElementById("betpannelrussiancover").style.display = "none";
      if (window.innerWidth > window.innerHeight) {
        spokerranking = "pokerrankingPlayerLndsp";
        info = "lndspinfo";
        // betactive = "betactiveLndsp";
        betactive = this.state.betactvglwcss;
        mode = "landscape";
      } else {
        info = "portraitinfo";
        // betactive = "betactivePortrait";
        betactive = this.state.betactvglwcss;
        spokerranking = "pokerrankingPortait";
        mode = "portrait";
      }
    }

    if (document.getElementById("inforussian")) {
      document.getElementById("inforussian").classList.add(info);
      document.getElementById("inforussianland").classList.add(info);
    };

    this.setState({
      pokerranking: res.playerHand,
      infoText: res.message,
      multibuttonsebledsble: false,
      disablemultibutton: false


    });

    document.getElementById("rankinginfo").className = "";
    document.getElementById("rankinginfo").classList.add(spokerranking);
    this.setState({ isbonuspattable: false });
    document.getElementById("bnsactive").src = "";
    document.getElementById("bonustext").style.color = "";






    if (res.bonus && Number(res.bonusWinAmount) > 0) {
      // if(true){

      if (mode === "Windows") {

        if (res.bonusBetAmount) {


          setTimeout(() => {
            let t1 = gsap.timeline();
            t1.to("#chip10centerbns", {
              x: 339, y: 150, duration: 0.1, opacity: 1,
              onComplete: () => {
                gsap.set("#chip10centerbns", { opacity: 1 });
                document.getElementById("glowonwinchip").classList.add("chipglow_bonus")
              }
            });
            t1.play();
            const bonusText = document.querySelectorAll("#chip10centerbns span");
            // console.log(bonusText)
            for (let b = 0; b < bonusText.length; b++) {
              bonusText[b].textContent = res.bonusWinAmount;
            }

          }, 500);

        }
      }
      else if (mode === "portrait") {
        this.playerWinBonus = true;
        this.setState({ isbonuspattable: false });
        document.getElementById("bonustext").style.color = "";
        if (res.bonus) {
          // if(true){
          setTimeout(() => {
            if (document.getElementById("chip10centerbns")) {
              gsap.to("#chip10centerbns", {
                x: -630, y: 925, duration: 0.000001, autoAlpha: 1
              });
            }

            const bonusText = document.querySelectorAll("#chip10centerbns span");
            // console.log(bonusText)
            for (let b = 0; b < bonusText.length; b++) {
              bonusText[b].textContent = res.bonusWinAmount;
            }

          }, 500)


        }

      } else {
        this.playerWinBonus = true;
        document.getElementById("bonustext").style.color = "";
        if (res.bonus) {
          setTimeout(() => {
            if (document.getElementById('chip10centerbns')) {
              gsap.to("#chip10centerbns", {
                x: 550, y: -130, duration: 0.000001, autoAlpha: 1,
              });

            }
            const bonusText = document.querySelectorAll("#chip10centerbns span");
            // console.log(bonusText)
            for (let b = 0; b < bonusText.length; b++) {
              bonusText[b].textContent = res.bonusWinAmount;
            }
          }, 500)


        }
      }

    } else {

      if (mode === "Windows") {
        if (this.state.bonusbetamount) {
          let t1 = gsap.timeline();
          t1.to("#chip10centerbns", {
            x: 0, y: -649, duration: 0.5, opacity: 1,
            onComplete: () => {
              gsap.set("#chip10centerbns", { opacity: 0 });
            }
          });
          t1.to("#chip10centerbns", {
            x: 861, y: 557, duration: 0.5, zIndex: -2, opacity: 0,
            onComplete: () => {
              gsap.set("#chip10centerbns", { opacity: 0, delay: 2 });
            }

          });
          t1.play();
        }

      } else if (mode === "portrait") {
        this.playerWinBonus = false;
        document.getElementById("bonustext").style.color = "";
        if (this.state.bonusbetamount) {
          if (document.getElementById('chip10centerbns')) {
            let t1 = gsap.timeline();
            t1.to("#chip10centerbns", {
              x: 0, y: -900, duration: 0.5, opacity: 1,
              onComplete: () => {
                gsap.set("#chip10centerbns", { opacity: 0 });
              }
            });
            t1.to("#chip10centerbns", {

              x: -163, y: 1195, duration: 0.0005, zIndex: -2, opacity: 0,
              onComplete: () => {
                gsap.set("#chip10centerbns", { opacity: 0, });
              }

            });
            t1.play();
          }

        }


      } else {

        this.playerWinBonus = false;
        document.getElementById("bonustext").style.color = "";
        if (this.state.bonusbetamount) {
          if (document.getElementById('chip10centerbns')) {
            let t1 = gsap.timeline();
            t1.to("#chip10centerbns", {
              x: 0, y: -900, duration: 0.5, autoAlpha: 1, ease: "power2.inOut",
            });
            t1.to("#chip10centerbns", {
              x: 0, y: 0, duration: 0.0001, zIndex: -2, autoAlpha: 0,

            });
            t1.play();
          }

        }

      }


    }
    const hands = [
      "onepair", "Threekind", "aceking", "fullhse",
      "Straightflush", "frofkind", "twopair",
      "flush", "stright", "royalflush"
    ];

    hands.forEach(hand => {
      document.getElementById(hand).style.color = "";
    });

    this.highlightPlayerCardsName(res.playerHand);



    document.getElementById("betactive").src = bnsactive;
    document.getElementById("bettext").style.color = "#ffffff";
    if (mode === "Windows") {
      document.getElementById("betRussian").classList.add("addpointer");
      document.getElementById("betactive").classList.add(betactive);

    } else {

    }




    this.setState({
      revealCard: true,
      buttontext: "fold",
      betbutton: "Bet",
    });

  }


  showPlayerorDealerorFold = (res) => {


    if (res.message === `Attention! You have a ${res.playerHand}. Are you sure you want to fold?`) {
      this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerCards);
      // this.showParamBetStatus(false); //  disble the all button chips,  if we have are you sure you want to fold cards?
      this.ShowbonusstatusTousr(res);
      this.ConfirmbuydealercardactivePopup = true;
      if (res.playerReplaceCardAmount > 0 || res.playerBuyCardAmount > 0) {
        this.drawReplaceCards = true;
        //  if(res.playerBuyCardAmount > 0){
        this.prevstateBuyorDrawChip(res);
      }

      this.setState({
        infoText: res.message,
        cmfrmdealeraction: 2,
        cmfrmdealeractionNO: 2
      })
      const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popup");
      if (window.innerHeight > window.innerWidth) {

        confirmbuydealerPopupElement.className = "";
        confirmbuydealerPopupElement.style.display = "block";
        confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardPortrait");
        document.getElementById("alertTxt").innerHTML = res.message;
        document.getElementById("alertTxt").classList.add('alertTxtPortrait');
        document.getElementById("yes").classList.add("yesbutton_popup_portrait")
        document.getElementById("no").classList.add("nobutton_popup_portrait")

      }
      else {
        confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardLndsp");
        document.getElementById("alertTxt").innerHTML = res.message;
        document.getElementById("alertTxt").classList.add('alertTxtPortrait');
        document.getElementById("yes").classList.add("yesbutton_popup_portrait")
        document.getElementById("no").classList.add("nobutton_popup_portrait")

      }



    } else {


      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");
      const betbtntextElement = document.getElementById("betbtntext");
      const foldtextElement = document.getElementById("foldtext");
      const rankinginfodelerElement = document.getElementById("rankinginfodeler");
      const rankinginfoElement = document.getElementById("rankinginfo");
      const hands = [
        "onepair", "Threekind", "aceking", "fullhse",
        "Straightflush", "frofkind", "twopair",
        "flush", "stright", "royalflush"
      ];



      hands.forEach(hand => {
        document.getElementById(hand).style.color = "";
      });


      this.setState({
        previousAnteBetAmount: res.anteBetAmount,
        previousBonusbetamount: res.bonusBetAmount,
        infoText: this.props.language.Place_antebet,
        totalbet: 0,
        anteBetAmount: 0,
        bonusbetamount: 0,
        insurencetbet: 0,
        pokerranking: "",
        pokerrankingdeler: "",
        //  totalbet:res.totalBetAmount,,
        betbutton: "REBET",
        buttontext: "REBETX2",
      });


      document.getElementById("betpannelrussiancover").style.display = "none";
      betbutton.src = Betbtn;
      foldbutton.src = Foldbnwindows;

      betbtntextElement.className = "";
      foldtextElement.className = "";
      rankinginfodelerElement.className = "";
      rankinginfoElement.className = "";


      betbtntextElement.textContent = this.props.language.Rebet;
      foldtextElement.textContent = this.props.language.RebetX2;

      if (Number(res.anteBetAmount) > 0) {
        let convrAntebt = this.topLable_rsp(res.anteBetAmount);
        this.setState((prevState) => ({
          // storeantebetchips: [
          prevStatestoreantebetchips: [
            ...prevState.storeantebetchips,
            { chip: this.ReturnChipBasedAmount(res.anteBetAmount), chipvalue: convrAntebt, chipcolor1: "valtextPortrait1" },

          ],
        }));

      }

      if (Number(res.bonusBetAmount) > 0) {
        let convrBonusbet = this.topLable_rsp(res.bonusBetAmount);
        this.setState((prevState) => ({
          // storebonuschips: [
          prevStatestorebonuschips: [
            ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(res.bonusBetAmount), chipvalue: convrBonusbet, chipcolor1: "insuranceTextPortraitChip10" },

          ],
        }));
      }


      this.playerWinBonus = false;
      if (window.innerHeight > window.innerWidth) {
        // portrait;
        setTimeout(() => {
          if (document.getElementById("chipcenterposition")) {
            gsap.to("#chipcenterposition", { x: 13, y: 1200, duration: 0.000001, zIndex: -2, opacity: 0 });
          }

          if (document.getElementById("betchipsaddtobet")) {
            gsap.to("#betchipsaddtobet", { x: 1, y: 76, duration: 0.000001, zIndex: -2, opacity: 0 });
            gsap.to("#betTextaddtoBet", { x: 153, y: 185, duration: 0.000001, zIndex: -2, opacity: 0 });
          }


          if (document.getElementById("chiptoinsurence")) {
            gsap.to("#chiptoinsurence", { x: 15, y: 1376, duration: 0.000001, zIndex: -2, opacity: 0 });
          }
          gsap.to(this.chipdrawref.current, { x: 548, y: -36, duration: 0.000001, zIndex: -5, opacity: 0 });
          if (document.getElementById("chip10centerbns")) {
            gsap.to("#chip10centerbns", { x: -170, y: 1200, duration: 0.000001, zIndex: -2, opacity: 1 });


          }
          if (this.buyDealerCardDrawn.current) {
            gsap.to(this.buyDealerCardDrawn.current, { x: 0, y: 0, duration: 0.000001, zIndex: -2, opacity: 0 });
          }

        }, 100);



        betbtntextElement.classList.add("betportrait");
        Object.assign(betbutton.style, porttraitejson.Betbtnportraite);
        // Object.assign(betbtntextElement.style, porttraitejson.betportraite);
        Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);
        foldtextElement.classList.add("rebettextportrait");

      } else {
        // landscape


        // betbtntextElement.classList.add("betportrait");
        Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
        betbtntextElement.classList.add('betLandscape');
        Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
        foldtextElement.classList.add("foldtextLandscape");
        this.ifPlayerWinBonusAmount();

        setTimeout(() => {

          if (document.getElementById("chipcenterposition")) {
            gsap.to("#chipcenterposition", { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
          }


          if (document.getElementById("betTextaddtoBet")) {
            gsap.to("#betTextaddtoBet", { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
            gsap.to("#betchipsaddtobet", { x: 0, y: 0, duration: 0.0001 });

          }
          if (this.chipdrawref) {
            gsap.to(this.chipdrawref.current, { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
          }


          if (document.getElementById("chiptoinsurence")) {
            gsap.to("#chiptoinsurence", { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
          }
          if (this.buyDealerCardDrawn) {
            gsap.to(this.buyDealerCardDrawn.current, { opacity: 0, x: 0, y: 0, zIndex: -2, duration: 0.0001 })
          }


          if (document.getElementById("dealerchip")) {
            gsap.set('#dealerchip', { x: 0, y: 0, opacity: 0, zIndex: -10, duration: 0.00001 })
          }

        }, 100)

      }

      setTimeout(() => {

        this.clearActivesWeDid();
      }, 500)



    }













  }




  betChipsIncrementDecremenAction = () => {
    const betchipsRussianpoker = document.getElementById("betchips");

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {


      document.getElementById("val0").classList.remove("chip_10text");
      switch (this.state.count) {
        case 1:
          // console.log(this.storebetchips);
          // this.setState({ anteBetAmount: Number(this.chipVal[0].val) })
          betchipsRussianpoker.src = chip_10;
          document.getElementById("val0").classList.remove("valtext_windows");
          document.getElementById("val0").textContent = this.chipVal[0].label;
          document.getElementById("val0").classList.add("valtext_windows_chip10", "chip_10text");

          break;
        case 2:
          // this.setState({ anteBetAmount: Number(this.chipVal[1].val) })
          // this.setState({ totalbet: 20 })
          document.getElementById("val0").textContent = this.chipVal[1].label;
          document.getElementById("val0").classList.add("valtext_windows");
          betchipsRussianpoker.src = chip_25;
          break;
        case 3:
          // this.setState({ anteBetAmount: Number(this.chipVal[2].val) })
          // this.setState({ totalbet: 50 })
          document.getElementById("val0").textContent = this.chipVal[2].label;
          document.getElementById("val0").classList.add("valtext_windows");
          betchipsRussianpoker.src = chip_50;

          break;
        case 4:
          // this.setState({ anteBetAmount: Number(this.chipVal[3].val) })
          // this.setState({ totalbet: 100 })
          document.getElementById("val0").textContent = this.chipVal[3].label;
          document.getElementById("val0").classList.add("valtext_windows");
          betchipsRussianpoker.src = chip_100;

          break;
        case 5:
          // this.setState({ anteBetAmount: Number(this.chipVal[4].val) })
          // this.setState({ totalbet: 500 })
          document.getElementById("val0").textContent = this.chipVal[4].label;
          document.getElementById("val0").classList.add("valtext_windows");
          betchipsRussianpoker.src = chip_500;
          break;
        case 6:
          document.getElementById("val0").textContent = this.chipVal[5].label;
          document.getElementById("val0").classList.add("valtext_windows");
          betchipsRussianpoker.src = chip_1000;
          break;

        default:
          break;
      }

      betchipsRussianpoker.style.position = porttraitejson.betchpwindows.position;
      betchipsRussianpoker.style.width = porttraitejson.betchpwindows.width;
      betchipsRussianpoker.style.top = porttraitejson.betchpwindows.top;
      betchipsRussianpoker.style.left = porttraitejson.betchpwindows.left;
      betchipsRussianpoker.style.zIndex = porttraitejson.betchpwindows.zIndex;


    } else {
      const isPortrait = window.innerHeight > window.innerWidth;

      document.getElementById("val0").textContent = "";
      document.getElementById("val1").textContent = "";
      document.getElementById("val2").textContent = "";
      document.getElementById("val3").textContent = "";

      // console.log(this.state.count)
      document.getElementById("val0").classList.remove("valtextPortrait1");

      switch (this.state.count) {
        case 1:
          // console.log(this.storebetchips);
          betchipsRussianpoker.src = chip_10;
          document.getElementById("val0").textContent = this.chipVal[0].label;
          // this.setState({ anteBetAmount: Number(this.chipVal[0].val) })
          document.getElementById("val0").classList.add("valtextPortrait", "valtextPortrait1");

          break;
        case 2:
          // this.setState({ anteBetAmount: Number(this.chipVal[1].val) })
          document.getElementById("val0").textContent = this.chipVal[1].label;
          document.getElementById("val0").classList.add("valtextPortrait");

          betchipsRussianpoker.src = chip_25;
          break;
        case 3:
          // this.setState({ anteBetAmount: Number(this.chipVal[2].val) })
          document.getElementById("val0").textContent = this.chipVal[2].label;
          document.getElementById("val0").classList.add("valtextPortrait");
          betchipsRussianpoker.src = chip_50;

          break;
        case 4:
          // this.setState({ anteBetAmount: Number(this.chipVal[3].val) })
          document.getElementById("val0").textContent = this.chipVal[3].label;
          document.getElementById("val0").classList.add("valtextPortrait");
          betchipsRussianpoker.src = chip_100;

          break;
        case 5:
          // this.setState({ anteBetAmount: Number(this.chipVal[4].val) })
          document.getElementById("val0").textContent = this.chipVal[4].label;
          document.getElementById("val0").classList.add("valtextPortrait");
          betchipsRussianpoker.src = chip_500;
          break;
        case 6:
          // this.setState({ anteBetAmount: Number(this.chipVal[4].val) })
          document.getElementById("val0").textContent = this.chipVal[5].label;
          document.getElementById("val0").classList.add("valtextPortrait");
          betchipsRussianpoker.src = chip_1000;
          break;
        default:
          break;
      }

      if (isPortrait) {

        Object.assign(betchipsRussianpoker.style, porttraitejson.betchp);
      } else {
        Object.assign(betchipsRussianpoker.style, porttraitejson.betchiplndspe);
      }



    }

  }

  IncreamentBetchips = () => {
    document.getElementById("betcircle").classList.remove("insurencenodrop")
    if (this.state.count < 6) {
      this.playPokerBetSound(betchipsnd)
      this.setState(
        (prevState) => ({
          count: prevState.count + 1,
        }),

        () => {
          // console.log(this.state.count)

          if (this.state.count > 6) {
            this.setState({ betchipincrementbtn: true, betdecreamentbtn: false });
          }
          else {
            this.playPokerBetSound(betchipsnd)
            this.setState({ betchipincrementbtn: false });

          }
          this.betChipsIncrementDecremenAction()
        })


    }

    // console.log("this.state.count", this.state.count, "betdecreamentbtn", this.state.betdecreamentbtn, 'betchipincrementbtn', this.state.betchipincrementbtn)
  };



  DecreamentBetchips = () => {
    // console.log("this.state.count", this.state.count, "betdecreamentbtn", this.state.betdecreamentbtn, 'betchipincrementbtn', this.state.betchipincrementbtn)


    const betchipsRussianpoker = document.getElementById("betchips");
    document.getElementById("betcircle").classList.remove("insurencenodrop")


    if (this.state.count > 1) {
      this.setState(
        (prevState) => ({
          count: prevState.count - 1,
        }),
        () => {

          // console.log(this.state.count)
          if (this.state.count < 1) {
            this.setState({ betdecreamentbtn: true, betchipincrementbtn: false });
          } else {
            this.playPokerBetSound(betchipsnd)

            this.setState({ betdecreamentbtn: false });
          }

          this.betChipsIncrementDecremenAction();


        }
      );

    }


  };





  anteBet = () => {
    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }

    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");

    if (this.state.mutesounds) {
      this.betrussiansnd.pause();
    }
    else {
      this.betrussiansnd.play();

    }

    document.getElementById("betincrenet").classList.add("drwnodrop")
    document.getElementById("betdecrement").classList.add("drwnodrop")


    // console.log("this.props.minBetAmt", this.props.minBetAmt, "this.props.maxBetAmt", this.props.maxBetAmt)

    const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnce");
    if (popupbetGreaterthanBalnceElement) {
      popupbetGreaterthanBalnceElement.className = "";
      popupbetGreaterthanBalnceElement.classList.add("popupbetshighMobileIntial");
    }

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      if (this.state.anteBetAmount <= this.props.maxBetAmt) {

        document.getElementById("buttons_cover").classList.remove("buttons_cover")
      } else {
        document.getElementById("buttons_cover").classList.add("buttons_cover")

      }

      document.getElementById("foldtext").innerHTML = "";

      document.getElementById("crdscover").classList.add("crdscover")


      let bnsactived = document.getElementById("bnsactive");
      bnsactived.src = "";
      bnsactived.classList.remove("bnsctivewindows");
      bnsactived.classList.remove("bnsblink");
      document.getElementById("bonustext").style.color = "";
      document.getElementById("betpannelrussiancover").style.display = "block";
      document.getElementById("betpannelrussiancover").classList.add("bet_pannelcoverrussian");
      // console.log(this.state.anteBetAmount)
      if (this.state.anteBetAmount >= this.props.minBetAmt) {
        this.setState({
          infoText: "", betchipincrementbtn: true, betdecreamentbtn: true,
          disBetBtn: 1, keyboardhandle: false, multibuttonsebledsble: true, disablemultibutton: true

        })


        // console.log(this.state.anteBetAmount)
        if (this.state.anteBetAmount <= this.props.maxBetAmt) {
          document.getElementById("betpannelrussiancover").style.display = "block";
          document.getElementById("betpannelrussiancover").classList.add("bet_pannelcoverrussian");
          document.getElementById("bonusRussian").classList.add("bnspoiner");

          const betbutton = document.getElementById("betbtn");
          const foldbutton = document.getElementById("Foldbtn");
          betbutton.src = "";
          betbutton.style.display = "none"
          betbutton.style.cssText = ""

          foldbutton.src = "";
          foldbutton.style.display = "none"
          foldbutton.style.cssText = ""
          let betbtntextElement = document.getElementById("betbtntext")
          let foldtextElement = document.getElementById("foldtext")
          betbtntextElement.textContent = ""
          foldtextElement.textContent = ""
          const body = {
            sessionId: sessionStorage.getItem("sessionId"),
            CN: "RUSSIAN_POKER_BET",
            object: {
              gameId: this.props.gameId,
              action: "PARAM_BET",
              playerId: Number(this.props.playerId),
              anteBetAmount: Number(this.state.anteBetAmount.toFixed(2)),
              bonusBetAmount: Number(this.state.bonusbetamount.toFixed(2)),
              handId: Number(this.props.gameState.handId)
            },
          };
          // console.log(body);
          this.props.network.sendof(body);
          this.setState({ multibuttonsebledsble: false })

        }
        else {

          this.setState({ multibuttonsebledsble: true, disablemultibutton: false })
          let betbtntextElement = document.getElementById("betbtntext")
          let foldtextElement = document.getElementById("foldtext")
          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnwindows);
          betbtntextElement.classList.add("betbtntext");
          betbtntextElement.textContent = this.props.language.Deal;

          foldbutton.src = Foldbn;
          Object.assign(foldbutton.style, porttraitejson.foldbtnwindows);
          foldtextElement.classList.add("foldtext");
          foldtextElement.textContent = this.props.language.RP_Clear;
          this.setState({
            infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
            multibuttonsebledsble: true
          })
        }
      }
      else {
        this.setState({ betdecreamentbtn: false, betchipincrementbtn: false });
        document.getElementById("foldtext").innerHTML = this.props.language.RP_Clear
        document.getElementById("betpannelrussiancover").style.display = "none";
        document.getElementById("betpannelrussiancover").classList.remove("bet_pannelcoverrussian");
        this.setState({
          infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
          multibuttonsebledsble: true


        })
      }

    } else {
      let bnsactived = document.getElementById("bnsactive");
      bnsactived.src = "";
      bnsactived.className = "";
      bnsactived.className = "";
      document.getElementById("bonustext").style.color = "";
      this.setState({
        bsbtn: true,
        buydrawCheck: false
      })

      let betbtntextElement = document.getElementById("betbtntext")
      let foldtextElement = document.getElementById("foldtext")


      if (this.state.anteBetAmount >= this.props.minBetAmt) {
        if (this.state.anteBetAmount <= this.props.maxBetAmt) {
          this.setState({
            infoText: "", betchipincrementbtn: true, betdecreamentbtn: true,
            disBetBtn: 1, keyboardhandle: false, multibuttonsebledsble: true, disablemultibutton: true

          })


          document.getElementById("mobileAntiMaskCover").style.display = "block";
          document.getElementById("betpannelrussiancover").style.display = "block";
          document.getElementById("playerDealerCardContainer").style.zIndex = 20;

          betbtntextElement.textContent = "";
          foldtextElement.textContent = "";

          betbutton.src = "";
          foldbutton.src = "";
          betbutton.style.cssText = "";
          foldbutton.style.cssText = "";


          const body = {
            sessionId: sessionStorage.getItem("sessionId"),
            CN: "RUSSIAN_POKER_BET",
            object: {
              gameId: this.props.gameId,
              action: "PARAM_BET",
              playerId: Number(this.props.playerId),
              anteBetAmount: Number(this.state.anteBetAmount.toFixed(2)),
              bonusBetAmount: Number(this.state.bonusbetamount.toFixed(2)),
              handId: Number(this.props.gameState.handId)
            },
          };



          // console.log(body);
          this.props.network.sendof(body);
          this.setState({ multibuttonsebledsble: false })

        }

        else {
          betbutton.src = Betbtn;
          foldbutton.src = Foldbn;
          betbutton.style.cssText = Betbtn;
          foldbutton.style.cssText = Foldbn;
          if (window.innerHeight > window.innerWidth) {

            Object.assign(betbutton.style, porttraitejson.Betbtnportraite);
            betbtntextElement.classList.add("betportrait");
            betbtntextElement.textContent = this.props.language.Deal;


            Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);
            foldtextElement.classList.add("foldtextportrait");
            foldtextElement.textContent = this.props.language.RP_Clear;

          } else {

            Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
            betbtntextElement.classList.add("betLandscape");
            betbtntextElement.textContent = this.props.language.Deal;

            Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
            foldtextElement.classList.add("foldtextLandscape");
            foldtextElement.textContent = this.props.language.RP_Clear;

          }


          this.setState({
            infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
            // multibuttonsebledsble: true
          })
        }
      }
      else {
        document.getElementById("mobileAntiMaskCover").style.display = "none";
        document.getElementById("betpannelrussiancover").style.display = "block";
        betbutton.src = Betbtn;
        foldbutton.src = Foldbn;
        if (window.innerHeight > window.innerWidth) {

          Object.assign(betbutton.style, porttraitejson.Betbtnportraite);
          betbtntextElement.classList.add("betportrait");
          betbtntextElement.textContent = this.props.language.Deal;


          Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);
          foldtextElement.classList.add("foldtextportrait");
          foldtextElement.textContent = this.props.language.RP_Clear;

        } else {

          Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
          betbtntextElement.classList.add("betLandscape");
          betbtntextElement.textContent = this.props.language.Deal;

          Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
          foldtextElement.classList.add("foldtextLandscape");
          foldtextElement.textContent = this.props.language.RP_Clear;

        }

        // document.getElementById("betpannelrussiancover").style.display = "none";
        // document.getElementById("betpannelrussiancover").classList.add("bet_pannelcoverrussian");
        this.setState({
          infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
          // multibuttonsebledsble: true


        })
      }
    }

  };


  clearbets() {
    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }

    this.clearallsettimeouts()
    // this.updateplayerbalnace();
    const body2 = {
      CN: "GET_PLAYER_INFO",
      object: {
        gameId: Number(this.props.gameId,),
        playerId: Number(this.props.playerId),

      },
      sessionId: sessionStorage.getItem('sessionId'),
    }
    this.props.network.sendof(body2);
    this.body2 = {};



    document.getElementById("betcircle").classList.remove("insurencenodrop")
    document.getElementById("bonustext").style.color = ""
    document.getElementById("bnsactive").src = "";
    document.getElementById("bnsactive").classList.remove("betactive");
    this.setState({ multibuttonsebledsble: false })
    // document.getElementById("betbtn").classList.add("addpointer")
    // document.getElementById("betbtn").classList.remove("drwnodrop")
    this.clertime1 = setTimeout(() => {
      gsap.to(`#betchipsaddtoante${this.state.idincrement}`, { zIndex: -2 });
      gsap.to(this.chipbnsref.current, { zIndex: -2 });
      document.getElementById("betpannelrussiancover").style.display = "none";
      document.getElementById("betpannelrussiancover").classList.add("bet_pannelcoverrussian");
    }, 1000);



    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");


    betbutton.src = "";
    document.getElementById("betbtntext").classList.remove("betwindows");
    document.getElementById("betbtntext").textContent = "";
    document.getElementById("betbtntext").innerHTML = "";
    betbutton.style.cssText = ""

    foldbutton.src = "";
    document.getElementById("foldtext").classList.remove("foldtextwindows");
    document.getElementById("foldtext").textContent = "";
    document.getElementById("foldtext").innerHTML = "";
    foldbutton.style.cssText = ""

    const hands = [
      "onepair", "Threekind", "aceking", "fullhse",
      "Straightflush", "frofkind", "twopair",
      "flush", "stright", "royalflush"
    ];
    hands.forEach(hand => {
      document.getElementById(hand).style.color = "";
    });






    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      if (this.state.mutesounds) {
        this.clear_snd = new Audio(clear_snd)
        this.clear_snd.pause()

      }
      else {
        this.clear_snd = new Audio(clear_snd)
        this.clear_snd.play()

      }

      this.setState({
        totalbet: 0, disClearBtn: 1,
        anteBetAmount: 0,
        bonusbetamount: 0, bsbtn: true,
        // infoText: this.props.language.Play_Njoy 
        infoText: this.props.language.Place_antebet
      });


      document.getElementById("bonusRussian").classList.add("bnspoiner");
      document.getElementById("bonusRussian").classList.add("addpointer");
      document.getElementById("betcircle").classList.add("addpointer");
      document.getElementById("betcircle").classList.remove("nonpointer")
      document.getElementById("bonusRussian").classList.add("drwnodrop");
      document.getElementById("bonusRussian").classList.remove("addpointer")
      if (document.getElementById("popupbet_greaterthanbalnce")) {
        this.hidepopup_betshigh();
      }

      gsap.to(`#betchipsaddtoante${this.state.idincrement}`, {
        x: 1,
        y: 0,
        duration: 0.1,
        ease: "power2.inOut",
        zIndex: 2,
        opacity: 0
      });


      this.hidepopup_betshigh();

      document.getElementById("betpannelrussiancover").style.display = "block";
      document.getElementById("betpannelrussiancover").classList.add("pannelcoverrussian");
      setTimeout(() => {
        if (document.getElementById("chip10centerbns")) {

          gsap.to(`#chip10centerbns`, {
            x: 859,
            y: 547,
            duration: 0.5,
            ease: "power2.inOut",
            zIndex: -2,
            opacity: 0
          });




        }


        if (document.getElementById("chipcenterposition")) {

          gsap.to("#chipcenterposition", {

            x: 993,
            y: 544,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: -2,
            opacity: 0
          });




        }

      }, 500)




    } else {

      // "mobileAntiMaskCover"
      document.getElementById("mobileAntiMaskCover").style.display = "block";
      setTimeout(() => {
        document.getElementById("mobileAntiMaskCover").style.display = "none";
      }, 2000)

      if (this.state.mutesounds) {
        this.clear_snd = new Audio(clear_snd)
        this.clear_snd.pause()

      }
      else {
        this.clear_snd = new Audio(clear_snd)
        this.clear_snd.play()

      }
      setTimeout(() => {


        this.setState({
          totalbet: 0, disClearBtn: 1,
          isbonuspattable: false, anteBetAmount: 0,
          bonusbetamount: 0, bsbtn: true,
          // infoText: this.props.language.Play_Njoy 
          infoText: this.props.language.Place_antebet
        });



        gsap.to(`#betchipsaddtoante${this.state.idincrement}`, {
          x: 1,
          y: 0,
          duration: 0.3,
          ease: "power2.inOut",
          zIndex: 2,
        });

        this.setState({
          // storebetchips:[]
        });



        const isPortrait = window.innerHeight > window.innerWidth;
        // if(this.state.betshigh_balnce){
        if (document.getElementById("popupbet_greaterthanbalnce")) {
          this.hidepopup_betshigh();
        }
        // }

        document.getElementById("betpannelrussiancover").style.display = "block";
        if (isPortrait) {
          if (document.getElementById("chipcenterposition")) {
            gsap.to("#chipcenterposition", {
              x: 25,
              y: 1200,
              duration: 0.5,
              ease: "power2.inOut",
              zIndex: 2,
              opacity: 0
            });
          }

          // let t1 = gsap.timeline();
          // t1.to("#chipcenterposition", {
          //   y: -966, duration: 0.2, opacity: 1,
          //   onComplete: function () {
          //     gsap.set("#chipcenterposition", { opacity: 0 });
          //   }
          // });
          // t1.to("#chipcenterposition", {
          //   x: 13, y:-2170, duration: 0.0001, zIndex: -12, opacity: 0,
          //   onComplete: function () {
          //     gsap.set("#chipcenterposition", { opacity: 0, });
          //   }

          // });
          // t1.play();


          if (document.getElementById('chip10centerbns')) {
            gsap.to(`#chip10centerbns`, {
              x: -160,
              y: 1200,
              duration: 0.3,
              ease: "power2.inOut",
              zIndex: -2,
              opacity: 0
            });

          }




        } else {

          if (document.getElementById("chipcenterposition")) {
            gsap.to("#chipcenterposition", {
              x: 1050,
              y: 0,
              duration: 0.5,
              ease: "power2.inOut",
              zIndex: 2,
              opacity: 0
            });
          }


          if (document.getElementById('chip10centerbns')) {
            gsap.to(`#chip10centerbns`, {
              x: 890,
              y: 0,
              duration: 0.5,
              ease: "power2.inOut",
              zIndex: -2,
              autoAlpha: 1,
              onComplete: () => {
                gsap.to(`#chip10centerbns`, {
                  x: 0,
                  y: 0,
                  autoAlpha: 0,
                  duration: 0.000001,
                  zIndex: -2,
                })


              }
            });

          }

        }

      }, 1000);
    }
  }

  Showbonustouser = (res) => {

    let info = "";
    let spokerranking = "";
    let betactive = ""

    let mode = ""
    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)

    ) {
      mode = "Windows"
      betactive = "betactive"


    } else {
      document.getElementById("betpannelrussiancover").style.display = "none";
      if (window.innerWidth > window.innerHeight) {
        betactive = "betactiveLndsp";
        // betactive = this.state.betactvglwcss;
        mode = "landscape";
      } else {
        betactive = "betactivePortrait";
        // betactive = this.state.betactvglwcss;
        mode = "portrait";
      }
    }

    // document.getElementById("betactive").src = bnsactive;
    document.getElementById("betactive").classList.add(betactive);

    this.clertime2 = setTimeout(() => {
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)

      ) {

        document.getElementById("bonustext").style.color = "";
        document.getElementById("playerDealerCardContainer").classList.add("addpointer");
        info = "windowsinfo";
        spokerranking = "windowspokerranking"
        mode = "Windows"


      } else {
        //  this.setState({ isbonuspattable: false });
        // document.getElementById("betpannelrussiancover").style.display = "none";
        this.setState({ bsbtn: true })
        if (window.innerWidth > window.innerHeight) {
          spokerranking = "pokerrankingPlayerLndsp";
          info = "lndspinfo";
          mode = "landscape";
        } else {
          info = "portraitinfo";
          spokerranking = "pokerrankingPortait";
          mode = "portrait";
        }
      }
      if (document.getElementById("inforussian")) {
        document.getElementById("inforussian").classList.add(info);
        document.getElementById("inforussianland").classList.add(info);
      };

      this.setState({
        pokerranking: res.playerHand,
        // infoText: res.message,
        multibuttonsebledsble: false,
        disablemultibutton: false

        // betBtnValue:1,

      });
      document.getElementById("rankinginfo").className = "";
      document.getElementById("rankinginfo").classList.add(spokerranking);
      this.setState({ isbonuspattable: false });
      document.getElementById("bnsactive").src = "";
      document.getElementById("bonustext").style.color = "";
    }, 4000);

    this.clertime3 = setTimeout(() => {
      if (this.state.bonusbetamount > 0) {
        // this.playPokerChipSound(this.pokerchipsnd);
      }
      // const poker_Rankingsinsurence = [
      //   "Royal Flush","Straight" ,"Straight Flush", "Four of a Kind", 
      //   "Full House", "Flush", "Three of a Kind"
      // ];
      // if (poker_Rankingsinsurence.includes(res.playerHand)) {


      // res.playerHand == "Straight Flush" ||
      // res.playerHand == "Four of a Kind" ||
      // res.playerHand == "Full House" ||
      // res.playerHand == "Flush" ||
      // res.playerHand == "Three of a Kind"
      // ||res.playerHand == "High Card"||res.playerHand == "One Pair"

      const hands = [
        "onepair", "Threekind", "aceking", "fullhse",
        "Straightflush", "frofkind", "twopair",
        "flush", "stright", "royalflush"
      ];

      hands.forEach(hand => {
        document.getElementById(hand).style.color = "";
      });

      this.highlightPlayerCardsName(res.playerHand);



      if (res.bonus && Number(res.bonusWinAmount) > 0) {


        this.setState({ totalwinRussian: res.bonusWinAmount })
        // this.ShowBonuspayouttable();

        this.bonuspaytable.map((obj, val) => {
          const bonushandplayer = document.getElementById(`numrussian${val}`);
          const bonushandplayerval = document.getElementById(`numbersvalrussian${val}`);



          let playerbonuhand = res.playerHand.split(" , ");
          if (
            navigator.userAgentData &&
            navigator.userAgentData.platform &&
            navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)


          ) {
          }
          else {
            setTimeout(() => {

              // if (this.state.mutesounds) {
              //   this.plyerwin_snd = new Audio(plyerwin_snd)
              //   this.plyerwin_snd.pause();
              // }
              // else {

              //   this.plyerwin_snd = new Audio(plyerwin_snd)
              //   this.plyerwin_snd.play();

              // }
              this.playRemainingSounds(this.playerWin)

            }, 500)

          }


          if (bonushandplayer && playerbonuhand.includes(obj.k)) {

            if (
              navigator.userAgentData &&
              navigator.userAgentData.platform &&
              navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)


            ) {
              setTimeout(() => {
                // if (this.state.mutesounds) {
                //   this.plyerwin_snd = new Audio(plyerwin_snd)
                //   this.plyerwin_snd.pause();
                // }
                // else {

                //   this.plyerwin_snd = new Audio(plyerwin_snd)
                //   this.plyerwin_snd.play();

                // }
                this.playRemainingSounds(this.playerWin)
              }, 1500)

              if (bonushandplayer.classList.contains("num")) {
                bonushandplayer.classList.remove("num");

                bonushandplayer.classList.add("bonuswinhglt");
              }
              bonushandplayerval?.classList.add("bonuspayouthglt");

            }
            else {



              setTimeout(() => {
                this.ShowBonuspayouttable()
              }, 1500)
              const isPortrait = window.innerHeight > window.innerWidth;

              if (isPortrait) {
                if (bonushandplayer.classList.contains("numPortrait")) {
                  bonushandplayer.classList.remove("numPortrait")
                  bonushandplayerval.classList.add("bonuspayouthglt_portraite")
                  bonushandplayer.classList.add("bonuswinhglt_portrite")

                }

              }
              else {
                if (bonushandplayer.classList.contains("numPortrait")) {
                  bonushandplayer.classList.remove("numPortrait")
                  bonushandplayerval.classList.add("bonuspayouthglt_portraite")
                  bonushandplayer.classList.add("bonuswinhglt_portrite")

                }

              }

            }


          }
        });

        // if(true){
        const bonusText = document.querySelectorAll("#chip10centerbns span");
        // console.log(bonusText)
        for (let b = 0; b < bonusText.length; b++) {
          bonusText[b].textContent = res.bonusWinAmount;
        }

        if (mode === "Windows") {
          if (this.state.bonusbetamount) {

            gsap.set(["#chip10centerbns"], { clearProps: "all" });
            let t1 = gsap.timeline();
            t1.to("#chip10centerbns", {
              x: 339, y: 150, duration: 0.5, opacity: 1, delay: 1,
              onComplete: () => {
                gsap.set("#chip10centerbns", { opacity: 1 });
                document.getElementById("glowonwinchip").classList.add("chipglow_bonus")
              }
            });
            // t1.to("#chip10centerbns", {
            //   x: 861, y: 546, duration: 0.5, zIndex: -2, opacity: 0,
            //   onComplete: () => {
            //     gsap.set("#chip10centerbns", { opacity: 0, delay: 2 });
            //   }

            // });
            t1.play();

          }

        }
        else if (mode === "portrait") {
          this.playerWinBonus = true;
          // this.setState({ isbonuspattable: false });
          document.getElementById("bonustext").style.color = "";
          if (this.state.bonusbetamount) {
            if (document.getElementById("chip10centerbns")) {
              gsap.to("#chip10centerbns", {
                x: -630, y: 925, duration: 0.5, opacity: 1
                // x: -615, y: 920, duration: 0.5, opacity: 1
              });
            }

          }

        } else {
          this.playerWinBonus = true;
          document.getElementById("bonustext").style.color = "";
          if (this.state.bonusbetamount) {
            if (document.getElementById('chip10centerbns')) {

              gsap.to("#chip10centerbns", {
                x: 550, y: -130, duration: 0.5, autoAlpha: 1, ease: "power2.inOut",
              });

            }

          }

        }


      } else {






        if (mode === "Windows") {

          if (this.state.bonusbetamount > 0) {

            setTimeout(() => {
              this.playRemainingSounds(this.playerLose)

              // if (this.state.mutesounds) {
              //   this.playerLose = new Audio(playerLose)
              //   this.playerLose.pause();
              // }
              // else {

              //   this.playerLose = new Audio(playerLose)
              //   this.playerLose.play();

              // }

            }, 2300)
          }
          if (this.state.bonusbetamount) {

            let t1 = gsap.timeline();
            t1.to("#chip10centerbns", {
              x: 0, y: -649, duration: 0.5, opacity: 0, delay: 2.5,
              onComplete: () => {
                gsap.set("#chip10centerbns", { opacity: 0 });
              }
            });
            t1.to("#chip10centerbns", {
              x: 861, y: 557, duration: 0.1, zIndex: -2, opacity: 0,
              onComplete: () => {
                gsap.set("#chip10centerbns", { opacity: 0 });
              }

            });
            t1.play();
          }

        } else if (mode === "portrait") {
          if (this.state.bonusbetamount > 0) {

            setTimeout(() => {
              // if (this.state.mutesounds) {
              //   this.playerLose = new Audio(playerLose)
              //   this.playerLose.pause();
              // }
              // else {

              //   this.playerLose = new Audio(playerLose)
              //   this.playerLose.play();

              // }
              this.playRemainingSounds(this.playerLose)

            }, 300)
          }
          setTimeout(() => {
            this.playPokerChipSound(this.pokerchipsnd);
          }, 400)
          this.playerWinBonus = false;
          document.getElementById("bonustext").style.color = "";
          if (this.state.bonusbetamount) {
            if (document.getElementById('chip10centerbns')) {
              let t1 = gsap.timeline();
              t1.to("#chip10centerbns", {
                x: 0, y: -900, duration: 0.5, opacity: 1,
                onComplete: () => {
                  gsap.set("#chip10centerbns", { opacity: 0 });
                }
              });
              t1.to("#chip10centerbns", {
                // x: 0, y: 557, duration: 0.0005, zIndex: -2, opacity: 0,
                x: -163, y: 1195, duration: 0.0005, zIndex: -2, opacity: 0,
                onComplete: () => {
                  gsap.set("#chip10centerbns", { opacity: 0, });
                }

              });
              t1.play();
            }

          }


        } else {
          if (this.state.bonusbetamount > 0) {

            setTimeout(() => {
              // if (this.state.mutesounds) {
              //   this.playerLose = new Audio(playerLose)
              //   this.playerLose.pause();
              // }
              // else {

              //   this.playerLose = new Audio(playerLose)
              //   this.playerLose.play();

              // }
              this.playRemainingSounds(this.playerLose)

            }, 300)
          }
          setTimeout(() => {
            this.playPokerChipSound(this.pokerchipsnd);

          }, 400)
          this.playerWinBonus = false;
          document.getElementById("bonustext").style.color = "";
          if (this.state.bonusbetamount) {
            if (document.getElementById('chip10centerbns')) {
              let t1 = gsap.timeline();
              t1.to("#chip10centerbns", {
                x: 0, y: -900, duration: 0.5, autoAlpha: 1, ease: "power2.inOut",
              });
              t1.to("#chip10centerbns", {
                x: 0, y: 0, duration: 0.0001, zIndex: -2, autoAlpha: 0,

              });
              t1.play();
            }

          }

        }


      }


    }, 4000);

    if (mode === "Windows") {

    } else {
      document.getElementById("betactive").classList.add(betactive);
    }




    setTimeout(() => {

      // document.getElementById("bettext").style.color = "#ffffff";
      // document.getElementById("betactive").src = bnsactive;
      if (mode === "Windows") {
        document.getElementById("betRussian").classList.add("addpointer");
        document.getElementById("betactive").classList.add(betactive);
      }
    }, 5500)




    this.randomPlayerCards = [];
    this.shuffledPlayerCards = [];

    this.setState({
      revealCard: true,
      buttontext: "fold",
      betbutton: "Bet",
    });

  }







  rpAnteResultRes = (res) => {
    // this.setState({betdeductions:0,noupdateblnce:false})
    // this.state.betdeductions=0;
    // this.updateplayerbalnace();

    this.Showbonustouser(res);
    this.setState({ disClearBtn: 1, disBuybtn: 0 })
    // console.log("RP Ante Result: ");
    // console.log(res);
    // console.log(res.dealerCards);
    // console.log(res.playerCards);
    // console.log(res.playerFinalCards);

    this.randomPlayerCards = [...res.playerCards];
    this.shuffledPlayerCards = [...res.playerFinalCards];
    // console.log(this.randomPlayerCards)
    // console.log(this.shuffledPlayerCards)

    // this.randomDealerCards = [...res.dealerCards];

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)

    ) {
      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");
      betbutton.style.display = "none"
      foldbutton.style.display = "none"
      let betbtntextElement = document.getElementById("betbtntext")
      let foldtextElement = document.getElementById("foldtext")
      betbtntextElement.textContent = ""
      foldtextElement.textContent = ""


      document.getElementById("buttons_cover").classList.add("buttons_cover")
      setTimeout(() => {
        this.setState({ infoText: res.message })
      }, 7500)

    }

    for (let m = 0; m < res.dealerCards.length; m++) {
      if (res.dealerCards[m] === '-1') {
        this.randomDealerCards.push('xx')

      } else {
        this.randomDealerCards.push(res.dealerCards[m]);
      }
    }

    // console.log("this.randomDealerCards", this.randomDealerCards);
    // console.log("this.randomPlayerCards", this.randomPlayerCards);

    let cnt = this.randomPlayerCards.length;

    let mutesounds = this.state.mutesounds;

    function playSound(i) {
      let audio = new Audio(cardsdropping_snd);
      audio.currentTime = 0;
      if (mutesounds) {
        audio.pause();
      } else {
        audio.play();
      }


      audio.addEventListener('ended', function () {
        if (i + 1 < cnt) {
          this.clertime46 = setTimeout(() => {
            playSound(i + 1);
          }, 200)
          // },250)
        }
      });
    }


    for (let i = 0; i < cnt; i++) {
      var b1 = this.randomPlayerCards[i];
      // playSound(i);

      switch (i) {
        case 0:
          this.cardsRef.current.addCards("cardZero", b1, cnt);
          break;
        case 1:
          this.cardsRef.current.addCards("cardOne", b1, cnt);
          break;
        case 2:
          this.cardsRef.current.addCards("cardTwo", b1, cnt);
          break;
        case 3:
          this.cardsRef.current.addCards("cardThree", b1, cnt);
          break;
        case 4:
          this.cardsRef.current.addCards("cardFour", b1, cnt);
          break;
        case 5:
          this.cardsRef.current.addCards("cardFive", b1, cnt);
          break;
        default:
          break;
      }
    }

    document.getElementById("betpannelrussiancover").style.display = "block";
    this.clertime4 = setTimeout(() => {
      this.setState({
        pokerranking: res.playerHand,

      });
      this.onDrawingCards(this.shuffledPlayerCards, false, res, true);
      if (this.state.isMobilePortraitLndsp === true) {
        document.getElementById("playerDealerCardContainer").style.zIndex = 1;
      }
      // }, 1300)
    }, cnt * 2 * 500);

    setTimeout(() => {
      document.getElementById("betpannelrussiancover").style.display = "none";
    }, 7000)


    let dealerCardsLength = this.randomDealerCards.length;

    this.clertime5 = setTimeout(() => {
      for (let i = 0; i < dealerCardsLength; i++) {
        var a1 = this.randomDealerCards[i];
        // playSound(i);
        switch (i) {
          case 0:
            this.dealerCardsContainer.current.addCards(
              "cardZero",
              a1,
              dealerCardsLength
            );
            break;
          case 1:
            this.dealerCardsContainer.current.addCards(
              "cardOne",
              a1,
              dealerCardsLength
            );
            break;
          case 2:
            this.dealerCardsContainer.current.addCards(
              "cardTwo",
              a1,
              dealerCardsLength
            );
            break;
          case 3:
            this.dealerCardsContainer.current.addCards(
              "cardThree",
              a1,
              dealerCardsLength
            );
            break;
          case 4:
            this.dealerCardsContainer.current.addCards(
              "cardFour",
              a1,
              dealerCardsLength
            );
            break;
          default:
            break;
        }
      }
      // const foldbutton = document.getElementById("Foldbtn");
      // foldbutton.src = Foldbnwindows;
      // document.getElementById("foldtext").textContent = "Fold";
      // document.getElementById("foldtext").classList.add("foldtextwindows");
      // foldbutton.style.position = porttraitejson.foldbtnwindows.position;
      // foldbutton.style.top = porttraitejson.foldbtnwindows.top;
      // foldbutton.style.right = porttraitejson.foldbtnwindows.right;
      // foldbutton.style.width = porttraitejson.foldbtnwindows.width;
      // foldbutton.style.zIndex = porttraitejson.foldbtnwindows.zIndex;
      // }, dealerCardsLength * 300);
    }, dealerCardsLength * 400);

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)

    ) {

      document.getElementById("betcircle").classList.add("addpointer")
      document.getElementById("betcircle").classList.remove("insurencenodrop");
    }

  };



  onDrawingCards(data, drawBuyHideAndShow, res, cardBasedOnSound) {

    // console.log(data);
    let cnt = data.length;
    for (let i = 0; i < cnt; i++) {
      var b1 = data[i];
      this.cardsshuffle = new Audio(cardsshuffle);
      this.cardsshuffle.currentTime = 0;
      // if (cardBasedOnSound) {
      //   if (b1) {
      //     if (this.cardsshuffle) {
      //       if (this.state.mutesounds) {
      //         this.cardsshuffle.pause();
      //       } else {
      //         this.cardsshuffle.play()

      //       }
      //     }


      //   }
      // }
      switch (i) {
        case 0:
          this.cardsRef.current.SuffleCard("cardZero", b1, cnt);
          break;
        case 1:
          this.cardsRef.current.SuffleCard("cardOne", b1, cnt);
          break;
        case 2:
          this.cardsRef.current.SuffleCard("cardTwo", b1, cnt);
          break;
        case 3:
          this.cardsRef.current.SuffleCard("cardThree", b1, cnt);
          break;
        case 4:
          this.cardsRef.current.SuffleCard("cardFour", b1, cnt);
          break;
        case 5:
          this.cardsRef.current.SuffleCard("cardFive", b1, cnt);
          break;
        default:
          break;
      }
    }


    this.clertime6 = setTimeout(() => {
      this.setState({ infoText: res.message });
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {

        this.showParamBetStatus(drawBuyHideAndShow)

      }

      else {
        this.showParamBetStatus(drawBuyHideAndShow)
      }
    }, 2000)
  }


  showParamBetStatus = (drawBuyHideAndShow) => {
    const drawbutton = document.getElementById("drawbtn");
    const buybutton = document.getElementById("buybtn");
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");

    const drawtextElement = document.getElementById("drawtext");
    const buytextElement = document.getElementById("buytext");
    const betbtntextElement = document.getElementById("betbtntext");
    const foldtextElement = document.getElementById("foldtext");

    betbtntextElement.textContent = "";
    foldtextElement.textContent = "";

    drawbutton.src = "";
    drawbutton.style.cssText = "";


    buybutton.src = "";
    buybutton.style.cssText = "";

    betbutton.src = "";
    betbutton.style.cssText = "";


    foldbutton.src = "";
    foldbutton.style.cssText = "";

    drawtextElement.classList.add("drawtextopacity");

    this.setState({ drawbuttonhandle: true, disDrawbtn: 1 });

    document.getElementById("bettext").style.color = "#ffffff";
    document.getElementById("betactive").src = bnsactive;

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      this.buyCardsequenceArray = [];
      this.setState({ keyboardhandle: true, disBuybtn: 0, keyboardhandle: true, Foldbtn: 0 })



      if (drawBuyHideAndShow) {
        drawbutton.src = "";
        buybutton.src = "";
        document.getElementById("buytext").classList.remove("buytextwindows");
        document.getElementById("drawtext").textContent = "";

        document.getElementById("buytext").classList.remove("buytextwindows");
        document.getElementById("buytext").textContent = "";
        this.setState({ disDrawbtn: 1, disBuybtn: 1 })

      } else {
        document.getElementById("crdscover").classList.remove("crdscover")
        document.getElementById("buttons_cover").classList.remove("buttons_cover")
        drawbutton.src = Drawbtnwindows;
        buybutton.src = Buybtnwindows;
        document.getElementById("buytext").classList.add("buytextwindows");
        document.getElementById("drawtext").textContent = this.props.language.Draw;
        document.getElementById("buytext").textContent = this.props.language.BuyOne;
        Object.assign(drawbutton.style, porttraitejson.Drawbtnwindows);
        Object.assign(buybutton.style, porttraitejson.Buybtnwindows);
        this.setState({ keyboardhandle: true, disBuybtn: 0, disBetBtn: 0, keyboardhandle: true, betBtnValue: 1 })


      }


      betbutton.src = Betbtn;
      Object.assign(betbutton.style, porttraitejson.Betbtnwindows);
      document.getElementById("betbtntext").textContent = this.props.language.Bet;
      document.getElementById("betbtntext").classList.add("betwindows");


      foldbutton.src = Foldbnwindows;
      Object.assign(foldbutton.style, porttraitejson.foldbtnwindows);
      document.getElementById("foldtext").textContent = this.props.language.Fold;
      document.getElementById("foldtext").classList.add("foldtextwindows");
      document.getElementById("betpannelrussiancover").style.display = "block";
      document.getElementById("betpannelrussiancover").classList.add("bet_pannelcoverrussian");

    }

    else {
      drawtextElement.textContent = "";
      const isPortrait = window.innerHeight > window.innerWidth;
      document.getElementById("mobileAntiMaskCover").style.display = "block";
      if (isPortrait) {
        if (drawBuyHideAndShow) {
          drawtextElement.classList.remove("drawtextPortrait");
          drawtextElement.textContent = "";
          buytextElement.classList.remove("buytextPortrait");
          buytextElement.textContent = "";
          drawbutton.src = "";
          buybutton.src = "";

        } else {

          drawbutton.src = Drawbtn;
          buybutton.src = Buybtn;
          drawtextElement.classList.add("drawtextPortrait");
          drawtextElement.textContent = this.props.language.Draw;
          buytextElement.classList.add("buytextPortrait");
          buytextElement.textContent = this.props.language.BuyOne;

          Object.assign(drawbutton.style, porttraitejson.Drawbtnportraite);
          Object.assign(buybutton.style, porttraitejson.Buybtnportraite);

        }

        betbtntextElement.textContent = this.props.language.Bet;
        betbtntextElement.classList.add("betportrait");
        foldtextElement.textContent = this.props.language.Fold;
        foldtextElement.classList.add("foldtextportrait");
        betbutton.src = Betbtn;
        Object.assign(betbutton.style, porttraitejson.Betbtnportraite);


        foldbutton.src = Foldbn;
        Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);


      } else {

        if (drawBuyHideAndShow) {
          drawtextElement.classList.remove("drawtextLndsp");
          drawtextElement.textContent = "";
          buytextElement.classList.remove("buytextLndsp");
          buytextElement.textContent = "";
          drawbutton.src = "";
          buybutton.src = "";

        } else {
          drawbutton.src = Drawbtn;
          buybutton.src = Buybtn;
          drawtextElement.classList.add("drawtextLndsp");
          drawtextElement.textContent = this.props.language.Draw;
          buytextElement.classList.add("buytextLndsp");
          buytextElement.textContent = this.props.language.BuyOne;

          Object.assign(drawbutton.style, porttraitejson.Drawbtnlndspe);
          Object.assign(buybutton.style, porttraitejson.Buybtnlndspe);

        }

        betbtntextElement.textContent = this.props.language.Bet;
        betbtntextElement.classList.add("betLandscape");
        foldtextElement.textContent = this.props.language.Fold;
        foldtextElement.classList.add("foldtextLandscape");
        betbutton.src = Betbtn;
        Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);

        foldbutton.src = Foldbn;
        Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);


      }
    }




  }



  drawCard() {

    if (this.state.mutesounds) {
      this.betrussiansnd.pause();

    } else {
      this.betrussiansnd.play();

    }
    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }

    // const replaceCardslength = this.replaceCards.length
    if (Number((this.state.anteBetAmount * 3 + this.state.anteBetAmount + this.state.bonusbetamount).toFixed(2)) > this.props.balance) {
      this.setState({
        betshigh_balnce: true,
        lowBalaceErrorInfo: `Insufficient Funds to place the Draw of ${(this.state.anteBetAmount).toFixed(1)},Please DEPOSIT to continue playing.`
      });
      this.lowBalanceErrorInformationPopupanimation()
    } else {
      if (this.state.betshigh_balnce === true) {
        this.hidepopup_betshigh();
      }
      this.setState({ betshigh_balnce: null })



      this.playPokerChipSound(this.pokerchipsnd);

      const drawbutton = document.getElementById("drawbtn");
      const buybutton = document.getElementById("buybtn");
      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");

      const drawtextElement = document.getElementById("drawtext");
      const buytextElement = document.getElementById("buytext");

      const betbtntextElement = document.getElementById("betbtntext");
      const foldtextElement = document.getElementById("foldtext");


      drawbutton.src = "";
      drawbutton.style.cssText = ""

      buybutton.src = "";
      buybutton.style.cssText = ""


      betbutton.src = "";
      betbutton.style.cssText = ""


      foldbutton.src = "";
      foldbutton.style.cssText = ""



      drawtextElement.textContent = "";
      buytextElement.textContent = "";
      betbtntextElement.textContent = "";
      foldtextElement.textContent = "";



      let betchipsaddtodrawContainerElement = document.getElementById("betchipsaddtodrawContainer");
      let betchipsdraw = document.getElementById("betchipsaddtodraw");
      let betTextaddtodrawElement = document.getElementById("betchipsaddtodrawText");



      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {

        this.setState((prevState) => ({
          totalbet: prevState.totalbet + prevState.anteBetAmount,
          buydrawCheck: !prevState.buydrawCheck,
          betdeductions: prevState.betdeductions + prevState.anteBetAmount
        }));
        document.getElementById("crdscover").classList.add("crdscover")
        this.drawbuybets(this.state.anteBetAmount)

        const drawbutton = document.getElementById("drawbtn");
        const buybutton = document.getElementById("buybtn");

        // document.getElementById("drawtext").classList.remove("drawtextwindows");

        document.getElementById("buytext").classList.remove("buytextwindows");

      }

      else {

        let betTextaddtodrawElementClass = "bet-text-addtoBet20";

        betchipsdraw.src = this.ReturnChipBasedAmount(Number((this.state.anteBetAmount).toFixed(2)));
        switch (this.state.count) {
          case 1:
            // betchipsdraw.src = chip_10;
            betTextaddtodrawElementClass = "bet-text-addtoBet10";
            break;
          case 2:
            // betchipsdraw.src = chip_25;
            break;
          case 3:
            // betchipsdraw.src = chip_50;
            break;
          case 4:
            // betchipsdraw.src = chip_100;

            break;
          case 5:
            // betchipsdraw.src = chip_500;
            break;
          case 6:
            // betchipsdraw.src = chip_1000;
            break;

          default:
            betTextaddtodrawElementClass = "bet-text-addtoBet20";
            break;
        }

        this.buydrawchipactivated = true;

        this.setState((prevState) => ({
          totalbet: prevState.totalbet + (prevState.anteBetAmount),
          buydrawCheck: !prevState.buydrawCheck,
          betdeductions: prevState.betdeductions + (prevState.anteBetAmount),
          infoText: ""
        }));

        betTextaddtodrawElement.textContent = this.topLable_rsp(this.state.anteBetAmount);
        document.getElementById("betpannelrussiancover").style.display = "block";
        document.getElementById("playerDealerCardContainer").style.zIndex = 20;
        if (window.innerHeight > window.innerWidth) {

          drawtextElement.classList.remove("betportrait");
          buytextElement.classList.remove("foldtextportrait");

          betchipsaddtodrawContainerElement.classList.add("drawChipDiv");
          betTextaddtodrawElement.className = "";
          betTextaddtodrawElement.style.cssText = "";
          betTextaddtodrawElement.classList.add(betTextaddtodrawElementClass, 'betTextaddtodraw');
          betchipsdraw.classList.add("betchipsaddtodraw");

          gsap.to(this.chipdrawref.current, {
            // x: 360,
            x: 322,
            y: -1230,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 12,
            opacity: 1,
            autoAlpha: 1
          });

        } else {
          drawtextElement.classList.remove("betLandscape");
          buytextElement.classList.remove("foldtextLandscape");

          betchipsaddtodrawContainerElement.classList.add("drawChipDivLndsp");
          betTextaddtodrawElement.className = "";
          betTextaddtodrawElement.style.cssText = "";
          betTextaddtodrawElement.classList.add(betTextaddtodrawElementClass, 'betTextaddwLndsp');
          betchipsdraw.classList.add("betchipsaddtodraw")

          gsap.to(this.chipdrawref.current, {
            x: -1230,
            y: 0,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 12,
            autoAlpha: 1,
            autoAlpha: 1
          });

        }
      }

      // console.log(this.replaceCards);
      const body = {
        sessionId: sessionStorage.getItem("sessionId"),
        CN: "RUSSIAN_POKER_BET",
        object: {
          gameId: this.props.gameId,
          action: "REPLACE_CARDS",
          playerId: Number(this.props.playerId),
          // pcards: this.replaceCards,
          pcards: this.replaceCards,
          handId: Number(this.props.gameState.handId)
        },
      };
      // console.log(body);
      this.props.network.sendof(body);

      document.getElementById("buybtn").style.opacity = "1"
      document.getElementById("buytext").style.opacity = "1"
      this.setState({ disableBuyButton: false })



    }


  }

  revealDealercards(res) {
    this.dealerCardsRandom = [...res.dealerCards];
    this.dealerShuffledCards = [...res.dealerFinalCards];
    // this.setState({ totalwinRussian: res.totalWinAmount });
    // console.log(this.state.totalwinRussian);
    let cnt = this.dealerCardsRandom.length;
    // if (this.state.mutesounds) {

    //   this.reaveldealer_cards.pause()
    //   .then((e) =>{})
    //   .catch((e) =>{})
    // } else {
    //   this.reaveldealer_cards.play()
    //   .then((e) =>{})
    //   .catch((e) =>{})
    // }
    this.playRemainingSounds(this.reaveldealer_cards)

    for (let i = 0; i < cnt; i++) {
      let b1 = this.dealerCardsRandom[i];
      switch (i) {
        case 0:
          if (this.randomDealerCards[0] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardZero", b1, cnt);
          }
          break;
        case 1:
          if (this.randomDealerCards[1] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardOne", b1, cnt);
          }
          break;
        case 2:
          if (this.randomDealerCards[2] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardTwo", b1, cnt);
          }
          break;
        case 3:
          if (this.randomDealerCards[3] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardThree", b1, cnt);
          }
          break;
        case 4:
          if (this.randomDealerCards[4] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardFour", b1, cnt);
          }
          break;
        case 5:
          if (this.randomDealerCards[5] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardFive", b1, cnt);
          }
          break;
        default:
          break;
      }

    }

    this.clertime6 = setTimeout(() => {
      // console.log("before", this.randomDealerCards);
      // this.suffleDealerCards(this.randomDealerCards.sort(() => Math.random()-0.5));
      this.suffleDealerCards(this.dealerShuffledCards);
      // console.log("after", this.randomDealerCards);
    }, this.dealerShuffledCards.length * 500);

  }
  updateplayerbalnace = () => {
    this.props.gamstateHandle();
    // this.setState({betdeductions:0,noupdateblnce:true})
    const body2 = {
      CN: "GET_PLAYER_INFO",
      object: {
        gameId: Number(this.props.gameId,),
        playerId: Number(this.props.playerId),

      },
      sessionId: sessionStorage.getItem('sessionId'),
    }
    this.props.network.sendof(body2);
    this.body2 = {};

  }
  updateplayerbalnaceIfplayerWins = () => {
    this.props.gamstateHandle();
    // this.setState({betdeductions:0,noupdateblnce:true})
    const body2 = {
      CN: "GET_UPDATED_PLAYER_INFO",
      object: {
        gameId: Number(this.props.gameId,),
        playerId: Number(this.props.playerId),

      },
      sessionId: sessionStorage.getItem('sessionId'),
    }
    this.props.network.sendof(body2);
    this.body2 = {};

  }

  highlightPlayerCardsName = (playerHand) => {
    this.setState({
      pokerranking: playerHand
    });

    const hands = [
      "onepair", "Threekind", "aceking", "fullhse",
      "Straightflush", "frofkind", "twopair",
      "flush", "stright", "royalflush"
    ];

    hands.forEach(hand => {
      document.getElementById(hand).style.color = "";
    });


    let handrank = playerHand.split(" , ");
    // console.log(handrank)

    handrank.forEach(hand => {
      switch (hand) {
        case "Royal Flush":
          document.getElementById("royalflush").style.color = "#ffffff";
          break;
        case "Straight Flush":
          document.getElementById("Straightflush").style.color = "#ffffff";
          break;
        case "Four of a Kind":
          document.getElementById("frofkind").style.color = "#ffffff";
          break;
        case "Full House":
          document.getElementById("fullhse").style.color = "#ffffff";
          break;
        case "Flush":
          document.getElementById("flush").style.color = "#ffffff";
          break;
        case "Straight":
          document.getElementById("stright").style.color = "#ffffff";
          break;
        case "Three of a Kind":
          document.getElementById("Threekind").style.color = "#ffffff";
          break;
        case "Two Pair":
          document.getElementById("twopair").style.color = "#ffffff";
          break;
        case "One Pair":
          document.getElementById("onepair").style.color = "#ffffff";
          break;
        case "Ace-King":
          document.getElementById("aceking").style.color = "#ffffff";
          break;
        default:
          break;
      }


    })


  }
  insurenceactiveornot = (res) => {
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");

    if (res.insurance) {

      // if(true){
      document.getElementById('betactive').src = ""
      document.getElementById("betactive").classList.remove("betactive");
      document.getElementById("betdecrement").classList.remove("drwnodrop")
      document.getElementById("betincrenet").classList.remove("drwnodrop")
      document.getElementById("insurenceactive").src = bnsactive;
      document.getElementById("insurenceactive").classList.add("insurenceactivewindows");
      document.getElementById("insurenceactive").classList.add("insurenceblink");
      document.getElementById("insurenceRussian").classList.add("addpointer");
      document.getElementById("betpannelinsurececover").style.display = "block";
      document.getElementById("betpannelrussiancover").classList.add("bet_pannelinsurence");
      document.getElementById("insurenceRussian").classList.remove("insurencenodrop")
      // document.getElementById("Foldbtn").classList.add("insurencenodrop")

      document.getElementById("foldtext").classList.remove("rebettextwindows")
      // document.getElementById("insurenceactive").classList.add("bnsctivewindows");


      //  this.revealDealercards(res)


      document.getElementById("insurencetext").style.color = "#ffffff";
      this.setState({
        buttontext: "insurence",
        betbutton: "noinsurence", insurencebtn: false,
        betdecreamentbtn: false, betchipincrementbtn: false,
        infoText: res.message
        // disablemultibutton: true
      });
      betbutton.src = Betbtn;
      Object.assign(betbutton.style, porttraitejson.Betbtnwindows);

      document.getElementById("betbtntext").textContent = this.props.language.No_Insurence;
      document.getElementById("betbtntext").classList.add("noinsurence");

      foldbutton.src = Foldbnwindows;
      Object.assign(foldbutton.style, porttraitejson.foldbtnwindows);

      document.getElementById("foldtext").textContent = this.props.language.Insurence;
      document.getElementById("foldtext").classList.add("insurence");
      setTimeout(() => {
        this.setState({ infoText: "" })


      }, 2000);
    } else {
      this.clertime7 = setTimeout(() => {
        document.getElementById("rankinginfodeler").classList.add("windowspokerrankingdeler");
        this.setState({ pokerrankingdeler: res.dealerHand, })
      }, 3500);

      document.getElementById("betbtntext").textContent = "";
      document.getElementById("foldtext").textContent = "";
      this.revealDealercards(res)
      setTimeout(() => {
        this.checkpayerordealerwins(res);

      }, 3500)

    }
  }


  Showinsurenceactiveornot = (res) => {
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");

    if (res.insurance) {

      document.getElementById('betactive').src = ""
      document.getElementById("betactive").classList.remove("betactive");
      document.getElementById("rankinginfo").classList.add("windowspokerranking");
      document.getElementById("betpannelinsurececover").style.display = "block";
      document.getElementById("betpannelinsurececover").classList.add("bet_pannelinsurence");

      this.setState({ pokerranking: res.playerHand, insurenceMinbetAmt: res.insuranceMinBetAmount, insurenceMaxbetAmt: res.insuranceMaxBetAmount })
      document.getElementById("betdecrement").classList.remove("drwnodrop")
      document.getElementById("betincrenet").classList.remove("drwnodrop")
      document.getElementById("insurenceactive").src = bnsactive;
      document.getElementById("insurenceactive").classList.add("insurenceactivewindows");
      document.getElementById("insurenceactive").classList.add("insurenceblink");
      document.getElementById("insurenceRussian").classList.add("addpointer");
      document.getElementById("betpannelrussiancover").style.display = "none";
      document.getElementById("betpannelrussiancover").classList.add("bet_pannelcoverrussian");
      document.getElementById("insurenceRussian").classList.remove("insurencenodrop")
      // document.getElementById("Foldbtn").classList.add("insurencenodrop")
      document.getElementById("foldtext").classList.remove("rebettextwindows")
      document.getElementById("foldtext").classList.remove("foldtextwindows")



      // document.getElementById("insurenceactive").classList.add("bnsctivewindows");


      //  this.revealDealercards(res)
      // this.clertime56=setTimeout(() => {
      //   this.setState({ infoText: res.message})
      // }, 3000);
      this.setState({
        insurencebtn: false,
        // disablemultibutton: true,
        betdecreamentbtn: false,
        betchipincrementbtn: false,
        pokerranking: res.playerHand,
        infoText: res.message,
        anteBetAmount: res.anteBetAmount

      });

      document.getElementById("insurencetext").style.color = "#ffffff";
      this.setState({
        buttontext: "insurence",
        betbutton: "noinsurence", insurencebtn: false,
        betdecreamentbtn: false, betchipincrementbtn: false,
        // disablemultibutton: true
      });
      betbutton.src = Betbtn;
      Object.assign(betbutton.style, porttraitejson.Betbtnwindows);

      document.getElementById("betbtntext").textContent = this.props.language.No_Insurence;
      document.getElementById("betbtntext").classList.add("noinsurence");

      foldbutton.src = Foldbnwindows;
      Object.assign(foldbutton.style, porttraitejson.foldbtnwindows);

      document.getElementById("foldtext").textContent = this.props.language.Insurence;
      document.getElementById("foldtext").classList.add("insurence");
      setTimeout(() => {
        this.setState({ infoText: "", })

      }, 2000)

      this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerCards);
      if (res.betAmount > 0) {

        this.Showebetchipsgamestatehandle(res)
      }

    } else {
      // this.clertime7=setTimeout(() => {
      //   document.getElementById("rankinginfodeler").classList.add("windowspokerrankingdeler");
      //   this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand, })
      // }, 3000);

      document.getElementById("betbtntext").textContent = "";
      document.getElementById("foldtext").textContent = "";

      this.showcheckpayerordealerwins(res);

    }
  }

  showrussianPokerresp = (res) => {
    // console.log(res)
    //// }, dealerCardsLength*300+3000)
    // this.setState({betdeductions:0,noupdateblnce:false})
    // this.state.betdeductions=0;
    //  this.updateplayerbalnace();

    // document.getElementById("rankinginfodeler").style.textContent= res.message
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    const betTextaddtoBetElement = document.getElementById("betTextaddtoBet");
    this.setState({ playerhand_status: res.playerHand, disableBuyButton: false })
    document.getElementById("buybtn").style.opacity = "1"
    document.getElementById("buytext").style.opacity = "1"


    const betbtntextElement = document.getElementById("betbtntext");
    const foldtextElement = document.getElementById("foldtext");

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      // let t1 = gsap.timeline();
      //     t1.to("#chip10centerbns", {
      //       x:813, y:553, duration: 0.5, opacity: 0,
      //       onComplete: () => {
      //         gsap.set("#chip10centerbns", { opacity:0});
      //       }
      //     });
      this.Showinsurenceactiveornot(res)
      // this.revealDealercards(res);

    }

    else {
      document.getElementById("insurenceactive").className = "";
      this.setState({
        insurenceMinbetAmt: res.insuranceMinBetAmount, insurenceMaxbetAmt: res.insuranceMaxBetAmount

      });

      if (window.innerHeight > window.innerWidth) {

        if (res.insurance && res.winner !== "Player" && res.winner !== "Dealer" && res.winner !== "Dealer_no_hand") {
          this.shwnPrvsSteBuyDrawBtchips(res);
          this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerCards);
          document.getElementById("insurencetext").style.color = "#ffffff";
          document.getElementById("insurenceactive").src = bnsactive;
          document.getElementById("insurenceactive").classList.add("insurenceactivePortrait");
          // document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerPortrait");

          betbutton.style.cssText = "";
          foldbutton.style.cssText = "";
          this.setState({
            buttontext: "insurence",
            betbutton: "noinsurence",
            insurencebtn: false,
            // disablemultibutton: true,
            betdecreamentbtn: false,
            betchipincrementbtn: false,
            pokerranking: res.playerHand,
            // pokerrankingdeler:res.dealerHand,
            infoText: res.message,
            totalbet: res.totalBetAmount,
          });

          setTimeout(() => { this.setState({ infoText: "" }) }, 2000);

          // document.getElementById("foldtext").style.opacity = "0.5";
          // foldbutton.style.opacity = "0.5"

          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnportraite);


          foldbutton.src = Foldbn;
          Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);

          betbtntextElement.textContent = this.props.language.No_Insurence;
          betbtntextElement.className = "";
          betbtntextElement.style.cssText = "";
          betbtntextElement.classList.add("noinsurencePortrait");
          document.getElementById("foldtext").textContent = this.props.language.Insurence;
          document.getElementById("foldtext").classList.add("insurencePortrait");
        } else {

          // setTimeout(() => {
          //   this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand, })
          //   document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerPortrait");
          // }, 3000);



          switch (res.winner) {
            case "Dealer":
            case "Player":
              this.showPlayerorDealerorFold(res)

              // this.ifDealerWinsMobilePortraitAction();
              // break;
              // this.ifPlayerWinsMobilePortraitAction();

              // if(Number(res.anteBetAmount) >0){
              //   let convrAntebt = this.topLable_rsp(res.anteBetAmount);
              //   this.setState((prevState) => ({
              //     storeantebetchips: [
              //       ...prevState.storeantebetchips,
              //       { chip: chip_10, chipvalue: convrAntebt, chipcolor1: "valtextPortrait1" },

              //     ],
              //   }));

              // }


              break;

            case "Dealer_no_hand":
              this.dealerShuffledCards = [...res.dealerFinalCards]
              if (res.insurance) {
                if (res.insuranceBetAmount > 0) {
                  let convrInsurenceBet = this.topLable_rsp(res.insuranceBetAmount);
                  this.setState((prevState) => ({
                    storeinsurancechips: [
                      ...prevState.storeinsurancechips, { chip: this.ReturnChipBasedAmount(res.insuranceBetAmount), chipvalue: convrInsurenceBet, chipcolor1: "insuranceTextPortraitChip10" },
                    ],
                    insurencetbet: res.insuranceBetAmount,

                  }));
                }
              }





              this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerFinalCards);
              this.shwnPrvsSteBuyDrawBtchips(res)
              document.getElementById("betactive").src = "";
              document.getElementById("bettext").style.color = "";
              document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerPortrait");
              this.setState({
                betbutton: "YES", buttontext: "NO", Yesbtn: 1, Nobtn: 1, keyboardhandle: true,
                infoText: res.message, pokerrankingdeler: res.dealerHand, pokerranking: res.playerHand
              });
              document.getElementById("betbtntext").textContent = this.props.language.Yes;
              betbutton.src = Betbtn;
              Object.assign(betbutton.style, porttraitejson.Betbtnportraite);


              foldbutton.src = Foldbn;
              Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);

              // foldbutton.src = Foldbn;
              foldtextElement.textContent = this.props.language.No;
              document.getElementById("betbtntext").classList.remove("insurence", "betwindows");
              foldtextElement.classList.remove("noinsurence", "foldtextwindows");
              document.getElementById("betbtntext").classList.add("betportrait");
              foldtextElement.classList.add("foldtextportrait");
            default:
              break;
          }

        }


      } else {

        if (res.insurance && res.winner !== "Player" && res.winner !== "Dealer" && res.winner !== "Dealer_no_hand") {
          this.shwnPrvsSteBuyDrawBtchips(res);
          this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerCards);
          document.getElementById("insurencetext").style.color = "#ffffff";
          document.getElementById("insurenceactive").src = bnsactive;
          document.getElementById("insurenceactive").classList.add("insurenceactiveLndsp");
          if (this.state.pokerrankingdeler !== "") {
            document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerLndSpe");
          }
          betbutton.style.cssText = "";
          foldbutton.style.cssText = "";
          this.setState({
            infoText: res.message,
            // pokerrankingdeler: res.dealerHand,
            pokerranking: res.playerHand,
            buttontext: "insurence",
            betbutton: "noinsurence",
            insurencebtn: false,
            // disablemultibutton: true,
            betdecreamentbtn: false,
            betchipincrementbtn: false,

          });

          setTimeout(() => { this.setState({ infoText: "" }) }, 2000)
          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);


          foldbutton.src = Foldbn;
          Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);

          document.getElementById("betbtntext").textContent = this.props.language.No_Insurence;
          document.getElementById("betbtntext").className = "";
          document.getElementById("betbtntext").style.cssText = "";
          document.getElementById("betbtntext").classList.add("betLandscape");
          document.getElementById("foldtext").textContent = this.props.language.Insurence;
          document.getElementById("foldtext").classList.add("foldtextLandscape");

          // document.getElementById("foldtext").style.opacity = "0.5";
          // foldbutton.style.opacity = "0.5"
        } else {
          this.prevStatecheckPlayerOrDealerWinMblLndspe(res);

        }

      }

    }

  }


  shwnPrvsSteBuyDrawBtchips = (res) => {

    const rankinginfoElement = document.getElementById("rankinginfo");

    if (Number(res.anteBetAmount) > 0) {
      let convrAntebt = this.topLable_rsp(res.anteBetAmount);
      this.setState((prevState) => ({
        anteBetAmount: res.anteBetAmount,
        totalbet: res.totalBetAmount,
        infoText: res.message,
        //  storeantebetchips: [
        //    ...prevState.storeantebetchips,
        //    { chip: chip_10, chipvalue: convrAntebt, chipcolor1: "valtextPortrait1" },
        //  ],
      }));

    }

    if (res.playerReplaceCardAmount > 0 || res.playerBuyCardAmount > 0) {
      this.prevstateBuyorDrawChip(res);
    }


    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");

    const drawbutton = document.getElementById("drawbtn");
    const buybutton = document.getElementById("buybtn");
    let betTextaddtoBetElement = document.getElementById("betTextaddtoBet");
    let betchipsaddtobetwindows = document.getElementById("betchipsaddtobet");
    let betTextaddtoBetClass = "bet-text-addtoBet20";





    let convrBet = this.topLable_rsp(res.betAmount);
    betchipsaddtobetwindows.src = this.ReturnChipBasedAmount(res.betAmount);

    switch (this.state.count) {
      case 1:
        // betchipsaddtobetwindows.src = chip_10;
        // betTextaddtoBetElement.textContent = 10;
        betTextaddtoBetElement.textContent = convrBet;
        betTextaddtoBetClass = "bet-text-addtoBet10";
        break;
      case 2:
        // betchipsaddtobetwindows.src = chip_25;
        betTextaddtoBetElement.textContent = convrBet;
        break;
      case 3:
        // betchipsaddtobetwindows.src = chip_50;
        betTextaddtoBetElement.textContent = convrBet;
        break;
      case 4:
        // betchipsaddtobetwindows.src = chip_100;
        betTextaddtoBetElement.textContent = convrBet;
        break;
      case 5:
        // betchipsaddtobetwindows.src = chip_500;
        betTextaddtoBetElement.textContent = convrBet;
        break;
      case 6:
        // betchipsaddtobetwindows.src = chip_1000;
        betTextaddtoBetElement.textContent = convrBet;
        break;

      default:
        betTextaddtoBetClass = "bet-text-addtoBet20"
        break;
    }
    this.betchipactivated = true;

    document.getElementById("betpannelrussiancover").style.display = "block";
    if (window.innerHeight > window.innerWidth) {
      rankinginfoElement.classList.add("pokerrankingPortait");
      betTextaddtoBetElement.className = "";
      // betTextaddtoBetElement.classList.add(betTextaddtoBetClass, "bet-text-addtoBet-portrait");
      betTextaddtoBetElement.style.color = "#ffffff";
      Object.assign(betchipsaddtobetwindows.style, porttraitejson.BetchiptobetPortrait);
      gsap.to(this.chipbetref.current, {
        // x: -10,
        // y: -950,
        x: -27,
        y: -925,
        duration: 0.00001,
        zIndex: 2,
        autoAlpha: 1,
        opacity: 1,
      });


      gsap.to(betTextaddtoBetElement, {
        // x: 12,
        // y: -1739,
        x: -3,
        y: -1710,
        duration: 0.00001,
        zIndex: 2,
        opacity: 1,
        visibility: true,
        onStart: function () {
          betTextaddtoBetElement.style.zIndex = 2;
          betTextaddtoBetElement.style.visibility = 'visible';
        }
      });

    } else {

      rankinginfoElement.classList.add("pokerrankingPlayerLndsp");
      betTextaddtoBetElement.className = "";
      // betTextaddtoBetElement.classList.add(betTextaddtoBetClass, "bet-text-addtoBet-lndsp");
      betTextaddtoBetElement.style.color = "#ffffff";
      Object.assign(betchipsaddtobetwindows.style, porttraitejson.BetchiptobetLndsp);
      gsap.to(this.chipbetref.current, {

        x: -1060,
        y: 170,
        duration: 0.00001,
        zIndex: 2,
        autoAlpha: 1
      });


      gsap.to(betTextaddtoBetElement, {
        x: -1060,
        y: 170,
        duration: 0.00001,
        zIndex: 2,
        autoAlpha: 1,
        onStart: function () {
          betTextaddtoBetElement.style.zIndex = 2;
          betTextaddtoBetElement.style.visibility = 'visible';
        }
      });


      if (this.playerWinBonus === true) {
        if (document.getElementById('chip10centerbns')) {
          gsap.to("#chip10centerbns", {
            x: 0, y: 0, duration: 0.00001, autoAlpha: 0,
          });
        }
      }


    }



  }



  prevStatecheckPlayerOrDealerWinMblLndspe = (res) => {

    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    const drawtextElement = document.getElementById("drawtext");
    const buytextElement = document.getElementById("buytext");
    const betbtntextElement = document.getElementById("betbtntext");
    const foldtextElement = document.getElementById("foldtext");

    const betTextaddtoBetElement = document.getElementById("betTextaddtoBet");
    this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand, })
    document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerLndSpe");
    const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popup");

    betbutton.className = "";
    betbutton.style.cssText = "";

    foldbutton.className = "";
    foldbutton.style.cssText = "";

    drawtextElement.className = "";
    drawtextElement.style.cssText = "";
    drawtextElement.textContent = "";

    buytextElement.className = "";
    buytextElement.style.cssText = "";
    buytextElement.textContent = "";

    betbtntextElement.className = "";
    betbtntextElement.style.cssText = "";
    betbtntextElement.textContent = "";

    foldtextElement.className = "";
    foldtextElement.style.cssText = "";
    foldtextElement.textContent = "";


    switch (res.winner) {
      case "Dealer":
      case "Player":
        this.showPlayerorDealerorFold(res)

        break;
      // case "Dealer doesn't have a hand. Would you like to replace the dealer's higher card for the cost equivalent of ANTE? Press either NO or YES.":
      case "Dealer_no_hand":
        this.dealerShuffledCards = [...res.dealerFinalCards]
        if (res.insurance) {
          if (res.insuranceBetAmount > 0) {
            let convrInsurenceBet = this.topLable_rsp(res.insuranceBetAmount);
            this.setState((prevState) => ({
              storeinsurancechips: [
                ...prevState.storeinsurancechips, { chip: this.ReturnChipBasedAmount(res.insuranceBetAmount), chipvalue: convrInsurenceBet, chipcolor1: "insuranceTextPortraitChip10" },
              ],
              insurencetbet: res.insuranceBetAmount
            }));
          }
        };

        if (res.action !== "BUY_DEALER_CARD") {
          this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerFinalCards);
          this.shwnPrvsSteBuyDrawBtchips(res)
          document.getElementById("betactive").src = "";
          document.getElementById("bettext").style.color = "";

          betbtntextElement.className = "";
          foldtextElement.className = "";
          document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerLndSpe");
          this.setState({
            betbutton: "YES", buttontext: "NO", Yesbtn: 1, Nobtn: 1, keyboardhandle: true,
            infoText: res.message, pokerrankingdeler: res.dealerHand, pokerranking: res.playerHand, disablemultibutton: false
          });

          betbtntextElement.classList.add("betLandscape");
          betbtntextElement.textContent = this.props.language.Yes;
          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);

          foldtextElement.classList.add("foldtextLandscape");
          foldbutton.src = Foldbn;
          Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
          foldtextElement.textContent = this.props.language.No;
        }
      default:
        break;
    }





  }








  russianPokerresp = (res) => {

    //// }, dealerCardsLength*300+3000)
    // this.setState({betdeductions:0,noupdateblnce:false})
    // this.state.betdeductions=0;
    //  this.updateplayerbalnace();

    // document.getElementById("rankinginfodeler").style.textContent= res.message
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    const betTextaddtoBetElement = document.getElementById("betTextaddtoBet");
    this.setState({
      playerhand_status: res.playerHand, insurenceMinbetAmt: res.insuranceMinBetAmount,
      insurenceMaxbetAmt: res.insuranceMaxBetAmount,
      disableBuyButton: false
    })

    document.getElementById("buybtn").style.opacity = "1"
    document.getElementById("buytext").style.opacity = "1"
    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      // if (res.bonusWinAmount > 0) {

      //   this.removehigltbonusclasses(res);
      // }
      this.removehigltbonusclasses(res);

      // this.setState({infoText:res.message})
      // let t1 = gsap.timeline();
      // t1.to("#chip10centerbns", {
      //   x: 813, y: 553, duration: 0.5, opacity: 0,
      //   onComplete: () => {
      //     gsap.set("#chip10centerbns", { opacity: 0 });
      //   }
      // });

      let t1 = gsap.timeline();
      document.getElementById("glowonwinchip").classList.remove("chipglow_bonus")
      t1.to("#chip10centerbns", {
        x: 478, y: -674, duration: 0.3, zIndex: -2, opacity: 0,

        onComplete: () => {
          gsap.set("#chip10centerbns", { opacity: 0, zIndex: -2 });

        }

      });
      this.insurenceactiveornot(res)
      // this.revealDealercards(res);

    }

    else {
      this.setState({ bsbtn: true })
      document.getElementById("insurenceactive").className = "";
      //  const poker_Rankingsinsurence = [
      //     "Royal Flush","Straight","Straight Flush", "Four of a Kind", 
      //     "Full House", "Flush", "Three of a Kind"
      //   ];
      if (window.innerHeight > window.innerWidth) {


        // if (poker_Rankingsinsurence.includes(res.playerHand)) {

        // if (
        //   res.playerHand == "Royal Flush" || "Straight Flush" || "Four of a Kind" || "Full House" || "Flush" || "Three of a Kind") {
        // res.playerHand == "One Pair" ||{

        if (this.playerWinBonus === true) {
          if (document.getElementById("chip10centerbns")) {
            let ml = gsap.timeline()
            ml.to("#chip10centerbns", { x: 0, y: -900, duration: 0.2, autoAlpha: 1 })
            ml.to("#chip10centerbns", { x: -170, y: 1200, duration: 0.00001, zIndex: -2, autoAlpha: 0 });
            // gsap.to("#chip10centerbns", { x: -170, y: 1200, duration: 0.5, zIndex: -2, opacity: 1 });
            this.playerWinBonus = false;
          }
        }
        if (res.insurance) {
          document.getElementById("insurencetext").style.color = "#ffffff";
          document.getElementById("insurenceactive").src = bnsactive;
          document.getElementById("insurenceactive").classList.add("insurenceactivePortrait");

          betbutton.style.cssText = "";
          foldbutton.style.cssText = "";
          this.setState({
            buttontext: "insurence", betbutton: "noinsurence", insurencebtn: false,
            // disablemultibutton: true, 
            betdecreamentbtn: false, betchipincrementbtn: false, infoText: res.message,
          });
          setTimeout(() => { this.setState({ infoText: "" }) }, 2000)
          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnportraite);
          // foldbutton.style.opacity = "0.5";


          foldbutton.src = Foldbn;
          Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);

          document.getElementById("betbtntext").textContent = this.props.language.No_Insurence;
          document.getElementById("betbtntext").className = "";
          document.getElementById("betbtntext").style.cssText = "";
          document.getElementById("betbtntext").classList.add("noinsurencePortrait");
          document.getElementById("foldtext").textContent = this.props.language.Insurence;
          // document.getElementById("foldtext").style.opacity = "0.5";
          document.getElementById("foldtext").classList.add("insurencePortrait");
        } else {

          // setTimeout(() => {
          //   this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand, })
          //   document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerPortrait");
          // }, 3000);

          this.revealDealercards(res)
          this.clertime7 = setTimeout(() => {


            this.setState({ pokerrankingdeler: res.dealerHand, })
            // document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerPortrait");
            document.getElementById("rankinginfodeler").classList.add(`${this.state.rankinginfodelercss}`);

            switch (res.winner) {
              case "Dealer":
                setTimeout(() => {
                  this.ifDealerWinsMobilePortraitAction(res);
                }, 1000)
                break;

              // case `CONGRATULATIONS! You won FUN ${res.totalWinAmount}`:
              case "Player":
                this.ifPlayerWinsMobilePortraitAction(res);
                break;
              // case "Dealer doesn't have a hand. Would you like to replace the dealer's higher card for the cost equivalent of ANTE? Press either NO or YES.":
              case "Dealer_no_hand":
                document.getElementById("betactive").src = "";
                document.getElementById("bettext").style.color = "";

                this.setState({ betbutton: "YES", buttontext: "NO", Yesbtn: 1, Nobtn: 1, keyboardhandle: true, infoText: res.message, });
                document.getElementById("betbtntext").textContent = this.props.language.Yes;
                betbutton.src = Betbtn;
                // Object.assign(betbutton.style, porttraitejson.Betbtnportraite);
                Object.assign(betbutton.style, this.state.betbuttoncss);


                foldbutton.src = Foldbn;
                // Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);
                Object.assign(foldbutton.style, this.state.foldbuttoncss);

                // foldbutton.src = Foldbn;
                document.getElementById("foldtext").textContent = this.props.language.No;
                document.getElementById("betbtntext").classList.remove("insurence", "betwindows");
                document.getElementById("foldtext").classList.remove("noinsurence", "foldtextwindows");
                document.getElementById("betbtntext").classList.add(`${this.state.betbtntextcss}`);
                document.getElementById("foldtext").classList.add(`${this.state.foldbtntextcss}`);
              // document.getElementById("betbtntext").classList.add("betportrait");
              // document.getElementById("foldtext").classList.add("foldtextportrait");
              default:
                break;
            }

          }, 3500)
        }


      } else {

        if (res.insurance) {
          document.getElementById("insurencetext").style.color = "#ffffff";
          document.getElementById("insurenceactive").src = bnsactive;
          document.getElementById("insurenceactive").classList.add("insurenceactiveLndsp");
          // document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerLndSpe");

          betbutton.style.cssText = "";
          foldbutton.style.cssText = "";
          this.setState({
            infoText: res.message,
            buttontext: "insurence",
            betbutton: "noinsurence",
            insurencebtn: false,
            // disablemultibutton: true,
            betdecreamentbtn: false,
            betchipincrementbtn: false,
            // pokerrankingdeler: res.dealerHand,
          });

          setTimeout(() => { this.setState({ infoText: "" }) }, 2000)

          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);


          foldbutton.src = Foldbn;
          Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);

          document.getElementById("betbtntext").textContent = this.props.language.No_Insurence;
          document.getElementById("betbtntext").className = "";
          document.getElementById("betbtntext").style.cssText = "";
          document.getElementById("betbtntext").classList.add("betLandscape");
          document.getElementById("foldtext").textContent = this.props.language.Insurence;
          document.getElementById("foldtext").classList.add("foldtextLandscape");
          // foldbutton.style.opacity = "0.5";
          // document.getElementById("foldtext").style.opacity = "0.5";

        } else {

          this.revealDealercards(res);
          this.clertime7 = setTimeout(() => {
            this.checkPlayerOrDealerWinMblLndspe(res);
          }, 5000)
        }



      }

    }

  }




  checkPlayerOrDealerWinMblLndspe = (res) => {

    // this.playerWin = new Audio(playerWin);
    // this.playerWin.currentTime = 0;

    // this.playerLose = new Audio(playerLose);
    // this.playerLose.currentTime = 0;

    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    const drawtextElement = document.getElementById("drawtext");
    const buytextElement = document.getElementById("buytext");
    const betbtntextElement = document.getElementById("betbtntext");
    const foldtextElement = document.getElementById("foldtext");

    const insuranceText = document.querySelectorAll("#chiptoinsurence span");
    const winpopuprspelement = document.getElementById("winpopup_rsp");
    const winamtElement = document.getElementById("winamt_rsp");

    const anteText = document.querySelectorAll("#chipcenterposition span");
    const betText = document.querySelector("#betTextaddtoBet");


    const betTextaddtoBetElement = document.getElementById("betTextaddtoBet");
    this.setState({
      // infoText: res.message, 
      pokerrankingdeler: res.dealerHand,
    })
    // document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerLndSpe");
    document.getElementById("rankinginfodeler").classList.add(`${this.state.rankinginfodelercss}`);
    const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popup");

    betbutton.className = "";
    betbutton.style.cssText = "";

    foldbutton.className = "";
    foldbutton.style.cssText = "";

    drawtextElement.className = "";
    drawtextElement.style.cssText = "";
    drawtextElement.textContent = "";

    buytextElement.className = "";
    buytextElement.style.cssText = "";
    buytextElement.textContent = "";

    betbtntextElement.className = "";
    betbtntextElement.style.cssText = "";
    betbtntextElement.textContent = "";

    foldtextElement.className = "";
    foldtextElement.style.cssText = "";
    foldtextElement.textContent = "";




    switch (res.winner) {
      case "Dealer":
        this.updateplayerbalnace();
        // console.log(this.state.anteBetAmount, this.state.bonusbetamount);
        this.setState((prevState) => ({
          infoText: res.message,
          totalwinRussian: res.totalWinAmount,
          previousAnteBetAmount: res.anteBetAmount,
          previousBonusbetamount: res.bonusBetAmount,
          totalbet: 0,
          anteBetAmount: 0,
          bonusbetamount: 0,
          insurencetbet: 0,
          prevStatestoreantebetchips: [...prevState.storeantebetchips],
          prevStatestorebonuschips: [...prevState.storebonuschips],
          storebonuschips: [],

          // betdeductions: 0
        }));

        if (Number(res.bonusBetAmount) > 0) {
          if (this.state.storebonuschips.length === 0) {
            let convrBonusbet = this.topLable_rsp(res.bonusBetAmount);
            this.setState((prevState) => ({
              prevStatestorebonuschips: [...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(res.bonusBetAmount), chipvalue: convrBonusbet, chipcolor1: "insuranceTextPortraitChip10" },]
            }))
          }
        }

        this.clertime48 = setTimeout(() => {
          this.clearActivesWeDid();
        }, 2500)





        document.getElementById("betactive").src = "";
        document.getElementById("bettext").style.color = "";
        document.getElementById("betactive").classList.remove("betactive");
        this.setState({ betbutton: "REBET", bsbtn: true, buttontext: "REBETX2", betchipincrementbtn: false, betdecreamentbtn: false });
        let t1 = gsap.timeline();

        t1.to("#chipcenterposition", {
          y: -700, duration: 0.2, opacity: 1,
          onComplete: () => {
            gsap.set("#chipcenterposition", { autoAlpha: 0 });
          }
        });
        t1.to("#chipcenterposition", {
          y: 0, duration: 0.0001, zIndex: -2, autoAlpha: 0,
          onComplete: () => {
            gsap.set("#chipcenterposition", { autoAlpha: 0, });
          }
        });
        t1.play();


        let t2 = gsap.timeline();
        t2.to("#betchipsaddtobet", {
          y: -700, duration: 0.5, zIndex: 12, ease: "power2.inOut", autoAlpha: 1,
          onComplete: () => {
            gsap.set("#betchipsaddtobet", { autoAlpha: 0 });
          }
        });
        t2.to("#betchipsaddtobet", {
          x: 0, y: 0, duration: 0.0001, zIndex: -2, autoAlpha: 0,

        });
        t2.play();

        let t3 = gsap.timeline();
        t3.to("#betTextaddtoBet", {
          y: -700, duration: 0.5, ease: "power2.inOut", autoAlpha: 0,
          onComplete: () => {
            gsap.set("#betTextaddtoBet", { autoAlpha: 0 });
          }
        });
        t3.to("#betTextaddtoBet", {
          x: 0, y: 0, duration: 0.5, zIndex: -2, autoAlpha: 0,

        });

        t3.play();

        //  ----------------------------------------------------------------------------------------------------------
        // let t4 = gsap.timeline();
        // t4.to(this.chipdrawref.current, {
        //   y: -700, duration: 0.5, zIndex: 12, ease: "power2.inOut", autoAlpha: 1,
        //   onComplete: () => {
        //     gsap.set(this.chipdrawref.current, { autoAlpha: 0 });
        //   }
        // });
        // t4.to(this.chipdrawref.current, {
        //   x: 0, y: 0, duration: 0.5, zIndex: -2, autoAlpha: 0,
        //   onComplete: () => {
        //     gsap.set(this.chipdrawref.current, { autoAlpha: 0, });
        //   }
        // });

        // t4.play();


        if (res.insuranceWinAmount > 0) {
          if (document.getElementById("chiptoinsurence")) {
            for (let b = 0; b < insuranceText.length; b++) {
              insuranceText[b].textContent = res.insuranceWinAmount;
            }
            gsap.to("#chiptoinsurence", {
              x: 720, y: 45, duration: 0.5, ease: "power2.inOut", autoAlpha: 1, delay: 1, onComplete: () => {

                winpopuprspelement.classList.add("winPopupForMLsp");
                winamtElement.classList.add("winamtMpSw");

                gsap.fromTo(winpopuprspelement,
                  { autoAlpha: 0, scale: 0, duration: 0.5, },
                  { autoAlpha: 1, scale: 1, duration: 0.5, rotate: 0 }
                )


              }
            });
          }
        } else {
          gsap.to("#chiptoinsurence", { x: 0, y: -550, duration: 0.5, delay: 0.5 });

        }




        if (this.wanttobuydelercrd === true) {
          let t1 = gsap.timeline()
          t1.to(this.buyDealerCardDrawn.current, { y: -700, duration: 0.5 });
          t1.to(this.buyDealerCardDrawn.current, { opacity: 0, });
          t1.to(this.buyDealerCardDrawn.current, { opacity: 0, y: 0, zIndex: -5 });
          t1.play();
        }



        betbtntextElement.className = "";
        foldtextElement.className = "";
        betbtntextElement.textContent = "";
        foldtextElement.textContent = "";



        this.clertime49 = setTimeout(() => {
          document.getElementById("mobileAntiMaskCover").style.display = "none";
          this.setState({ infoText: this.props.language.Place_antebet, totalwinRussian: 0, storeantebetchips: [], storeinsurancechips: [], });
          gsap.fromTo(winpopuprspelement, { scale: 1, duration: 0.5, rotate: 360 }, {
            autoAlpha: 0, scale: 0, duration: 0.5, onComplete: () => {
              winpopuprspelement.classList.remove("winPopupForMLsp");
              winamtElement.classList.remove("winamtMpSw");
            }
          })
          betbutton.src = Betbtn;
          // Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
          Object.assign(betbutton.style, this.state.betbuttoncss);
          betbtntextElement.textContent = this.props.language.Rebet;
          // betbtntextElement.classList.add('betLandscape');
          betbtntextElement.classList.add(`${this.state.betbtntextcss}`);

          foldbutton.src = Foldbn;
          // Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
          Object.assign(foldbutton.style, this.state.foldbuttoncss);
          foldtextElement.textContent = this.props.language.RebetX2;
          // foldtextElement.classList.add("foldtextLandscape");
          foldtextElement.classList.add(`${this.state.foldbtntextcss}`);
        }, 3000);

        // delaerChipAnimDiv.className ="";
        // dealerchipElement.src = "";

        // if (this.state.mutesounds) {
        //   this.playerLose.pause();
        // } else {
        //   this.playerLose.play();
        // }
        this.playRemainingSounds(this.playerLose)

        document.getElementById("onepair").style.color = "";
        document.getElementById("Threekind").style.color = "";
        document.getElementById("aceking").style.color = "";
        document.getElementById("fullhse").style.color = "";
        document.getElementById("Straightflush").style.color = "";
        document.getElementById("frofkind").style.color = "";
        document.getElementById("twopair").style.color = "";
        document.getElementById("flush").style.color = "";
        document.getElementById("stright").style.color = "";
        document.getElementById("royalflush").style.color = "";
        break;

      // case `CONGRATULATIONS! You won FUN ${res.totalWinAmount}`:
      case "Player":

        const delaerChipAnimDiv = document.getElementById('delaerchipanim')
        const dealerchipElement = document.getElementById('dealerchip');


        if (Number(res.anteWinAmount) > Number(res.anteBetAmount)) {
          dealerchipElement.src = this.ReturnChipBasedAmount(Number(res.anteBetAmount));

          Object.assign(dealerchipElement.style, porttraitejson.dealerchipstoplayerLndsp)

          let t6 = gsap.timeline()
          t6.to("#dealerchip", {
            // x: 645,
            // y: 425,
            x: -60,
            y: 550,
            duration: 0.5,
            ease: "power2.inOut",
            onStart: () => {
              gsap.set('#dealerchip', { autoAlpha: 1, zIndex: 2 })
            },
            onComplete: () => {
              gsap.set('#dealerchip', { y: 0, autoAlpha: 0, zIndex: -10 })

              for (let b = 0; b < anteText.length; b++) {
                anteText[b].textContent = res.anteWinAmount;
              }

            },
          });


        }

        setTimeout(() => {
          // this.updateplayerbalnace();
          this.clertime53 = setTimeout(() => {
            this.clearActivesWeDid();
          }, 3000);

          // console.log(this.state.anteBetAmount, this.state.bonusbetamount);

          this.setState((prevState) => ({
            // infoText: res.message,
            isbonuspattable: false,
            // totalwinRussian: res.totalWinAmount,
            previousAnteBetAmount: res.anteBetAmount,
            previousBonusbetamount: res.bonusBetAmount,
            insurencetbet: 0,
            anteBetAmount: 0,
            bonusbetamount: 0,
            prevStatestoreantebetchips: [...prevState.storeantebetchips],
            prevStatestorebonuschips: [...prevState.storebonuschips],
            storebonuschips: [],
            // storeinsurancechips: [],
          }));

          if (Number(res.bonusBetAmount) > 0) {
            if (this.state.storebonuschips.length === 0) {
              let convrBonusbet = this.topLable_rsp(res.bonusBetAmount);
              this.setState((prevState) => ({
                prevStatestorebonuschips: [...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(res.bonusBetAmount), chipvalue: convrBonusbet, chipcolor1: "insuranceTextPortraitChip10" },]
              }))
            }
          }




          betText.textContent = res.winAmount;
          this.resetAnimationChips = true;



          setTimeout(() => {
            // if (this.state.mutesounds) {
            //   this.playerWin.pause();
            // } else {
            //   this.playerWin.play();
            // }
            this.playRemainingSounds(this.playerWin)
            winpopuprspelement.classList.add("winPopupForMLsp");
            winamtElement.classList.add("winamtMpSw");
            this.setState({
              infoText: res.message,
              totalwinRussian: res.totalWinAmount,
            })
          }, 2000)

          gsap.fromTo(winpopuprspelement,
            {
              autoAlpha: 0, scale: 0, duration: 0.5,
            },
            {
              autoAlpha: 1, scale: 1, duration: 0.5, delay: 2, rotate: 0, onStart: () => {
                setTimeout(() => {
                  // winpopuprspelement.classList.add("winPopupForMLsp");
                  // winamtElement.classList.add("winamtMpSw");
                  // this.setState({
                  //   infoText: res.message,
                  //   totalwinRussian: res.totalWinAmount,
                  // })
                }, 1500)

              }
            },
          )




          document.getElementById("betactive").src = "";
          document.getElementById("bettext").style.color = "";
          document.getElementById("betactive").classList.remove("betactive");

          if (document.getElementById("chipcenterposition")) {
            gsap.to("#chipcenterposition", { x: 720, y: -130, duration: 0.5 });
          }


          if (document.getElementById("betchipsaddtobet")) {
            gsap.to("#betchipsaddtobet", { x: -340, y: -130, duration: 0.5, delay: 0.6 });
          }
          if (document.getElementById("betTextaddtoBet")) {
            gsap.to("#betTextaddtoBet", {
              x: -340, y: -130, duration: 0.5, delay: 0.6, onComplete: () => {
                betText.textContent = Number((res.anteWinAmount + res.winAmount).toFixed(2));
              }
            });
          }

          //  -------------------------------------------------------------------------------------------------------------
          // gsap.to(this.chipdrawref.current, { x: 102, y: -130, duration: 0.5, delay: 1 });
          // let t5 = gsap.timeline();
          // t5.to(this.chipdrawref.current, {
          //   // x: 360, y: -2060, duration: 0.2, zIndex: 12, ease: "power2.inOut", opacity: 1,
          //   x: 320, y: -2060, duration: 0.2, zIndex: 12, ease: "power2.inOut", opacity: 1,
          //   onComplete: () => {
          //     gsap.set(this.chipdrawref.current, { opacity: 0 });
          //   }
          // });
          // t5.to(this.chipdrawref.current, {
          //   x: 548, y: -36, duration: 0.001, zIndex: -2, opacity: 0,
          //   onComplete: () => {
          //     gsap.set(this.chipdrawref.current, { opacity: 0, delay: 2 });
          //   }
          // });

          // t5.play();




          if (res.insuranceWinAmount > 0) {
            if (document.getElementById("chiptoinsurence")) {
              for (let b = 0; b < insuranceText.length; b++) {
                insuranceText[b].textContent = res.insuranceWinAmount;
              }
              gsap.to("#chiptoinsurence", {
                x: 720, y: 45, duration: 0.5, delay: 1, onComplete: () => {
                  for (let b = 0; b < insuranceText.length; b++) {
                    insuranceText[b].textContent = Number((res.anteWinAmount + res.winAmount + res.insuranceWinAmount).toFixed(2));
                    betText.textContent = Number((res.anteWinAmount + res.winAmount + res.insuranceWinAmount).toFixed(2));
                  }
                }
              });
            }
          } else {
            gsap.to("#chiptoinsurence", { x: 0, y: -550, duration: 0.5, delay: 0.5 });

          }


          // if(this.wanttobuydelercrd === true){
          //    gsap.to(this.buyDealerCardDrawn.current, { x: -450, y: -280, duration: 0.5, delay: 1 });
          // }




          if (this.wanttobuydelercrd === true) {
            let t1 = gsap.timeline()
            t1.to(this.buyDealerCardDrawn.current, { y: -700, duration: 0.5 });
            t1.to(this.buyDealerCardDrawn.current, { opacity: 0, });
            t1.to(this.buyDealerCardDrawn.current, { opacity: 0, x: 0, y: 0, zIndex: -5 });
            t1.play();
          }



          this.setState({ betbutton: "REBET", buttontext: "REBETX2", disablemultibutton: false, bsbtn: true, betchipincrementbtn: false, betdecreamentbtn: false })


          betbtntextElement.className = "";
          foldtextElement.className = "";




          this.resetPyrWnCpsAmn = true;

        }, 1000);

        setTimeout(() => {
          this.updateplayerbalnaceIfplayerWins()
          this.updateplayerbalnace();
        }, 5000);

        // this.clertime54 = setTimeout(() => {

        //   delaerChipAnimDiv.className = "";
        //   dealerchipElement.src = "";
        //   dealerchipElement.className = "";

        //   document.getElementById("mobileAntiMaskCover").style.display = "none";
        //   betbutton.src = Betbtn;
        //   // Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
        //   Object.assign(betbutton.style, this.state.betbuttoncss);
        //   betbtntextElement.textContent = this.props.language.Rebet;
        //   // betbtntextElement.classList.add('betLandscape');
        //   betbtntextElement.classList.add(`${this.state.betbtntextcss}`);

        //   foldbutton.src = Foldbnwindows;
        //   // Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
        //   Object.assign(foldbutton.style, this.state.foldbuttoncss);
        //   foldtextElement.textContent = this.props.language.RebetX2;
        //   // foldtextElement.classList.add("foldtextLandscape");
        //   foldtextElement.classList.add(`${this.state.foldbtntextcss}`);



        //   gsap.fromTo(winpopuprspelement, { scale: 1, duration: 0.5, rotate: 360 }, {
        //     autoAlpha: 0, scale: 0, duration: 0.5, onComplete: () => {
        //       winpopuprspelement.classList.remove("winPopupForMLsp");
        //       winamtElement.classList.remove("winamtMpSw");
        //     }
        //   })
        //   this.setState({
        //     totalbet: 0,
        //     totalwinRussian: 0,
        //     infoText: this.props.language.Play_Njoy,
        //     storeantebetchips: [],
        //     storeinsurancechips: []
        //   });

        //   document.getElementById("onepair").style.color = "";
        //   document.getElementById("Threekind").style.color = "";
        //   document.getElementById("aceking").style.color = "";
        //   document.getElementById("fullhse").style.color = "";
        //   document.getElementById("Straightflush").style.color = "";
        //   document.getElementById("frofkind").style.color = "";
        //   document.getElementById("twopair").style.color = "";
        //   document.getElementById("flush").style.color = "";
        //   document.getElementById("stright").style.color = "";
        //   document.getElementById("royalflush").style.color = "";



        //   gsap.to("#chipcenterposition", { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
        //   gsap.to("#betTextaddtoBet", { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
        //   gsap.to("#betchipsaddtobet", { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
        //   gsap.to(this.chipdrawref.current, { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
        //   if (this.insuranceActive) {
        //     gsap.to("#chiptoinsurence", { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
        //   };
        //   if (this.wanttobuydelercrd === true) {
        //     gsap.to(this.buyDealerCardDrawn.current, { opacity: 0, x: 0, y: 0, zIndex: -2, duration: 0.0001 })

        //   }
        //   // gsap.set('#dealerchip', { x: 0, y: 0, opacity: 0, zIndex: -10, duration: 0.00001 })
        // }, 5000)




        break;
      // case "Dealer doesn't have a hand. Would you like to replace the dealer's higher card for the cost equivalent of ANTE? Press either NO or YES.":
      case "Dealer_no_hand":
        if (res.action !== "BUY_DEALER_CARD") {

          document.getElementById("betactive").src = "";
          document.getElementById("bettext").style.color = "";
          betbtntextElement.className = "";
          foldtextElement.className = "";

          this.setState({ betbutton: "YES", buttontext: "NO", Yesbtn: 1, Nobtn: 1, keyboardhandle: true, disablemultibutton: false, infoText: res.message });

          // betbtntextElement.classList.add("betLandscape");
          betbtntextElement.classList.add(`${this.state.betbtntextcss}`);
          betbtntextElement.textContent = this.props.language.Yes;
          betbutton.src = Betbtn;
          // Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
          Object.assign(betbutton.style, this.state.betbuttoncss);

          // foldtextElement.classList.add("foldtextLandscape");
          foldtextElement.classList.add(`${this.state.foldbtntextcss}`);
          foldbutton.src = Foldbn;
          // Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
          Object.assign(foldbutton.style, this.state.foldbuttoncss);
          foldtextElement.textContent = this.props.language.No;
        }
      default:
        break;
    }





  }




















  ifDealerWinsMobilePortraitAction = (res) => {
    // this.playerLose = new Audio(playerLose);
    // this.playerLose.currentTime = 0;
    // console.log(this.state.storebonuschips)
    const insuranceText = document.querySelectorAll("#chiptoinsurence span");
    const winpopuprspelement = document.getElementById("winpopup_rsp");
    const winamtElement = document.getElementById("winamt_rsp");
    this.clertime47 = setTimeout(() => {

      this.updateplayerbalnace();
      // console.log(this.state.anteBetAmount, this.state.bonusbetamount);
      this.setState((prevState) => ({
        infoText: res.message,
        totalwinRussian: res.totalWinAmount,
        previousAnteBetAmount: res.anteBetAmount,
        previousBonusbetamount: res.bonusBetAmount,
        totalbet: 0,
        anteBetAmount: 0,
        bonusbetamount: 0,
        insurencetbet: 0,
        prevStatestoreantebetchips: [...prevState.storeantebetchips],
        prevStatestorebonuschips: [...prevState.storebonuschips],
        storebonuschips: [],
        // storeinsurancechips: [],
        // betdeductions: 0
      }));
      if (Number(res.bonusBetAmount) > 0) {
        if (this.state.storebonuschips.length === 0) {
          let convrBonusbet = this.topLable_rsp(res.bonusBetAmount);
          this.setState((prevState) => ({
            prevStatestorebonuschips: [...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(res.bonusBetAmount), chipvalue: convrBonusbet, chipcolor1: "insuranceTextPortraitChip10" },]
          }))
        }
      }




      this.clertime48 = setTimeout(() => {
        this.clearActivesWeDid();
      }, 1000);





      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");
      const betTextaddtoBetElement = document.getElementById("betTextaddtoBet");


      document.getElementById("betactive").src = "";
      document.getElementById("bettext").style.color = "";
      document.getElementById("betactive").classList.remove("betactive");
      this.setState({ betbutton: "REBET", bsbtn: true, buttontext: "REBETX2", betchipincrementbtn: false, betdecreamentbtn: false });
      let t1 = gsap.timeline();

      t1.to("#chipcenterposition", {
        y: -2060, duration: 0.2, opacity: 1,
        onComplete: () => {
          gsap.set("#chipcenterposition", { opacity: 0 });
        }
      });
      t1.to("#chipcenterposition", {
        x: 13, y: 1200, duration: 0.0001, zIndex: -2, opacity: 0,
        onComplete: () => {
          gsap.set("#chipcenterposition", { opacity: 0, });
        }
      });
      t1.play();


      let t2 = gsap.timeline();
      t2.to("#betchipsaddtobet", {
        x: -20, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
        onComplete: () => {
          gsap.set("#betchipsaddtobet", { opacity: 0 });
        }
      });
      t2.to("#betchipsaddtobet", {
        x: -2, y: 72, duration: 0.5, zIndex: -2, opacity: 0,

      });
      t2.play();

      let t3 = gsap.timeline();
      t3.to("#betTextaddtoBet", {
        x: 110, y: -2000, duration: 0.5, ease: "power2.inOut", opacity: 0,
        onComplete: () => {
          gsap.set("#betTextaddtoBet", { opacity: 0 });
        }
      });
      t3.to("#betTextaddtoBet", {
        x: 153, y: 185, duration: 0.5, zIndex: -2, opacity: 0,

      });

      t3.play();

      //  ---------------------------------------------------------------------------------------------------
      // let t4 = gsap.timeline();
      // t4.to(this.chipdrawref.current, {
      //   // x: 360, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
      //   x: 340, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
      //   onComplete: () => {
      //     gsap.set(this.chipdrawref.current, { opacity: 0 });
      //   }
      // });
      // t4.to(this.chipdrawref.current, {
      //   x: 548, y: -36, duration: 0.5, zIndex: -2, opacity: 0,
      //   onComplete: () => {
      //     gsap.set(this.chipdrawref.current, { opacity: 1, delay: 2 });
      //   }
      // });

      // t4.play();


      if (this.insuranceActive) {
        let t5 = gsap.timeline();
        if (document.getElementById("chiptoinsurence")) {

          if (res.insuranceWinAmount > 0) {
            for (let b = 0; b < insuranceText.length; b++) {
              insuranceText[b].textContent = res.insuranceWinAmount;
            }
            // gsap.to("#chiptoinsurence", { x: -430, y: 1130, duration: 0.5, delay: 1 });
            gsap.to("#chiptoinsurence img", {
              x: -430, y: 1130, duration: 0.5, delay: 1, onComplete: () => {
                gsap.fromTo(winpopuprspelement,
                  {
                    autoAlpha: 0, scale: 0, duration: 0.5,
                  },
                  { autoAlpha: 1, scale: 1, duration: 0.5, rotate: 0 }
                )

              }
            });
            gsap.to("#chiptoinsurence span", { x: -430, y: 1130, duration: 0.5, delay: 1 });


            winpopuprspelement.classList.add("winPopupForMptSw");
            winamtElement.classList.add("winamtMpSw");



          } else {
            gsap.to("#chiptoinsurence img", {
              x: 0, y: -900, duration: 0.3, delay: 0.5, autoAlpha: 1, onComplete: () => {
                gsap.set("#chiptoinsurence img", { autoAlpha: 0, duration: 0.00001 })
              }
            });
            gsap.to("#chiptoinsurence span", {
              x: 0, y: -900, duration: 0.3, delay: 0.5, autoAlpha: 1, onComplete: () => {
                gsap.set("chiptoinsurence span", { autoAlpha: 0, duration: 0.00001 })
              }
            });
            // t5.to("#chiptoinsurence", {
            //   x: -430, y: 1136, duration: 0.5, ease: "power2.inOut", opacity: 1,

            //   onComplete: function () {
            //     gsap.set("#chiptoinsurence", { opacity: 0 });
            //   }
            // });
            // t5.to("#chiptoinsurence", {
            //   // x: 550, y: 145, duration: 0.5, zIndex: -2, zIndex: -2, opacity: 0,
            //   x: -430, y: 1125, duration: 0.5, zIndex: -2, zIndex: -2, opacity: 0,
            //   onComplete: function () {
            //     gsap.set("#chiptoinsurence", { opacity: 1, delay: 2 });
            //   }
            // });
            // t5.play();
          }





        }
      };



      if (this.wanttobuydelercrd === true) {
        let t1 = gsap.timeline()
        t1.to(this.buyDealerCardDrawn.current, { x: -190, y: -2200, duration: 0.5 });
        t1.to(this.buyDealerCardDrawn.current, { opacity: 0, });
        t1.to(this.buyDealerCardDrawn.current, { opacity: 0, x: 0, y: 0, zIndex: -5 });
        t1.play();
      }




      this.clertime49 = setTimeout(() => {
        document.getElementById("mobileAntiMaskCover").style.display = "none";
        gsap.fromTo(winpopuprspelement, { scale: 1, duration: 0.5, rotate: 360 }, {
          autoAlpha: 0, scale: 0, duration: 0.5, onComplete: () => {
            winpopuprspelement.classList.remove("winPopupForMptSw");
            winamtElement.classList.remove("winamtMpSw");
          }
        })
        this.setState({ infoText: this.props.language.Place_antebet, totalwinRussian: 0, storeantebetchips: [], storeinsurancechips: [], });
        betbutton.src = Betbtn;
        // Object.assign(betbutton.style, porttraitejson.Betbtnportraite);
        Object.assign(betbutton.style, this.state.betbuttoncss);

        foldbutton.src = Foldbn;
        // Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);
        Object.assign(foldbutton.style, this.state.foldbuttoncss);
        document.getElementById("betbtntext").textContent = this.props.language.Rebet;

        document.getElementById("betbtntext").classList.add(`${this.state.betbtntextcss}`);
        document.getElementById("foldtext").classList.add(`${this.state.foldbtntextcss}`);
        // document.getElementById("betbtntext").classList.add("betportrait");
        // document.getElementById("foldtext").classList.add("rebettextportrait");

        document.getElementById("foldtext").textContent = this.props.language.RebetX2;
      }, 3000);

      // delaerChipAnimDiv.className ="";
      // dealerchipElement.src = "";

      // if (this.state.mutesounds) {
      //   this.playerLose.pause();
      // } else {
      //   this.playerLose.play();
      // }
      this.playRemainingSounds(this.playerLose)

      document.getElementById("onepair").style.color = "";
      document.getElementById("Threekind").style.color = "";
      document.getElementById("aceking").style.color = "";
      document.getElementById("fullhse").style.color = "";
      document.getElementById("Straightflush").style.color = "";
      document.getElementById("frofkind").style.color = "";
      document.getElementById("twopair").style.color = "";
      document.getElementById("flush").style.color = "";
      document.getElementById("stright").style.color = "";
      document.getElementById("royalflush").style.color = "";


    }, 2000);


  }



  suffleDealerCards = (data) => {
    let cnt = data.length;
    for (let i = 0; i < cnt; i++) {
      let b1 = data[i];
      this.cardsshuffle = new Audio(cardsshuffle);
      // if (this.cardsshuffle) {
      //   this.cardsshuffle.currentTime = 0;
      //   if (this.state.mutesounds) {
      //     this.cardsshuffle.pause()
      //   } else {
      //     this.cardsshuffle.play()
      //   }



      // }
      switch (i) {
        case 0:
          this.dealerCardsContainer.current.SuffleCard("cardZero", b1, cnt);
          break;
        case 1:
          this.dealerCardsContainer.current.SuffleCard("cardOne", b1, cnt);
          break;
        case 2:
          this.dealerCardsContainer.current.SuffleCard("cardTwo", b1, cnt);
          break;
        case 3:
          this.dealerCardsContainer.current.SuffleCard("cardThree", b1, cnt);
          break;
        case 4:
          this.dealerCardsContainer.current.SuffleCard("cardFour", b1, cnt);
          break;
        case 5:
          this.dealerCardsContainer.current.SuffleCard("cardFive", b1, cnt);
          break;
        default:
          break;
      }
    }
  };

  rpReplaceCards = (res) => {
    // let handrank = "One Pair , Ace-King".split(" , ");
    // this.setState({ playerrankinghand: handrank })
    this.drawReplaceCards = true;

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {
      // this.insurenceactiveornot(res);
      // this.Showbonustouser(res);

    } else {
      this.setState({
        bsbtn: true
      })
    }





    this.unsequenceDrawCards = [];
    this.sequenceDrawCards = [];
    this.replaceCards = []
    // console.log(res);

    this.unsequenceDrawCards = [...res.playerCards];
    this.sequenceDrawCards = [...res.playerFinalCards];
    let cnt = this.unsequenceDrawCards.length;

    let numberOfCardsToDraw = res.playerNewCardsToReplace.length;

    for (let i = 0; i < cnt; i++) {

      var b1 = this.unsequenceDrawCards[i];

      switch (i) {
        case 0:
          if (this.shuffledPlayerCards[0] != b1) {
            this.cardsRef.current.Drawcard("cardZero", b1, cnt);
          }
          break;
        case 1:
          if (this.shuffledPlayerCards[1] != b1) {
            this.cardsRef.current.Drawcard("cardOne", b1, cnt);
          }
          break;
        case 2:
          if (this.shuffledPlayerCards[2] != b1) {
            this.cardsRef.current.Drawcard("cardTwo", b1, cnt);
          }
          break;
        case 3:
          if (this.shuffledPlayerCards[3] != b1) {
            this.cardsRef.current.Drawcard("cardThree", b1, cnt);
          }
          break;
        case 4:
          if (this.shuffledPlayerCards[4] != b1) {
            this.cardsRef.current.Drawcard("cardFour", b1, cnt);
          }
          break;
        case 5:
          if (this.shuffledPlayerCards[5] != b1) {
            this.cardsRef.current.Drawcard("cardFive", b1, cnt);
          }
          break;
        default:
          break;
      }

    }

    this.clertime8 = setTimeout(() => {
      this.highlightPlayerCardsName(res.playerHand);
      if (this.state.isMobilePortraitLndsp === true) {
        document.getElementById("playerDealerCardContainer").style.zIndex = 1;
      }
      this.setState({ pokerranking: res.playerHand })
      this.onDrawingCards(this.sequenceDrawCards, true, res, true);
      this.shuffledPlayerCards = [...this.sequenceDrawCards];

      const drawbutton = document.getElementById("drawbtn");
      const buybutton = document.getElementById("buybtn");
      document.getElementById("drawtext").textContent = "";
      document.getElementById("buytext").textContent = "";

      drawbutton.src = "";
      drawbutton.style.cssText = "";

      buybutton.src = "";
      buybutton.style.cssText = "";
      this.Removebuydrawchips();

    }, numberOfCardsToDraw * 2 * 500);
  };

  ShowrpReplaceCards = (res) => {

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {


      // this.insurenceactiveornot(res);
      // this.Showbonustouser(res);

    } else {

      this.setState({ infoText: res.message });

      // console.log(res);



      this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerCards)

    }






    this.highlightPlayerCardsName(res.playerHand);
    if (this.state.isMobilePortraitLndsp === true) {
      document.getElementById("playerDealerCardContainer").style.zIndex = 1;
    }
    this.setState({ pokerranking: res.playerHand })
    const drawbutton = document.getElementById("drawbtn");
    const buybutton = document.getElementById("buybtn");
    document.getElementById("drawtext").textContent = "";
    document.getElementById("buytext").textContent = "";

    drawbutton.src = "";
    drawbutton.style.cssText = "";

    buybutton.src = "";
    buybutton.style.cssText = "";


  };

  BuyplayerCard() {

    if (this.state.mutesounds) {
      this.betrussiansnd.pause();

    } else {
      this.betrussiansnd.play();

    }

    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }

    if (Number((this.state.anteBetAmount * 4 + this.state.bonusbetamount).toFixed(2)) > this.props.balance) {
      this.setState({
        betshigh_balnce: true,
        lowBalaceErrorInfo: `Insufficient Funds to place the Buy of ${this.state.anteBetAmount.toFixed(1)},Please DEPOSIT to continue playing.`
      });
      this.lowBalanceErrorInformationPopupanimation()
    } else {
      this.setState({ betshigh_balnce: null })
      this.playPokerChipSound(this.pokerchipsnd);

      document.getElementById("crdscover").classList.add("crdscover");
      const drawbutton = document.getElementById("drawbtn");
      const buybutton = document.getElementById("buybtn");
      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");


      betbutton.src = "";
      betbutton.style.cssText = "";

      foldbutton.src = "";
      foldbutton.style.cssText = "";

      drawbutton.src = "";
      drawbutton.style.cssText = "";


      document.getElementById("buytext").textContent = "";
      buybutton.src = "";
      buybutton.style.cssText = "";



      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {
        this.setState((prevState) => ({
          totalbet: prevState.totalbet + prevState.anteBetAmount,
          buydrawCheck: !prevState.buydrawCheck,
          betdeductions: prevState.betdeductions + prevState.anteBetAmount,
          betBtnValue: 0,
          disBetBtn: 1
        }))
        this.drawbuybets();
        document.getElementById("betbtntext").innerHTML = "";
        document.getElementById("betbtntext").classList.remove("betwindows");
        document.getElementById("foldtext").classList.remove("foldtextwindows");
        document.getElementById("foldtext").textContent = "";
        document.getElementById("drawtext").textContent = "";
        document.getElementById("buytext").textContent = "";

      } else {

        this.setState((prevState) => ({
          totalbet: prevState.totalbet + prevState.anteBetAmount,
          buydrawCheck: !prevState.buydrawCheck,
          betdeductions: prevState.betdeductions + prevState.anteBetAmount,
          infoText: ""
        }))

        let betchipsaddtodrawContainerElement = document.getElementById("betchipsaddtodrawContainer");
        let betchipsdraw = document.getElementById("betchipsaddtodraw");
        let betTextaddtodrawElement = document.getElementById("betchipsaddtodrawText");

        document.getElementById("betbtntext").innerHTML = "";
        document.getElementById("betbtntext").classList.remove("betwindows", "betportrait");
        document.getElementById("foldtext").classList.remove("foldtextwindows");
        document.getElementById("foldtext").textContent = "";
        document.getElementById("drawtext").textContent = "";
        document.getElementById("drawtext").className = "";
        document.getElementById("buytext").textContent = "";
        document.getElementById("buytext").className = "";




        let betTextaddtodrawElementClass = "bet-text-addtoBet20";
        let convrBuy = this.topLable_rsp(this.state.anteBetAmount);
        betchipsdraw.src = this.ReturnChipBasedAmount(Number(this.state.anteBetAmount).toFixed(2));
        switch (this.state.count) {
          case 1:
            // betchipsdraw.src = chip_10;
            // betTextaddtodrawElement.textContent = 10;
            betTextaddtodrawElement.textContent = convrBuy;
            betTextaddtodrawElementClass = "bet-text-addtoBet10";
            break;
          case 2:
            // betchipsdraw.src = chip_25;
            betTextaddtodrawElement.textContent = convrBuy;
            betTextaddtodrawElementClass = "bet-text-addtoBet20";
            break;
          case 3:
            // betchipsdraw.src = chip_50;
            betTextaddtodrawElement.textContent = convrBuy;
            betTextaddtodrawElementClass = "bet-text-addtoBet20";
            break;
          case 4:
            // betchipsdraw.src = chip_100;
            betTextaddtodrawElement.textContent = convrBuy;
            betTextaddtodrawElementClass = "bet-text-addtoBet20";
            break;
          case 5:
            // betchipsdraw.src = chip_500;
            betTextaddtodrawElement.textContent = convrBuy;
            betTextaddtodrawElementClass = "bet-text-addtoBet20";
            break;
          case 6:
            // betchipsdraw.src = chip_1000;
            betTextaddtodrawElement.textContent = convrBuy;
            betTextaddtodrawElementClass = "bet-text-addtoBet20";
            break;

          default:
            betTextaddtodrawElementClass = "bet-text-addtoBet20";
            break;
        }


        this.buydrawchipactivated = true;

        document.getElementById("playerDealerCardContainer").style.zIndex = 20;
        if (window.innerHeight > window.innerWidth) {
          document.getElementById("betpannelrussiancover").style.display = "block";
          document.getElementById("betbtntext").classList.remove("betportrait");
          document.getElementById("foldtext").classList.remove("foldtextportrait");

          betchipsaddtodrawContainerElement.classList.add("drawChipDiv");
          betTextaddtodrawElement.className = "";
          betTextaddtodrawElement.style.cssText = "";
          betTextaddtodrawElement.classList.add('betTextaddtodraw', betTextaddtodrawElementClass);
          betchipsdraw.classList.add("betchipsaddtodraw")

          gsap.to(this.chipdrawref.current, {
            // x: 360,
            x: 322,
            y: -1230,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 12,
            opacity: 1,
            autoAlpha: 1,
            onStart: () => {
              gsap.set(this.chipdrawref.current, { opacity: 1 });
            }
          });

        } else {

          // document.getElementById("playerDealerCardContainer").style.zIndex = 20;
          document.getElementById("betpannelrussiancover").style.display = "block";
          document.getElementById("betbtntext").classList.remove("betLandscape");
          document.getElementById("foldtext").classList.remove("foldtextLandscape");

          betchipsaddtodrawContainerElement.classList.add("drawChipDivLndsp");
          betTextaddtodrawElement.className = "";
          betTextaddtodrawElement.style.cssText = "";
          betTextaddtodrawElement.classList.add(betTextaddtodrawElementClass, 'betTextaddwLndsp');
          betchipsdraw.classList.add("betchipsaddtodraw")

          gsap.to(this.chipdrawref.current, {
            // x: 360,
            x: -1230,
            y: 0,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 12,
            opacity: 1,
            autoAlpha: 1,
            onStart: () => {
              gsap.set(this.chipdrawref.current, { opacity: 1 });
            }
          });


        }
      }


      const body = {
        sessionId: sessionStorage.getItem("sessionId"),
        CN: "RUSSIAN_POKER_BET",
        object: {
          action: "BUY_PLAYER_CARD",
          gameId: this.props.gameId,
          playerId: Number(this.props.playerId),
          handId: Number(this.props.gameState.handId)
        },
      };
      // console.log(body);
      this.props.network.sendof(body);

    }


  }

  Removebuydrawchips = () => {



    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      let t4 = gsap.timeline();
      t4.to("#betchipsaddtodraw", {
        // x: 360, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
        x: -1084, y: -1266, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
        onComplete: () => {
          gsap.set("#betchipsaddtodraw", { opacity: 0 });

        }
      });
      t4.to("#betchipsaddtodraw", {
        x: 12, y: -51, zIndex: -10, duration: 0.1, opacity: 0, delay: 0.5

      });
      gsap.to("#betTextaddtodraw", { opacity: 0, duration: 0.1, zIndex: -15 })




    } else {

      if (window.innerHeight > window.innerWidth) {
        let t4 = gsap.timeline();
        t4.to(this.chipdrawref.current, {
          // x: 360, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
          x: 340, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
          onComplete: () => {
            gsap.set(this.chipdrawref.current, { opacity: 0 });
          }
        });
        t4.to(this.chipdrawref.current, {
          x: 548, y: -36, duration: 0.5, zIndex: -2, opacity: 0,
          onComplete: () => {
            gsap.set(this.chipdrawref.current, { opacity: 1, delay: 2 });
          }
        });

        t4.play();

      } else {

        let t4 = gsap.timeline();
        t4.to(this.chipdrawref.current, {
          y: -700, duration: 0.5, zIndex: 12, ease: "power2.inOut", autoAlpha: 1,
          onComplete: () => {
            gsap.set(this.chipdrawref.current, { autoAlpha: 0 });
          }
        });
        t4.to(this.chipdrawref.current, {
          x: 0, y: 0, duration: 0.5, zIndex: -2, autoAlpha: 0,
          onComplete: () => {
            gsap.set(this.chipdrawref.current, { autoAlpha: 0, });
          }
        });

        t4.play();

      }
    }
  }


  buyplayercardresp(res) {

    this.drawReplaceCards = true;

    const drawbutton = document.getElementById("drawbtn");
    const buybutton = document.getElementById("buybtn");
    const rankinginfoElement = document.getElementById("rankinginfo");
    // let handrank = res.playerHand.split(" , ");
    // this.setState({ playerrankinghand: handrank })


    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      //  this.insurenceactiveornot(res);
      // this.Showbonustouser(res);
      setTimeout(() => {
        this.setState({ betBtnValue: 1, disBetBtn: 0 });

      }, 5000)
    }
    else {
      this.setState({
        // infoText: res.message, 
        bsbtn: true,
        // pokerranking: res.playerHand
      });

      setTimeout(() => {
        this.setState({
          pokerranking: res.playerHand
        });

      }, 3000)

      if (rankinginfoElement) {
        if (this.state.isMobilePortraitLndsp) {
          if (window.innerHeight > window.innerWidth) {
            rankinginfoElement.style.left = "33%";
          } else {
            rankinginfoElement.style.left = "42.5%";
          }
        } else {

        };
      }
    }




    this.buyCardUnsequenceArray = [...res.playerCards];
    this.buyCardsequenceArray = [...res.playerFinalCards];
    // console.log(this.buyCardUnsequenceArray);
    // this.shuffledPlayerCards

    // console.log("buyCardUnsequenceArray", this.buyCardUnsequenceArray);




    let cnt = this.buyCardUnsequenceArray.length;

    for (let i = 0; i < cnt; i++) {
      var b1 = this.buyCardUnsequenceArray[i];

      switch (i) {
        case 0:
          if (this.shuffledPlayerCards[0] == b1) {
            this.cardsRef.current.MoveCard("cardZero", b1, cnt);
          }
          break;
        case 1:
          if (this.shuffledPlayerCards[1] == b1) {
            this.cardsRef.current.MoveCard("cardOne", b1, cnt);
          }
          break;
        case 2:
          if (this.shuffledPlayerCards[2] == b1) {
            this.cardsRef.current.MoveCard("cardTwo", b1, cnt);
          }
          break;
        case 3:
          if (this.shuffledPlayerCards[3] == b1) {
            this.cardsRef.current.MoveCard("cardThree", b1, cnt);
          }
          break;
        case 4:
          if (this.shuffledPlayerCards[4] == b1) {
            this.cardsRef.current.MoveCard("cardFour", b1, cnt);
          }
          break;
        case 5:
          // console.log("this.buyCardUnsequenceArray[5] != b1  : ", this.buyCardUnsequenceArray[5], b1, this.buyCardUnsequenceArray[5] != b1);
          if (this.shuffledPlayerCards[5] != b1) {
            this.cardsRef.current.addCards("cardFive", b1, cnt);
          }
          break;
        default:
          break;
      }

    }


    this.clertime9 = setTimeout(() => {
      if (this.state.isMobilePortraitLndsp) {
        document.getElementById("playerDealerCardContainer").style.zIndex = 1;
      }
      this.highlightPlayerCardsName(res.playerHand);
      for (let d = 0; d < res.playerCards.length; d++) {
        if (res.playerCards[d] !== res.playerFinalCards[d]) {
          this.onDrawingCards(this.buyCardsequenceArray, true, res, true);
          break
        } else {
          this.onDrawingCards(this.buyCardsequenceArray, true, res, false);

        }
      }
      drawbutton.src = "";
      drawbutton.style.position = "";
      drawbutton.style.height = "";
      drawbutton.style.width = "";
      drawbutton.style.transform = "";
      document.getElementById("drawtext").textContent = "";

      document.getElementById("buytext").textContent = "";
      buybutton.src = "";
      buybutton.style.position = "";
      buybutton.style.width = "";
      buybutton.style.transform = "";

      this.Removebuydrawchips();
    }, 3000);
  }

  ShowBuyplayercardresp(res) {
    const drawbutton = document.getElementById("drawbtn");
    const buybutton = document.getElementById("buybtn");




    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {


      document.getElementById("crdscover").classList.add("crdscover")
      document.getElementById("rankinginfo").classList.add("windowspokerranking");
      this.setState({
        infoText: res.message,
        pokerranking: res.playerHand,
        totalbet: res.totalBetAmount,
        anteBetAmount: res.anteBetAmount,
        buttontext: "fold",
        betbutton: "Bet",
        Foldbtn: 0,
        betBtnValue: 1,
        disBetBtn: 0,
        keyboardhandle: true

      });
      this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerCards);
      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");

      betbutton.src = Betbtn;
      Object.assign(betbutton.style, porttraitejson.Betbtnwindows);
      document.getElementById("betbtntext").textContent = this.props.language.Bet;
      document.getElementById("betbtntext").classList.add("betwindows");


      foldbutton.src = Foldbnwindows;
      Object.assign(foldbutton.style, porttraitejson.foldbtnwindows);
      document.getElementById("foldtext").textContent = this.props.language.Fold;
      document.getElementById("foldtext").classList.add("foldtextwindows");

      document.getElementById("bnsactive").src = bnsactive
      document.getElementById("bnsactive").classList.add("betactive");
      let betexttodraw = document.getElementById("betTextaddtodraw");
      gsap.to("#betTextaddtodraw", { opacity: 2, zIndex: 15 })
      let betchipsdraw = document.getElementById("betchipsaddtodraw");


      // Object.assign(betchipsdraw.style, porttraitejson.betchipsdrawwindows)




      // let betTextaddtoBetClass = "bet-text-addtodraw20";
      // this.setState((prevState) => ({
      //   totalbet: prevState.totalbet + prevState.anteBetAmount

      // }));
      // if (res.playerBuyCardAmount > 0) {
      //   betexttodraw.textContent = this.topLable_rsp(res.playerBuyCardAmount);
      // }
      // else {
      //   betexttodraw.textContent = this.topLable_rsp(res.playerReplaceCardAmount);
      // }

      // switch (this.state.count) {
      //   case 1:

      //     betchipsdraw.src = chip_10;
      //     betTextaddtoBetClass = "bet-text-addtodraw10";

      //     break;
      //   case 2:

      //     betchipsdraw.src = chip_25;
      //     betexttodraw.textContent = this.chipVal[1].label;
      //     break;
      //   case 3:

      //     betchipsdraw.src = chip_50;

      //     break;
      //   case 4:
      //     betchipsdraw.src = chip_100;
      //     break;
      //   case 5:
      //     betchipsdraw.src = chip_500;
      //     break;
      //   case 6:
      //     betchipsdraw.src = chip_1000;
      //     break;

      //   default:
      //     break;
      // }
      // this.clertime45 = setTimeout(() => {
      //   betexttodraw.classList.add(betTextaddtoBetClass, "bet-text-addtodraw");

      // }, 100)
      // let t1 = gsap.timeline();
      // // t1.to("#betchipsaddtodraw,betchipsaddtconfrm", {
      // t1.to("#betchipsaddtodraw", {
      //   x: -1084,
      //   y: -598, duration: 0.1, opacity: 1,

      // });

      // t1.play();


      //  this.insurenceactiveornot(res);
      // this.Showbonustouser(res);

      this.setState({ infoText: res.message });





    }


    else {
      this.setState({
        infoText: res.message,
        pokerranking: res.playerHand,
        totalbet: res.totalBetAmount,
        anteBetAmount: res.anteBetAmount,
        buttontext: "fold",
        betbutton: "Bet",
        buydrawCheck: true
        // bonusbetamount: res.bonusBetAmount,
        // betdeductions: this.state.previousAnteBetAmount + this.state.previousBonusbetamount,
      });






      document.getElementById("betbtntext").innerHTML = "";
      document.getElementById("betbtntext").className = "";
      document.getElementById("foldtext").className = "";
      document.getElementById("foldtext").textContent = "";
      document.getElementById("drawtext").textContent = "";


      this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerCards);
      this.showParamBetStatus(false);

      // if (res.playerReplaceCardAmount > 0 || res.playerBuyCardAmount > 0) {
      //   //  if(res.playerBuyCardAmount > 0){
      //   this.prevstateBuyorDrawChip(res);
      // }



      drawbutton.src = "";
      drawbutton.style.position = "";
      drawbutton.style.height = "";
      drawbutton.style.width = "";
      drawbutton.style.transform = "";
      document.getElementById("drawtext").textContent = "";

      document.getElementById("buytext").textContent = "";
      buybutton.src = "";
      buybutton.style.position = "";
      buybutton.style.width = "";

    }



  }



  prevstateBuyorDrawChip = (res) => {
    let betchipsaddtodrawContainerElement = document.getElementById("betchipsaddtodrawContainer");
    let betchipsdraw = document.getElementById("betchipsaddtodraw");
    let betTextaddtodrawElement = document.getElementById("betchipsaddtodrawText");
    const rankinginfoElement = document.getElementById("rankinginfo");

    let betactive
    if (window.innerWidth > window.innerHeight) {
      betactive = "betactiveLndsp";
      // betactive = this.state.betactvglwcss;

    } else {
      betactive = "betactivePortrait";
      // betactive = this.state.betactvglwcss;

    }
    // console.log(typeof res.betAmount, res.betAmount > 0, Number(res.betAmount) > 0)
    if (res.betAmount > 0) {
      document.getElementById("betactive").src = "";
      document.getElementById("betactive").className = "";
    } else {
      document.getElementById("betactive").src = bnsactive;
      document.getElementById("betactive").classList.add(betactive);

    }







    let convrBuy = this.topLable_rsp(res.anteBetAmount);
    let buyordraw = res.anteBetAmount;
    if (res.playerReplaceCardAmount > 0) {
      convrBuy = this.topLable_rsp(res.playerReplaceCardAmount);
      buyordraw = res.playerReplaceCardAmount;

    }
    this.highlightPlayerCardsName(res.playerHand);
    let betTextaddtodrawElementClass = "bet-text-addtoBet20";
    betchipsdraw.src = this.ReturnChipBasedAmount(buyordraw);
    switch (this.state.count) {
      case 1:
        // alert("7777777777")
        // betchipsdraw.src = chip_10;
        // betTextaddtodrawElement.textContent = 10;
        betTextaddtodrawElement.textContent = convrBuy;
        betTextaddtodrawElementClass = "bet-text-addtoBet10";
        break;
      case 2:
        // betchipsdraw.src = chip_25;
        betTextaddtodrawElement.textContent = convrBuy;
        break;
      case 3:
        // betchipsdraw.src = chip_50;
        betTextaddtodrawElement.textContent = convrBuy;
        break;
      case 4:
        // betchipsdraw.src = chip_100;
        betTextaddtodrawElement.textContent = convrBuy;
        break;
      case 5:
        // betchipsdraw.src = chip_500;
        betTextaddtodrawElement.textContent = convrBuy;
        break;
      case 6:
        // betchipsdraw.src = chip_1000;
        betTextaddtodrawElement.textContent = convrBuy;
        break;

      default:
        betTextaddtodrawElementClass = "bet-text-addtoBet20";
        break;
    }


    this.buydrawchipactivated = true;
    if (window.innerHeight > window.innerWidth) {
      document.getElementById("playerDealerCardContainer").style.zIndex = 1;
      document.getElementById("betpannelrussiancover").style.display = "block";
      document.getElementById("betpannelrussiancover").classList.add("pannelcoverrussian");
      document.getElementById("betbtntext").classList.remove("betportrait");
      document.getElementById("foldtext").classList.remove("foldtextportrait");

      betchipsaddtodrawContainerElement.classList.add("drawChipDiv");
      betTextaddtodrawElement.className = "";
      betTextaddtodrawElement.style.color = "#ffffff";

      // betTextaddtodrawElement.classList.add('betTextaddtodraw', betTextaddtodrawElementClass);

      betchipsdraw.classList.add("betchipsaddtodraw")
      rankinginfoElement.classList.add("pokerrankingPortait");
      gsap.to(this.chipdrawref.current, {
        // x: 360,
        x: 322,
        y: -1230,
        duration: 0.000001,
        zIndex: 12,
        autoAlpha: 1,
        onStart: () => {
          gsap.set(this.chipdrawref.current, { opacity: 1 });
        }
      });

    } else {

      // document.getElementById("playerDealerCardContainer").style.zIndex = 20;
      document.getElementById("betpannelrussiancover").style.display = "block";
      document.getElementById("betpannelrussiancover").classList.add("PlayerCardpannelcoverLndsp");
      document.getElementById("betbtntext").classList.remove("betLandscape");
      document.getElementById("foldtext").classList.remove("foldtextLandscape");

      betchipsaddtodrawContainerElement.classList.add("drawChipDivLndsp");
      betTextaddtodrawElement.className = "";
      betTextaddtodrawElement.style.cssText = "";
      betTextaddtodrawElement.style.color = "#ffffff";
      // betTextaddtodrawElement.classList.add(betTextaddtodrawElementClass, 'betTextaddwLndsp');

      betchipsdraw.classList.add("betchipsaddtodraw")
      rankinginfoElement.classList.add("pokerrankingPlayerLndsp");
      gsap.to(this.chipdrawref.current, {
        x: -1230,
        y: 0,
        duration: 0.00001,
        zIndex: 12,
        opacity: 1,
        onStart: () => {
          gsap.set(this.chipdrawref.current, { opacity: 1 });
        }
      });

    }



    if (rankinginfoElement) {
      if (window.innerHeight > window.innerWidth) {
        if (res.action === "BUY_PLAYER_CARD" ||
          res.playerFinalCards.length === 6
        ) {
          rankinginfoElement.style.left = "33%";
        } else {
          if (this.state.isMobilePortraitLndsp) {
            rankinginfoElement.style.left = "35%";
          }

        }

      } else {

        if (res.action === "BUY_PLAYER_CARD" ||
          res.playerFinalCards.length === 6
        ) {
          // rankinginfoElement.style.left = "61.5%";
          rankinginfoElement.style.left = "42.5%";
        } else {
          if (this.state.isMobilePortraitLndsp) {
            // rankinginfoElement.style.left = "62.5%";
            rankinginfoElement.style.left = "43.5%";
          }

        }


      }


    };


    if (this.state.isMobilePortraitLndsp) {
      document.getElementById("playerDealerCardContainer").style.zIndex = 1;
    }


  }


  FoldCards() {
    if (this.state.mutesounds) {
      this.betrussiansnd.pause();
    }
    else {
      this.betrussiansnd.play();

    }
    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }
    this.clearallsettimeouts()

    // this.setState({bsbtn:true,insurencebtn:true,antebetcircle:true})
    // alert("foldcard");
    const drawbutton = document.getElementById("drawbtn");
    const buybutton = document.getElementById("buybtn");
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");


    document.getElementById("drawtext").textContent = "";
    document.getElementById("buytext").textContent = "";

    betbutton.src = "";
    betbutton.style.cssText = ""

    foldbutton.src = "";
    foldbutton.style.cssText = ""

    drawbutton.src = "";
    drawbutton.style.cssText = ""


    buybutton.src = "";
    buybutton.style.cssText = ""


    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      this.setState({ Foldbtn: 1 })
      // document.getElementById("betTextaddtodraw").style.zIndex=-1
      document.getElementById("betbtntext").classList.remove("betwindows");
      document.getElementById("foldtext").classList.remove("foldtextwindows");
      document.getElementById("drawtext").textContent = "";
      document.getElementById("betbtntext").textContent = "";

      buybutton.src = "";
      buybutton.style.cssText = ""

    } else {

      document.getElementById("onepair").style.color = "";
      document.getElementById("Threekind").style.color = "";
      document.getElementById("aceking").style.color = "";
      document.getElementById("fullhse").style.color = "";
      document.getElementById("Straightflush").style.color = "";
      document.getElementById("frofkind").style.color = "";
      document.getElementById("twopair").style.color = "";
      document.getElementById("flush").style.color = "";
      document.getElementById("stright").style.color = "";
      document.getElementById("royalflush").style.color = "";


      this.setState({ betchipincrementbtn: false, betdecreamentbtn: false });

      document.getElementById("betbtntext").classList.remove("betportrait");
      document.getElementById("foldtext").classList.remove("foldtextportrait");
      document.getElementById("betbtntext").textContent = "";
      document.getElementById("foldtext").textContent = "";

      // document.getElementById("mobileAntiMaskCover").style.display = "none";
      // document.getElementById("betpannelrussiancover").style.display = "none";
      // const isPortrait = window.innerHeight > window.innerWidth;
      // if (isPortrait) {
      //   document.getElementById("mobileAntiMaskCover").style.display = "none";
      //   document.getElementById("betpannelrussiancover").style.display = "none";
      //   // alert(".......123portraiteclear");
      // } else {
      //   // alert(".......landscape");
      // }
    }

    const body = {
      sessionId: sessionStorage.getItem("sessionId"),

      CN: "RUSSIAN_POKER_BET",
      object: {
        action: "FOLD",
        gameId: this.props.gameId,
        playerId: Number(this.props.playerId),
        handId: Number(this.props.gameState.handId)
      },
    };
    // console.log(body);
    this.props.network.sendof(body);



  }


  InsurenceClaim(txt) {

    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }

    if (this.state.insurencetbet <= 0 && txt !== "noinsurence") {

      if (Number((this.state.totalbet + this.state.insurenceMinbetAmt).toFixed(2)) > this.props.balance) {
        this.setState({
          betshigh_balnce: true,
          lowBalaceErrorInfo: `Insufficient Funds to place the Insurance of ${this.state.insurenceMinbetAmt.toFixed(1)},Please DEPOSIT to continue playing.`
        });
        this.lowBalanceErrorInformationPopupanimation();
      } else if (txt == "insurence" && Number((this.state.insurencetbet + this.state.insurenceMinbetAmt).toFixed(2)) < this.state.insurenceMinbetAmt) {
        this.setState({
          infoText: `You have a ${this.state.playerhand_status}! Bet limits for INSURANCE are ${this.state.insurenceMinbetAmt.toFixed(1)} - ${(this.state.insurenceMaxbetAmt).toFixed(1)}
          Press NO INSURANCE to continue without insurance.`,
        })

      }
      else {
        // this.setState({ betshigh_balnce: null });
        // console.log(Number(this.state.insurencetbet.toFixed(2)), this.state.insurencetbet.toFixed(2), this.state.insurenceMinbetAmt, this.state.insurenceMaxbetAmt);
        let betchipstoinsurence = document.getElementById("betchipsaddinsurence");
        if (txt === "insurence") {
          if (this.state.betshigh_balnce === true) {
            this.hidepopup_betshigh();
          }

          this.playPokerChipSound(this.pokerchipsnd);
          if (
            navigator.userAgentData &&
            navigator.userAgentData.platform &&
            navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
          ) {
            betchipstoinsurence.src = this.ReturnChipBasedAmount(Number(this.state.insurenceMinbetAmt));
            let insurencebetamount = this.topLable_rsp(Number(this.state.insurenceMinbetAmt))
            this.setState({
              totalbet: this.state.totalbet + Number(this.state.insurenceMinbetAmt),
              insurencetbet: this.state.insurencetbet + Number(this.state.insurenceMinbetAmt),
              betdeductions: this.state.betdeductions + Number(this.state.insurenceMinbetAmt)
            })


            Object.assign(betchipstoinsurence.style, porttraitejson.betchipstoinsurencewindows)



            var tl = gsap.timeline();
            tl.to(this.chipinsurenceref.current, {
              x: -966,
              y: -671,
              duration: 0.2,
              ease: "power2.inOut",
              zIndex: 22,
            });
            tl.to(this.chipinsurenceref.current, {
              opacity: 0,
              x: 0,
              y: 0,
              duration: 0.00001,
            });
            // tl.reverse();
            tl.to(this.chipinsurenceref.current, { opacity: 1, zIndex: -2 });
            tl.play();


            this.clertime38 = setTimeout(() => {
              this.setState((prevState) => ({
                storebetchips: [
                  ...prevState.storebetchips,
                  <div id="chiptoinsurence">
                    <img
                      className="chiptoinsurence_windows"
                      src={this.ReturnChipBasedAmount(Number(this.state.insurenceMinbetAmt))}
                      alt="Chip 10"
                    />
                    <span className="insuranceTextwndwsChip10"> {insurencebetamount} </span>
                  </div>
                ],
              }));
            }, 150);




          } else {



            let convrInsurenceBet = this.topLable_rsp(this.state.insurenceMinbetAmt);
            this.setState((prevState) => ({
              insurencetbet: Number(this.state.insurenceMinbetAmt),
              totalbet: prevState.totalbet + Number(this.state.insurenceMinbetAmt),
              betdeductions: prevState.betdeductions + Number(this.state.insurenceMinbetAmt)
            }));
            betchipstoinsurence.src = this.ReturnChipBasedAmount(Number(this.state.insurenceMinbetAmt.toFixed(2)));
            this.clertime42 = setTimeout(() => {
              this.setState((prevState) => ({
                storeinsurancechips: [
                  ...prevState.storeinsurancechips, { chip: this.ReturnChipBasedAmount(Number(this.state.insurenceMinbetAmt.toFixed(2))), chipvalue: convrInsurenceBet, chipcolor1: null },
                ],

              }));
            }, 150);
            if (window.innerHeight > window.innerWidth) {
              var tl = gsap.timeline();
              tl.to(this.chipinsurenceref.current, {
                x: -16,
                y: -1373,
                duration: 0.15,
                ease: "power2.inOut",
                zIndex: 15,
                autoAlpha: 1,
                opacity: 1,
              });

              tl.to(this.chipinsurenceref.current, {
                opacity: 0,
                x: 0,
                y: 0,
                duration: 0.00001,
              });

              tl.to(this.chipinsurenceref.current, { opacity: 0, zIndex: -2 });
              tl.play();

            } else {
              var tl = gsap.timeline();
              tl.to(this.chipinsurenceref.current, {
                x: -1050,
                y: -175,
                duration: 0.15,
                ease: "power2.inOut",
                zIndex: 15,
                autoAlpha: 1
              });
              tl.to(this.chipinsurenceref.current, {
                autoAlpha: 0,
                x: 0,
                y: 0,
                duration: 0.00001,
              });

              tl.play();

            }




          }
        }


      }



    } else {

      if (txt == "insurence" && Number((this.state.insurencetbet.toFixed(2))) < this.state.insurenceMinbetAmt) {
        this.setState({
          infoText: `You have a ${this.state.playerhand_status}! Bet limits for INSURANCE are ${this.state.insurenceMinbetAmt.toFixed(1)} - ${(this.state.insurenceMaxbetAmt).toFixed(1)}
          Press NO INSURANCE to continue without insurance.`,
        })

      } else {

        const betbutton = document.getElementById("betbtn");
        const foldbutton = document.getElementById("Foldbtn");
        const betbtntextElement = document.getElementById("betbtntext");
        const foldtextextElement = document.getElementById("foldtext");
        betbutton.src = "";
        betbutton.style.cssText = "";
        betbtntextElement.className = "";
        betbtntextElement.textContent = "";
        foldbutton.src = "";
        foldbutton.style.cssText = "";
        foldtextextElement.className = "";
        foldtextextElement.textContent = "";
        if (this.state.betshigh_balnce === true) {
          this.hidepopup_betshigh();
        }
        if (txt == "insurence") {

          if (this.state.mutesounds) {
            this.betrussiansnd.pause();
          }
          else {
            this.betrussiansnd.play();

          }

        } else {

          this.setState({
            totalbet: this.state.totalbet - this.state.insurencetbet,
            betdeductions: this.state.betdeductions - this.state.insurencetbet,
            insurencetbet: 0,


          })

          if (this.state.mutesounds) {
            this.clear_snd = new Audio(clear_snd)
            this.clear_snd.pause()

          }
          else {
            this.clear_snd = new Audio(clear_snd)
            this.clear_snd.play()

          }

        }


        let insurencecliam;
        if (
          navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
        ) {
          document.getElementById("insurenceRussian").classList.add("insurencenodrop")
          this.setState({ insurencebtn: true, infoText: "" })
          document.getElementById("insurenceactive").src = "";
          document.getElementById("insurenceactive").classList.remove("insurenceactivewindows");
          document.getElementById("insurenceactive").classList.remove("insurenceblink");
          if (txt == "insurence") {
            insurencecliam = true
            this.insuranceActive = true;
          }
          else {

            if (document.getElementById("Foldbtn").classList.contains("insurencenodrop")) {
              // alert(".....added")
              document.getElementById("Foldbtn").classList.remove("insurencenodrop");
              document.getElementById("Foldbtn").classList.remove("addpointer");
            }
            this.setState({ infoText: "" })
            let tl = gsap.timeline()

            tl.to("#chiptoinsurence", {
              x: 950, y: 690, duration: 0.2, ease: "power2.inOut", opacity: 1,

              onComplete: function () {
                gsap.set("#chiptoinsurence", { opacity: 0 });
              }
            });



            document.getElementById("insurenceactive").src = "";
            document.getElementById("insurenceactive").classList.remove("insurenceblink");
            document.getElementById("insurenceRussian").classList.remove("addpointer");
            insurencecliam = false
            this.insuranceActive = false;
            this.setState({ disablemultibutton: true, infoText: "", })
          }


        } else {
          this.setState({
            insurencebtn: true,
            betchipincrementbtn: true,
            betdecreamentbtn: true,
            infoText: "",
            bsbtn: true

          })
          document.getElementById("mobileAntiMaskCover").style.display = "block";
          document.getElementById("insurenceactive").src = "";

          if (txt == "insurence") {
            insurencecliam = true;
            this.insuranceActive = true;
          }
          else {
            insurencecliam = false;
            this.insuranceActive = false;
            if (window.innerHeight > window.innerWidth) {
              document.getElementById("insurenceactive").classList.remove("insurenceactivePortrait");
              if (document.getElementById("chiptoinsurence")) {
                gsap.to("#chiptoinsurence", { x: 15, y: 1376, duration: 0.5, zIndex: -2, opacity: 0 });
              };

            } else {
              document.getElementById("insurenceactive").classList.remove("insurenceactiveLndsp");
              if (document.getElementById("chiptoinsurence")) {
                let t1 = gsap.timeline();
                t1.to("#chiptoinsurence", { x: 1050, y: 175, duration: 0.5, zIndex: 2, autoAlpha: 1, ease: "power2.inOut" });
                t1.to("#chiptoinsurence", { x: 0, y: 0, duration: 0.00001, zIndex: -2, autoAlpha: 0 });
              };
            }

            const foldbutton = document.getElementById("Foldbtn");
            document.getElementById("foldtext").style.opacity = "1";
            foldbutton.style.opacity = "1"


          }

        }


        console.log(this.state.insurencetbet)
        const body = {
          sessionId: sessionStorage.getItem("sessionId"),
          CN: "RUSSIAN_POKER_BET",
          object: {
            insuranceBetAmount: insurencecliam ? Number(this.state.insurencetbet.toFixed(2)) : 0,
            action: "INSURANCE",
            hasInsurance: insurencecliam,
            gameId: this.props.gameId,
            playerId: Number(this.props.playerId),
            handId: Number(this.props.gameState.handId)
          },
        };
        // console.log(body);

        this.props.network.sendof(body);



      }




    }



  }




  FoldHandresp(res) {

    document.getElementById("insurencetext").style.color = "";
    document.getElementById("bonustext").style.color = "";
    document.getElementById("bettext").style.color = "";


    if (res.message === `Attention! You have a ${res.playerHand}. Are you sure you want to fold?`) {
      this.setState({ cmfrmdealeraction: 2, cmfrmdealeractionNO: 2 })
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {


        const drawbutton = document.getElementById("drawbtn");
        const buybutton = document.getElementById("buybtn");
        const betbutton = document.getElementById("betbtn");
        const foldbutton = document.getElementById("Foldbtn");

        const drawtextElement = document.getElementById("drawtext");
        const buytextElement = document.getElementById("buytext");
        const betbtntextElement = document.getElementById("betbtntext");
        const foldtextElement = document.getElementById("foldtext");

        betbtntextElement.textContent = "";
        foldtextElement.textContent = "";

        drawbutton.src = "";
        drawbutton.style.cssText = "";


        buybutton.src = "";
        buybutton.style.cssText = "";

        betbutton.src = "";
        betbutton.style.cssText = "";


        foldbutton.src = "";
        foldbutton.style.cssText = "";


        document.getElementById("confirmbuydealer_popup").style.display = "block";
        document.getElementById("alertTxt").classList.add("alertTxt");

        document.getElementById("alertTxt").innerHTML = res.message;
        document.getElementById("confirmbuydealer_popup").classList.add("popup_cnfrmbuydealrcard");
        document.getElementById("yes").classList.add("yesbutton_popup")
        document.getElementById("no").classList.add("nobutton_popup")

      }
      else {
        this.setState({ bsbtn: true })
        const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popup");


        setTimeout(() => {
          document.getElementById("mobileAntiMaskCover").style.display = "none";
          document.getElementById("betpannelrussiancover").style.display = "none";
        }, 500)
        // const isPortrait = window.innerHeight > window.innerWidth;
        // if (isPortrait) {
        //   document.getElementById("mobileAntiMaskCover").style.display = "none";
        //   document.getElementById("betpannelrussiancover").style.display = "none";

        // } else {

        // }
        if (window.innerHeight > window.innerWidth) {

          confirmbuydealerPopupElement.className = "";
          confirmbuydealerPopupElement.style.display = "block";
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardPortrait");
          document.getElementById("alertTxt").innerHTML = res.message;
          document.getElementById("alertTxt").classList.add('alertTxtPortrait');
          document.getElementById("yes").classList.add("yesbutton_popup_portrait")
          document.getElementById("no").classList.add("nobutton_popup_portrait")

        }
        else {
          confirmbuydealerPopupElement.style.display = "block";
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardLndsp");
          document.getElementById("alertTxt").innerHTML = res.message;
          document.getElementById("alertTxt").classList.add('alertTxtPortrait');
          document.getElementById("yes").classList.add("yesbutton_popup_portrait")
          document.getElementById("no").classList.add("nobutton_popup_portrait")

        }
      }


    }
    else {

      // this.clear_snd = new Audio(clear_snd);
      // if (this.state.mutesounds) {
      //   this.clear_snd.pause();
      // }
      // else {
      //   this.clear_snd.play();
      // }

      // if (this.state.mutesounds) {
      //   this.playerLose.pause();
      // } else {
      //   this.playerLose.play();
      // }
      this.playRemainingSounds(this.playerLose)
      this.HideBonuspayouttable()

      this.updateplayerbalnace();
      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");

      const hands = [
        "onepair", "Threekind", "aceking", "fullhse",
        "Straightflush", "frofkind", "twopair",
        "flush", "stright", "royalflush"
      ];

      hands.forEach(hand => {
        document.getElementById(hand).style.color = "";
      });


      document.getElementById("betactive").src = "";
      document.getElementById("betactive").classList.remove("betactive");
      // document.getElementById("betpannelrussiancover").style.display = "block";

      // this.setState({ betbutton: "REBET", buttontext: "REBETX2" })
      // const betbutton = document.getElementById("betbtn");
      // const foldbutton = document.getElementById("Foldbtn");
      document.getElementById("glowonwinchip").classList.remove("chipglow_bonus")


      // if (document.getElementById("dealbtntext")) {
      //   document.getElementById("dealbtntext").textContent = "REBET";
      // }
      // if (document.getElementById("betbtntextrebet")) {
      //   document.getElementById("betbtntextrebet").textContent = "REBET";
      // }






      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {

        if (res.bonusWinAmount > 0) {

          this.removehigltbonusclasses(res);
        }

        gsap.set("#betTextaddtodraw ,betchipsaddtconfrm", { zIndex: -2, opacity: 0, duration: 0.1 })
        this.setState((prevState) => ({
          previousAnteBetAmount: res.anteBetAmount,
          previousBonusbetamount: res.bonusBetAmount,
          totalbet: 0,
          totalwinRussian: 0,
          anteBetAmount: 0,
          bonusbetamount: 0,
          insurencetbet: 0,
          // betdeductions: 0,
          betbutton: "REBET",
          buttontext: "REBETX2",
          keyboardhandle: true,
          betBtnValue: 2,
          disBetBtn: 0,
          infoText: res.message,
          betchipincrementbtn: false, betdecreamentbtn: false
        }));
        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();
        document.getElementById("rankinginfodeler").classList.remove("windowspokerrankingdeler");
        document.getElementById("rankinginfo").classList.remove("windowspokerranking");
        document.getElementById("bnsactive").classList.remove("betactive");

        document.getElementById("betdecrement").classList.remove("drwnodrop")
        document.getElementById("betincrenet").classList.remove("drwnodrop")


        // this.clertime11=setTimeout(() => {
        setTimeout(() => {
          document.getElementById("betpannelrussiancover").classList.remove("bet_pannelcoverrussian");

          document.getElementById("betbtntext").textContent = "";
          document.getElementById("foldtext").textContent = "";
          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnwindows)

          document.getElementById("betbtntext").innerHTML = this.props.language.Rebet;
          document.getElementById("betbtntext").classList.add("betwindows");


          foldbutton.src = Foldbnwindows;
          Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
          document.getElementById("foldtext").textContent = this.props.language.RebetX2;
          document.getElementById("foldtext").classList.add("rebettextwindows");



          this.setState({ infoText: this.props.language.Place_antebet });


        }, 2300)

        this.setState({ bsbtn: true });
        document.getElementById("bonusRussian").classList.remove("addpointer");
        document.getElementById("bonusRussian").classList.add("drwnodrop");


        gsap.set("#betTextaddtodraw ,betchipsaddtconfrm", { zIndex: -2, opacity: 0, duration: 0.1 })
        let t1 = gsap.timeline();
        t1.to("#chipcenterposition", {
          // y: -2060, duration: 20.5, opacity: 1,
          x: 0, y: -662, duration: 0.2, opacity: 1,
          onComplete: () => {
            gsap.set("#chipcenterposition", { opacity: 0 });
          }
        });
        t1.to("#chipcenterposition", {
          x: 955, y: 554, duration: 0.1, zIndex: -12, opacity: 0,
          onComplete: () => {
            gsap.set("#chipcenterposition", { opacity: 0 });
          }

        });

        t1.play();
        // document.getElementById("centerchips").classList.remove("chip10centerposition")

        // gsap.to("#betTextaddtodraw,betchipsaddtodrawText", { opacity: 0, duration: 0.1 })


        let t2 = gsap.timeline();
        t2.to("#betchipsaddtodraw, betchipsaddtconfrm", {
          x: -1082,
          y: -1267, duration: 0.5, opacity: 1,
          onComplete: () => {
            gsap.set("#betchipsaddtodraw ,betchipsaddtconfrm", { opacity: 0 });
          }
        });
        t2.to("#betchipsaddtodraw ,betchipsaddtconfrm", {
          x: 45, y: -54, duration: 0.5, zIndex: -2, opacity: 0,

          onComplete: () => {
            gsap.set("#betchipsaddtodraw ,betchipsaddtconfrm", { opacity: 0, delay: 2 });

          }

        });
        t2.play();


        document.getElementById("buytext").textContent = "";
        gsap.to("#betchipsaddtodrawText", { opacity: 0, duration: 0.1, zIndex: -2 })
        let centerbns = gsap.timeline();
        document.getElementById("glowonwinchip").classList.remove("chipglow_bonus")
        centerbns.to("#chip10centerbns", {
          x: 478, y: -674, duration: 0.5, zIndex: -2, opacity: 0,

          onComplete: () => {
            gsap.set("#chip10centerbns", { opacity: 0 });

          }

        });
        centerbns.play();
        gsap.set(["#betchipsaddtconfrm", "#betchipsaddtodraw", "#chipcenterposition", "#betchipsaddtobet"], { clearProps: "all" });
      }
      else {
        this.clertime10 = setTimeout(() => {
          this.setState({ infoText: this.props.language.Place_antebet });
        }, 1000);
        this.HideBonuspayouttable()
        this.setState((prevState) => ({
          previousAnteBetAmount: prevState.anteBetAmount,
          previousBonusbetamount: prevState.bonusbetamount,
          totalbet: 0,
          totalwinRussian: 0,
          anteBetAmount: 0,
          bonusbetamount: 0,
          insurencetbet: 0,
          prevStatestoreantebetchips: [...prevState.storeantebetchips],
          prevStatestorebonuschips: [...prevState.storebonuschips],
          storebonuschips: [],
          storeinsurancechips: [],
          // betdeductions: 0,
          betbutton: "REBET",
          buttontext: "REBETX2",
          infoText: res.message,
          bsbtn: true
        }));

        setTimeout(() => {
          betbutton.src = Betbtn;
          //document.getElementById("betbtntext").textContent = "";
          document.getElementById("betbtntext").innerHTML = this.props.language.Rebet;

          // document.getElementById("betbtntext").classList.add("rebet");
          document.getElementById("foldtext").textContent = this.props.language.RebetX2;
        }, 1500)



        const betbtntextElement = document.getElementById("betbtntext");
        const foldtextElement = document.getElementById("foldtext");
        const betactiveBlinkElement = document.getElementById("betactive");

        const drawbtnElement = document.getElementById("drawbtn");
        const buybtnElement = document.getElementById("buybtn");
        const drawtextElment = document.getElementById("drawtext");
        const buytextElement = document.getElementById("buytext");

        if (res.action === "CONFIRM_FOLD") {
          if (drawbtnElement && buybtnElement && drawtextElment && buytextElement) {
            drawbtnElement.src = "";
            buybtnElement.src = "";
            drawtextElment.textContent = "";
            buytextElement.textContent = "";
          }

          if (window.innerHeight > window.innerWidth) {
            let t4 = gsap.timeline();
            t4.to(this.chipdrawref.current, {
              // x: 360, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
              x: 340, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
              onComplete: () => {
                gsap.set(this.chipdrawref.current, { opacity: 0 });
              }
            });
            t4.to(this.chipdrawref.current, {
              x: 548, y: -36, duration: 0.5, zIndex: -2, opacity: 0,
              onComplete: () => {
                gsap.set(this.chipdrawref.current, { opacity: 1, delay: 2 });
              }
            });

            t4.play();

          } else {
            let t4 = gsap.timeline();
            t4.to(this.chipdrawref.current, {
              y: -700, duration: 0.5, zIndex: 12, ease: "power2.inOut", autoAlpha: 1,
              onComplete: () => {
                gsap.set(this.chipdrawref.current, { autoAlpha: 0 });
              }
            });
            t4.to(this.chipdrawref.current, {
              x: 0, y: 0, duration: 0.5, zIndex: -2, autoAlpha: 0,
              onComplete: () => {
                gsap.set(this.chipdrawref.current, { autoAlpha: 0, });
              }
            });

            t4.play();
          }

        }



        betactiveBlinkElement.src = "";
        betactiveBlinkElement.classList.remove("betactivePortrait");
        let rankinginfoElement = document.getElementById("rankinginfo");
        rankinginfoElement.className = "";
        rankinginfoElement.textContent = "";


        setTimeout(() => {

          this.clearActivesWeDid();
        }, 500)


        setTimeout(() => {
          betbutton.src = Betbtn;
          foldbutton.src = Foldbn;
          document.getElementById("mobileAntiMaskCover").style.display = "none";
        }, 1500)
        if (window.innerHeight > window.innerWidth) {

          setTimeout(() => {
            Object.assign(betbutton.style, porttraitejson.Betbtnportraite)
            Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);

            betbtntextElement.textContent = this.props.language.Rebet;
            foldtextElement.textContent = this.props.language.RebetX2;
            // porttraitejson.betportraite
            betbtntextElement.classList.add("betportrait");
            foldtextElement.classList.add("rebettextportrait");
          }, 1500)


          // x: 25,
          // y: 1200,
          let t1 = gsap.timeline();
          t1.to("#chipcenterposition", {
            // x:25, y: 1200, duration: 0.2, opacity: 1,
            x: 25, y: -2060, duration: 0.2, opacity: 1,
            onComplete: function () {
              gsap.set("#chipcenterposition", { opacity: 0 });
            }
          });
          t1.to("#chipcenterposition", {
            // x: 13,
            y: -1130, duration: 0.0001, zIndex: -12, opacity: 0,
            onComplete: function () {
              gsap.set("#chipcenterposition", { opacity: 0, });
            }

          });
          t1.play();

          if (this.state.buydrawCheck) {

            let t4 = gsap.timeline();
            t4.to(this.chipdrawref.current, {
              x: 340, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
              // x: 360, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
              onComplete: () => {
                gsap.set(this.chipdrawref.current, { opacity: 0 });
              }
            });
            t4.to(this.chipdrawref.current, {
              x: 548, y: -36, duration: 0.5, zIndex: -2, opacity: 0,
              onComplete: () => {
                gsap.set(this.chipdrawref.current, { opacity: 1, delay: 2 });
              }
            });
            t4.play();
          }


          if (this.playerWinBonus === true) {
            if (document.getElementById("chip10centerbns")) {
              let ml = gsap.timeline()
              ml.to("#chip10centerbns", { x: 0, y: -900, duration: 0.2, autoAlpha: 1 })
              ml.to("#chip10centerbns", { x: -170, y: 1200, duration: 0.00001, zIndex: -2, autoAlpha: 0 });
              // gsap.to("#chip10centerbns", { x: -170, y: 1200, duration: 0.5, zIndex: -2, opacity: 1 });
              this.playerWinBonus = false;

            }

          }



        } else {

          // IF MOBILE LANDSCAPE ;


          setTimeout(() => {
            Object.assign(betbutton.style, porttraitejson.Betbtnlndspe)
            Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);

            betbtntextElement.textContent = this.props.language.Rebet;
            foldtextElement.textContent = this.props.language.RebetX2;
            // porttraitejson.betportraite
            betbtntextElement.classList.add("betLandscape");
            foldtextElement.classList.add("foldtextLandscape");
          }, 1500)




          let t1 = gsap.timeline();
          t1.to("#chipcenterposition",
            { x: 0, y: -600, duration: 0.3, autoAlpha: 1, ease: "power2.inOut", });
          t1.to("#chipcenterposition",
            { x: 0, y: 0, duration: 0.0001, zIndex: -12, autoAlpha: 0 });
          t1.play();

          if (this.state.buydrawCheck) {
            let t4 = gsap.timeline();
            t4.to(this.chipdrawref.current, { y: -600, duration: 0.3, ease: "power2.inOut", autoAlpha: 1 });
            t4.to(this.chipdrawref.current, { x: 0, y: 0, duration: 0.0001, zIndex: -2, autoAlpha: 0 });
            t4.play();
          }

          if (this.playerWinBonus === true) {
            // this.ifPlayerWinBonusAmount();
            let ml = gsap.timeline()
            ml.to("#chip10centerbns", {
              x: 600, y: -500, duration: 0.2, autoAlpha: 1,
            });
            ml.to("#chip10centerbns", {
              x: 0, y: 0, duration: 0.00001, autoAlpha: 0,
            });
          }



          // gsap.to("#chipcenterposition",  { x: 0, y: 0, duration: 0.0001 ,zIndex: -2,opacity: 0});
          // gsap.to("#betTextaddtoBet",     { x: 0, y: 0, duration:0.0001,zIndex: -2,opacity: 0});
          // gsap.to("#betchipsaddtobet",    { x: 0, y: 0, duration: 0.0001});
          // gsap.to(this.chipdrawref.current, {x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0});
          // if (this.insuranceActive) {
          //   gsap.to("#chiptoinsurence", { x: 0, y:0, duration: 0.0001,zIndex: -2, opacity: 0 });
          // };
          // if (this.wanttobuydelercrd === true) {
          //   gsap.to(this.buyDealerCardDrawn.current, { opacity: 0, x: 0, y: 0, zIndex: -2,duration:0.0001})

          // }

          // gsap.set('#dealerchip', {x:0,y:0, opacity: 0, zIndex: -10 ,duration:0.00001})

        }

      }
    }

  }










  Show_FoldHandresp(res) {

    if (res.message === `Attention! You have a ${res.playerHand}. Are you sure you want to fold?`) {
      if (res.playerReplaceCardAmount > 0 || res.playerBuyCardAmount > 0) {
        this.drawReplaceCards = true;

      }


      this.setState({ cmfrmdealeraction: 2, cmfrmdealeractionNO: 2, infoText: res.message })
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {



        this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerCards);
        this.showParamBetStatus(false);
        this.ShowbonusstatusTousr(res);



        document.getElementById("confirmbuydealer_popup").style.display = "block";
        document.getElementById("alertTxt").classList.add("alertTxt");

        document.getElementById("alertTxt").innerHTML = res.message;
        document.getElementById("confirmbuydealer_popup").classList.add("popup_cnfrmbuydealrcard");
        document.getElementById("yes").classList.add("yesbutton_popup")
        document.getElementById("no").classList.add("nobutton_popup")

      }

    }
    else {
      this.updateplayerbalnace();
      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");
      this.clertime10 = setTimeout(() => {
        this.setState({ infoText: this.props.language.Place_antebet });
      }, 700);
      const hands = [
        "onepair", "Threekind", "aceking", "fullhse",
        "Straightflush", "frofkind", "twopair",
        "flush", "stright", "royalflush"
      ];

      hands.forEach(hand => {
        document.getElementById(hand).style.color = "";
      });


      document.getElementById("betactive").src = "";
      document.getElementById("betactive").classList.remove("betactive");
      // document.getElementById("betpannelrussiancover").style.display = "block";
      document.getElementById("betpannelrussiancover").classList.remove("bet_pannelcoverrussian");

      // this.setState({ betbutton: "REBET", buttontext: "REBETX2" })
      // const betbutton = document.getElementById("betbtn");
      // const foldbutton = document.getElementById("Foldbtn");
      betbutton.src = Betbtn;
      //document.getElementById("betbtntext").textContent = "";
      document.getElementById("betbtntext").innerHTML = this.props.language.Rebet;

      // document.getElementById("betbtntext").classList.add("rebet");







      document.getElementById("foldtext").textContent = this.props.language.RebetX2;

      // if (document.getElementById("dealbtntext")) {
      //   document.getElementById("dealbtntext").textContent = "REBET";
      // }
      // if (document.getElementById("betbtntextrebet")) {
      //   document.getElementById("betbtntextrebet").textContent = "REBET";
      // }


      // this.setState({ infoText: res.message });



      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {

        this.setState((prevState) => ({
          previousAnteBetAmount: res.anteBetAmount,
          previousBonusbetamount: res.bonusBetAmount,
          totalbet: 0,
          anteBetAmount: 0,
          bonusbetamount: 0,
          insurencetbet: 0,
          betdeductions: 0,
          betbutton: "REBET",
          buttontext: "REBETX2",
          keyboardhandle: true,
          betBtnValue: 2,
          disBetBtn: 0,
          infoText: res.message
        }));

        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();
        document.getElementById("rankinginfodeler").classList.remove("windowspokerrankingdeler");
        document.getElementById("rankinginfo").classList.remove("windowspokerranking");
        document.getElementById("bnsactive").classList.remove("betactive");



        document.getElementById("betbtntext").textContent = "";
        document.getElementById("foldtext").textContent = "";
        // this.clertime11=setTimeout(() => {

        betbutton.src = Betbtn;
        Object.assign(betbutton.style, porttraitejson.Betbtnwindows)

        document.getElementById("betbtntext").innerHTML = this.props.language.Rebet;
        document.getElementById("betbtntext").classList.add("betwindows");


        foldbutton.src = Foldbnwindows;
        Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
        document.getElementById("foldtext").textContent = this.props.language.RebetX2;
        document.getElementById("foldtext").classList.add("rebettextwindows");
        this.setState({ infoText: this.props.language.Place_antebet, bsbtn: true });
        document.getElementById("bonusRussian").classList.remove("addpointer");
        document.getElementById("bonusRussian").classList.add("drwnodrop");



        let t1 = gsap.timeline();
        t1.to("#chipcenterposition", {
          // y: -2060, duration: 20.5, opacity: 1,
          x: 0, y: -662, duration: 0.2, opacity: 1,
          onComplete: () => {
            gsap.set("#chipcenterposition", { opacity: 0 });
          }
        });
        t1.to("#chipcenterposition", {
          x: 955, y: 554, duration: 0.1, zIndex: -12, opacity: 0,
          onComplete: () => {
            gsap.set("#chipcenterposition", { opacity: 0 });
          }

        });

        t1.play();
        // document.getElementById("centerchips").classList.remove("chip10centerposition")


        let t2 = gsap.timeline();
        t2.to("#betchipsaddtodraw, betchipsaddtconfrm", {
          x: -1082,
          y: -1267, duration: 0.5, opacity: 1,
          onComplete: () => {
            gsap.set("#betchipsaddtodraw ,betchipsaddtconfrm", { opacity: 0 });
          }
        });
        t2.to("#betchipsaddtodraw ,betchipsaddtconfrm", {
          x: 45, y: -54, duration: 0.5, zIndex: -2, opacity: 0,
          onComplete: () => {
            gsap.set("#betchipsaddtodraw ,betchipsaddtconfrm", { opacity: 0, delay: 2 });
          }

        });
        t2.play();


      }



    }
  }









  ifPlayerWinBonusAmount = () => {
    if (document.getElementById("chip10centerbns")) {
      this.playerWinBonus = false;
      let tl = gsap.timeline();
      // tl.to("#chip10centerbns", { x: 890, y: 0, duration: 0.5, zIndex: 2, ease: "power2.inOut", autoAlpha: 1 });
      tl.to("#chip10centerbns", { x: 0, y: 0, duration: 0.00001, zIndex: -2, autoAlpha: 0 });

    }

  }

  TotalbetamountGreaterthanbalance(betamnt) {
    let balance = this.balnce;

    if (navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform === "Windows") {

      if (this.state.totalbet > this.props.balance) {

        document.getElementById("inforussian").classList.add("windowsinfo");
        document.getElementById("betbtn").classList.remove("addpointer")
        document.getElementById("Foldbtn").classList.remove("addpointer")
        document.getElementById("betbtn").classList.add("drwnodrop")
        document.getElementById("Foldbtn").classList.add("drwnodrop")

        this.setState({
          infoText: this.props.language.Low_Balance,
          disablemultibutton: false,
          multibuttonsebledsble: false


        });
        document.getElementById("betpannelrussiancover").style.display = "block";
        document.getElementById("betpannelrussiancover").classList.add("bet_pannelcoverrussian");
        document.getElementById("betbtn").classList.add("addpointer")
        document.getElementById("Foldbtn").classList.add("addpointer")

      }
      else {
        document.getElementById("betpannelrussiancover").style.display = "none";
        document.getElementById("betpannelrussiancover").classList.remove("bet_pannelcoverrussian");
        // this.setState({ infoText: this.props.language.deal_tostart })
        document.getElementById("betbtn").classList.remove("drwnodrop")
        document.getElementById("Foldbtn").classList.remove("drwnodrop")



      }
    } else {
      const inforussianElement = document.getElementById("inforussian");
      const inforussianlandElement = document.getElementById("inforussianland");
      const betbtnElement = document.getElementById("betbtn");
      const FoldbtnElement = document.getElementById("Foldbtn");
      const mobileAntiMaskCoverElement = document.getElementById("mobileAntiMaskCover")


      if (window.innerHeight > window.innerWidth) {
        // console.log(this.state.totalbet, this.props.balance)
        if (this.state.totalbet > this.props.balance) {
          inforussianElement.className = "";
          betbtnElement.className = "";
          FoldbtnElement.className = "";
          inforussianElement.classList.add('portraitinfo');
          mobileAntiMaskCoverElement.style.display = 'block';
          mobileAntiMaskCoverElement.classList.add('mobileAntiMaskCover');
          this.setState({
            infoText: this.props.language.Low_Balance,
            disablemultibutton: false,
            multibuttonsebledsble: false
          });

        }

      } else {
        if (this.state.totalbet > this.props.balance) {
          inforussianlandElement.className = "";
          betbtnElement.className = "";
          FoldbtnElement.className = "";
          inforussianlandElement.classList.add('lndspinfo');
          mobileAntiMaskCoverElement.style.display = 'none';
          mobileAntiMaskCoverElement.classList.remove('mobileLndspAntiMaskCover');
          this.setState({ infoText: this.props.language.deal_tostart });
          betbtnElement.classList.remove("drwnodrop");
          FoldbtnElement.classList.remove("drwnodrop");
        }

      }
    }

  }



  previousantebonousamount(bettext) {

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)

    ) {
      this.setState({ multibuttonsebledsble: false, infoText: this.props.language.deal_tostart })
      setTimeout(() => {
        this.setState({ infoText: "" })
      }, 20)


    }
    let rebetamount = 0;
    let rebetorrebetX2 = 1;
    if (bettext == "REBET") {
      rebetorrebetX2 = 1;
      // console.log(bettext)
      rebetamount = this.state.previousAnteBetAmount + this.state.previousBonusbetamount
      // this.setState({ totalbet: rebetamount }, () => {
      //   this.balnce = this.topLable_rsp(Math.floor(this.props.balance - rebetamount));
      // })
      this.setState({
        totalbet: rebetamount,
        anteBetAmount: this.state.previousAnteBetAmount,
        bonusbetamount: this.state.previousBonusbetamount,
        betdeductions: this.state.previousAnteBetAmount + this.state.previousBonusbetamount,
        betBtnValue: 0, disBetBtn: 0

      })

      // console.log("rebet    : ", this.state.anteBetAmount, this.state.bonusbetamount, this.state.previousAnteBetAmount, this.state.previousBonusbetamount);

    }
    // console.log("this.state.previousAnteBetAmount", this.state.previousAnteBetAmount,
    //   "this.state.previousBonusbetamount", this.state.previousBonusbetamount
    // );
    else if (bettext == "REBETX2") {
      rebetorrebetX2 = 2;
      let rebetdouble = (this.state.previousAnteBetAmount + this.state.previousBonusbetamount) * 2;
      rebetamount = rebetdouble;
      this.setState((prevState) => ({
        totalbet: rebetamount,
        anteBetAmount: prevState.previousAnteBetAmount * 2,
        bonusbetamount: prevState.previousBonusbetamount * 2,
        betdeductions: (this.state.previousAnteBetAmount + this.state.previousBonusbetamount) * 2,
        betBtnValue: 0, disBetBtn: 0

      }));
      // this.balnce = this.topLable_rsp(Math.floor(this.props.balance - rebetdouble));
      // console.log("rebetX2    : ", this.state.previousAnteBetAmount, this.state.previousBonusbetamount, this.state.previousAnteBetAmount, this.state.previousBonusbetamount)
    }
  }


  ReturnChipBasedAmount = (amt) => {
    const amount = Number(amt)
    let chipImage = chip_10;
    if (Number(this.chipVal[0].val) <= amount && Number(this.chipVal[1].val) > amount) {
      chipImage = chip_10
    } else if (Number(this.chipVal[1].val) <= amount && Number(this.chipVal[2].val) > amount) {
      chipImage = chip_25
    }
    else if (Number(this.chipVal[2].val) <= amount && Number(this.chipVal[3].val) > amount) {
      chipImage = chip_50
    }
    else if (Number(this.chipVal[3].val) <= amount && Number(this.chipVal[4].val) > amount) {
      chipImage = chip_100

    } else if (Number(this.chipVal[4].val) <= amount && Number(this.chipVal[5].val) > amount) {
      chipImage = chip_500

    } else {
      chipImage = chip_1000
    }
    return chipImage
  }



  playPokerChipSound = (chipSound) => {
    const { isPlayingChip, mutesounds } = this.state;
    let currentSound = chipSound;
    if (!mutesounds) {

      if (isPlayingChip) {
        chipSound.stop();
      }
      if (!chipSound) {
        currentSound = this.initializeChipSound();
      }

      currentSound.play();
      this.setState({
        isPlayingChip: true
      })
    }

  };

  // playPokerBetSound = (chipSound) => {
  //   const { isPlayingBet, mutesounds } = this.state;
  //   let currentSound = chipSound;
  //   if (!mutesounds) {

  //     if (isPlayingBet) {
  //       chipSound.stop();
  //     }
  //     if (!chipSound) {
  //       currentSound = this.initializeBetSound();
  //     }

  //     currentSound.play();
  //     this.setState({
  //       isPlayingBet: true
  //     })
  //   }

  // };
  playRemainingSounds = (chipSound) => {
    const { isPlayingBet, mutesounds } = this.state;
    let currentSound = chipSound;
    if (!mutesounds) {

      // if (currentSound) {
      //   chipSound.stop();
      // }
      // if (!chipSound) {
      //   currentSound = this.initializeBetSound();
      // }

      currentSound.play();
      // this.setState({
      //   isPlayingBet: true
      // })
    }

  };



  anteclick(bettext) {

    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }

    if (bettext == "REBETX2" || bettext == "REBET") {
      setTimeout(() => {
        this.setState({ infoText: this.props.language.deal_Game })

      }, 200)
    }


    // const betbutton = document.getElementById("betbtn");
    // const foldbutton = document.getElementById("Foldbtn");
    // this.pokerchipsnd = new Audio(pokerchipsnd);
    // this.pokerchipsnd.currentTime = 0;
    const chipsstoredcontainerElement = document.getElementById('chipsstoredcontainer');


    const betchipstoantebet = document.getElementById(`betchipsaddtoante${this.state.idincrement}`);
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    const betbtntextElement = document.getElementById("betbtntext");
    const foldtextElement = document.getElementById("foldtext");
    let bnsactived = document.getElementById("bnsactive");
    // bnsactived.src = bnsactive;
    const winpopuprspelement = document.getElementById("winpopup_rsp");
    const winamtElement = document.getElementById("winamt_rsp");

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)

    ) {

      // if (bettext == "REBET") {
      //   this.state.bonusactive=this.props.gameState.response.bonusBetAmount
      // }
      // else if (bettext == "REBETX2") {
      //   this.state.bonusactive=this.props.gameState.response.bonusBetAmount*2
      // }


      document.getElementById("bonusRussian").classList.remove("drwnodrop");
      document.getElementById("bonusRussian").classList.add("addpointer");
    } else {

      if (bettext == "REBETX2" || bettext == "REBET") {

        if (this.state.mutesounds) {
          this.betrussiansnd.pause();
        }
        else {
          this.betrussiansnd.play();

        }
        betbutton.src = "";
        foldbutton.src = "";
        betbutton.style.cssText = "";
        foldbutton.style.cssText = "";
        betbtntextElement.className = "";
        foldtextElement.className = "";
        betbtntextElement.textContent = "";
        foldtextElement.textContent = "";
      }




    }


    document.getElementById("betbtn").classList.remove("bnspoiner")


    gsap.to(`#betchipsaddtoante${this.state.idincrement}`, {

      opacity: 1
    });


    // pokerranking

    this.setState((prevState) => ({
      idincrement: prevState.idincrement + 1,
      // betbutton: "DEAL",
      // buttontext: "clear",
      pokerranking: "",
      pokerrankingdeler: "",
      // totalwinRussian: 0,
      disClearBtn: 0,
      betBtnValue: 0,
      disBetBtn: 0,
      keyboardhandle: true
      //  totalbet:prevState.totalbet + 1,
    }));

    // this.cardsRef.current.ReBet();
    // this.dealerCardsContainer.current.ReBet();
    this.randomPlayerCards = [];
    this.shuffledPlayerCards = [];
    this.randomDealerCards = [];
    this.replaceCards = [];
    this.unsequenceDrawCards = [];
    this.sequenceDrawCards = [];
    this.buyCardUnsequenceArray = [];
    this.buyCardsequenceArray = [];
    this.dealerCardsRandom = [];
    this.dealerShuffledCards = [];
    this.dealerUnsequenceDrawCards = [];
    this.dealerSequenceDrawFinalCards = [];
    this.drawReplaceCards = false;

    document.getElementById('rankinginfodeler').className = "";
    document.getElementById('rankinginfo').className = "";
    document.getElementById("bonusRussian").classList.remove("bnspoiner");
    let rebetamount = 0;
    let rebetorrebetX2 = 1;


    // this.setState({ infoText: this.props.language.deal_tostart, betchipincrementbtn: false, betdecreamentbtn: false })

    console.log(this.state.previousAnteBetAmount * rebetorrebetX2)
    console.log(Number((this.state.previousAnteBetAmount * rebetorrebetX2).toFixed(2)))
    if (Number((this.state.previousAnteBetAmount * rebetorrebetX2).toFixed(2)) <= this.props.maxBetAmt) {


      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {

        // if (this.props.gameState.response && this.state.previousBonusbetamount >0 && this.state.bonusactive<=this.props.maxBetAmt) {
        // if (this.props.gameState.response && this.state.previousBonusbetamount >0) {
        // if (this.props.gameState.response.bonusBetAmount > 0) {
        if (this.state.previousBonusbetamount > 0) {

          if (bettext == "REBET") {

            rebetorrebetX2 = 1;
            // console.log(bettext)
            rebetamount = this.state.previousAnteBetAmount + this.state.previousBonusbetamount

            // this.setState({ totalbet: rebetamount }, () => {
            //   this.balnce = this.topLable_rsp(Math.floor(this.props.balance - rebetamount));
            // })
            this.setState({
              totalbet: rebetamount,
              anteBetAmount: this.state.previousAnteBetAmount,
              bonusbetamount: this.state.previousBonusbetamount,
              betBtnValue: 0, disBetBtn: 0

            })
            // console.log("rebet    : ", this.state.anteBetAmount, this.state.bonusbetamount);

          }
          // console.log("this.state.previousAnteBetAmount", this.state.previousAnteBetAmount,
          //   "this.state.previousBonusbetamount", this.state.previousBonusbetamount
          // );
          else if (bettext == "REBETX2") {
            rebetorrebetX2 = 2;
            let rebetdouble = (this.state.previousAnteBetAmount + this.state.previousBonusbetamount) * 2;
            rebetamount = rebetdouble;
            this.setState((prevState) => ({
              totalbet: rebetamount,
              anteBetAmount: prevState.previousAnteBetAmount * 2,
              bonusbetamount: prevState.previousBonusbetamount * 2,
              betBtnValue: 0, disBetBtn: 0

            }));
            // this.balnce = this.topLable_rsp(Math.floor(this.props.balance - rebetdouble));
            // console.log("rebetX2    : ", this.state.previousAnteBetAmount, this.state.previousBonusbetamount)
          }
        }
        // }
        else {
          // this.setState({ infoText: this.props.language.bonus_maxbet })
        }

        this.setState({ betchipincrementbtn: false, betdecreamentbtn: false })


        this.setState({ betbutton: "DEAL", buttontext: "clear", })
        const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnce");
        const textbetsHighElement = document.getElementById("textbets_high");
        const attentionElement = document.getElementById("attention");
        const betbtnElement = document.getElementById("betbtn");

        let rebetstatus = 1;
        if (bettext == "REBETX2" || bettext == "REBET") {


          if (this.state.mutesounds) {
            this.betrussiansnd.pause();
          }
          else {
            this.betrussiansnd.play();

          }
          // if (this.state.anteBetAmount >= this.props.maxBetAmt) {
          //   this.setState({
          //     betdeductions: (this.state.previousAnteBetAmount + this.state.previousBonusbetamount) * 2,
          //     betdeductions: this.state.previousAnteBetAmount + this.state.previousBonusbetamount,
          //   })

          // }
          // else {
          //   this.setState({
          //     betdeductions: 0,
          //     betdeductions: 0
          //   })
          // }





          let bnsactived = document.getElementById("bnsactive");
          bnsactived.classList.add("bnsctivewindows");
          bnsactived.classList.add("bnsblink");

          // document.getElementById("crdscover").classList.add("crdscover")
          document.getElementById("playerDealerCardContainer").classList.add("addpointer")



          // if (this.props.gameState.response && this.state.previousBonusbetamount >0 && this.state.bonusactive<=this.props.maxBetAmt) {
          // if (this.props.gameState.response && this.state.previousBonusbetamount >0) {
          // if (this.props.gameState.response.bonusBetAmount > 0) {
          if (this.state.previousBonusbetamount > 0) {
            var tl = gsap.timeline();
            tl.to(this.chipbnsref.current, {
              x: -861,
              y: -554,
              duration: 0.2,
              ease: "power2.inOut",
              zIndex: 12,
            });
            tl.to(this.chipbnsref.current, {
              x: -48,
              y: 0,
              opacity: 0,
              duration: 0.00001,
            });
            // tl.reverse();
            tl.to(this.chipbnsref.current, { opacity: 1, zIndex: -2 });
            tl.play();
          }
          //  }
          let totalBetAmountForLimitsCheck;
          let totalAnteBetAmount;

          for (let k = 1; k <= this.chipVal.length; k++) {
            if (this.state.count === k) {
              totalBetAmountForLimitsCheck = this.state.totalbet + Number(this.chipVal[k - 1].val);
              if (bettext == "REBETX2") {
                let chip10centerbnsElement = document.querySelectorAll("#chip10centerbns span");
                // console.log(this.state.anteBetAmount)
                // alert("...................p1")
                // let chipcenterpositionElement = document.querySelectorAll("#chipcenterposition span");
                // let chip10centerbnsElement = document.querySelectorAll("#chip10centerbns span");
                // console.log(chipcenterpositionElement)
                // for (let a = 0; a < chipcenterpositionElement.length; a++) {
                //   chipcenterpositionElement[a].innerHTML = this.state.previousAnteBetAmount * 2;
                // }
                for (let b = 0; b < chip10centerbnsElement.length; b++) {
                  chip10centerbnsElement[b].innerHTML = this.state.previousBonusbetamount * 2;
                }
                let doubleante = (this.state.previousAnteBetAmount) * 2
                // if (this.props.gameState.response && this.state.previousBonusbetamount >0   && this.state.bonusactive<=this.props.maxBetAmt) {
                // if (this.props.gameState.response && this.state.previousBonusbetamount >0) {
                // if (this.props.gameState.response.bonusBetAmount > 0) {
                if (this.state.previousBonusbetamount > 0) {
                  let doublebnsbet = (this.state.previousBonusbetamount) * 2;
                  chip10centerbnsElement = this.topLable_rsp(doublebnsbet);
                }

                // }
                totalAnteBetAmount = this.topLable_rsp(doubleante);


                rebetstatus = 2;
                // document.querySelector('antebetChipPortrait10').innerHTML = totalAnteBetAmount
              } else {

                let chip10centerbnsElement = document.querySelectorAll("#chip10centerbns span");

                for (let b = 0; b < chip10centerbnsElement.length; b++) {
                  chip10centerbnsElement[b].innerHTML = this.state.previousBonusbetamount;
                }

                totalAnteBetAmount = this.topLable_rsp(this.state.previousAnteBetAmount);
                // console.log(totalAnteBetAmount)
                chip10centerbnsElement = this.topLable_rsp(this.state.bonusbetamount);
                rebetstatus = 1;

                // if (this.props.gameState.response && this.state.previousBonusbetamount >0   && this.state.bonusactive<=this.props.maxBetAmt){
                // if (this.props.gameState.response && this.state.previousBonusbetamount >0) {

                // if (this.props.gameState.response.bonusBetAmount > 0) {
                if (this.state.previousBonusbetamount > 0) {
                  let chip10centerbnsElement = document.querySelectorAll("#chip10centerbns span");

                  for (let b = 0; b < chip10centerbnsElement.length; b++) {
                    chip10centerbnsElement[b].innerHTML = this.state.previousBonusbetamount;
                  }

                  chip10centerbnsElement = this.topLable_rsp(this.state.bonusbetamount);
                }
                // }
                // alert("..............."+this.state.bonusbetamount)

                // totalAnteBetAmount = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);
              }
            }
          }

          if (Number((Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus)).toFixed(2)) > Number(this.props.balance) ||
            Number((Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus)).toFixed(2)) > this.props.balance) {
            // console.log("this.state.anteBetAmount :------", this.state.anteBetAmount);
            // if (Number(this.state.anteBetAmount)  + Number(this.state.bonusbetamount) > 39) {

            this.setState({
              anteBetAmount: 0,
              bonusbetamount: 0,
              totalbet: 0,
            })
            // this.setState({ betshigh_balnce: true, multibuttonsebledsble: true });
            const betbutton = document.getElementById("betbtn");
            const foldbutton = document.getElementById("Foldbtn");
            const betbtntextElement = document.getElementById("betbtntext");
            const foldtextElement = document.getElementById("foldtext");

            betbutton.src = "";
            foldbutton.src = "";
            betbtntextElement.className = "";
            foldtextElement.className = "";

            //  if(this.props.balance!=0){
            if (this.state.isplayerinfoupdate) {
              console.log(Number(this.state.previousAnteBetAmount * 3 * rebetstatus), Number(this.state.previousBonusbetamount * rebetstatus) > Number(this.props.balance))

              if (Number((Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus)).toFixed(2)) > Number(this.props.balance)) {
                if (this.state.previousBonusbetamount > 0) {

                  this.setState({
                    betshigh_balnce: true,
                    lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)}, OR BONUS of ${(this.state.previousBonusbetamount * rebetstatus).toFixed(1)} Please DEPOSIT  to continue playing`
                  })


                }
                else {
                  this.setState({
                    betshigh_balnce: true,
                    lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},Please DEPOSIT to continue playing`
                  })

                }


                // this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })
              } else if (Number((Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus)).toFixed(2)) > Number(this.props.balance)) {

                if (this.state.previousBonusbetamount > 0) {
                  this.setState({
                    betshigh_balnce: true,
                    lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},with BET ${(this.state.previousAnteBetAmount * rebetstatus * 2).toFixed(1)}
                 OR Insufficient Funds to follow up your BONUS of ${(this.state.previousBonusbetamount * rebetstatus).toFixed(1)},with BET ${(this.state.previousBonusbetamount * rebetstatus * 2).toFixed(1)}
                 Please DEPOSIT to continue playing.`
                  })


                }
                else {
                  this.setState({
                    betshigh_balnce: false,
                    lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},with BET ${(this.state.previousAnteBetAmount * rebetstatus * 2).toFixed(1)} Please DEPOSIT to continue playing.`
                  })

                }
              }

            }



            //  }


            document.getElementById("betbtn").classList.remove("addpointer")
            document.getElementById("betbtn").classList.add("drwnodrop")
            popupbetGreaterthanBalnceElement.className = "";
            gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0 },
              { scale: 1, duration: 1, rotate: 720, opacity: 1, ease: "power2.out" });

            if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {

              popupbetGreaterthanBalnceElement.classList.add("popupbetshighwindows");
              textbetsHighElement.classList.add("poptext_betshigh");
              attentionElement.classList.add("attention_text");

            }

          }
          else if (this.state.previousAnteBetAmount * rebetstatus > this.props.maxBetAmt) {

            document.getElementById("betbtntext").textContent = this.props.language.Deal;
            document.getElementById("betbtntext").classList.add("betwindows");
            document.getElementById("foldtext").classList.remove("rebettextwindows");
            document.getElementById("foldtext").textContent = this.props.language.RP_Clear;
            document.getElementById("foldtext").classList.add("foldtextwindows");
            this.setState({ betbutton: "DEAL", buttontext: "clear", })

            this.setState({
              infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
              multibuttonsebledsble: true,
              anteBetAmount: 0,
              bonusbetamount: 0,
              totalbet: 0,
            })

          } else {

            bnsactived.src = bnsactive;
            // setTimeout(() => {
            this.previousantebonousamount(bettext)
            // }, 300)

            this.setState({ multibuttonsebledsble: false, bsbtn: false, });
            this.setState({ infoText: this.props.language.deal_tostart, betchipincrementbtn: false, betdecreamentbtn: false })
            // gsap.to("#chipcenterposition", { opacity: 0 });
            const betbutton = document.getElementById("betbtn");
            const foldbutton = document.getElementById("Foldbtn");


            document.getElementById("betbtntext").textContent = "";
            document.getElementById("foldtext").textContent = "";

            betbutton.src = "";
            betbutton.style.position = "";
            betbutton.style.width = "";
            betbutton.style.transform = "";

            foldbutton.src = "";
            foldbutton.style.position = "";
            foldbutton.style.width = "";
            foldbutton.style.transform = "";

            //  this.clertime25=setTimeout(() => {
            betbutton.src = Betbtn;
            Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
            document.getElementById("betbtntext").textContent = this.props.language.Deal;


            document.getElementById("betbtntext").classList.add("betwindows");

            foldbutton.src = Foldbnwindows;
            Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
            document.getElementById("foldtext").classList.remove("rebettextwindows");
            document.getElementById("foldtext").textContent = this.props.language.RP_Clear;
            document.getElementById("foldtext").classList.add("foldtextwindows");

            document.getElementById("bonustext").style.color = "#ffffff";

            // Object.assign(betchipstoantebet.style,porttraitejson.Betchiptoantewindows)
            this.clertime25 = setTimeout(() => {

              Object.assign(betchipstoantebet.style, porttraitejson.Betchiptoantewindows)
            }, 1500)
            console.log(this.props.gameState.response.bonusBetAmount)
            // if (this.props.gameState.response.bonusBetAmount && this.state.previousBonusbetamount >0
            // && this.state.bonusactive<=this.props.maxBetAmt

            // ) {
            if (this.state.previousBonusbetamount > 0) {


              // alert("..................."+this.props.gameState.response.bonusBetAmount)
              // alert("..................."+this.state.previousBonusbetamount)

              let bonusbetAmount = 0;
              //               if (Number(this.props.gameState.response.bonusBetAmount > 0 && this.state.bonusactive<=this.props.maxBetAmt
              // )) {
              // console.log("......................"+this.props.gameState.response.bonusBetAmount)
              // if (Number(this.props.gameState.response.bonusBetAmount) > 0) {
              console.log(this.state.previousBonusbetamount)
              if (this.state.previousBonusbetamount > 0) {
                console.log(this.state.previousBonusbetamount)


                // this.ShowBonuspayouttable();
                let totalbets_bonus

                if (bettext == "REBET") {
                  totalbets_bonus = this.topLable_rsp(this.state.previousBonusbetamount)
                  bonusbetAmount = this.state.previousBonusbetamount
                }
                else {
                  totalbets_bonus = this.topLable_rsp(this.state.previousBonusbetamount * 2)
                  bonusbetAmount = this.state.previousBonusbetamount * 2

                }
                let betchipstobns = document.getElementById("betchipsaddtobonus");







                switch (this.state.count) {

                  case 1:

                    betchipstobns.src = chip_10;
                    this.clertime13 = setTimeout(() => {
                      this.setState((prevState) => ({
                        storebetchips: [
                          ...prevState.storebetchips,
                          <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                            <img className="chip10centerbns"
                              src={this.ReturnChipBasedAmount(Number(bonusbetAmount.toFixed(2)))}
                              alt="Chip 10"
                            />
                            <span id="val_10" className="bonusChipsForwindows_val10">{totalbets_bonus}</span>
                          </div>

                        ],
                      }));
                    }, 150);

                    break;
                  case 2:
                    betchipstobns.src = chip_25;
                    //  document.getElementById("antebetval_10").textContent = "";

                    this.clertime13 = setTimeout(() => {
                      this.setState((prevState) => ({
                        storebetchips: [
                          ...prevState.storebetchips,
                          <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                            <img className="chip100centerbns"
                              src={this.ReturnChipBasedAmount(Number(bonusbetAmount.toFixed(2)))}
                              alt="Chip 10"
                            />
                            <span id="val_20" className="bonusChipsForwindows">{totalbets_bonus}</span>
                          </div>

                        ],
                      }));
                    }, 150);

                    break;
                  case 3:

                    betchipstobns.src = chip_50;


                    this.clertime13 = setTimeout(() => {
                      this.setState((prevState) => ({
                        storebetchips: [
                          ...prevState.storebetchips,
                          <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                            <img className="chip100centerbns"
                              src={this.ReturnChipBasedAmount(Number(bonusbetAmount.toFixed(2)))}
                              alt="Chip 10"
                            />
                            <span id="val_50" className="bonusChipsForwindows">{totalbets_bonus}</span>
                          </div>

                        ],
                      }));
                    }, 150);

                    break;
                  case 4:

                    betchipstobns.src = chip_100;


                    this.clertime13 = setTimeout(() => {
                      this.setState((prevState) => ({
                        storebetchips: [
                          ...prevState.storebetchips,
                          <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                            <img className="chip100centerbns"
                              src={this.ReturnChipBasedAmount(Number(bonusbetAmount.toFixed(2)))}
                              alt="Chip 10"
                            />
                            <span id="val_100" className="bonusChipsForwindows">{totalbets_bonus}</span>
                          </div>

                        ],
                      }));
                    }, 150);


                    break;
                  case 5:
                    betchipstobns.src = chip_500;


                    this.clertime13 = setTimeout(() => {
                      this.setState((prevState) => ({
                        storebetchips: [
                          ...prevState.storebetchips,
                          <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                            <img className="chip500centerbns"
                              src={this.ReturnChipBasedAmount(Number(bonusbetAmount.toFixed(2)))}
                              alt="Chip 10"
                            />
                            <span id="val_500" className="bonusChipsForwindows">{totalbets_bonus}</span>
                          </div>

                        ],
                      }));
                    }, 150);
                  case 6:
                    betchipstobns.src = chip_1000;
                    this.clertime13 = setTimeout(() => {
                      this.setState((prevState) => ({
                        storebetchips: [
                          ...prevState.storebetchips,
                          <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                            <img className="chip500centerbns"
                              src={this.ReturnChipBasedAmount(Number(bonusbetAmount.toFixed(2)))}
                              alt="Chip 10"
                            />
                            <span id="val_500" className="bonusChipsForwindows">{totalbets_bonus}</span>
                          </div>

                        ],
                      }));
                    }, 150);

                    break;

                  default:
                    break;
                }



                Object.assign(betchipstobns.style, porttraitejson.betchipstobnswindows)

              }




            }



            var tl = gsap.timeline();
            tl.to(this.chipref.current, {
              x: -994,
              y: -553,
              duration: 0.2,
              ease: "power2.inOut",
              zIndex: 12,
            });
            tl.to(this.chipref.current, {

              x: 0,
              y: 0,
              duration: 0.00001,
            });
            // tl.reverse();
            // tl.to(this.chipref.current, { opacity: 1, zIndex: -2 });
            tl.play();
            const betchipstoantebet = document.getElementById("betchipsaddtoantecclick");
            // let totalBetAmountForLimitsCheck = 0;

            // for (let k = 1; k <= this.chipVal.length; k++) {
            //   if (this.state.count === k) {
            //     totalBetAmountForLimitsCheck = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);
            //   }
            // }

            // this.clertime12=setTimeout(() => {
            // document.getElementById("bonustext").style.color = "#ffffff";

            switch (this.state.count) {

              case 1:

                betchipstoantebet.src = chip_10;
                this.clertime13 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterposition">
                        <img id="centerchips" className="chip10centerposition"
                          src={this.ReturnChipBasedAmount(this.state.previousAnteBetAmount)}
                          alt="Chip 10"
                        />
                        <span id="antebet_windows" className="antebet_textval10">{totalAnteBetAmount}</span>
                      </div>

                    ],
                  }));
                }, 150);

                break;
              case 2:
                betchipstoantebet.src = chip_25;
                //  document.getElementById("antebetval_10").textContent = "";

                this.clertime14 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterposition">

                        <img
                          id="centerchips"
                          className="chip20centerposition"
                          src={this.ReturnChipBasedAmount(this.state.previousAnteBetAmount)}
                          alt="Chip 10"
                        />
                        <span id="antebet_windows" className="antebet_text20">{totalAnteBetAmount}</span>
                      </div>
                    ],
                  }));
                }, 150);

                break;
              case 3:

                betchipstoantebet.src = chip_50;

                // document.getElementById("antebetval_20").textContent = "";
                this.clertime15 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterposition">

                        <img
                          id="centerchips"
                          className="chip50centerposition"
                          src={this.ReturnChipBasedAmount(this.state.previousAnteBetAmount)}
                          alt="Chip 10"
                        />
                        <span id="antebet_windows" className="antebet_text50">{totalAnteBetAmount}</span>
                      </div>
                    ],
                  }));
                }, 150);

                break;
              case 4:

                betchipstoantebet.src = chip_100;

                // document.getElementById("antebetval_20").textContent = "";
                this.clertime15 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterposition">

                        <img
                          id="centerchips"
                          className="chip100centerposition"
                          src={this.ReturnChipBasedAmount(this.state.previousAnteBetAmount)}
                          alt="Chip 10"
                        />
                        <span id="antebet_windows" className="antebet_text100">{totalAnteBetAmount}</span>
                      </div>
                    ],
                  }));
                }, 150);


                break;
              case 5:
                betchipstoantebet.src = chip_500;

                this.clertime17 = setTimeout(() => {

                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterposition">

                        <img
                          id="centerchips"
                          className="chip500centerposition"

                          src={this.ReturnChipBasedAmount(this.state.previousAnteBetAmount)}
                          alt="Chip 10"
                        />
                        <span id="antebet_windows" className="antebet_text50">{totalAnteBetAmount}</span>
                      </div>
                    ],
                  }));
                }, 150);
                break;
              case 6:
                betchipstoantebet.src = chip_1000;

                this.clertime17 = setTimeout(() => {


                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterposition">

                        <img
                          id="centerchips"
                          className="chip500centerposition"

                          src={this.ReturnChipBasedAmount(this.state.previousAnteBetAmount)}
                          alt="Chip 10"
                        />
                        <span id="antebet_windows" className="antebet_text50">{totalAnteBetAmount}</span>
                      </div>
                    ],
                  }));
                }, 150);
                break;

              default:
                break;
            }

            Object.assign(betchipstoantebet.style, porttraitejson.Betchiptoantewindows)


            // if (this.state.bonusbetamount > 0) {
            //   // this.setState({ isbonuspattable: true })
            //   gsap.to("#chip10centerbns", { x: 0, y: 0, duration: 0.5, opacity: 1, zIndex: 1, });
            //   this.ShowBonuspayouttable()

            // }


          }




          document.getElementById("rankinginfodeler").classList.remove("windowspokerrankingdeler");
          document.getElementById("rankinginfo").classList.remove("windowspokerranking");
          this.cardsRef.current.ReBet();
          this.dealerCardsContainer.current.ReBet();
          this.randomPlayerCards = [];
          this.shuffledPlayerCards = [];

          // gsap.to("#chipcenterposition", { x: 0, y: 0, duration: 0.5 });

          // gsap.to("#betchipsaddtobet", { x: 0, y: 0, duration: 0.5 });


          this.playPokerChipSound(this.pokerchipsnd);

          // console.log(this.state.totalbet)
          // gsap.to("#chip10centerbns",{zIndex:-2})



          // document.getElementById("betpannelrussiancover").style.display = "none";
          // document.getElementById("betpannelrussiancover").classList.add("bet_pannelcoverrussian");
          this.setState({ betchipincrementbtn: false, betdecreamentbtn: false })
          document.getElementById("betincrenet").classList.remove("drwnodrop")
          document.getElementById("betdecrement").classList.remove("drwnodrop")

          // console.log(this.state.totalbet)

          // console.log(this.balnce)
          // this.setState({infoText:"Press Deal to start the Game!"})
          // alert("................."+this.state.count)
          // const betbutton = document.getElementById("betbtn");
          // const foldbutton = document.getElementById("Foldbtn");
          const betbutton = document.getElementById("betbtn");
          const foldbutton = document.getElementById("Foldbtn");


          document.getElementById("bonusRussian").classList.add("addpointer");




          this.setState((prevState) => ({
            idincrement: prevState.idincrement + 1,
            //  totalbet:prevState.totalbet + 1,
          }));
          // this.clertime18 = setTimeout(() => {
          //   gsap.to(`#chipcenterposition`, { zIndex: -15 })
          // }, 550);
          // const betchipstoantebet = document.getElementById(
          //   `betchipsaddtoante${this.state.idincrement}`
          // );
          // console.log(document.getElementById('chipcenterposition'))
          const betchipstoantebet = document.getElementById("betchipsaddtoantecclick");

          var tl = gsap.timeline();
          tl.to(this.chipref.current, {
            x: -994,
            y: -553,
            duration: 0.2,
            ease: "power2.inOut",
            zIndex: 12,
          });
          tl.to(this.chipref.current, {
            opacity: 0,
            x: 0,
            y: 0,
            duration: 0.00001,
          });
          // tl.reverse();
          tl.to(this.chipref.current, { opacity: 1, zIndex: -2 });
          tl.play();


        }
        else {

          let totalBetAmountForLimitsCheck = 0;
          let totalAnteBetAmount = 0;
          for (let k = 1; k <= this.chipVal.length; k++) {
            if (this.state.count === k) {
              // console.log(this.state.anteBetAmount)
              // console.log(Number(this.chipVal[k - 1].val))
              totalBetAmountForLimitsCheck = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);
              totalAnteBetAmount = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);
            }
          }


          if (totalAnteBetAmount > Number(this.props.maxBetAmt)) {

            //  document.getElementById("betcircle").classList.add("nonpointer")
            //  setTimeout(() =>{
            this.playPokerChipSound(this.pokerchipsnd);

            this.setState({
              infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl
            }, () => {
              // console.log(this.state.infoText)
            })
            //  },100)
            document.getElementById("betcircle").classList.remove("addpointer")
            document.getElementById("betcircle").classList.add("insurencenodrop")

          } else {

            // alert(".1111")
            // document.getElementById("betcircle").classList.remove("nonpointer")
            // console.log(totalBetAmountForLimitsCheck)

            totalAnteBetAmount = totalBetAmountForLimitsCheck
            // console.log(this.state.anteBetAmount)
            // console.log(totalAnteBetAmount)
            // console.log(Number(this.props.maxBetAmt))




            if (Number((Number(totalAnteBetAmount) + Number(this.state.bonusbetamount)).toFixed(2)) > this.props.balance ||
              Number((Number(totalAnteBetAmount * 3) + Number(this.state.bonusbetamount)).toFixed(2)) > this.props.balance) {
              // if (totalBetAmountForLimitsCheck > 50) {
              // this.setState({ betshigh_balnce: true, multibuttonsebledsble: true,disBetBtn:1 })

              // if (totalAnteBetAmount * 3+ Number(this.state.bonusbetamount) > this.props.balance) {
              //   this.setState({ betshigh_balnce: false, multibuttonsebledsble: true })
              // } else if (totalAnteBetAmount+ Number(this.state.bonusbetamount) > this.props.balance) {
              //   this.setState({ betshigh_balnce: true, multibuttonsebledsble: true,disBetBtn:1 })
              // }

              let amount = 0
              for (let k = 1; k <= this.chipVal.length; k++) {
                if (this.state.count === k) {
                  amount = Number(this.chipVal[k - 1].val);

                }
              }

              if (Number((Number(totalAnteBetAmount) + Number(this.state.bonusbetamount)).toFixed(2)) > this.props.balance) {

                this.setState({
                  betshigh_balnce: true,
                  multibuttonsebledsble: false,
                  lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.anteBetAmount + amount).toFixed(1)},Please DEPOSIT to continue playing.`
                })

                betbutton.src = Betbtn;
                Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
                document.getElementById("betbtntext").classList.remove("betwindowsmlylm");
                document.getElementById("betbtntext").textContent = this.props.language.Deal;
                document.getElementById("betbtntext").classList.add("betwindows");

                foldbutton.src = Foldbnwindows;
                Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
                document.getElementById("foldtext").classList.remove("rebettextwindows");
                document.getElementById("foldtext").textContent = this.props.language.RP_Clear;
                document.getElementById("foldtext").classList.add("foldtextwindows");

                // betbutton.src = "";
                // foldbutton.src = "";
                // betbtntextElement.className = "";
                // foldtextElement.className = "";
              } else if (Number((Number(totalAnteBetAmount * 3) + Number(this.state.bonusbetamount)).toFixed(2)) > this.props.balance) {
                document.getElementById("betbtntext").textContent = this.props.language.Deal;
                document.getElementById("foldtext").textContent = this.props.language.RP_Clear;
                betbutton.src = Betbtn;
                Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
                document.getElementById("betbtntext").classList.remove("betwindowsmlylm");
                document.getElementById("betbtntext").textContent = this.props.language.Deal;
                document.getElementById("betbtntext").classList.add("betwindows");

                foldbutton.src = Foldbnwindows;
                Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
                document.getElementById("foldtext").classList.remove("rebettextwindows");
                document.getElementById("foldtext").textContent = this.props.language.RP_Clear;
                document.getElementById("foldtext").classList.add("foldtextwindows");
                this.setState({
                  betshigh_balnce: true,
                  betbutton: "DEAL", buttontext: "clear",
                  // betshigh_balnce: false,
                  lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.anteBetAmount + amount).toFixed(1)},with bet ${((this.state.anteBetAmount + amount) * 2).toFixed(1)} Please DEPOSIT to continue playing.`
                }) // multibuttonsebledsble: true
              }



              betbtnElement.classList.remove("addpointer")
              betbtnElement.classList.add("drwnodrop");
              popupbetGreaterthanBalnceElement.className = "";

              gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0, duration: 0.5 },
                { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });

              if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
                popupbetGreaterthanBalnceElement.classList.add("popupbetshighwindows");
                textbetsHighElement.classList.add("poptextBetshighwindows");
                attentionElement.classList.add("attentionTextwindows");

              }

            }
            //  else if(this.state.anteBetAmount < this.props.minBetAmt || 
            //           this.state.anteBetAmount > this.props.maxBetAmt) {


            else {
              this.playPokerChipSound(this.pokerchipsnd);
              // console.log(this.state.anteBetAmount)
              // console.log(Number(this.props.balance))

              for (let k = 1; k <= this.chipVal.length; k++) {
                if (this.state.count === k) {


                  if (this.state.anteBetAmount + Number(this.chipVal[k - 1].val) < Number(this.props.balance)) {


                    this.setState({ multibuttonsebledsble: false, bsbtn: false, });
                    this.setState({ infoText: this.props.language.deal_tostart, betchipincrementbtn: false, betdecreamentbtn: false })
                    this.setState({ betbutton: "DEAL", buttontext: "clear", });
                    let bnsactived = document.getElementById("bnsactive");
                    bnsactived.src = bnsactive;
                    bnsactived.classList.add("bnsctivewindows");
                    bnsactived.classList.add("bnsblink");
                    if (this.chipref) {

                      var tl = gsap.timeline();
                      tl.to(this.chipref.current, {
                        x: -994,
                        y: -553,
                        duration: 0.2,
                        ease: "power2.inOut",
                        zIndex: 12,
                      });
                      tl.to(this.chipref.current, {
                        opacity: 0,
                        x: 0,
                        y: 0,
                        zIndex: -2,
                        duration: 0.00001,
                      });
                      // tl.reverse();
                      tl.to(this.chipref.current, { opacity: 1, zIndex: -2 });
                      tl.play();
                    }

                    switch (this.state.count) {

                      case 1:
                        this.setState((prevState) => ({
                          totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                          betdeductions: prevState.totalbet + Number(this.chipVal[0].val),
                          anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[0].val),
                        }));
                        betchipstoantebet.src = chip_10;
                        this.clertime19 = setTimeout(() => {
                          this.setState((prevState) => ({
                            storebetchips: [
                              ...prevState.storebetchips,
                              <div id="chipcenterposition">
                                <img id="centerchips" className="chip10centerposition"
                                  src={this.ReturnChipBasedAmount(totalAnteBetAmount)}
                                  alt="Chip 10"
                                />
                                <span id="antebet_windows" className="antebet_textval10">{totalAnteBetAmount}</span>
                              </div>

                            ],
                          }));
                        }, 150);

                        break;
                      case 2:
                        betchipstoantebet.src = chip_25;
                        //  document.getElementById("antebetval_10").textContent = "";
                        this.setState((prevState) => ({
                          totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                          betdeductions: prevState.totalbet + Number(this.chipVal[1].val),
                          anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[1].val),
                        }));
                        this.clertime20 = setTimeout(() => {
                          this.setState((prevState) => ({
                            storebetchips: [
                              ...prevState.storebetchips,
                              <div id="chipcenterposition">

                                <img
                                  id="centerchips"
                                  className="chip20centerposition"
                                  src={this.ReturnChipBasedAmount(totalAnteBetAmount)}
                                  alt="Chip 10"
                                />
                                <span id="antebet_windows" className="antebet_text20">{totalAnteBetAmount}</span>
                              </div>
                            ],
                          }));
                        }, 150);

                        break;
                      case 3:

                        betchipstoantebet.src = chip_50;
                        // document.getElementById("antebetval_20").textContent = "";
                        this.clertime21 = setTimeout(() => {
                          this.setState((prevState) => ({
                            storebetchips: [
                              ...prevState.storebetchips,
                              <div id="chipcenterposition">

                                <img
                                  id="centerchips"
                                  className="chip50centerposition"

                                  src={this.ReturnChipBasedAmount(totalAnteBetAmount)}
                                  alt="Chip 10"
                                />

                                <span id="antebet_windows" className="antebet_text50">{totalAnteBetAmount}</span>
                              </div>
                            ],
                          }));
                        }, 150);
                        this.setState((prevState) => ({
                          totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                          betdeductions: prevState.totalbet + Number(this.chipVal[2].val),
                          anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[2].val),
                        }));
                        break;
                      case 4:
                        betchipstoantebet.src = chip_100;

                        // document.getElementById("antebetval_50").textContent = "";
                        this.setState((prevState) => ({
                          totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                          anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[3].val),
                          betdeductions: prevState.totalbet + Number(this.chipVal[3].val),
                        }));
                        this.clertime22 = setTimeout(() => {
                          this.setState((prevState) => ({
                            storebetchips: [
                              ...prevState.storebetchips,
                              <div id="chipcenterposition">

                                <img
                                  id="centerchips"
                                  className="chip100centerposition"

                                  src={this.ReturnChipBasedAmount(totalAnteBetAmount)}
                                  alt="Chip 10"
                                />
                                <span id="antebet_windows" className="antebet_text100">{totalAnteBetAmount}</span>
                              </div>
                            ],

                          }));
                        }, 150);
                        break;
                      case 5:
                        betchipstoantebet.src = chip_500;
                        this.setState((prevState) => ({
                          totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                          betdeductions: prevState.totalbet + Number(this.chipVal[4].val),
                          anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[4].val),
                        }));
                        this.clertime23 = setTimeout(() => {

                          this.setState((prevState) => ({
                            storebetchips: [
                              ...prevState.storebetchips,
                              <div id="chipcenterposition">

                                <img
                                  id="centerchips"
                                  className="chip500centerposition"

                                  src={this.ReturnChipBasedAmount(totalAnteBetAmount)}
                                  alt="Chip 10"
                                />
                                <span id="antebet_windows" className="antebet_text50">{totalAnteBetAmount}</span>
                              </div>
                            ],
                          }));
                        }, 150);
                        break;
                      case 6:
                        betchipstoantebet.src = chip_1000;
                        this.setState((prevState) => ({
                          totalbet: prevState.totalbet + Number(this.chipVal[5].val),
                          betdeductions: prevState.totalbet + Number(this.chipVal[5].val),
                          anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[5].val),
                        }));
                        this.clertime23 = setTimeout(() => {

                          this.setState((prevState) => ({
                            storebetchips: [
                              ...prevState.storebetchips,
                              <div id="chipcenterposition">

                                <img
                                  id="centerchips"
                                  className="chip500centerposition"

                                  src={this.ReturnChipBasedAmount(totalAnteBetAmount)}
                                  alt="Chip 10"
                                />
                                <span id="antebet_windows" className="antebet_text50">{totalAnteBetAmount}</span>
                              </div>
                            ],
                          }));
                        }, 150);
                        break;

                      default:
                        break;

                    }


                    // this.clertime25=setTimeout(() => {
                    betbutton.src = Betbtn;
                    Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
                    document.getElementById("betbtntext").classList.remove("betwindowsmlylm");
                    document.getElementById("betbtntext").textContent = this.props.language.Deal;
                    document.getElementById("betbtntext").classList.add("betwindows");

                    foldbutton.src = Foldbnwindows;
                    Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
                    document.getElementById("foldtext").classList.remove("rebettextwindows");
                    document.getElementById("foldtext").textContent = this.props.language.RP_Clear;
                    document.getElementById("foldtext").classList.add("foldtextwindows");

                    document.getElementById("bonustext").style.color = "#ffffff";
                    Object.assign(betchipstoantebet.style, porttraitejson.Betchiptoantewindows)


                    // }, 1500)

                  }
                  else {

                    let doubleantebetamount = this.state.anteBetAmount * 2;
                    let amount = 0
                    for (let k = 1; k <= this.chipVal.length; k++) {
                      if (this.state.count === k) {
                        amount = Number(this.chipVal[k - 1].val);

                      }
                    }
                    this.setState({
                      betshigh_balnce: true,
                      // betshigh_balnce: false,
                      lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.anteBetAmount + amount).toFixed(1)},with bet ${(doubleantebetamount + amount).toFixed(1)} Please DEPOSIT to continue playing.`
                    }) // multibuttonsebledsble: true




                    betbtnElement.classList.remove("addpointer")
                    betbtnElement.classList.add("drwnodrop");
                    popupbetGreaterthanBalnceElement.className = "";

                    gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0, duration: 0.5 },
                      { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });

                    if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
                      popupbetGreaterthanBalnceElement.classList.add("popupbetshighwindows");
                      textbetsHighElement.classList.add("poptextBetshighwindows");
                      attentionElement.classList.add("attentionTextwindows");

                    }

                  }

                }
              }
              // this.clertime24=setTimeout(() => {



            }

            totalAnteBetAmount = this.topLable_rsp(totalBetAmountForLimitsCheck)
          }

        }

        if (this.state.count > 0) {
          // alert("..........."+this.state.totalbet)
          this.TotalbetamountGreaterthanbalance(this.state.totalbet);

        }
        if (document.getElementById("chiptoinsurence")) {
          gsap.to("#chiptoinsurence", { zIndex: -2 });

        }




      }

      else {

        this.betchipactivated = false;
        this.buydrawchipactivated = false;
        this.ConfirmbuydealercardactivePopup = false;
        this.resetPyrWnCpsAmn = false;


        document.getElementById("betpannelrussiancover").style.display = "block";
        if (document.getElementById("rankinginfodeler")) {
          document.getElementById("rankinginfodeler").style.cssText = "";
          document.getElementById("rankinginfo").style.cssText = "";
        };
        const betbtnElement = document.getElementById("betbtn");
        const betbtntextElement = document.getElementById("betbtntext");
        const foldtextElement = document.getElementById("foldtext");
        const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnce");
        const textbetsHighElement = document.getElementById("textbets_high");
        const attentionElement = document.getElementById("attention");




        betbtntextElement.className = "";
        foldtextElement.className = "";
        betbtntextElement.textContent = this.props.language.Deal;
        foldtextElement.textContent = this.props.language.RP_Clear;
        betbutton.src = Betbtn;
        foldbutton.src = Foldbn;


        this.setState({
          multibuttonsebledsble: false,
          betbutton: "DEAL",
          buttontext: "clear",
        })

        const isPortrait = window.innerHeight > window.innerWidth;

        const rankinginfoElement = document.getElementById("rankinginfo");
        if (isPortrait) {
          document.getElementById("betpannelrussiancover").classList.add("PlayerCardpannelcoverPortrait");
          if (rankinginfoElement) {
            if (this.state.isMobilePortraitLndsp) {
              rankinginfoElement.style.left = "35%";
            }
          }

          if (this.resetAnimationChips) {

            if (document.getElementById("chipcenterposition")) {
              gsap.to("#chipcenterposition", { x: 13, y: 1200, duration: 0.00001, zIndex: -2, opacity: 0 });
            }

            if (document.getElementById("betchipsaddtobet")) {
              gsap.to("#betchipsaddtobet", { x: 1, y: 76, duration: 0.00001, zIndex: -2, opacity: 0 });
              gsap.to("#betTextaddtoBet", { x: 153, y: 185, duration: 0.5, zIndex: -2, opacity: 0 });
            }

            // gsap.to("#chiptoinsurence" , {x:550, y: 145, duration:0.5,zIndex:-2,opacity:0});
            if (document.getElementById("chiptoinsurence")) {
              gsap.to("#chiptoinsurence", { x: 15, y: 1376, duration: 0.00001, zIndex: -2, opacity: 0 });
            };

            // gsap.to(this.chipdrawref.current, { x: 548, y: 140, duration: 0.5, delay: 1, zIndex: -5, opacity: 1 });
            gsap.to(this.chipdrawref.current, { x: 548, y: -36, duration: 0.00001, zIndex: -5, opacity: 0 });
            // if (this.playerWinBonus === true) {

            // if (document.getElementById("chip10centerbns")) {
            //   gsap.to("#chip10centerbns", { x: -170, y: 1200, duration: 0.00001, zIndex: -2, opacity: 1 });
            //   this.playerWinBonus = false;

            // }


            // }
            if (this.wanttobuydelercrd === true) {
              gsap.to(this.buyDealerCardDrawn.current, { x: 0, y: 0, duration: 0.00001, zIndex: -2, opacity: 0 });
            }
          }

          this.resetAnimationChips = false;
          bnsactived.classList.add("bnsactivePortrait");
          bnsactived.classList.add("bnsblink");

          Object.assign(betbutton.style, porttraitejson.Betbtnportraite);
          betbtntextElement.classList.add('betportrait');
          Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);
          foldtextElement.classList.add('foldtextportrait');



          let rebetstatus = 1;
          if (bettext === "REBETX2") {
            rebetstatus = 2;
          }
          let totalBetAmountForLimitsCheck = 0;
          let totalAnteBetAmount = 0;
          if (bettext == "REBETX2" || bettext == "REBET") {


            if (Number((Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus)).toFixed(2)) > Number(this.props.balance) ||
              Number((Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus)).toFixed(2)) > Number(this.props.balance)) {
              // console.log("this.state.anteBetAmount :------", this.state.previousAnteBetAmount, this.state.previousBonusbetamount);
              this.setState({
                anteBetAmount: 0,
                bonusbetamount: 0,
                totalbet: 0,
              })
              // if (Number(this.state.previousAnteBetAmount)  + Number(this.state.previousBonusbetamount) > 39) {

              // if (Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > this.props.balance) {
              //   this.setState({ betshigh_balnce: false, multibuttonsebledsble: true, disablemultibutton: true })
              // } else if (Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > Number(this.props.balance)) {
              //   this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })
              // }

              // betbutton.src ="" ;
              // foldbutton.src ="" ;
              // betbtntextElement.className ="";
              // foldtextElement.className ="";
              if (Number((Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus)).toFixed(2)) > Number(this.props.balance)) {


                // betbutton.src = "";
                // foldbutton.src = "";
                // betbutton.style.cssText = "";
                // foldbutton.style.cssText = "";
                // betbtntextElement.className = "";
                // foldtextElement.className = "";
                // betbtntextElement.textContent = "";
                // foldtextElement.textContent = "";


                // this.setState({
                //   betshigh_balnce: true,
                //   lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},Please DEPOSIT to continue playing.`
                // })
                if (this.state.previousBonusbetamount > 0) {
                  this.setState({
                    betshigh_balnce: true,
                    lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},
                                          OR BONUS of ${(this.state.previousBonusbetamount * rebetstatus).toFixed(1)}  Please DEPOSIT  to continue playing`
                  })
                }
                else {
                  this.setState({
                    betshigh_balnce: true,
                    lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},Please DEPOSIT to continue playing`
                  })

                }
                // this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })
              } else if (Number((Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount) * rebetstatus).toFixed(2)) > this.props.balance) {
                // this.setState({
                //   betshigh_balnce: true,
                //   // betshigh_balnce: false,
                //   lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},with bet ${(this.state.previousAnteBetAmount * rebetstatus * 2).toFixed(1)} Please DEPOSIT to continue playing.`
                // })
                if (this.state.previousBonusbetamount > 0) {
                  this.setState({
                    betshigh_balnce: true,
                    lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},with BET ${(this.state.previousAnteBetAmount * rebetstatus * 2).toFixed(1)}
                   OR Insufficient Funds to follow up your BONUS of ${(this.state.previousBonusbetamount * rebetstatus).toFixed(1)},with BET ${(this.state.previousBonusbetamount * rebetstatus * 2).toFixed(1)}
                   Please DEPOSIT to continue playing.`
                  })
                }
                else {
                  this.setState({
                    betshigh_balnce: true,
                    lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},with BET ${(this.state.previousAnteBetAmount * rebetstatus * 2).toFixed(1)} Please DEPOSIT to continue playing.`
                  })

                }
                // this.setState({ betshigh_balnce: false, multibuttonsebledsble: true, disablemultibutton: true })
              }


              betbtnElement.classList.remove("addpointer")
              betbtnElement.classList.add("drwnodrop");
              popupbetGreaterthanBalnceElement.className = "";
              gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0 },
                { scale: 1, duration: 1, rotate: 720, opacity: 1, ease: "power2.out" });

              if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {

                popupbetGreaterthanBalnceElement.classList.add("popupbetshighPortrait");
                textbetsHighElement.classList.add("poptextBetshighPortrait");
                attentionElement.classList.add("attentionTextPortrait");

              }

            }
            // else if(this.state.anteBetAmount < this.props.minBetAmt || 
            //           this.state.anteBetAmount > this.props.maxBetAmt) {
            else if (Number((this.state.previousAnteBetAmount * rebetstatus).toFixed(2)) > this.props.maxBetAmt) {
              this.setState({
                infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
                anteBetAmount: 0,
                bonusbetamount: 0,
                totalbet: 0,
                // multibuttonsebledsble: true,
                disablemultibutton: false,
              })

            } else {
              // this.setState({ multibuttonsebledsble: false });
              bnsactived.src = bnsactive;
              this.previousantebonousamount(bettext);
              console.log(this.state.anteBetAmount)
              betbtntextElement.textContent = this.props.language.Deal;
              foldtextElement.textContent = this.props.language.RP_Clear;

              // console.log(betbtntextElement.textContent, foldtextElement.textContent, this.props.language.Deal, this.props.language.RP_Clear)
              // let convrAntebt = this.topLable_rsp(totalAnteBetAmount);
              this.playPokerChipSound(this.pokerchipsnd);
              this.setState({
                disablemultibutton: false,
                infoText: this.props.language.deal_tostart,
                betchipincrementbtn: false,
                betdecreamentbtn: false,
                bsbtn: false,
                betbutton: "DEAL",
                buttontext: "clear",
                // storeantebetchips: [...this.state.prevStatestoreantebetchips],
                // storebonuschips: [...this.state.prevStatestorebonuschips],
                storeantebetchips: [
                  {
                    chip: this.ReturnChipBasedAmount(Number(this.state.previousAnteBetAmount * rebetstatus)),
                    chipvalue: this.topLable_rsp(Number(this.state.previousAnteBetAmount * rebetstatus)),
                    chipcolor1: "valtextPortrait1"
                  },

                ],
                storebonuschips: [
                  {
                    chip: this.ReturnChipBasedAmount(Number(this.state.previousBonusbetamount * rebetstatus)),
                    chipvalue: this.topLable_rsp(Number(this.state.previousBonusbetamount * rebetstatus)),
                    chipcolor1: "insuranceTextPortraitChip10"
                  },

                ],

              }, () => {

                // for (let k = 1; k <= this.chipVal.length; k++) {
                //   if (this.state.count === k) {
                //     totalBetAmountForLimitsCheck = this.state.totalbet + Number(this.chipVal[k - 1].val);
                //     if (bettext == "REBETX2") {
                //       let chipcenterpositionElement = document.querySelectorAll("#chipcenterposition span");
                //       let chip10centerbnsElement = document.querySelectorAll("#chip10centerbns span");
                //       for (let a = 0; a < chipcenterpositionElement.length; a++) {
                //         chipcenterpositionElement[a].innerHTML = this.topLable_rsp(this.state.previousAnteBetAmount * 2);
                //       }
                //       // if(this.state.previousBonusbetamount > 0){
                //       for (let b = 0; b < chip10centerbnsElement.length; b++) {
                //         chip10centerbnsElement[b].innerHTML = this.topLable_rsp(this.state.previousBonusbetamount * 2);
                //       }

                //       // }


                //       totalAnteBetAmount = (this.state.previousAnteBetAmount + Number(this.chipVal[k - 1].val)) * 2;
                //       rebetstatus = 2;
                //       // document.querySelector('antebetChipPortrait10').innerHTML = totalAnteBetAmount
                //     } else {
                //       totalAnteBetAmount = this.state.previousAnteBetAmount + Number(this.chipVal[k - 1].val);
                //       rebetstatus = 1;
                //     }
                //   }
                // }



                document.getElementById("bonustext").style.color = "#ffffff";
                if (document.getElementById('chipcenterposition')) {
                  gsap.to("#chipcenterposition", {
                    x: 13, y: 1200, duration: 0.0001, zIndex: 0, opacity: 1, onStart: () => {
                      gsap.set("#chipcenterposition", { opacity: 0 });
                    }
                  });
                  gsap.to("#chipcenterposition", { x: 0, y: 0, duration: 0.2, autoAlpha: 1, zIndex: 1, });
                  // gsap.to("#chipcenterposition", { x: 0, y: 0, duration: 0.5, autoAlpha:1, zIndex: 1, });
                  // gsap.to("#chipcenterposition", { x: 0, y: -2170, duration: 0.5, opacity: 1, zIndex: 1, });
                  const chipcenterpositionElement = document.querySelectorAll("#chipcenterposition");
                  // console.log(chipcenterpositionElement)
                  for (let q = 0; q < chipcenterpositionElement.length; q++) {

                    chipcenterpositionElement[q].style.position = 'relative';
                  }

                }
                // console.log(this.state.previousBonusbetamount, typeof (this.state.previousBonusbetamount))

                // if (this.state.previousBonusbetamount > 0 &&Number(this.state.previousBonusbetamount * rebetstatus) <= this.props.maxBetAmt) {
                if (this.state.previousBonusbetamount > 0) {
                  this.setState({ isbonuspattable: true });
                  let tl2 = gsap.timeline();
                  if (document.getElementById("chip10centerbns")) {
                    tl2.to("#chip10centerbns", { x: -170, y: 1200, duration: 0.00001, zIndex: -2, opacity: 1 });
                    tl2.to("#chip10centerbns", {
                      x: 0, y: 0, duration: 0.2, autoAlpha: 1, zIndex: 2, delay: 0.1, onStart: () => {
                        // x: 0, y: 0, duration: 0.5, autoAlpha:1, zIndex: 2, delay: 0.5, onStart: () => {
                        this.playPokerChipSound(this.pokerchipsnd);
                      }
                    });
                  }
                  this.ShowBonuspayouttable()

                } else {
                  if (document.getElementById("chip10centerbns")) {
                    gsap.to("#chip10centerbns", { duration: 0.000001, opacity: 0 })
                  }
                  // this.setState({ infoText: this.props.language.bonus_maxbet });

                }
              });


            }
          } else {

            let totalBetAmountForLimitsCheck = 0;
            let totalAnteBetAmount = 0;
            // console.log(this.chipVal)

            for (let k = 1; k <= this.chipVal.length; k++) {
              if (this.state.count === k) {
                totalBetAmountForLimitsCheck = this.state.totalbet + Number(this.chipVal[k - 1].val);
                totalAnteBetAmount = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);

              }
            }


            // console.log("this.state.anteBetAmount :------", totalBetAmountForLimitsCheck,
            //   this.state.totalbet, this.props.minBetAmt, this.props.maxBetAmt,
            //   this.state.anteBetAmount + Number(this.chipVal[this.state.count - 1].val)

            // );


            if (Number((Number(totalAnteBetAmount) + Number(this.state.bonusbetamount)).toFixed(2)) > Number(this.props.balance) ||
              Number((totalAnteBetAmount * 3 + Number(this.state.bonusbetamount)).toFixed(2)) > Number(this.props.balance)) {

              // if (totalAnteBetAmount > 50 || totalAnteBetAmount*3 >50) {

              // if (totalAnteBetAmount * 3 + Number(this.state.bonusbetamount) > this.props.balance) {
              //   this.setState({ betshigh_balnce: false, multibuttonsebledsble: true })
              // } else if (totalAnteBetAmount  + Number(this.state.bonusbetamount) > this.props.balance) {
              //   this.setState({ betshigh_balnce: true, multibuttonsebledsble: true })
              // }
              let amount = 0
              for (let k = 1; k <= this.chipVal.length; k++) {
                if (this.state.count === k) {
                  amount = Number(this.chipVal[k - 1].val);

                }
              }

              if (Number((totalAnteBetAmount + Number(this.state.bonusbetamount)).toFixed(2)) > this.props.balance) {
                this.setState({
                  betshigh_balnce: true,
                  multibuttonsebledsble: false,
                  // multibuttonsebledsble: true,
                  lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.anteBetAmount + amount).toFixed(1)},Please DEPOSIT to continue playing.`
                })


                // betbutton.src = "";
                // foldbutton.src = "";
                // betbutton.style.cssText = "";
                // foldbutton.style.cssText = "";
                // betbtntextElement.className = "";
                // foldtextElement.className = "";
                // betbtntextElement.textContent = "";
                // foldtextElement.textContent = "";


              } else if (Number((totalAnteBetAmount * 3 + Number(this.state.bonusbetamount)).toFixed(2)) > this.props.balance) {
                this.setState({
                  betshigh_balnce: true,
                  // betshigh_balnce: false,
                  lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.anteBetAmount + amount).toFixed(1)},with BET ${((this.state.anteBetAmount + amount) * 2).toFixed(1)} Please DEPOSIT to continue playing.`
                }) // multibuttonsebledsble: true
              }

              betbtnElement.classList.remove("addpointer")
              betbtnElement.classList.add("drwnodrop");
              popupbetGreaterthanBalnceElement.className = "";

              gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0, duration: 0.5 },
                { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });

              if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
                popupbetGreaterthanBalnceElement.classList.add("popupbetshighPortrait");
                textbetsHighElement.classList.add("poptextBetshighPortrait");
                attentionElement.classList.add("attentionTextPortrait");

              }

            }
            //  else if(this.state.anteBetAmount < this.props.minBetAmt || 
            //           this.state.anteBetAmount > this.props.maxBetAmt) {
            else if (totalAnteBetAmount > Number(this.props.maxBetAmt)) {
              this.setState({
                infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
                // multibuttonsebledsble: true,
              }, () => {
                // console.log(this.state.infoText)
              });



            } else {
              bnsactived.src = bnsactive;
              document.getElementById("bonustext").style.color = "#ffffff";
              betbtntextElement.textContent = this.props.language.Deal;
              foldtextElement.textContent = this.props.language.RP_Clear;

              // console.log(betbtntextElement.textContent, foldtextElement.textContent, this.props.language.Deal, this.props.language.RP_Clear)
              this.setState({
                multibuttonsebledsble: false,
                betbutton: "DEAL",
                buttontext: "clear",
                bsbtn: false,
                infoText: this.props.language.deal_tostart,
                betchipincrementbtn: false,
                betdecreamentbtn: false,
                previousAnteBetAmount: 0,
                previousBonusbetamount: 0,
              });
              let convrAntebt = this.topLable_rsp(totalAnteBetAmount);

              // antebetpdvcss:"chip-center-position",
              // antechpimgcss:"chip20centerpositionPortrait",
              // antespntxtcss:"antebetChipPortrait20",
              this.playPokerChipSound(this.pokerchipsnd);


              switch (this.state.count) {
                case 1:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[0].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[0].val),
                  }))
                  betchipstoantebet.src = chip_10;
                  this.clertime13 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: "valtextPortrait1" },

                      ],
                    }));
                  }, 150);
                  break;
                case 2:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[1].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[1].val),
                  }))
                  betchipstoantebet.src = chip_25;
                  this.clertime14 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                case 3:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[2].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[2].val),
                  }))
                  betchipstoantebet.src = chip_50;
                  this.clertime15 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                case 4:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[3].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[3].val),
                  }))
                  betchipstoantebet.src = chip_100;
                  this.clertime16 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                case 5:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[4].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[4].val),
                  }))
                  betchipstoantebet.src = chip_500;
                  this.clertime17 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },
                      ],
                    }));
                  }, 150);
                  break;
                case 6:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[5].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[5].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[5].val),
                  }))
                  betchipstoantebet.src = chip_1000;
                  this.clertime17 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },
                      ],
                    }));
                  }, 150);
                  break;
                default:
                  break;
              }


              Object.assign(betchipstoantebet.style, porttraitejson.Betchiptoanteprotrait);
              var tl = gsap.timeline();
              tl.to(this.chipref.current, {
                x: -10,
                y: -1200,
                duration: 0.15,
                // duration: 0.2,
                ease: "power2.inOut",
                zIndex: 12,
                autoAlpha: 1
              });


              tl.to(this.chipref.current, {
                x: 0,
                y: 0,
                duration: 0.0001,
                autoAlpha: 0,
                zIndex: -3,
              });
              tl.play();
            }

          }

        } else {




          if (chipsstoredcontainerElement && chipsstoredcontainerElement !== null) {
            chipsstoredcontainerElement.classList.add("chipsstoredcontainerLndsp");
          }

          if (rankinginfoElement) {
            if (this.state.isMobilePortraitLndsp) {
              // rankinginfoElement.style.left = "62.5%";
              rankinginfoElement.style.left = "43.5%";
            }
          }


          betbutton.style.cssText = "";
          foldbutton.style.cssText = "";
          betbtntextElement.className = "";
          foldtextElement.className = "";

          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
          betbtntextElement.classList.add('betLandscape');

          foldbutton.src = Foldbn;
          Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
          foldtextElement.classList.add('foldtextLandscape');

          let rebetstatus = 1;
          let totalBetAmountForLimitsCheck;
          let totalAnteBetAmount;
          // console.log(this.state.previousAnteBetAmount)
          // console.log(this.state.previousBonusbetamount, bettext)
          if (bettext === "REBETX2") {
            rebetstatus = 2;
          }
          if (bettext === "REBETX2" || bettext === "REBET") {

            if (Number((Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus)).toFixed(2)) > Number(this.props.balance) ||
              Number((Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus)).toFixed(2)) > this.props.balance) {
              // if (Number(this.state.previousAnteBetAmount)  + Number(this.state.previousBonusbetamount) > 39) {
              // console.log("this.state.anteBetAmount :------", this.state.previousAnteBetAmount, this.state.previousBonusbetamount);
              this.setState({
                anteBetAmount: 0,
                bonusbetamount: 0,
                totalbet: 0,
              })

              // betbutton.src ="" ;
              // foldbutton.src ="" ;
              // betbtntextElement.className ="";
              // foldtextElement.className ="";
              if (Number((Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus)).toFixed(2)) > Number(this.props.balance)) {


                // betbutton.src = "";
                // foldbutton.src = "";
                // betbutton.style.cssText = "";
                // foldbutton.style.cssText = "";
                // betbtntextElement.className = "";
                // foldtextElement.className = "";
                // betbtntextElement.textContent = "";
                // foldtextElement.textContent = "";

                // this.setState({
                //   betshigh_balnce: true,
                //   lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},Please DEPOSIT to continue playing.`
                // })

                if (this.state.previousBonusbetamount > 0) {
                  this.setState({
                    betshigh_balnce: true,
                    lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},
                                          OR BONUS of ${(this.state.previousBonusbetamount * rebetstatus).toFixed(1)}  Please DEPOSIT  to continue playing`
                  })
                }
                else {
                  this.setState({
                    betshigh_balnce: true,
                    lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},Please DEPOSIT to continue playing`
                  })

                }


                // this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })
              } else if (Number((Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus)).toFixed(2)) > this.props.balance) {

                if (this.state.previousBonusbetamount > 0) {
                  this.setState({
                    betshigh_balnce: true,
                    lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},with BET ${(this.state.previousAnteBetAmount * rebetstatus * 2).toFixed(1)}
                   OR Insufficient Funds to follow up your BONUS of ${(this.state.previousBonusbetamount * rebetstatus).toFixed(1)},with BET ${(this.state.previousBonusbetamount * rebetstatus * 2).toFixed(1)}
                   Please DEPOSIT to continue playing.`
                  })
                }
                else {
                  this.setState({
                    betshigh_balnce: true,
                    lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},with BET ${(this.state.previousAnteBetAmount * rebetstatus * 2).toFixed(1)} Please DEPOSIT to continue playing.`
                  })

                }
                // this.setState({ betshigh_balnce: false, multibuttonsebledsble: true, disablemultibutton: true })
              }





              // if (Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > this.props.balance) {
              //   this.setState({ betshigh_balnce: false, multibuttonsebledsble: true, disablemultibutton: true })
              // } else if (Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > Number(this.props.balance)) {
              //   this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })
              // }


              betbtnElement.classList.remove("addpointer")
              betbtnElement.classList.add("drwnodrop");
              popupbetGreaterthanBalnceElement.className = "";
              gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0 },
                { scale: 1, duration: 1, rotate: 720, opacity: 1, ease: "power2.out" });

              if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {

                popupbetGreaterthanBalnceElement.classList.add("popupbetshighLndsp");
                textbetsHighElement.classList.add("poptextBetshighPortrait");
                attentionElement.classList.add("attentionTextPortrait");

              }

            }
            // else if(this.state.anteBetAmount < this.props.minBetAmt || 
            //           this.state.anteBetAmount > this.props.maxBetAmt) {
            else if (Number((this.state.previousAnteBetAmount * rebetstatus).toFixed(2)) > this.props.maxBetAmt) {
              this.setState({
                infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
                anteBetAmount: 0,
                bonusbetamount: 0,
                totalbet: 0,
                // multibuttonsebledsble: true,
                disablemultibutton: false,
              })

            } else {



              bnsactived.src = bnsactive;
              bnsactived.classList.add("bnsactiveLandscape");
              bnsactived.classList.add("bnsblink");
              this.previousantebonousamount(bettext);

              Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
              betbtntextElement.classList.add('betLandscape');
              betbutton.src = Betbtn;

              foldbutton.src = Foldbn;
              Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
              foldtextElement.classList.add('foldtextLandscape');


              // this.setState({ multibuttonsebledsble: false });
              this.playPokerChipSound(this.pokerchipsnd);
              this.setState({
                disablemultibutton: false,
                betbutton: "DEAL",
                buttontext: "clear",
                bsbtn: false,
                infoText: this.props.language.deal_tostart,
                betchipincrementbtn: false,
                betdecreamentbtn: false,
                // storeantebetchips: [...this.state.prevStatestoreantebetchips],
                // storebonuschips: [...this.state.prevStatestorebonuschips],
                storeantebetchips: [
                  {
                    chip: this.ReturnChipBasedAmount(Number(this.state.previousAnteBetAmount * rebetstatus)),
                    chipvalue: this.topLable_rsp(Number(this.state.previousAnteBetAmount * rebetstatus)),
                    chipcolor1: "valtextPortrait1"
                  },

                ],
                storebonuschips: [
                  {
                    chip: this.ReturnChipBasedAmount(Number(this.state.previousBonusbetamount * rebetstatus)),
                    chipvalue: this.topLable_rsp(Number(this.state.previousBonusbetamount * rebetstatus)),
                    chipcolor1: "insuranceTextPortraitChip10"
                  },

                ],

              }, () => {


                // for (let k = 1; k <= this.chipVal.length; k++) {
                //   if (this.state.count === k) {
                //     totalBetAmountForLimitsCheck = this.state.totalbet + Number(this.chipVal[k - 1].val);
                //     if (bettext === "REBETX2") {
                //       let chipcenterpositionElement = document.querySelectorAll("#chipcenterposition span");
                //       let chip10centerbnsElement = document.querySelectorAll("#chip10centerbns span");
                //       for (let a = 0; a < chipcenterpositionElement.length; a++) {
                //         chipcenterpositionElement[a].innerHTML = this.topLable_rsp(this.state.previousAnteBetAmount * 2);
                //       }
                //       for (let b = 0; b < chip10centerbnsElement.length; b++) {
                //         chip10centerbnsElement[b].innerHTML = this.topLable_rsp(this.state.previousBonusbetamount * 2);
                //       }

                //       totalAnteBetAmount = (this.state.previousAnteBetAmount + Number(this.chipVal[k - 1].val)) * 2;
                //       rebetstatus = 2;
                //       // document.querySelector('antebetChipPortrait10').innerHTML = totalAnteBetAmount
                //     } else {
                //       totalAnteBetAmount = this.state.previousAnteBetAmount + Number(this.chipVal[k - 1].val);
                //       rebetstatus = 1;
                //     }
                //   }
                // }




                document.getElementById("bonustext").style.color = "#ffffff";
                if (document.getElementById('chipcenterposition')) {
                  gsap.fromTo("#chipcenterposition",
                    { x: 1200, y: 0, autoAlpha: 0, duration: 0.00001 },
                    { x: 0, y: 0, autoAlpha: 1, duration: 0.2, zIndex: 1, ease: "power2.out" },
                  )
                  const chipcenterpositionElement = document.querySelectorAll("#chipcenterposition");
                  // console.log(chipcenterpositionElement)
                  for (let q = 0; q < chipcenterpositionElement.length; q++) {

                    chipcenterpositionElement[q].style.position = 'relative';
                  }

                }

                if (this.state.previousBonusbetamount > 0) {

                  this.setState({ isbonuspattable: true });
                  gsap.fromTo("#chip10centerbns",
                    { x: 1200, y: 0, autoAlpha: 0, duration: 0.00001, delay: 1 },
                    {
                      x: 0, y: 0, autoAlpha: 1, duration: 0.2, ease: "power2.out", onStart: () => {
                        this.playPokerChipSound(this.pokerchipsnd);
                      }
                    });
                  this.ShowBonuspayouttable()

                } else {
                  gsap.to("#chip10centerbns", { duration: 0.000001, opacity: 0 })
                }

              });


            }
          } else {


            let totalBetAmountForLimitsCheck = 0;
            let totalAnteBetAmount = 0;
            // console.log(this.chipVal)

            for (let k = 1; k <= this.chipVal.length; k++) {
              if (this.state.count === k) {
                totalBetAmountForLimitsCheck = this.state.totalbet + Number(this.chipVal[k - 1].val);
                totalAnteBetAmount = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);

              }
            }


            // console.log("this.state.anteBetAmount :------", totalBetAmountForLimitsCheck,
            //   this.state.totalbet, this.props.minBetAmt, this.props.maxBetAmt,
            //   this.state.anteBetAmount + Number(this.chipVal[this.state.count - 1].val)

            // );


            if (Number((Number(totalAnteBetAmount) + Number(this.state.bonusbetamount)).toFixed(2)) > Number(this.props.balance) ||
              Number((Number(totalAnteBetAmount * 3) + Number(this.state.bonusbetamount)).toFixed(2)) > Number(this.props.balance)) {

              // if (totalAnteBetAmount > 50 || totalAnteBetAmount*3 >50) {
              let amount = 0
              for (let k = 1; k <= this.chipVal.length; k++) {
                if (this.state.count === k) {
                  amount = Number(this.chipVal[k - 1].val);

                }
              }
              if (Number((totalAnteBetAmount + Number(this.state.bonusbetamount)).toFixed(2)) > this.props.balance) {
                this.setState({
                  betshigh_balnce: true,
                  multibuttonsebledsble: false,
                  // multibuttonsebledsble: true,
                  lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.anteBetAmount + amount).toFixed(1)},Please DEPOSIT to continue playing.`
                })

                // betbutton.src = "";
                // foldbutton.src = "";
                // betbutton.style.cssText = "";
                // foldbutton.style.cssText = "";
                // betbtntextElement.className = "";
                // foldtextElement.className = "";
                // betbtntextElement.textContent = "";
                // foldtextElement.textContent = "";

              } else if (Number((totalAnteBetAmount * 3 + Number(this.state.bonusbetamount)).toFixed(2)) > this.props.balance) {
                this.setState({
                  betshigh_balnce: true,
                  lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.anteBetAmount + amount).toFixed(1)},with BET ${((this.state.anteBetAmount + amount) * 2).toFixed(1)} Please DEPOSIT to continue playing.`
                }) // multibuttonsebledsble: true
              }

              betbtnElement.classList.remove("addpointer")
              betbtnElement.classList.add("drwnodrop");
              popupbetGreaterthanBalnceElement.className = "";

              gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0, duration: 0.5 },
                { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });

              if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
                popupbetGreaterthanBalnceElement.classList.add("popupbetshighLndsp");
                textbetsHighElement.classList.add("poptextBetshighPortrait");
                attentionElement.classList.add("attentionTextPortrait");

              }

            }
            //  else if(this.state.anteBetAmount < this.props.minBetAmt || 
            //           this.state.anteBetAmount > this.props.maxBetAmt) {
            else if (totalAnteBetAmount > Number(this.props.maxBetAmt)) {
              this.setState({
                infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
                // multibuttonsebledsble: true,
              }, () => {
                // console.log(this.state.infoText)
              });



            } else {
              bnsactived.src = bnsactive;
              document.getElementById("bonustext").style.color = "#ffffff";
              betbutton.src = Betbtn;
              Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
              betbtntextElement.classList.add('betLandscape');

              foldbutton.src = Foldbn;
              Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
              foldtextElement.classList.add('foldtextLandscape');

              bnsactived.classList.add("bnsactiveLandscape");
              bnsactived.classList.add("bnsblink");

              this.setState({
                multibuttonsebledsble: false,
                betbutton: "DEAL",
                buttontext: "clear",
                bsbtn: false,
                infoText: this.props.language.deal_tostart,
                betchipincrementbtn: false,
                betdecreamentbtn: false,
                previousAnteBetAmount: 0,
                previousBonusbetamount: 0,
              });
              let convrAntebt = this.topLable_rsp(totalAnteBetAmount);

              Object.assign(betchipstoantebet.style, porttraitejson.betchiplndspe);
              var tl = gsap.timeline();
              tl.to(this.chipref.current, {
                x: -1050,
                y: 0,
                // duration: 0.5,
                duration: 0.15,
                ease: "power2.inOut",
                zIndex: 12,
                autoAlpha: 1,
              });


              tl.to(this.chipref.current, {
                autoAlpha: 0,
                x: 0,
                y: 0,
                duration: 0.00001,
                zIndex: -3,
              });
              tl.play();

              this.playPokerChipSound(this.pokerchipsnd);

              switch (this.state.count) {
                case 1:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[0].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[0].val),
                  }))
                  betchipstoantebet.src = chip_10;
                  this.clertime13 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: "valtextPortrait1" },

                      ],
                    }));
                  }, 150);
                  break;
                case 2:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[1].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[1].val),
                  }))
                  betchipstoantebet.src = chip_25;
                  this.clertime14 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                case 3:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[2].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[2].val),
                  }))
                  betchipstoantebet.src = chip_50;
                  this.clertime15 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                case 4:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[3].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[3].val),
                  }))
                  betchipstoantebet.src = chip_100;
                  this.clertime16 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                case 5:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[4].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[4].val),
                  }))
                  betchipstoantebet.src = chip_500;
                  this.clertime17 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                case 6:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[5].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[5].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[5].val),
                  }))
                  betchipstoantebet.src = chip_1000;
                  this.clertime17 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                default:
                  break;
              }

            }
          }

        }
      }


    }
    else {
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {

        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();
        document.getElementById("betbtntext").textContent = "";
        document.getElementById("foldtext").textContent = "";
        document.getElementById("betbtn").classList.remove("addpointer")
        document.getElementById("betbtn").classList.add("bnspoiner")
        document.getElementById("betbtntext").textContent = this.props.language.Deal;
        document.getElementById("foldtext").textContent = this.props.language.RP_Clear;
        this.setState({
          muteunmutesounds: true
        })
      } else {
        this.setState({
          // disablemultibutton: true,
        })

      }
      this.setState({
        totalbet: 0,
        infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl
      })

    }



  }


  getClickSound = () => {

    this.selectCard.currentTime = 0;
    if (this.state.mutesounds) {
      this.selectCard.pause();
    } else {
      this.selectCard.play()
        .then(() => { })
        .catch(() => { })
    }
  }




  Showoptionsmenu() {
    this.getClickSound();
    this.setState((prevState) => {
      const isMenuOpen = !prevState.isoptionmenu;
      return {
        isoptionmenu: isMenuOpen,
        isactiveoptionsmenu: true,
        ShowLimits: isMenuOpen ? prevState.ShowLimits : false,
        ShowHistory: isMenuOpen ? prevState.ShowHistory : false,
        ShowGamerules: isMenuOpen ? prevState.ShowGamerules : false,
      };
    }, () => {
      // this.setState((prevState) => ({ isoptionmenu:!prevState.isoptionmenu,isactiveoptionsmenu: true }), () => { 
      // this.setState({ isoptionmenu:true,isactiveoptionsmenu: true }, () => {
      // this.setState({ isoptionmenu:true,isactiveoptionsmenu: true }, () => {


      var tl = gsap.timeline();
      if (document.getElementById("optionsmenubutton")) {

        tl.to("#optionsmenubutton", {
          x: 513,
          y: 75,
          duration: 0.5,
          ease: "power2.inOut",
          zIndex: 12,
          autoAlpha: 1,
          // opacity: 1,

        });
      }
      if (document.getElementById("optiontopmenu")) {

        tl.to("#optiontopmenu", {
          x: 316,
          y: 0,
          duration: 0.5,
          ease: "power2.inOut",
          zIndex: 12,
          autoAlpha: 1,
          // opacity: 1,

        });
      }


      if (document.getElementById("limits")) {

        tl.to("#limits", {
          x: 294,
          y: -5,
          duration: 0.2,
          ease: "power2.inOut",
          zIndex: 12,
          autoAlpha: 1,
          // opacity: 1,
          stagger: 2,

        });
      }

      if (document.getElementById("help")) {
        tl.to("#help", {
          x: 294,
          y: -5,
          duration: 0.2,
          ease: "power2.inOut",
          zIndex: 12,
          opacity: 1,
          // opacity: 0.5,
          stagger: 3,

        });
      }
      if (document.getElementById("history")) {
        tl.to("#history", {
          x: 294,
          y: -5,
          duration: 0.2,
          ease: "power2.inOut",
          zIndex: 12,
          autoAlpha: 1,
          // opacity: 1,
          stagger: 4,

        });
      }
      if (document.getElementById("exitmenu")) {
        tl.to("#exitmenu", {
          x: 313,
          y: -2,
          duration: 0.2,
          ease: "power2.inOut",
          zIndex: 12,
          autoAlpha: 1,
          // opacity: 1,
          stagger: 5,

        });
      }



      tl.play()

      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {

        if (document.getElementById("optionsmenubutton")) {
          document.getElementById("optionsmenubutton").classList.remove("optionsmenu_lndspe");
        }

        if (document.getElementById("optionsmenubutton")) {
          document.getElementById("optionsmenubutton").classList.remove("optionsmenu_portraite");
        }



        if (document.getElementById("optionsmenubutton")) {
          document.getElementById("optionsmenubutton").classList.add("optionsmenu");
        }
        if (document.getElementById("optiontopmenu")) {
          document.getElementById("optiontopmenu").classList.add("topmenu");
        }
        if (document.getElementById("optiontext")) {
          document.getElementById("optiontext").classList.add("optionheading");
        }
        if (document.getElementById("limits")) {
          document.getElementById("limits").classList.add("limitstext");
        }
        if (document.getElementById("help")) {
          document.getElementById("help").classList.add("helptext");
        }
        if (document.getElementById("history")) {
          document.getElementById("history").classList.add("historytext");
        }
        if (document.getElementById("arraow")) {
          document.getElementById("arraow").classList.add("arraowtext");
        }
        if (document.getElementById("exitmenu")) {
          document.getElementById("exitmenu").classList.add("exitoptionmenu");
        }


      }
      else {
        if (window.innerHeight > window.innerWidth) {
          // alert("...por")
          if (document.getElementById("optionsmenubutton")) {
            document.getElementById("optionsmenubutton").classList.remove("optionsmenu_lndspe");
          }


          if (document.getElementById("optionsmenubutton")) {
            document.getElementById("optionsmenubutton").classList.add("optionsmenu_portraite");
          }
          if (document.getElementById("optiontopmenu")) {
            document.getElementById("optiontopmenu").classList.add("topmenu");
          }
          if (document.getElementById("optiontext")) {
            document.getElementById("optiontext").classList.add("optionheading");
          }
          if (document.getElementById("limits")) {
            document.getElementById("limits").classList.add("limitstext");
          }
          if (document.getElementById("help")) {
            document.getElementById("help").classList.add("helptext");
          }
          if (document.getElementById("history")) {
            document.getElementById("history").classList.add("historytext");
          }
          if (document.getElementById("arraow")) {
            document.getElementById("arraow").classList.add("arraowtext");
          }
          if (document.getElementById("exitmenu")) {
            document.getElementById("exitmenu").classList.add("exitoptionmenu");
          }


        }
        else {

          if (document.getElementById("optionsmenubutton")) {
            document.getElementById("optionsmenubutton").classList.remove("optionsmenu_portraite");
          }

          if (document.getElementById("optionsmenubutton")) {
            document.getElementById("optionsmenubutton").classList.add("optionsmenu_lndspe");
          }
          if (document.getElementById("optiontopmenu")) {
            document.getElementById("optiontopmenu").classList.add("topmenu");
          }
          if (document.getElementById("optiontext")) {
            document.getElementById("optiontext").classList.add("optionheading");
          }
          if (document.getElementById("limits")) {
            document.getElementById("limits").classList.add("limitstext");
          }
          if (document.getElementById("help")) {
            document.getElementById("help").classList.add("helptext");
          }
          if (document.getElementById("history")) {
            document.getElementById("history").classList.add("historytext");
          }
          if (document.getElementById("arraow")) {
            document.getElementById("arraow").classList.add("arraowtext");
          }
          if (document.getElementById("exitmenu")) {
            document.getElementById("exitmenu").classList.add("exitoptionmenu");
          }


        }

      }
    });





  }
  ShowBonuspayouttable() {
    this.getClickSound();
    document.getElementById("bonustext").style.color = "#ffffff";

    this.setState({ isbonuspattable: !this.state.isbonuspattable }, () => {
      for (let i = 0; i < 8; i++) {
        let bonuspayout_info = document.getElementById(`numbersvalrussian${i}`)
        if (
          navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
        ) {

          // const bonusInfoTheadElement = document.getElementById('bonusInfoThead');
          // const bonusInfoTbodyElement = document.getElementById("bonusInfoTbody");

          // if (bonusInfoTheadElement && bonusInfoTbodyElement) {
          //   Object.assign(bonusInfoTheadElement.style, porttraitejson.bonusTheadwindows);
          //   Object.assign(bonusInfoTbodyElement.style, porttraitejson.bonusInfoTbodywindows);
          // }



          // if (document.getElementById(`numbersvalrussian${i}`)) {

          //   if (document.getElementById("heading_table")) {
          //     document.getElementById("heading_table").classList.add("windowstablehead");
          //   }
          //   //  alert("........CLASSADD")
          //   bonuspayout_info.classList.add("numbersval");
          // }
          // if (document.getElementById(`numrussian${i}`)) {
          //   // alert("........CLASSADD1")
          //   document.getElementById(`numrussian${i}`).classList.add("num");
          // }

          // if (document.getElementById(`numbers${i}`)) {
          //   // alert("........CLASSADD2")

          //   document.getElementById(`numbers${i}`).classList.add("numbers");


          // }
        }
        else {

          const bonusInfoTheadElement = document.getElementById('bonusInfoThead');
          const bonusInfoTbodyElement = document.getElementById("bonusInfoTbody");
          const rankingInfoTable = document.getElementById("bonuspaytableInfo");


          if (rankingInfoTable) {
            rankingInfoTable.className = "";
            bonusInfoTheadElement.style.cssText = "";
            bonusInfoTbodyElement.style.cssText = "";
          }

          if (window.innerHeight > window.innerWidth) {
            if (bonusInfoTheadElement && bonusInfoTbodyElement) {
              Object.assign(bonusInfoTheadElement.style, porttraitejson.bonusTheadPortrait);
              Object.assign(bonusInfoTbodyElement.style, porttraitejson.bonusInfoTbodyPortrait);
            }

            if (rankingInfoTable) {
              rankingInfoTable.classList.add("rankingInfoTablePortrait");
            }

            if (document.getElementById(`numbersvalrussian${i}`)) {

              if (document.getElementById("heading_table")) {
                document.getElementById("heading_table").style.cssText = "";
                document.getElementById("heading_table").classList.add("portraittablehead");
              }

              bonuspayout_info.classList.add("numbersvalPortrait");
            }
            if (document.getElementById(`numrussian${i}`)) {

              document.getElementById(`numrussian${i}`).style.cssText = "";
              document.getElementById(`numrussian${i}`).classList.add("numPortrait");
            }

            if (document.getElementById(`numbers${i}`)) {

              document.getElementById(`numbers${i}`).style.cssText = "";
              document.getElementById(`numbers${i}`).classList.add("numbers");
            }


          } else {

            //  IF MOBILE LANDSCAP ;
            if (bonusInfoTheadElement && bonusInfoTbodyElement) {
              Object.assign(bonusInfoTheadElement.style, porttraitejson.bonusTheadPortrait);
              Object.assign(bonusInfoTbodyElement.style, porttraitejson.bonusInfoTbodyPortrait);
            }


            if (rankingInfoTable) {
              rankingInfoTable.classList.add("rankingInfoTableLndsp");
            }

            if (document.getElementById(`numbersvalrussian${i}`)) {
              if (document.getElementById("heading_table")) {
                document.getElementById("heading_table").style.cssText = "";
                document.getElementById("heading_table").classList.add("portraittablehead");
              }
              bonuspayout_info.classList.add("numbersvalPortrait");
            }

            if (document.getElementById(`numrussian${i}`)) {
              document.getElementById(`numrussian${i}`).style.cssText = "";
              document.getElementById(`numrussian${i}`).classList.add("numPortrait");
            }


            if (document.getElementById(`numbers${i}`)) {
              document.getElementById(`numbers${i}`).style.cssText = "";
              document.getElementById(`numbers${i}`).classList.add("numbers");
            }


          }
        }
      }
    });

  }

  HideBonuspayouttable() {
    document.getElementById("bonustext").style.color = "";
    if (this.state.isbonuspattable) {
      this.setState({ isbonuspattable: false });
    }



  }


  lowBalanceErrorInformationPopupanimation = () => {
    const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnce");
    const textbetsHighElement = document.getElementById("textbets_high");
    const attentionElement = document.getElementById("attention");


    popupbetGreaterthanBalnceElement.className = "";

    gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0, },
      { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });


    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
        popupbetGreaterthanBalnceElement.classList.add("popupbetshighwindows");
        textbetsHighElement.classList.add("poptextBetshighwindows");
        attentionElement.classList.add("attentionTextwindows");

      }

    } else {
      if (window.innerHeight > window.innerWidth) {
        // Portrait

        if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
          popupbetGreaterthanBalnceElement.classList.add("popupbetshighPortrait");
          textbetsHighElement.classList.add("poptextBetshighPortrait");
          attentionElement.classList.add("attentionTextPortrait");

        }
      } else {
        // Landscape

        if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {

          popupbetGreaterthanBalnceElement.classList.add("popupbetshighLndsp");
          textbetsHighElement.classList.add("poptextBetshighPortrait");
          attentionElement.classList.add("attentionTextPortrait");

        }
      }
    }
  }

  BonusActivated() {
    setTimeout(() => {
      this.setState({ infoText: this.props.language.deal_Game })

    }, 150)

    // this.playPokerChipSound(this.pokerchipsnd);

    this.TotalbetamountGreaterthanbalance();

    // alert("bonus")
    // document.getElementById("betcircle").classList.remove("addpointer")
    document.getElementById("bonusRussian").classList.remove("bnspoiner");


    let totalBetAmountForLimitsCheck = 0;

    for (let k = 1; k <= this.chipVal.length; k++) {
      if (this.state.count === k) {
        totalBetAmountForLimitsCheck = this.state.bonusbetamount + Number(this.chipVal[k - 1].val);

      }
    }

    if (Number(totalBetAmountForLimitsCheck.toFixed(2)) <= this.props.maxBetAmt) {
      // this.setState({ infoText: this.props.language.deal_tostart });

      let totalbets_bonus = this.topLable_rsp(totalBetAmountForLimitsCheck)
      let betchipstobns = document.getElementById("betchipsaddtobonus");

      if (this.state.anteBetAmount * 3 + totalBetAmountForLimitsCheck > this.props.balance) {
        this.setState({
          betshigh_balnce: true,
          lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${this.state.anteBetAmount.toFixed(1)},with bet ${(this.state.anteBetAmount * 2).toFixed(1)} Please DEPOSIT to continue playing.`
        });
        this.lowBalanceErrorInformationPopupanimation()
      } else {

        this.playPokerChipSound(this.pokerchipsnd);


        this.setState({ isbonuspattable: true, betshigh_balnce: null }, () => {

          for (let i = 0; i < 8; i++) {
            let bonuspayout_info = document.getElementById(`numbersvalrussian${i}`)

            if (
              navigator.userAgentData &&
              navigator.userAgentData.platform &&
              navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
            ) {
              // const bonusInfoTheadElement = document.getElementById('bonusInfoThead');
              // const bonusInfoTbodyElement = document.getElementById("bonusInfoTbody");

              // if (bonusInfoTheadElement && bonusInfoTbodyElement) {
              //   Object.assign(bonusInfoTheadElement.style, porttraitejson.bonusTheadwindows);
              //   Object.assign(bonusInfoTbodyElement.style, porttraitejson.bonusInfoTbodywindows);
              // }
              // if (document.getElementById(`numbersvalrussian${i}`)) {

              //   if (document.getElementById("heading_table")) {
              //     document.getElementById("heading_table").classList.add("windowstablehead");
              //   }
              //   //  alert("........CLASSADD")
              //   bonuspayout_info.classList.add("numbersval");
              // }
              // if (document.getElementById(`numrussian${i}`)) {
              //   // alert("........CLASSADD1")
              //   document.getElementById(`numrussian${i}`).classList.add("num");
              // }

              // if (document.getElementById(`numbers${i}`)) {
              //   // alert("........CLASSADD2")

              //   document.getElementById(`numbers${i}`).classList.add("numbers");
              // }

            } else {

              const bonusInfoTheadElement = document.getElementById('bonusInfoThead');
              const bonusInfoTbodyElement = document.getElementById("bonusInfoTbody");
              const rankingInfoTable = document.getElementById("bonuspaytableInfo");


              if (rankingInfoTable) {
                rankingInfoTable.className = "";
                bonusInfoTheadElement.style.cssText = "";
                bonusInfoTbodyElement.style.cssText = "";
              }



              if (window.innerHeight > window.innerWidth) {

                if (bonusInfoTheadElement && bonusInfoTbodyElement) {
                  Object.assign(bonusInfoTheadElement.style, porttraitejson.bonusTheadPortrait);
                  Object.assign(bonusInfoTbodyElement.style, porttraitejson.bonusInfoTbodyPortrait);
                }


                if (rankingInfoTable) {
                  rankingInfoTable.classList.add("rankingInfoTablePortrait");
                }

                if (document.getElementById(`numbersvalrussian${i}`)) {
                  if (document.getElementById("heading_table")) {
                    document.getElementById("heading_table").className = "";
                    document.getElementById("heading_table").classList.add("portraittablehead");
                  }
                  bonuspayout_info.classList.add("numbersvalPortrait");
                }
                if (document.getElementById(`numrussian${i}`)) {
                  document.getElementById(`numrussian${i}`).style.cssText = "";
                  document.getElementById(`numrussian${i}`).classList.add("numPortrait");
                }

                if (document.getElementById(`numbers${i}`)) {
                  document.getElementById(`numbers${i}`).style.cssText = "";
                  document.getElementById(`numbers${i}`).classList.add("numbers");
                }


              } else {

                //  IF MOBILE LANDSCAP ;
                if (bonusInfoTheadElement && bonusInfoTbodyElement) {
                  Object.assign(bonusInfoTheadElement.style, porttraitejson.bonusTheadPortrait);
                  Object.assign(bonusInfoTbodyElement.style, porttraitejson.bonusInfoTbodyPortrait);
                }

                if (rankingInfoTable) {
                  rankingInfoTable.classList.add("rankingInfoTableLndsp");
                }

                if (document.getElementById(`numbersvalrussian${i}`)) {
                  if (document.getElementById("heading_table")) {
                    document.getElementById("heading_table").style.cssText = "";
                    document.getElementById("heading_table").classList.add("portraittablehead");
                  }
                  bonuspayout_info.classList.add("numbersvalPortrait");
                }

                if (document.getElementById(`numrussian${i}`)) {
                  document.getElementById(`numrussian${i}`).style.cssText = "";
                  document.getElementById(`numrussian${i}`).classList.add("numPortrait");
                }

                if (document.getElementById(`numbers${i}`)) {
                  document.getElementById(`numbers${i}`).style.cssText = "";
                  document.getElementById(`numbers${i}`).classList.add("numbers");
                }


              }
            }
          }

        });
        if (
          navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
        ) {
          Object.assign(betchipstobns.style, porttraitejson.betchipstobnswindows)

          // this.playPokerChipSound(this.pokerchipsnd);
          switch (this.state.count) {
            case 1:
              betchipstobns.src = chip_10;
              // document.getElementById("val_500").textContent=""

              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[0].val),
                betdeductions: prevState.totalbet + Number(this.chipVal[0].val),
              }))

              this.clertime31 = setTimeout(() => {
                this.setState((prevState) => ({

                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                      <img
                        className="chip10centerbns"

                        src={this.ReturnChipBasedAmount(totalbets_bonus)}
                        alt="Chip 10"
                      />
                      <span id="val_10" className="bonusChipsForwindows_val10">{totalbets_bonus}</span>
                    </div>

                  ],
                }));

              }, 150);

              break;
            case 2:
              betchipstobns.src = chip_25;
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[1].val),
                betdeductions: prevState.totalbet + Number(this.chipVal[1].val),
              }))
              this.clertime32 = setTimeout(() => {
                this.setState((prevState) => ({

                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                      <img
                        className="chip10centerbns"

                        src={this.ReturnChipBasedAmount(totalbets_bonus)}
                        alt="Chip 10"
                      />
                      <span id="val_20" className="bonusChipsForwindows">{totalbets_bonus}</span>
                    </div>
                  ],

                }));

              }, 150);

              break;
            case 3:
              betchipstobns.src = chip_50;


              this.clertime33 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                      <img
                        className="chip10centerbns"

                        src={this.ReturnChipBasedAmount(totalbets_bonus)}
                        alt="Chip 10"
                      />
                      <span id="val_50" className="bonusChipsForwindows">{totalbets_bonus}</span>
                    </div>
                  ],
                }));

              }, 150);
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[2].val),
                betdeductions: prevState.totalbet + Number(this.chipVal[2].val),
              }));
              break;
            case 4:
              betchipstobns.src = chip_100;


              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[3].val),
                betdeductions: prevState.totalbet + Number(this.chipVal[3].val),
              }));
              this.clertime34 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                      <img
                        className="chip10centerbns"

                        src={this.ReturnChipBasedAmount(totalbets_bonus)}
                        alt="Chip 10"
                      />
                      <span id="val_100" className="bonusChipsForwindows">{totalbets_bonus}</span>
                    </div>
                  ],
                }));

              }, 150);
              break;
            case 5:
              betchipstobns.src = chip_500;

              // document.getElementById("val_100").textContent=""
              // document.getElementById("betchipsaddtobonustxt").textContent = "500"
              // document.getElementById("betchipsaddtobonustxt").classList.add("betchipsaddtobonustxt");
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[4].val),
                betdeductions: prevState.totalbet + Number(this.chipVal[4].val),
              }));
              this.clertime35 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                      <img
                        className="chip10centerbns"
                        src={this.ReturnChipBasedAmount(totalbets_bonus)}
                        alt="Chip 10"
                      />
                      <span id="val_500" className="bonusChipsForwindows">{totalbets_bonus}</span>
                    </div>
                  ],
                }));

              }, 150);
              break;
            case 6:
              betchipstobns.src = chip_1000;
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[5].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[5].val),
                betdeductions: prevState.totalbet + Number(this.chipVal[5].val),
              }));
              this.clertime35 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                      <img
                        className="chip10centerbns"
                        src={this.ReturnChipBasedAmount(totalbets_bonus)}
                        alt="Chip 10"
                      />
                      <span id="val_500" className="bonusChipsForwindows">{totalbets_bonus}</span>
                    </div>
                  ],
                }));

              }, 150);
              break;

            default:
              break;
          }



          var tl = gsap.timeline();
          tl.to(this.chipbnsref.current, {
            x: -861,
            y: -554,
            duration: 0.2,
            ease: "power2.inOut",
            zIndex: 12,
          });
          tl.to(this.chipbnsref.current, {
            x: -48,
            y: 0,
            opacity: 0,
            duration: 0.00001,
          });
          // tl.reverse();
          tl.to(this.chipbnsref.current, { opacity: 1, zIndex: -2 });
          tl.play();
        }

        else {
          let inforussianElement = document.getElementById("inforussian");
          let inforussianlandElement = document.getElementById("inforussianland");
          inforussianElement.className = "";
          inforussianlandElement.className = "";
          let convrBonusbet = this.topLable_rsp(totalBetAmountForLimitsCheck);
          if (window.innerHeight > window.innerWidth) {
            inforussianElement.classList.add("portraitinfo");
            Object.assign(betchipstobns.style, porttraitejson.betchipstobnsPortrait);


            this.setState((prevState) => ({
              // totalbet: prevState.totalbet + 1,
              isbonuspattable: true,
            }));
            switch (this.state.count) {
              case 1:
                betchipstobns.src = chip_10;
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[0].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[0].val),

                }));
                this.clertime31 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: "insuranceTextPortraitChip10" },

                    ],
                  }));
                }, 150);

                break;
              case 2:
                betchipstobns.src = chip_25;
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[1].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[1].val),

                }));
                this.clertime32 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: null },
                    ],
                  }));
                }, 150);

                break;
              case 3:
                betchipstobns.src = chip_50;
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[2].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[2].val),

                }));
                this.clertime33 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: null },
                    ],
                  }));
                }, 150);
                break;
              case 4:
                betchipstobns.src = chip_100;
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[3].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[3].val),

                }));
                this.clertime34 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: null },
                    ],
                  }));
                }, 150);
                break;
              case 5:
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[4].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[4].val),

                }));
                betchipstobns.src = chip_500;
                this.clertime35 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: null },
                    ],
                  }));
                }, 150);
                break;
              case 6:
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[5].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[5].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[5].val),

                }));
                betchipstobns.src = chip_1000;
                this.clertime35 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: null },
                    ],
                  }));
                }, 150);
                break;

              default:
                break;

            }

            var tl = gsap.timeline();
            tl.to(this.chipbnsref.current, {
              autoAlpha: 1,
              x: 160,
              y: -1200,
              duration: 0.15,
              ease: "power2.inOut",
              zIndex: 12,
            });
            tl.to(this.chipbnsref.current, {
              autoAlpha: 0,
              x: 0,
              y: 0,
              duration: 0.00001,
              zIndex: -2
            });
            tl.play();


          } else {

            //  IF MOBILE LAND SCAPE;

            inforussianlandElement.classList.add("lndspinfo");
            Object.assign(betchipstobns.style, porttraitejson.betchipstobnsLndsp);


            this.setState((prevState) => ({
              // totalbet: prevState.totalbet + 1,
              isbonuspattable: true,
            }));

            var tl = gsap.timeline();
            tl.to(this.chipbnsref.current, {
              autoAlpha: 1,
              x: -890,
              y: 0,
              duration: 0.15,
              ease: "power2.inOut",
              zIndex: 12,
            });
            tl.to(this.chipbnsref.current, {
              autoAlpha: 0,
              x: 0,
              y: 0,
              duration: 0.00001,
              zIndex: -2
            });
            tl.play();
            switch (this.state.count) {
              case 1:
                betchipstobns.src = chip_10;
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[0].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[0].val),

                }));
                this.clertime31 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: "insuranceTextPortraitChip10" },

                    ],
                  }));
                }, 150);

                break;
              case 2:
                betchipstobns.src = chip_25;
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[1].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[1].val),

                }));
                this.clertime32 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: null },
                    ],
                  }));
                }, 150);

                break;
              case 3:
                betchipstobns.src = chip_50;
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[2].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[2].val),

                }));
                this.clertime33 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: null },
                    ],
                  }));
                }, 150);
                break;
              case 4:
                betchipstobns.src = chip_100;
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[3].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[3].val),

                }));
                this.clertime34 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: null },
                    ],
                  }));
                }, 150);
                break;
              case 5:
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[4].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[4].val),

                }));
                betchipstobns.src = chip_500;
                this.clertime35 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: null },
                    ],
                  }));
                }, 150);
                break;
              case 6:
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[5].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[5].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[5].val),

                }));
                betchipstobns.src = chip_1000;
                this.clertime35 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: null },
                    ],
                  }));
                }, 150);
                break;

              default:
                break;

            }
          }




        }


      }

    } else {
      this.playPokerChipSound(this.pokerchipsnd);
      this.setState({ infoText: this.props.language.bonus_maxbet })
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {
        document.getElementById("bonusRussian").classList.add("drwnodrop");
      }
    }







  }

  RusssianpokerBet() {

    if (this.replaceCards.length > 0) {
      this.cardsRef.current.unselectPokerCards();
    }

    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }

    if (this.state.isbonuspattable === true) {
      this.setState({
        isbonuspattable: false,
      })
    }

    let doubleamt = this.state.anteBetAmount * 2
    // console.log(this.state.anteBetAmount, this.props.balance)
    if (Number((this.state.betdeductions + doubleamt).toFixed(2)) <= this.props.balance) {
      if (this.state.betshigh_balnce === true) {
        this.hidepopup_betshigh();
      }
      this.setState((prevState) => ({
        betdeductions: prevState.betdeductions + doubleamt,
        totalbet: prevState.totalbet + doubleamt,
        disBetBtn: 1,
        keyboardhandle: false,
        infoText: ""
      }));


      this.playPokerChipSound(this.pokerchipsnd);

      if (this.state.mutesounds) {
        this.betrussiansnd.pause();

      } else {
        this.betrussiansnd.play();

      }

      const drawbutton = document.getElementById("drawbtn");
      const buybutton = document.getElementById("buybtn");
      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");
      let betTextaddtoBetElement = document.getElementById("betTextaddtoBet");
      let betchipsaddtobetwindows = document.getElementById("betchipsaddtobet");
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {




        gsap.set(["#betTextaddtoBet"], { clearProps: "all" })
        this.setState({ disBuybtn: 1, disClearBtn: 1, disDrawbtn: 1, Foldbtn: 1, disBetBtn: 1 })
        document.getElementById("crdscover").classList.add("crdscover")
        document.getElementById("betpannelrussiancover").style.display = "block";
        document.getElementById("betpannelrussiancover").classList.add("bet_pannelcoverrussian");
        document.getElementById("betactive").src = ""
        document.getElementById("betactive").classList.remove("betactive");
        document.getElementById("betbtntext").innerHTML = "";

        document.getElementById("glowonwinchip").classList.remove("chipglow_bonus")
        // t1.to("#chip10centerbns", {
        //   x: 861, y: 546, duration: 0.5, zIndex: -2, opacity: 0,

        //   onComplete: () => {
        //     gsap.set("#chip10centerbns", { opacity: 0 });

        //   }

        // });
        // t1.play();
        // let t1 = gsap.timeline();
        // document.getElementById("glowonwinchip").classList.remove("chipglow_bonus")
        // t1.to("#chip10centerbns", {
        //   x: 478, y: -674, duration: 0.5, zIndex: -2, opacity: 0,

        //   onComplete: () => {
        //     gsap.set("#chip10centerbns", { opacity: 0 });

        //   }

        // });






        document.getElementById("drawtext").textContent = "";
        document.getElementById("buytext").textContent = "";
        document.getElementById("foldtext").textContent = "";

        document.getElementById("betbtntext").classList.remove("betwindows");
        betbutton.src = "";
        betbutton.style.cssText = ""


        foldbutton.src = "";
        foldbutton.style.cssText = ""
        document.getElementById("foldtext").classList.remove("foldtextwindows");


        drawbutton.src = "";
        drawbutton.style.cssText = ""


        buybutton.src = "";
        buybutton.style.cssText = ""


        let betTextaddtoBetClass = "";

        if (
          navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
        ) {

          // gsap.to("#betTextaddtoBet", { opacity: 1, duration: 0.1 })
          let russianbet_amount
          switch (this.state.count) {

            case 1:
              betTextaddtoBetClass = "";
              betchipsaddtobetwindows.src = this.ReturnChipBasedAmount(this.state.anteBetAmount * 2);
              russianbet_amount = this.topLable_rsp(this.state.anteBetAmount * 2)
              betTextaddtoBetElement.textContent = russianbet_amount
              betTextaddtoBetClass = "bet-text-addtoBet10";

              break;
            case 2:
              betTextaddtoBetClass = "";
              betchipsaddtobetwindows.src = this.ReturnChipBasedAmount(this.state.anteBetAmount * 2);
              russianbet_amount = this.topLable_rsp(this.state.anteBetAmount * 2)
              betTextaddtoBetElement.textContent = russianbet_amount
              betTextaddtoBetClass = "bet-text-addtoBet20";

              break;
            case 3:
              betTextaddtoBetClass = "";
              betchipsaddtobetwindows.src = this.ReturnChipBasedAmount(this.state.anteBetAmount * 2);
              russianbet_amount = this.topLable_rsp(this.state.anteBetAmount * 2)
              betTextaddtoBetElement.textContent = russianbet_amount
              betTextaddtoBetClass = "bet-text-addtoBet20";

              break;
            case 4:
              betTextaddtoBetClass = "";
              betchipsaddtobetwindows.src = this.ReturnChipBasedAmount(this.state.anteBetAmount * 2);
              russianbet_amount = this.topLable_rsp(this.state.anteBetAmount * 2)
              betTextaddtoBetElement.textContent = russianbet_amount
              betTextaddtoBetClass = "bet-text-addtoBet20";

              break;
            case 5:
              betTextaddtoBetClass = "";
              betchipsaddtobetwindows.src = this.ReturnChipBasedAmount(this.state.anteBetAmount * 2);
              russianbet_amount = this.topLable_rsp(this.state.anteBetAmount * 2)
              betTextaddtoBetElement.textContent = russianbet_amount
              betTextaddtoBetClass = "bet-text-addtoBet20";
              break;
            case 6:
              betTextaddtoBetClass = "";
              betchipsaddtobetwindows.src = this.ReturnChipBasedAmount(this.state.anteBetAmount * 2);
              russianbet_amount = this.topLable_rsp(this.state.anteBetAmount * 2)
              betTextaddtoBetElement.textContent = russianbet_amount
              betTextaddtoBetClass = "bet-text-addtoBet20";
              break;

            default:
              break;
          }


          gsap.to(this.chipbetref.current, {
            x: -965,
            y: -484,
            duration: 0.1,
            ease: "power2.inOut",
            zIndex: 2,
            opacity: 1,
            onComplete: () => {
              Object.assign(betchipsaddtobetwindows.style, porttraitejson.Betchiptobetwindows)
              betTextaddtoBetElement.classList.add(betTextaddtoBetClass, "bet-text-addtoBet");

            }
          });


        }



      }


      else {

        if (this.state.isbonuspattable === true) {
          this.setState({
            isbonuspattable: false,
          })
        }

        let betTextaddtoBetElement = document.getElementById("betTextaddtoBet");
        let betchipsaddtobetwindows = document.getElementById("betchipsaddtobet");
        let betTextaddtoBetClass = "bet-text-addtoBet20";
        document.getElementById("drawtext").textContent = "";
        document.getElementById("buytext").textContent = "";
        document.getElementById("foldtext").textContent = "";
        betbutton.src = "";
        betbutton.style.cssText = "";
        document.getElementById("betbtntext").classList.remove("betwindows");
        document.getElementById("betbtntext").textContent = "";
        betbutton.style.position = "";
        betbutton.style.width = "";
        betbutton.style.transform = "";

        foldbutton.src = "";
        foldbutton.style.cssText = "";
        document.getElementById("foldtext").classList.remove("foldtextwindows");
        foldbutton.style.position = "";
        foldbutton.style.width = "";
        foldbutton.style.transform = "";

        drawbutton.src = "";
        drawbutton.style.cssText = "";
        drawbutton.style.position = "";
        drawbutton.style.height = "";
        drawbutton.style.width = "";
        drawbutton.style.transform = "";

        buybutton.src = "";
        buybutton.style.cssText = "";
        buybutton.style.position = "";
        buybutton.style.width = "";
        buybutton.style.transform = "";


        document.getElementById("betactive").src = "";
        document.getElementById("betactive").className = "";
        document.getElementById("mobileAntiMaskCover").style.display = "block";
        this.setState({ bsbtn: true })

        // let doubleamt = this.state.totalbet * 2;
        let convrBet = this.topLable_rsp(this.state.anteBetAmount * 2);

        betchipsaddtobetwindows.src = this.ReturnChipBasedAmount(Number((this.state.anteBetAmount * 2).toFixed(2)));
        switch (this.state.count) {
          case 1:
            // betchipsaddtobetwindows.src = chip_10;
            // betTextaddtoBetElement.textContent = 10;
            betTextaddtoBetElement.textContent = convrBet;
            betTextaddtoBetClass = "bet-text-addtoBet10";
            break;
          case 2:
            // betchipsaddtobetwindows.src = chip_25;
            betTextaddtoBetElement.textContent = convrBet;
            break;
          case 3:
            // betchipsaddtobetwindows.src = chip_50;
            betTextaddtoBetElement.textContent = convrBet;
            break;
          case 4:
            // betchipsaddtobetwindows.src = chip_100;
            betTextaddtoBetElement.textContent = convrBet;
            break;
          case 5:
            // betchipsaddtobetwindows.src = chip_500;
            betTextaddtoBetElement.textContent = convrBet;
            break;
          case 6:
            // betchipsaddtobetwindows.src = chip_1000;
            betTextaddtoBetElement.textContent = convrBet;
            break;

          default:
            betTextaddtoBetClass = "bet-text-addtoBet20"
            break;
        }
        this.betchipactivated = true;

        if (window.innerHeight > window.innerWidth) {
          document.getElementById("betpannelrussiancover").style.display = "block";
          betTextaddtoBetElement.className = "";
          betTextaddtoBetElement.style.cssText = "";
          betTextaddtoBetElement.classList.add(betTextaddtoBetClass, "bet-text-addtoBet-portrait");
          Object.assign(betchipsaddtobetwindows.style, porttraitejson.BetchiptobetPortrait);
          gsap.to(this.chipbetref.current, {
            // x: -10,
            // y: -950,
            x: -27,
            y: -925,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 2,
            autoAlpha: 1,
            opacity: 1,
          });


          gsap.to(betTextaddtoBetElement, {
            // x: 12,
            // y: -1739,
            x: -3,
            y: -1710,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 2,
            opacity: 1,
            visibility: true,
            onStart: function () {
              betTextaddtoBetElement.style.zIndex = 2;
              betTextaddtoBetElement.style.visibility = 'visible';
            }
          });

          if (this.playerWinBonus === true) {
            if (document.getElementById("chip10centerbns")) {
              let ml = gsap.timeline()
              ml.to("#chip10centerbns", { x: 0, y: -900, duration: 0.2, autoAlpha: 1 })
              ml.to("#chip10centerbns", { x: -170, y: 1200, duration: 0.00001, zIndex: -2, autoAlpha: 0 });
              this.playerWinBonus = false;
            }
          }




        }

        else {
          document.getElementById("betpannelrussiancover").style.display = "block";
          betTextaddtoBetElement.className = "";
          betTextaddtoBetElement.style.cssText = "";
          betTextaddtoBetElement.classList.add(betTextaddtoBetClass, "bet-text-addtoBet-lndsp");
          Object.assign(betchipsaddtobetwindows.style, porttraitejson.BetchiptobetLndsp);
          gsap.to(this.chipbetref.current, {

            // x: -27,
            // y: -925,

            x: -1060,
            y: 170,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 2,
            autoAlpha: 1
          });


          gsap.to(betTextaddtoBetElement, {
            // x: 12,
            // y: -1739,
            x: -1060,
            y: 170,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 2,
            autoAlpha: 1,
            onStart: function () {
              betTextaddtoBetElement.style.zIndex = 2;
              betTextaddtoBetElement.style.visibility = 'visible';
            }
          });


          if (this.playerWinBonus === true) {
            if (document.getElementById('chip10centerbns')) {
              let ml = gsap.timeline()
              ml.to("#chip10centerbns", {
                x: 600, y: -500, duration: 0.2, autoAlpha: 1,
              });
              ml.to("#chip10centerbns", {
                x: 0, y: 0, duration: 0.00001, autoAlpha: 0,
              });
            }

          }

          this.playerWinBonus = false;

        }

      }


      const body = {
        sessionId: sessionStorage.getItem("sessionId"),
        CN: "RUSSIAN_POKER_BET",
        object: {
          action: "BET",
          gameId: this.props.gameId,
          playerId: Number(this.props.playerId),
          handId: Number(this.props.gameState.handId)
        },
      };
      // console.log(body);
      this.props.network.sendof(body);
      // const body2 = {
      //   CN: "GET_PLAYER_INFO",
      //   object: {
      //     gameId: Number(this.props.gameId,),
      //     playerId: Number(this.props.playerId),

      //   },
      //   sessionId: sessionStorage.getItem('sessionId'),
      // }
      // this.props.network.sendof(body2);
      // this.body2 = {};

    } else {

      const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnce");
      const textbetsHighElement = document.getElementById("textbets_high");
      const attentionElement = document.getElementById("attention");

      popupbetGreaterthanBalnceElement.className = "";
      textbetsHighElement.className = "";
      attentionElement.className = "";
      this.setState({
        betshigh_balnce: true,
        lowBalaceErrorInfo: `Insufficient Funds to place the BET of ${doubleamt.toFixed(1)},Please DEPOSIT to continue playing.`
      })
      gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0, duration: 0.5 },
        { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });

      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {

        if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
          popupbetGreaterthanBalnceElement.classList.add("popupbetshighwindows");
          textbetsHighElement.classList.add("poptextBetshighwindows");
          attentionElement.classList.add("attentionTextwindows");

        }
      } else {
        if (window.innerHeight > window.innerWidth) {
          if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {

            popupbetGreaterthanBalnceElement.classList.add("popupbetshighPortrait");
            textbetsHighElement.classList.add("poptextBetshighPortrait");
            attentionElement.classList.add("attentionTextPortrait");

          }
        } else {
          if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {

            popupbetGreaterthanBalnceElement.classList.add("popupbetshighLndsp");
            textbetsHighElement.classList.add("poptextBetshighPortrait");
            attentionElement.classList.add("attentionTextPortrait");

          }
        }
      }
















    }
  }


  Exitoptionmenu() {
    this.setState({ isoptionmenu: false });
    var tl = gsap.timeline();
    tl.to("#optionsmenubutton", {
      x: 458,
      y: 75,
      duration: 0.5,
      ease: "power2.inOut",
      zIndex: 12,
      opacity: 1
    });
    tl.play();

  }
  topLable_rsp(value) {

    // // Nine Zeroes for Billions
    // return Math.abs(Number(labelValue)) >= 1.0e+9

    //   ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
    //   // Six Zeroes for Millions 
    //   : Math.abs(Number(labelValue)) >= 1.0e+6

    //     ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
    //     // Three Zeroes for Thousands
    //     : Math.abs(Number(labelValue)) >= 1.0e+3

    //       ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"

    //       : Math.abs(Number(labelValue));


    // console.log(value)
    // Nine Zeroes for Billions
    const labelValue = Math.abs(Number(value));
    return labelValue >= 1.0e+9

      ? Number((labelValue / 1.0e+9).toFixed(2)) + "B"
      // Six Zeroes for Millions 
      : labelValue >= 1.0e+6

        ? Number((labelValue / 1.0e+6).toFixed(2)) + "M"
        // Three Zeroes for Thousands
        : labelValue >= 1.0e+3

          ? Number((labelValue / 1.0e+3).toFixed(2)) + "K"

          : Number(labelValue.toFixed(2));
  }
  // disabledrwbutton(){

  //    this.setState({drawbuttonhandle:false})
  //     document.getElementById("drawtext").classList.remove("drawtextopacity");
  // }
  numberOfCardsToBeDraw = (drawCardArray) => {
    // console.log("drawCardArray", drawCardArray);
    this.replaceCards = [...drawCardArray];
    // console.log(this.replaceCards.length);
    if (this.replaceCards.length > 0) {
      // alert("................>0length")
      this.setState({ drawbuttonhandle: false, disDrawbtn: 0 });
      document.getElementById("drawtext").classList.remove("drawtextopacity");
      document.getElementById("drawbtn").classList.remove("drwnodrop");

      document.getElementById("buybtn").style.opacity = "0.5";
      document.getElementById("buytext").style.opacity = "0.5";
      this.setState({ disableBuyButton: true })
    } else {
      // alert("................0length")
      this.setState({ drawbuttonhandle: true, disDrawbtn: 1 });
      document.getElementById("drawtext").classList.add("drawtextopacity");
      document.getElementById("drawbtn").classList.add("drwnodrop");

      document.getElementById("buybtn").style.opacity = "1"
      document.getElementById("buytext").style.opacity = "1"
      this.setState({ disableBuyButton: false })
    }
  };


  checkpayerordealerwins(res) {





    this.removehigltbonusclasses(res);


    document.getElementById("insurencetext").style.color = "";
    document.getElementById("bonustext").style.color = "";
    document.getElementById("bettext").style.color = "";
    this.clearallsettimeouts()
    document.getElementById("insurenceactive").src = "";
    document.getElementById("insurenceactive").classList.remove("insurenceactivewindows");
    document.getElementById("insurenceactive").classList.remove("insurenceblink");
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");

    let bnsactived = document.getElementById("bnsactive");


    this.setState({
      previousAnteBetAmount: res.anteBetAmount,
      previousBonusbetamount: res.bonusBetAmount, isplayerinfoupdate: false
    })



    const hands = [
      "onepair", "Threekind", "aceking", "fullhse",
      "Straightflush", "frofkind", "twopair",
      "flush", "stright", "royalflush"
    ];


    switch (res.winner) {
      case "Dealer":
        this.setState({ isplayerinfoupdate: false })
        if (res.insuranceWinAmount > 0) {
          const antetext = document.querySelectorAll("#chiptoinsurence span");
          antetext.forEach(el => el.textContent = res.insuranceWinAmount || "");


        }

        this.setState({ infoText: res.message })





        if (res.insuranceWinAmount > 0) {

          var tl = gsap.timeline();

          tl.to("#chiptoinsurence", {
            x: 312,
            y: 343,
            duration: 0.5,
            ease: "power2.inOut",

          });


          tl.to("#chiptoinsurence", {
            x: 312,
            y: 343,
            duration: 1,
            onComplete: () => {
              gsap.set("#chiptoinsurence", { opacity: 0 });
            }
          });


          // tl.to("#chiptoinsurence", {
          //   x: 956,
          //   y: 692,
          //   duration: 0.1,
          //   onComplete: () => {
          //     gsap.set("#chiptoinsurence", { opacity: 0 });
          //   }
          // });



        }

        else {

          var tl = gsap.timeline();
          tl.to("#chiptoinsurence", {
            x: 0,
            y: -414,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 12,
            onComplete: () => {
              gsap.set("#chiptoinsurence", { opacity: 0 })

            }
          });
          tl.to("#chiptoinsurence", {
            opacity: 0,
            x: 956,
            y: 692,
            duration: 0.1,
          });
          // tl.reverse();
          // tl.to("#chiptoinsurence", { opacity: 0, zIndex: -2 });
          tl.play();



        }



        document.getElementById("betpannelinsurececover").style.display = "none";
        document.getElementById("betpannelrussiancover").classList.remove("bet_pannelinsurence");

        hands.forEach(hand => {
          document.getElementById(hand).style.color = "";
        });

        this.updateplayerbalnace();


        document.getElementById("foldtext").classList.remove("insurence");
        document.getElementById("betdecrement").classList.remove("drwnodrop")
        document.getElementById("betincrenet").classList.remove("drwnodrop")

        this.setState({ betchipincrementbtn: false, betdecreamentbtn: false })
        this.playPokerChipSound(this.pokerchipsnd);

        // gsap.from("#chip10centerbns",{x:1046,y:276,zIndex:-2,duration:1})
        document.getElementById("betactive").src = "";
        document.getElementById("bettext").style.color = "";
        document.getElementById("betactive").classList.remove("betactive");
        this.setState({ betbutton: "REBET", buttontext: "REBETX2", betBtnValue: 2, keyboardhandle: true });



        let t1 = gsap.timeline();
        t1.to("#chipcenterposition", {
          x: 2,
          y: -662,
          duration: 0.5,
          zIndex: 12,
          ease: "power2.inOut",
          opacity: 1,
          onComplete: () => {
            gsap.set("#chipcenterposition", { opacity: 0 });
          }
        });


        let t2 = gsap.timeline();
        t2.to("#betchipsaddtobet", {
          x: -967,
          y: -1281,
          duration: 0.5,
          zIndex: 12,
          ease: "power2.inOut",
          opacity: 1,
          delay: 5,
          onComplete: () => {
            gsap.set("#betchipsaddtobet", { opacity: 0 });
          }
        });
        t2.to("#betchipsaddtobet", {
          x: -2,
          y: 72,
          zIndex: -10,
          duration: 0.1,
          opacity: 0,
          delay: 0.5,

        });

        gsap.to("#betTextaddtoBet", { duration: 0.1, zIndex: -2 })


        let centerchips = gsap.timeline();
        centerchips.to("#centerchips", {
          zIndex: -2,
          opacity: 0,
          onComplete: () => {
            document.getElementById("centerchips").removeAttribute("src");
            document.getElementById("centerchips").classList.remove("chip10centerposition");
          }
        });
        centerchips.pause();


        let t6 = gsap.timeline();
        t6.to("#betchipsaddtconfrm", {
          x: -1084,
          y: -1266, duration: 0.5, opacity: 1,
          onComplete: () => {
            gsap.set("#betchipsaddtconfrm", { opacity: 0 });
          }
        });
        t6.to("#betchipsaddtconfrm", {
          x: 12, y: -51, zIndex: -10, duration: 0.3, opacity: 0, delay: 1.5

        });
        gsap.to("#betchipsaddtodrawText", { opacity: 0, zIndex: -2 })


        // t6.play();

        // gsap.from("#betTextaddtodraw", { opacity: 0, zIndex: -3 })
        gsap.from("#betTextaddtoinsurence", { zIndex: -2 })

        gsap.set(["#chiptoinsurence", "#chipcenterposition", "#betchipsaddtobet"], { clearProps: "all" });

        setTimeout(() => {
          // if (this.state.mutesounds) {
          //   this.playerLose.pause();
          // } else {
          //   this.playerLose.play();
          // }
          this.playRemainingSounds(this.playerLose)
          document.getElementById("buttons_cover").classList.remove("buttons_cover")

          document.getElementById("betpannelrussiancover").style.display = "none";
          document.getElementById("betpannelrussiancover").classList.remove("bet_pannelcoverrussian");

          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnwindows)

          foldbutton.src = Foldbnwindows;
          Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)

          document.getElementById("foldtext").textContent = "";

          document.getElementById("betbtntext").textContent = this.props.language.Rebet;
          document.getElementById("betbtntext").classList.add("betwindows");
          document.getElementById("foldtext").classList.add("rebettextwindows");


          document.getElementById("foldtext").textContent = this.props.language.RebetX2;
          this.setState({
            infoText: this.props.language.Place_antebet, bsbtn: true, disBetBtn: 0, totalwinRussian: 0,
            totalbet: 0,
            anteBetAmount: 0,
            bonusbetamount: 0,
            insurencetbet: 0,
            updateBet: 0,
            disablemultibutton: false

          });
          document.getElementById("betcircle").classList.remove("nonpointer")

          document.getElementById("bonusRussian").classList.remove("addpointer");
          document.getElementById("bonusRussian").classList.add("drwnodrop");
          document.getElementById("rankinginfodeler").classList.remove("windowspokerrankingdeler");
          document.getElementById("rankinginfo").className = "";
          document.getElementById("rankinginfodeler").textContent = "";
          document.getElementById("rankinginfo").textContent = "";
          this.cardsRef.current.ReBet();
          this.dealerCardsContainer.current.ReBet();
          document.getElementById("crdscover").classList.remove("crdscover")

        }, 3500);


        // gsap.to("#betchipsaddtconfrm", { zIndex: -2 })
        // gsap.to("#betchipsaddtodraw", { zIndex: -2 })
        gsap.to("#betTextaddtodraw", { zIndex: -2 })

        gsap.to("#betchipsaddtobet", { zIndex: -2 })

        bnsactived.src = "";
        document.getElementById("bonustext").style.color = "";
        bnsactived.classList.remove("bnsctivewindows");
        bnsactived.classList.remove("bnsblink");

        break;
      case "Player":
        this.setState({ isplayerinfoupdate: false })
        if (res.insuranceWinAmount > 0) {
          const antetext = document.querySelectorAll("#chiptoinsurence span");
          antetext.forEach(el => el.textContent = res.insuranceWinAmount || "");



        }


        if (res.insuranceWinAmount > 0) {

          var tl = gsap.timeline();

          tl.to("#chiptoinsurence", {
            x: 312,
            y: 343,
            duration: 1.5,
            ease: "power2.inOut",
            onComplete: () => {
              setTimeout(() => {
                const antetext = document.querySelectorAll("#chiptoinsurence span");
                antetext.forEach(el => el.textContent = Number((res.insuranceWinAmount + res.anteWinAmount + res.winAmount).toFixed(2)));

              }, (1000));

            }


          });


          tl.to("#chiptoinsurence", {
            x: 312,
            y: 343,
            duration: 2,
            onComplete: () => {

              gsap.set("#chiptoinsurence", { opacity: 0 });


            }
          });


          tl.to("#chiptoinsurence", {
            x: 956,
            y: 692,
            duration: 0.1,
            onComplete: () => {
              gsap.set("#chiptoinsurence", { opacity: 0 });
            }
          });


        }

        else {

          var tl = gsap.timeline();
          tl.to("#chiptoinsurence", {
            x: 0,
            y: -414,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 12,
            onComplete: () => {
              gsap.set("#chiptoinsurence", { opacity: 0 })

            }
          });
          tl.to("#chiptoinsurence", {
            opacity: 0,
            x: 956,
            y: 692,
            duration: 0.1,
          });
          // tl.reverse();
          // tl.to("#chiptoinsurence", { opacity: 0, zIndex: -2 });
          tl.play();



        }



        document.getElementById("betpannelinsurececover").style.display = "none";
        document.getElementById("betpannelrussiancover").classList.remove("bet_pannelinsurence");

        // alert(".....12")
        gsap.to("#betTextaddtoinsurence", { zIndex: -2 })
        gsap.to("#betTextaddtodraw", { opacity: 0, zIndex: -20 })

        hands.forEach(hand => {
          document.getElementById(hand).style.color = "";
        });


        this.Dealerbetstoplayer(res)
        var tl = gsap.timeline();
        // tl.to("#chiptoinsurence", {
        //   x: -3,
        //   y: -410,
        //   duration: 0.5,
        //   ease: "power2.inOut",
        //   zIndex: 12,
        //   onComplete: () => {
        //     gsap.set("#chiptoinsurence", { opacity: 0 })
        //   }
        // });
        // tl.to("#chiptoinsurence", {
        //   opacity: 0,
        //   x: 990,
        //   y: 696,
        //   duration: 0.1,
        // });
        // // tl.reverse();
        // tl.to("#chiptoinsurence", { opacity: 0, zIndex: -2 });



        // document.getElementById("foldtext").textContent = "";

        document.getElementById("betdecrement").classList.remove("drwnodrop")
        document.getElementById("betincrenet").classList.remove("drwnodrop")
        document.getElementById("foldtext").innerHTML = "";
        document.getElementById("foldtext").classList.remove("insurence");
        document.getElementById("foldtext").classList.add("rebettextwindows");

        this.setState(prevState => ({
          previousAnteBetAmount: prevState.anteBetAmount, previousBonusbetamount: prevState.bonusbetamount

        }))

        //  gsap.to("#chip10centerbns",{x:-950,y:338,zIndex:-2})
        gsap.from("#betTextaddtoinsurence", { zIndex: -2 })
        // gsap.to("#chip10centerbns",{x:-126,y:269,zIndex:2,duration:1})
        // gsap.from("#chip10centerbns",{x:1046,y:276,zIndex:-2,duration:1,delay:5})
        // gsap.from("#betchipsaddtodraw",{x:-949,y:-337})
        // gsap.from("#betchipsaddtodraw",{x:44,y:-48,delay:4,opacity:0})
        // gsap.from("#betchipsaddtobet",{zIndex:-2})
        // gsap.from("#betTextaddtodraw", { opacity: 0, zIndex: -2 })
        //  gsap.to("#betTextaddtoBet", { zIndex: -20 })


        let finalwinamount = this.topLable_rsp(res.totalWinAmount)

        this.setState({
          betbutton: "REBET", buttontext: "REBETX2",
          keyboardhandle: true,
          // totalwinRussian: res.totalWinAmount,
          betchipincrementbtn: false, betdecreamentbtn: false,
          betBtnValue: 2
        })
        // a

        // setTimeout(()=>{
        //   this.setState({infoText:`CONGRATULATIONS! You won ${finalwinamount}`,})

        //  },2500)
        document.getElementById("betactive").src = "";
        document.getElementById("bettext").style.color = "";
        document.getElementById("betactive").classList.remove("betactive");

        // ............................commmented on 24-07-24 ......................../
        // if(document.getElementById("chipcenterposition")){
        //   gsap.to("#chipcenterposition", { x: 0, y: 264, duration: 0.5 });

        let ttop = gsap.timeline();


        ttop.to("#chipcenterposition", {
          x: 308,
          y: 206,
          duration: 1.5,
          zIndex: 12,
          ease: "power2.inOut",
          opacity: 1,

          onComplete: () => {
            gsap.set("#chipcenterposition", { delay: 2, opacity: 0 });
          }
        });


        // const disableChip = () => {

        //   let ttop = gsap.timeline();

        const antetext = document.querySelectorAll("#chipcenterposition span");
        antetext.forEach(el => el.textContent = res.anteWinAmount || "");

        setTimeout(() => {

          // if (this.state.mutesounds) {
          //   this.playerWin = new Audio(playerWin);
          //   this.playerWin.pause();

          // }
          // else {
          //   this.playerWin = new Audio(playerWin);
          //   this.playerWin.play();

          // }
          this.playRemainingSounds(this.playerWin)

          if (document.getElementById("winpopup_rsp")) {
            document.getElementById("winpopup_rsp").classList.add("winpopup_russian")

            document.getElementById("winamt_rsp").classList.add("winamt_russian")


          }


          this.setState({ infoText: res.message, totalwinRussian: res.totalWinAmount, })
          // this.updateplayerbalnace();


        }, 3000)

        let t7 = gsap.timeline();
        t7.to("#betchipsaddtodraw", {
          x: -1083,
          y: -1265, duration: .5, opacity: 1,
          onComplete: () => {
            gsap.set("#betchipsaddtodraw", { opacity: 0 });
          }
        });
        t7.to("#betchipsaddtodraw", {
          x: 47, y: -52, duration: 0.5, zIndex: -2, opacity: 0,
          onComplete: () => {
            gsap.set("#betchipsaddtodraw", { opacity: 1, delay: 2 });
          }

        });
        t7.play();
        let t8 = gsap.timeline();
        t8.to("#betchipsaddtconfrm", {
          x: -1083,
          y: -1265, duration: .5, opacity: 1,
          onComplete: () => {
            gsap.set("#betchipsaddtconfrm", { opacity: 0 });
          }
        });
        t8.to("#betchipsaddtconfrm", {
          x: 47, y: -52, duration: 0.5, zIndex: -2, opacity: 0,
          onComplete: () => {
            gsap.set("#betchipsaddtconfrm", { opacity: 1, delay: 2 });
          }

        });
        t8.play();

        let t9 = gsap.timeline();
        t9.to("#betchipsaddtobet", {
          x: -657,
          y: -431, duration: 0.5, opacity: 1, delay: 1.5,
          onComplete: () => {
            gsap.set("#betchipsaddtobet", { opacity: 0 });
          }
        });
        t9.to("#betchipsaddtobet", {
          x: 26, y: -66, duration: 0.1,
          onComplete: () => {
            gsap.set("#betchipsaddtobet", { opacity: 0 });

          }

        });
        t9.play()

        let bettext = gsap.timeline();

        bettext.to("#betTextaddtoBet", {
          x: 307,
          y: 20, duration: 0.5, delay: 1.5, opacity: 1,


          onComplete: () => {
            gsap.set("#betTextaddtoBet", { opacity: 0 });

            const antetext = document.querySelectorAll("#chipcenterposition span");
            antetext.forEach(el => el.textContent = Number((res.anteWinAmount + res.winAmount).toFixed(2)));

          }




        });
        // bettext.to("#betTextaddtoBet", {
        //   x: 0,
        //   y: -2, duration: 0.1, delay: 3, opacity: 0,

        // });
        gsap.to("#betchipsaddtodrawText", { opacity: 0, zIndex: -2 })

        // const bettext_windows = document.querySelectorAll("#betTextaddtoBet");
        // console.log(bettext_windows)

        // let p=bettext_windows.forEach(ele=>ele.textContent=res.winAmount || "")
        // console.log(p)
        setTimeout(() => {
          const bettext_windows = document.querySelectorAll("#betTextaddtoBet");
          console.log(bettext_windows);

          bettext_windows.forEach(ele => {
            ele.textContent = res.winAmount || "";
            console.log(ele.textContent);
          });

        }, 500)

        setTimeout(() => {
          this.updateplayerbalnace();
          this.updateplayerbalnaceIfplayerWins();



        }, 5000)
        // setTimeout(() => {


        //   document.getElementById("buttons_cover").classList.remove("buttons_cover")
        //   document.getElementById("betpannelrussiancover").style.display = "none";
        //   document.getElementById("betpannelrussiancover").classList.remove("bet_pannelcoverrussian");

        //   betbutton.src = Betbtn;
        //   Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
        //   document.getElementById("betbtntext").textContent = this.props.language.Rebet;

        //   document.getElementById("betbtntext").classList.add("betwindows");

        //   foldbutton.src = Foldbnwindows;
        //   Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)

        //   document.getElementById("foldtext").textContent = this.props.language.RebetX2;
        //   document.getElementById("foldtext").classList.add("rebettextwindows");

        //   // gsap.to("#betchipsaddtobet", { x: 27, y: 0, duration: 0 });

        //   // document.getElementById("glowonwinchip").classList.remove("chipglow_russian")
        //   gsap.to("#betTextaddtoBet", { zIndex: -20 })


        //   this.setState({
        //     infoText: this.props.language.Place_antebet,

        //     totalbet: 0,
        //     anteBetAmount: 0,
        //     bonusbetamount: 0,
        //     insurencetbet: 0,
        //     disablemultibutton: false,

        //     disBetBtn: 0, bsbtn: true, totalwinRussian: 0, updateBet: 0, bonusBetAmount: 0
        //   });

        //   // gsap.to("#chipcenterposition", {
        //   //    x: 955, y: 290, duration: 0.1, opacity: 0,

        //   // });
        //   document.getElementById("rankinginfodeler").classList.remove("windowspokerrankingdeler");


        //   document.getElementById("betcircle").classList.remove("nonpointer")

        //   // gsap.to("#betchipsaddtobet", { zIndex: -2 })
        //   // gsap.to("#betchipsaddtconfrm", { zIndex: -2 })
        //   // gsap.to("#betchipsaddtodraw", { zIndex: -2 })
        //   // gsap.to("#chipcenterposition", { zIndex: -2 })

        //   gsap.to("#betchipsaddtodrawText", { zIndex: -2, duration: 0.1 })

        //   document.getElementById("rankinginfo").className = "";
        //   document.getElementById("rankinginfodeler").textContent = "";
        //   document.getElementById("rankinginfo").textContent = "";
        //   this.cardsRef.current.ReBet();
        //   this.dealerCardsContainer.current.ReBet();

        //   document.getElementById("crdscover").classList.remove("crdscover")
        //   if (document.getElementById("winpopup_rsp")) {
        //     document.getElementById("winpopup_rsp").classList.remove("winpopup_russian")
        //     document.getElementById("winamt_rsp").classList.remove("winamt_russian")
        //   }
        //  }, 8000);



        gsap.set(["#chipcenterposition"], { clearProps: "all" });
        bnsactived.src = "";
        document.getElementById("bonustext").style.color = "";
        bnsactived.classList.remove("bnsctivewindows");
        bnsactived.classList.remove("bnsblink");

        break;

      case "Dealer_no_hand":

        // gsap.from("#betchipsaddtobet", { zIndex: -2 })
        //gsap.from("#betTextaddtodraw",{zIndex:-2})
        gsap.from("#betTextaddtoinsurence", { zIndex: -2 })
        document.getElementById("betbtntext").classList.remove("noinsurence");
        document.getElementById("betactive").src = "";
        document.getElementById("bettext").style.color = "";
        this.clertime55 = setTimeout(() => {
          this.setState({
            betbutton: "YES", infoText: res.message, buttontext: "NO", Yesbtn: 0, Nobtn: 0, keyboardhandle: true,
            disablemultibutton: false, disClearBtn: 1, disBuybtn: 1, disDrawbtn: 1, Foldbtn: 1


          });
          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
          document.getElementById("betbtntext").textContent = this.props.language.Yes;

          foldbutton.src = Foldbnwindows;
          Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
          document.getElementById("foldtext").textContent = this.props.language.No;

          document.getElementById("betbtntext").classList.remove("insurence");
          document.getElementById("foldtext").classList.remove("noinsurence");
          document.getElementById("betbtntext").classList.add("betwindows");
          document.getElementById("foldtext").classList.add("foldtextwindows");

        }, 500);

      default:
        break;
    }

  }
  UpdateplayerinfoPlayerWins() {
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    const betbtntextElement = document.getElementById("betbtntext");
    const foldtextElement = document.getElementById("foldtext");

    const winpopuprspelement = document.getElementById("winpopup_rsp");
    const winamtElement = document.getElementById("winamt_rsp");


    const anteText = document.querySelectorAll("#chipcenterposition span");
    const betText = document.querySelector("#betTextaddtoBet");
    const insuranceText = document.querySelectorAll("#chiptoinsurence span");

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {
      setTimeout(() => {


        document.getElementById("buttons_cover").classList.remove("buttons_cover")
        document.getElementById("betpannelrussiancover").style.display = "none";
        document.getElementById("betpannelrussiancover").classList.remove("bet_pannelcoverrussian");

        betbutton.src = Betbtn;
        Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
        document.getElementById("betbtntext").textContent = this.props.language.Rebet;

        document.getElementById("betbtntext").classList.add("betwindows");

        foldbutton.src = Foldbnwindows;
        Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)

        document.getElementById("foldtext").textContent = this.props.language.RebetX2;
        document.getElementById("foldtext").classList.add("rebettextwindows");

        // gsap.to("#betchipsaddtobet", { x: 27, y: 0, duration: 0 });

        // document.getElementById("glowonwinchip").classList.remove("chipglow_russian")
        gsap.to("#betTextaddtoBet", { zIndex: -20 })


        this.setState({
          infoText: this.props.language.Place_antebet,

          totalbet: 0,
          anteBetAmount: 0,
          bonusbetamount: 0,
          insurencetbet: 0,
          disablemultibutton: false,

          disBetBtn: 0, bsbtn: true, totalwinRussian: 0, updateBet: 0, bonusBetAmount: 0
        });

        // gsap.to("#chipcenterposition", {
        //    x: 955, y: 290, duration: 0.1, opacity: 0,

        // });
        document.getElementById("rankinginfodeler").classList.remove("windowspokerrankingdeler");


        document.getElementById("betcircle").classList.remove("nonpointer")

        // gsap.to("#betchipsaddtobet", { zIndex: -2 })
        // gsap.to("#betchipsaddtconfrm", { zIndex: -2 })
        // gsap.to("#betchipsaddtodraw", { zIndex: -2 })
        // gsap.to("#chipcenterposition", { zIndex: -2 })

        gsap.to("#betchipsaddtodrawText", { zIndex: -2, duration: 0.1 })

        document.getElementById("rankinginfo").className = "";
        document.getElementById("rankinginfodeler").textContent = "";
        document.getElementById("rankinginfo").textContent = "";
        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();

        document.getElementById("crdscover").classList.remove("crdscover")
        if (document.getElementById("winpopup_rsp")) {
          document.getElementById("winpopup_rsp").classList.remove("winpopup_russian")
          document.getElementById("winamt_rsp").classList.remove("winamt_russian")
        }
      }, 1000);





    } else {


      const delaerChipAnimDiv = document.getElementById('delaerchipanim')
      const dealerchipElement = document.getElementById('dealerchip');
      if (window.innerHeight > window.innerWidth) {
        this.clertime54 = setTimeout(() => {
          document.getElementById("mobileAntiMaskCover").style.display = "none";
          gsap.fromTo(winpopuprspelement, { scale: 1, duration: 0.5, rotate: 360 }, {
            autoAlpha: 0, scale: 0, duration: 0.5, onComplete: () => {
              winpopuprspelement.classList.remove("winPopupForMptSw");
              winamtElement.classList.remove("winamtMpSw");
            }
          })
          // this.updateplayerbalnace();
          this.setState({
            totalwinRussian: 0,
            totalbet: 0,
            infoText: this.props.language.Play_Njoy,
            storeantebetchips: [],
            storeinsurancechips: [],
          });

          betbutton.src = Betbtn;
          // Object.assign(betbutton.style, porttraitejson.Betbtnportraite);
          Object.assign(betbutton.style, this.state.betbuttoncss);

          betbtntextElement.classList.remove('noinsurencePortrait', 'betportraite');

          betbtntextElement.textContent = this.props.language.Rebet;


          // betbtntextElement.classList.add("betportrait");
          betbtntextElement.classList.add(`${this.state.betbtntextcss}`);

          // Object.assign(betbtntextElement.style, porttraitejson.betportraite);

          foldbutton.src = Foldbnwindows;
          // Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);
          Object.assign(foldbutton.style, this.state.foldbuttoncss);

          foldtextElement.classList.remove('insurencePortrait', 'foldtextportraite', 'foldtextportrait');
          foldtextElement.textContent = this.props.language.RebetX2;
          // foldtextElement.classList.add("rebettextportrait");
          foldtextElement.classList.add(`${this.state.foldbtntextcss}`);


          const hands = [
            "onepair", "Threekind", "aceking", "fullhse",
            "Straightflush", "frofkind", "twopair",
            "flush", "stright", "royalflush"
          ];
          hands.forEach(hand => {
            document.getElementById(hand).style.color = "";
          });


          if (this.resetAnimationChips) {
            if (document.getElementById("chipcenterposition")) {
              gsap.to("#chipcenterposition", { x: 13, y: 1200, duration: 0.00001, zIndex: -2, opacity: 0 });
            }

            if (document.getElementById("betchipsaddtobet")) {
              gsap.to("#betchipsaddtobet", { x: 1, y: 76, duration: 0.00001, zIndex: -2, opacity: 0 });
              gsap.to("#betTextaddtoBet", { x: 153, y: 185, duration: 0.00001, zIndex: -2, opacity: 0 });
            }

            // gsap.to("#chiptoinsurence" , {x:550, y: 145, duration:0.5,zIndex:-2,opacity:0});
            if (document.getElementById("chiptoinsurence")) {
              gsap.to("#chiptoinsurence", { x: 15, y: 1376, duration: 0.00001, zIndex: -2, opacity: 0 });
            };

            // gsap.to(this.chipdrawref.current, { x: 548, y: 140, duration: 0.5, delay: 1, zIndex: -5, opacity: 1 });
            gsap.to(this.chipdrawref.current, { x: 548, y: -36, duration: 0.00001, zIndex: -5, opacity: 0 });
            // if (this.playerWinBonus === true) {

            // if (document.getElementById("chip10centerbns")) {
            //   gsap.to("#chip10centerbns", { x: -170, y: 1200, duration: 0.00001, zIndex: -2, opacity: 1 });
            //   this.playerWinBonus = false;

            // }


            // }
            if (this.wanttobuydelercrd === true) {
              gsap.to(this.buyDealerCardDrawn.current, { x: 0, y: 0, duration: 0.00001, zIndex: -2, opacity: 0 });
            }
          }

          // }, 4000)
        }, 1000)



      } else {

        this.clertime54 = setTimeout(() => {

          delaerChipAnimDiv.className = "";
          dealerchipElement.src = "";
          dealerchipElement.className = "";

          document.getElementById("mobileAntiMaskCover").style.display = "none";
          betbutton.src = Betbtn;
          // Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
          Object.assign(betbutton.style, this.state.betbuttoncss);
          betbtntextElement.textContent = this.props.language.Rebet;
          // betbtntextElement.classList.add('betLandscape');
          betbtntextElement.classList.add(`${this.state.betbtntextcss}`);

          foldbutton.src = Foldbnwindows;
          // Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
          Object.assign(foldbutton.style, this.state.foldbuttoncss);
          foldtextElement.textContent = this.props.language.RebetX2;
          // foldtextElement.classList.add("foldtextLandscape");
          foldtextElement.classList.add(`${this.state.foldbtntextcss}`);



          gsap.fromTo(winpopuprspelement, { scale: 1, duration: 0.5, rotate: 360 }, {
            autoAlpha: 0, scale: 0, duration: 0.5, onComplete: () => {
              winpopuprspelement.classList.remove("winPopupForMLsp");
              winamtElement.classList.remove("winamtMpSw");
            }
          })
          this.setState({
            totalbet: 0,
            totalwinRussian: 0,
            infoText: this.props.language.Play_Njoy,
            storeantebetchips: [],
            storeinsurancechips: []
          });

          document.getElementById("onepair").style.color = "";
          document.getElementById("Threekind").style.color = "";
          document.getElementById("aceking").style.color = "";
          document.getElementById("fullhse").style.color = "";
          document.getElementById("Straightflush").style.color = "";
          document.getElementById("frofkind").style.color = "";
          document.getElementById("twopair").style.color = "";
          document.getElementById("flush").style.color = "";
          document.getElementById("stright").style.color = "";
          document.getElementById("royalflush").style.color = "";



          gsap.to("#chipcenterposition", { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
          gsap.to("#betTextaddtoBet", { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
          gsap.to("#betchipsaddtobet", { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
          gsap.to(this.chipdrawref.current, { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
          if (this.insuranceActive) {
            gsap.to("#chiptoinsurence", { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
          };
          if (this.wanttobuydelercrd === true) {
            gsap.to(this.buyDealerCardDrawn.current, { opacity: 0, x: 0, y: 0, zIndex: -2, duration: 0.0001 })

          }
          // gsap.set('#dealerchip', { x: 0, y: 0, opacity: 0, zIndex: -10, duration: 0.00001 })
          // }, 5000)
        }, 1000)




      }
    }




  }


  Showebetchipsgamestatehandle = (res) => {


    let betTextaddtoBetElement = document.getElementById("betTextaddtoBet");
    let betchipsaddtobetwindows = document.getElementById("betchipsaddtobet");
    betchipsaddtobetwindows.src = chip_10;
    // alert("............"+betchipsaddtobetwindows)
    betTextaddtoBetElement.textContent = this.topLable_rsp(res.betAmount);
    betTextaddtoBetElement.classList.add("bet-text-addtoBet")
    gsap.to(this.chipbetref.current, {
      x: -965,
      y: -484,
      duration: 0.1,
      ease: "power2.inOut",
      zIndex: 2,
      opacity: 1
    });
    // document.getElementById("rankinginfodeler").classList.add("windowspokerrankingdeler");
    // document.getElementById("rankinginfo").classList.add("windowspokerranking");
    Object.assign(betchipsaddtobetwindows.style, porttraitejson.Betchiptobetwindows)

    this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand, pokerranking: res.playerHand })
  }


  showcheckpayerordealerwins(res) {
    this.removehigltbonusclasses(res);
    this.clearallsettimeouts()
    document.getElementById("insurenceactive").src = "";
    document.getElementById("insurenceactive").classList.remove("insurenceactivewindows");
    document.getElementById("insurenceactive").classList.remove("insurenceblink");
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");


    // this.clertime37=setTimeout(() => {
    this.setState((prevState) => ({
      previousAnteBetAmount: prevState.anteBetAmount,
      previousBonusbetamount: prevState.bonusbetamount,
      totalbet: 0,
      // anteBetAmount: 0,
      // bonusbetamount: 0,
      insurencetbet: 0,

      // betdeductions: 0
    }));


    const hands = [
      "onepair", "Threekind", "aceking", "fullhse",
      "Straightflush", "frofkind", "twopair",
      "flush", "stright", "royalflush"
    ];

    switch (res.winner) {
      case "Dealer":
        hands.forEach(hand => {
          document.getElementById(hand).style.color = "";
        });

        this.updateplayerbalnace();

        document.getElementById("foldtext").classList.remove("insurence");
        document.getElementById("betdecrement").classList.remove("drwnodrop")
        document.getElementById("betincrenet").classList.remove("drwnodrop")
        document.getElementById("betpannelrussiancover").style.display = "none";
        document.getElementById("betpannelrussiancover").classList.remove("bet_pannelcoverrussian");

        this.setState({ betchipincrementbtn: false, betdecreamentbtn: false })
        // this.playPokerChipSound(this.pokerchipsnd);

        // gsap.from("#chip10centerbns",{x:1046,y:276,zIndex:-2,duration:1})
        document.getElementById("betactive").src = "";
        document.getElementById("bettext").style.color = "";
        document.getElementById("betactive").classList.remove("betactive");
        this.setState({
          betbutton: "REBET", previousAnteBetAmount: res.anteBetAmount,
          anteBetAmount: 0,
          bonusbetamount: 0,
          insurencetbet: 0,

          previousBonusbetamount: res.bonusBetAmount,


          buttontext: "REBETX2", disBetBtn: 0, betBtnValue: 2, keyboardhandle: true, totalWinAmount: 0
        });


        gsap.from("#betTextaddtoinsurence", { zIndex: -2 })

        // setTimeout(() => {
        betbutton.src = Betbtn;
        Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
        document.getElementById("betbtntext").textContent = this.props.language.Rebet;
        document.getElementById("betbtntext").classList.add("betwindows_rebet");




        foldbutton.src = Foldbnwindows;
        Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
        document.getElementById("foldtext").textContent = "";
        document.getElementById("foldtext").textContent = this.props.language.RebetX2;
        document.getElementById("foldtext").classList.add("rebettextwindows");
        // }, 2000);
        this.setState({ infoText: this.props.language.Place_antebet, bsbtn: true, disBetBtn: 0, totalwinRussian: 0, updateBet: 0, bonusBetAmount: 0 });

        document.getElementById("bonusRussian").classList.remove("addpointer");
        document.getElementById("bonusRussian").classList.add("drwnodrop");
        document.getElementById("rankinginfodeler").classList.remove("windowspokerrankingdeler");
        document.getElementById("rankinginfo").className = "";
        document.getElementById("rankinginfodeler").textContent = "";
        document.getElementById("rankinginfo").textContent = "";
        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();



        gsap.to("#betchipsaddtodrawText", { opacity: 0, zIndex: -2 })
        gsap.to("#betchipsaddtconfrm", { zIndex: -2 })
        gsap.to("#betchipsaddtodraw", { zIndex: -2 })
        gsap.to("#betTextaddtodraw", { zIndex: -2 })

        gsap.to("#betchipsaddtobet", { zIndex: -2 })

        // this.showcardsAdBetsParamState(res)

        break;

      //  case `CONGRATULATIONS! You won FUN ${this.state.totalwinRussian}`:
      case "Player":



        this.setState({
          infoText: this.props.language.Place_antebet,
          anteBetAmount: 0,
          bonusbetamount: 0,
          disBetBtn: 0,
          bsbtn: true,
          totalwinRussian: 0,
          updateBet: 0,
          bonusBetAmount: 0
        });

        gsap.to("#betTextaddtoinsurence", { zIndex: -2 })
        gsap.to("#betTextaddtodraw", { opacity: 0, zIndex: -20 })
        gsap.to("#betTextaddtoBet", { zIndex: -20 })
        hands.forEach(hand => {
          document.getElementById(hand).style.color = "";
        });


        this.updateplayerbalnace();
        // this.Dealerbetstoplayer()



        document.getElementById("foldtext").textContent = "";

        document.getElementById("betdecrement").classList.remove("drwnodrop")
        document.getElementById("betincrenet").classList.remove("drwnodrop")

        document.getElementById("foldtext").classList.remove("insurence");
        document.getElementById("foldtext").classList.add("rebettextwindows");

        document.getElementById("betpannelrussiancover").style.display = "none";
        document.getElementById("betpannelrussiancover").classList.remove("bet_pannelcoverrussian");


        this.setState(prevState => ({
          previousAnteBetAmount: prevState.anteBetAmount, previousBonusbetamount: prevState.bonusbetamount

        }))

        //  gsap.to("#chip10centerbns",{x:-950,y:338,zIndex:-2})
        gsap.from("#betTextaddtoinsurence", { zIndex: -2 })
        // gsap.to("#chip10centerbns",{x:-126,y:269,zIndex:2,duration:1})
        // gsap.from("#chip10centerbns",{x:1046,y:276,zIndex:-2,duration:1,delay:5})
        // gsap.from("#betchipsaddtodraw",{x:-949,y:-337})
        // gsap.from("#betchipsaddtodraw",{x:44,y:-48,delay:4,opacity:0})
        // gsap.from("#betchipsaddtobet",{zIndex:-2})
        // gsap.from("#betTextaddtodraw", { opacity: 0, zIndex: -2 })
        gsap.to("#betTextaddtoBet", { zIndex: -20 })
        this.setState({
          betbutton: "REBET", buttontext: "REBETX2",
          keyboardhandle: true,
          betchipincrementbtn: false, betdecreamentbtn: false,
          previousAnteBetAmount: res.anteBetAmount, previousBonusbetamount: res.bonusBetAmount,

          betBtnValue: 2
        })
        // alert("..........congratss")
        document.getElementById("betactive").src = "";
        document.getElementById("bettext").style.color = "";
        document.getElementById("betactive").classList.remove("betactive");

        // ............................commmented on 24-07-24 ......................../
        // if(document.getElementById("chipcenterposition")){
        //   gsap.to("#chipcenterposition", { x: 0, y: 264, duration: 0.5 });

        // }



        if (document.getElementById("winpopup_rsp")) {
          document.getElementById("winpopup_rsp").classList.add("winpopup_russian")
          document.getElementById("winamt_rsp").classList.add("winamt_russian")

        }
        // document.getElementById("glowonwinchip").classList.add("chipglow_russian")


        //  setTimeout(() => {
        betbutton.src = Betbtn;
        Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
        document.getElementById("betbtntext").textContent = this.props.language.Rebet;
        document.getElementById("betbtntext").classList.add("betwindows_rebet");



        foldbutton.src = Foldbnwindows;
        Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)

        document.getElementById("foldtext").textContent = this.props.language.RebetX2;

        document.getElementById("foldtext").classList.add("rebettextwindows");
        // }, 2000);

        // gsap.to("#betchipsaddtobet", { x: 27, y: 0, duration: 0 });
        if (document.getElementById("winpopup_rsp")) {
          document.getElementById("winpopup_rsp").classList.remove("winpopup_russian")
          document.getElementById("winamt_rsp").classList.remove("winamt_russian")
        }
        // document.getElementById("glowonwinchip").classList.remove("chipglow_russian")


        // gsap.to("#chipcenterposition", {
        //    x: 955, y: 290, duration: 0.1, opacity: 0,

        // });


        gsap.to("#betchipsaddtobet", { zIndex: -2 })
        gsap.to("#betchipsaddtodrawText", { opacity: 0, zIndex: -2 })
        gsap.to("#betchipsaddtconfrm", { zIndex: -2 })
        gsap.to("#betchipsaddtodraw", { zIndex: -2 })
        gsap.to("#chipcenterposition", { zIndex: -2, opacity: 0 })
        document.getElementById("rankinginfodeler").classList.remove("windowspokerrankingdeler");
        document.getElementById("rankinginfo").className = "";
        document.getElementById("rankinginfodeler").textContent = "";
        document.getElementById("rankinginfo").textContent = "";
        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();



        document.getElementById("rankinginfodeler").classList.remove("windowspokerrankingdeler");

        // this.showcardsAdBetsParamState(res)

        break;
      // case "Dealer doesn't have a hand. Would you like to replace the dealer's higher card for the cost equivalent of ANTE? Press either NO or YES.":
      // alert(".............pppppyada")
      case "Dealer_no_hand":
        // this.revealDealercards(res)
        this.dealerShuffledCards = [...res.dealerFinalCards]
        this.setState({
          anteBetAmount: res.anteBetAmount,
          totalbet: res.totalBetAmount,
          infoText: res.message,
          pokerranking: res.playerHand,
          pokerrankingdeler: res.dealerHand



        })
        document.getElementById("rankinginfodeler").classList.add("windowspokerrankingdeler");
        document.getElementById("rankinginfo").classList.add("windowspokerranking");
        this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerFinalCards);

        if (res.insurance) {
          if ((res.insuranceBetAmount) > 0) {
            let insurencestatebetamount = this.topLable_rsp(res.insuranceBetAmount);
            this.setState((prevState) => ({
              storebetchips: [
                ...prevState.storebetchips,
                <div id="chiptoinsurence">

                  <img
                    className="chiptoinsurence_windows"
                    src={this.ReturnChipBasedAmount(res.insuranceBetAmount)}
                    alt="Chip 10"
                  />
                  <span className="insuranceTextwndwsChip10"> {insurencestatebetamount} </span>
                </div>
              ],
            }));


          }
        }
        if (res.betAmount > 0) {

          this.Showebetchipsgamestatehandle(res)
          //  let betTextaddtoBetClass = "bet-text-addtoBet10";
          // Object.assign(betchipsaddtobetwindows.style,porttraitejson.Betchiptobetwindows)
        }
        // gsap.from("#betchipsaddtobet", { zIndex: -2 })
        //gsap.from("#betTextaddtodraw",{zIndex:-2})
        gsap.from("#betTextaddtoinsurence", { zIndex: -2 })
        document.getElementById("betbtntext").classList.remove("noinsurence");
        document.getElementById("betactive").src = "";
        document.getElementById("betactive").classList.remove("betactive");
        document.getElementById("bettext").style.color = "";
        // this.clertime55=setTimeout(() => {
        this.setState({
          betbutton: "YES", buttontext: "NO", Yesbtn: 0, Nobtn: 0, keyboardhandle: true,

          disablemultibutton: false
        });
        betbutton.src = Betbtn;
        Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
        document.getElementById("betbtntext").textContent = this.props.language.Yes;

        foldbutton.src = Foldbnwindows;
        Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
        document.getElementById("foldtext").textContent = this.props.language.No;

        document.getElementById("betbtntext").classList.remove("insurence");
        document.getElementById("foldtext").classList.remove("noinsurence");
        document.getElementById("betbtntext").classList.add("betwindows");
        document.getElementById("foldtext").classList.add("foldtextwindows");

      // }, 500);

      default:
        break;
    }
    // }, 3500)

  }
  buydealercardResponse(res) {
    this.setState({ cmfrmdealeraction: 1, cmfrmdealeractionNO: 1 })
    // this.setState({ totalwinRussian: res.totalWinAmount,cmfrmdealeraction: 1, cmfrmdealeractionNO: 1})


    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {



      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");
      betbutton.src = "";
      document.getElementById("betbtntext").classList.remove("betwindows");
      betbutton.style.position = "";
      betbutton.style.width = "";
      betbutton.style.transform = "";

      document.getElementById("betbtntext").textContent = "";


      foldbutton.src = "";
      document.getElementById("foldtext").classList.remove("foldtextwindows");
      foldbutton.style.position = "";
      foldbutton.style.width = "";
      foldbutton.style.transform = "";
      document.getElementById("confirmbuydealer_popup").style.display = "none";
      document.getElementById("confirmbuydealer_popup").classList.remove("popup_cnfrmbuydealrcard");
      document.getElementById("yes").classList.remove("yesbutton_popup")
      document.getElementById("no").classList.remove("nobutton_popup")


      if (res.message == `Attention! You have a ${res.playerHand}. Are you sure you want to buy a dealer card?`) {
        //   document.getElementById("russiangamecover").style.display = "block";
        //  document.getElementById("russiangamecover").classList.add("gamecover_enbledisable")

        this.setState({
          multibuttonsebledsble: true, disablemultibutton: true,

          Yesbtn: 1, Nobtn: 1
        })
        // alert(".......popupdealerconfirmation")

        document.getElementById("betbtn").classList.remove("addpointer")
        document.getElementById("Foldbtn").classList.remove("addpointer")
        document.getElementById("betbtn").classList.add("drwnodrop")
        document.getElementById("Foldbtn").classList.add("drwnodrop")
        // document.getElementById("crdscover").style.display="block"
        document.getElementById("crdscover").classList.add("crdscover")
        document.getElementById("playerDealerCardContainer").classList.remove("addpointer")

        document.getElementById("confirmbuydealer_popup").style.display = "block";
        document.getElementById("confirmbuydealer_popup").classList.add("popup_cnfrmbuydealrcard");
        document.getElementById("alertTxt").classList.add("alertTxt");

        document.getElementById("alertTxt").innerHTML = res.message;
        document.getElementById("yes").classList.add("yesbutton_popup")
        document.getElementById("no").classList.add("nobutton_popup")
        // this.setState({ infoText: res.message })
        this.setState({ infoText: "" })
      }
      else {

        if (res.dealerNewCardToReplace) {
          setTimeout(() => {
            this.checkpayerordealerwins(res);

          }, 3500)


        }


        else {

          this.checkpayerordealerwins(res)
        }




      }
      // else {
      //   const betbutton = document.getElementById("betbtn");
      //   const foldbutton = document.getElementById("Foldbtn");
      //   if (
      //     navigator.userAgentData &&
      //     navigator.userAgentData.platform &&
      //     navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
      //   ) {
      //     this.setState({ betbutton: "REBET", buttontext: "REBETX2",disBetBtn:0,betBtnValue:2,keyboardhandle:true })
      //     document.getElementById("drawtext").textContent = "";
      //     document.getElementById("buytext").textContent = "";
      //     betbutton.src = "";
      //     betbutton.style.cssText=""
      //     document.getElementById("betbtntext").classList.remove("betwindows");


      //     foldbutton.src = "";
      //     foldbutton.style.cssText=""
      //     document.getElementById("foldtext").classList.remove("foldtextwindows");

      //     document.getElementById("confirmbuydealer_popup").classList.remove("popup_cnfrmbuydealrcard");
      //     document.getElementById("alertTxt").classList.remove("alertTxt");
      //     document.getElementById("alertTxt").innerHTML ="";
      //     document.getElementById("yes").classList.remove("yesbutton_popup")
      //     document.getElementById("no").classList.remove("nobutton_popup")
      //     this.setState({ infoText: res.message })

      //     betbutton.src = Betbtn;
      //     Object.assign(betbutton.style,porttraitejson.Betbtnwindows)
      //     document.getElementById("betbtntext").textContent = this.props.language.Rebet;

      //     foldbutton.src = Foldbnwindows;
      //     Object.assign(foldbutton.style,porttraitejson.foldbtnwindows)
      //     document.getElementById("foldtext").textContent = this.props.language.RebetX2;

      //   }

      // }

    } else {
      this.setState({ bsbtn: true })
      const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popup");
      document.getElementById("playerDealerCardContainer").style.zIndex = 1;

      // foldtext betbtntext

      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");
      const drawtextElement = document.getElementById("drawtext");
      const buytextElement = document.getElementById("buytext");
      const foldtextElement = document.getElementById("foldtext");
      const betbtntextElement = document.getElementById("betbtntext");
      betbutton.src = "";
      foldbutton.src = "";
      betbutton.style.cssText = "";
      foldbutton.style.cssText = "";
      drawtextElement.textContent = "";
      buytextElement.textContent = "";
      foldtextElement.textContent = "";
      betbtntextElement.textContent = "";

      if (window.innerHeight > window.innerWidth) {



        if (res.message == `Attention! You have a ${res.playerHand}. Are you sure you want to buy a dealer card?`) {
          // console.log("Attention! You have a Three of a Kind. Are you sure you want to buy a dealer card?")
          //   document.getElementById("russiangamecover").style.display = "block";
          //  document.getElementById("russiangamecover").classList.add("gamecover_enbledisable")

          this.setState({ multibuttonsebledsble: true, disablemultibutton: true })
          // alert(".......popupdealerconfirmation")
          confirmbuydealerPopupElement.className = "";
          confirmbuydealerPopupElement.style.display = "block";
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardPortrait");
          document.getElementById("alertTxt").innerHTML = res.message;
          document.getElementById("alertTxt").classList.add('alertTxtPortrait');
          document.getElementById("yes").classList.add("yesbutton_popup_portrait")
          document.getElementById("no").classList.add("nobutton_popup_portrait")
          this.setState({
            // infoText: res.message
            infoText: ""
          })
        }
        else {

          // this.setState({ betbutton: "REBET", buttontext: "REBETX2" })
          // drawtextElement.textContent = "";
          // buytextElement.textContent = "";

          // betbutton.style.cssText=""
          // document.getElementById("betbtntext").classList.remove("betportrait");

          // foldbutton.src = "";
          // foldbutton.style.cssText=""
          // document.getElementById("foldtext").classList.remove("foldtextportrait");

          // confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardPortrait");
          // confirmbuydealerPopupElement.style.display = "none";
          // document.getElementById("yes").classList.remove("yesbutton_popup_portrait")
          // document.getElementById("no").classList.remove("nobutton_popup_portrait")
          // this.setState({ infoText: res.message })

          // betbutton.src = Betbtn;
          // document.getElementById("betbtntext").textContent = this.props.language.Rebet;
          // Object.assign(betbutton.style, porttraitejson.Betbtnportraite);


          // foldbutton.src = Foldbn;
          // document.getElementById("foldtext").textContent = this.props.language.RebetX2;
          // Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);

          switch (res.winner) {
            case "Dealer":
              this.ifDealerWinsMobilePortraitAction(res);
              break;

            // case `CONGRATULATIONS! You won FUN ${this.state.totalwinRussian}`:
            // case `CONGRATULATIONS! You won FUN ${res.totalWinAmount}`:
            case "Player":
              this.ifPlayerWinsMobilePortraitAction(res);
              break;

            case "Dealer_no_hand":
              // case "Dealer doesn't have a hand. Would you like to replace the dealer's higher card for the cost equivalent of ANTE? Press either NO or YES.":
              // alert(".............pppppyada")
              ;
            default:
              break;
          }

        }

      } else {

        this.checkPlayerOrDealerWinMblLndspe(res);
        if (res.message == `Attention! You have a ${res.playerHand}. Are you sure you want to buy a dealer card?`) {
          // console.log("Attention! You have a Three of a Kind. Are you sure you want to buy a dealer card?")
          //   document.getElementById("russiangamecover").style.display = "block";
          //  document.getElementById("russiangamecover").classList.add("gamecover_enbledisable")

          this.setState({ multibuttonsebledsble: true, disablemultibutton: true })
          confirmbuydealerPopupElement.className = "";
          confirmbuydealerPopupElement.style.display = "block";
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardLndsp");
          document.getElementById("alertTxt").innerHTML = res.message;
          document.getElementById("alertTxt").classList.add('alertTxtPortrait');
          document.getElementById("yes").classList.add("yesbutton_popup_portrait")
          document.getElementById("no").classList.add("nobutton_popup_portrait")
          this.setState({
            // infoText: res.message
            infoText: ""
          })
        }
        else {

          this.setState({ betbutton: "REBET", buttontext: "REBETX2" })
          drawtextElement.textContent = "";
          buytextElement.textContent = "";

          betbutton.style.cssText = ""
          betbtntextElement.className = "";

          foldbutton.src = "";
          foldbutton.style.cssText = ""
          foldtextElement.className = "";

          confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardLndsp");
          confirmbuydealerPopupElement.style.display = "none";
          document.getElementById("yes").classList.remove("yesbutton_popup_portrait")
          document.getElementById("no").classList.remove("nobutton_popup_portrait")
          // this.setState({
          //   infoText: res.message

          // })

          // betbutton.src = Betbtn;
          // Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
          // betbtntextElement.textContent = this.props.language.Rebet;
          // betbtntextElement.classList.add('betLandscape');


          // foldbutton.src = Foldbn;
          // Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
          // foldtextElement.textContent = this.props.language.RebetX2;
          // foldtextElement.classList.add("foldtextLandscape");



        }
      }
    }

  };
  removeconfirmdealercard_popup = () => {
    document.getElementById("confirmbuydealer_popup").style.display = "none";
    document.getElementById("confirmbuydealer_popup").classList.remove("popup_cnfrmbuydealrcard");
    document.getElementById("alertTxt").classList.remove("alertTxt");
    document.getElementById("alertTxt").innerHTML = "";
    document.getElementById("yes").classList.remove("yesbutton_popup")
    document.getElementById("no").classList.remove("nobutton_popup")

  }

  ShowbuydealercardResponse(res) {

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      // document.getElementById("rankinginfo").classList.add("windowspokerranking");
      document.getElementById("rankinginfodeler").classList.add("windowspokerrankingdeler");
      this.setState({

        pokerrankingdeler: res.dealerHand,
        // pokerranking:res.playerHand,
        totalwinRussian: res.totalWinAmount
      })



      // this.showcardsAdBetsParamState(res,res.dealerCards,res.playerFinalCards)
      this.showcheckpayerordealerwins(res);

      if (res.message == `Attention! You have a ${res.playerHand}. Are you sure you want to buy a dealer card?`) {
        //   document.getElementById("russiangamecover").style.display = "block";
        //  document.getElementById("russiangamecover").classList.add("gamecover_enbledisable")

        this.setState({ multibuttonsebledsble: true, disablemultibutton: true, Yesbtn: 1, Nobtn: 1 })
        // alert(".......popupdealerconfirmation")

        document.getElementById("betbtn").classList.remove("addpointer")
        document.getElementById("Foldbtn").classList.remove("addpointer")
        document.getElementById("betbtn").classList.add("drwnodrop")
        document.getElementById("Foldbtn").classList.add("drwnodrop")
        // document.getElementById("crdscover").style.display="block"
        document.getElementById("crdscover").classList.add("crdscover")
        document.getElementById("playerDealerCardContainer").classList.remove("addpointer")

        document.getElementById("confirmbuydealer_popup").style.display = "block";
        document.getElementById("confirmbuydealer_popup").classList.add("popup_cnfrmbuydealrcard");
        document.getElementById("alertTxt").classList.add("alertTxt");

        document.getElementById("alertTxt").innerHTML = res.message;
        document.getElementById("yes").classList.add("yesbutton_popup")
        document.getElementById("no").classList.add("nobutton_popup")
        this.setState({
          //  infoText: res.message
          infoText: ""
        })
        if (res.insurance) {
          if ((res.insuranceBetAmount) > 0) {
            let insurencestatebetamount = this.topLable_rsp(res.insuranceBetAmount);
            this.setState((prevState) => ({
              storebetchips: [
                ...prevState.storebetchips,
                <div id="chiptoinsurence">

                  <img
                    className="chiptoinsurence_windows"
                    src={this.ReturnChipBasedAmount(res.insuranceBetAmount)}
                    alt="Chip 10"
                  />
                  <span className="insuranceTextwndwsChip10"> {insurencestatebetamount} </span>
                </div>
              ],
            }));


          }
        }
      }
      else {
        const betbutton = document.getElementById("betbtn");
        const foldbutton = document.getElementById("Foldbtn");
        if (
          navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
        ) {
          this.removeconfirmdealercard_popup();

          this.setState({ betbutton: "REBET", buttontext: "REBETX2", disBetBtn: 0, betBtnValue: 2, keyboardhandle: true })
          document.getElementById("drawtext").textContent = "";
          document.getElementById("buytext").textContent = "";
          betbutton.src = "";
          betbutton.style.cssText = ""
          document.getElementById("betbtntext").classList.remove("betwindows");


          foldbutton.src = "";
          foldbutton.style.cssText = ""
          document.getElementById("foldtext").classList.remove("foldtextwindows");

          // this.setState({ infoText: res.message })

          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
          document.getElementById("betbtntext").textContent = this.props.language.Rebet;

          foldbutton.src = Foldbnwindows;
          Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
          document.getElementById("foldtext").textContent = this.props.language.RebetX2;

        }

      }

    } else {

      const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popup");
      document.getElementById("playerDealerCardContainer").style.zIndex = 1;
      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");
      if (window.innerHeight > window.innerWidth) {
        switch (res.winner) {
          case "Dealer":
          case "Player":
            this.showPlayerorDealerorFold(res);

            break;
          case "Dealer_no_hand":
            // case "Dealer doesn't have a hand. Would you like to replace the dealer's higher card for the cost equivalent of ANTE? Press either NO or YES.":
            // alert(".............pppppyada")
            ;
          default:
            break;
        }


        if (res.message == `Attention! You have a ${res.playerHand}. Are you sure you want to buy a dealer card?`) {
          // console.log("Attention! You have a Three of a Kind. Are you sure you want to buy a dealer card?")
          this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerFinalCards);
          this.shwnPrvsSteBuyDrawBtchips(res);
          this.ConfirmbuydealercardactivePopup = true
          // this.randomDealerCards = [...res.dealerFinalCards];
          this.dealerShuffledCards = [...res.dealerFinalCards]

          if (res.insurance) {
            if (res.insuranceBetAmount > 0) {
              let convrInsurenceBet = this.topLable_rsp(res.insuranceBetAmount);
              this.setState((prevState) => ({
                storeinsurancechips: [
                  ...prevState.storeinsurancechips, { chip: this.ReturnChipBasedAmount(res.insuranceBetAmount), chipvalue: convrInsurenceBet, chipcolor1: "insuranceTextPortraitChip10" },
                ],
                insurencetbet: res.insuranceBetAmount,
              }));
            }
          }


          this.setState({
            multibuttonsebledsble: true,
            disablemultibutton: true,
            // infoText: res.message,
            infoText: "",
            pokerranking: res.playerHand,
            pokerrankingdeler: res.dealerHand

          })

          confirmbuydealerPopupElement.className = "";
          confirmbuydealerPopupElement.style.display = "block";
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardPortrait");
          document.getElementById("alertTxt").innerHTML = res.message;
          document.getElementById("alertTxt").classList.add('alertTxtPortrait');
          document.getElementById("yes").classList.add("yesbutton_popup_portrait")
          document.getElementById("no").classList.add("nobutton_popup_portrait")
          document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerPortrait")
          document.getElementById("rankinginfo").classList.add("pokerrankingPortait")

        }
        else {


          this.setState({ betbutton: "REBET", buttontext: "REBETX2" })
          document.getElementById("drawtext").textContent = "";
          document.getElementById("buytext").textContent = "";
          betbutton.src = "";
          betbutton.style.cssText = ""
          document.getElementById("betbtntext").classList.remove("betportrait");

          foldbutton.src = "";
          foldbutton.style.cssText = ""
          document.getElementById("foldtext").classList.remove("foldtextportrait");
          document.getElementById("confirmbuydealer_popup").style.display = "none";
          confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardPortrait");
          confirmbuydealerPopupElement.style.display = "none";
          document.getElementById("yes").classList.remove("yesbutton_popup_portrait")
          document.getElementById("no").classList.remove("nobutton_popup_portrait")
          // this.setState({ infoText: res.message })

          betbutton.src = Betbtn;
          document.getElementById("betbtntext").textContent = this.props.language.Rebet;
          Object.assign(betbutton.style, porttraitejson.Betbtnportraite);


          foldbutton.src = Foldbn;
          document.getElementById("foldtext").textContent = this.props.language.RebetX2;
          Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);



        }

      } else {

        const drawtextElement = document.getElementById("drawtext")
        const buytextElement = document.getElementById("buytext")
        const foldtextElement = document.getElementById("foldtext")
        const betbtntextElement = document.getElementById("betbtntext")
        this.prevStatecheckPlayerOrDealerWinMblLndspe(res);


        if (res.message == `Attention! You have a ${res.playerHand}. Are you sure you want to buy a dealer card?`) {

          //   document.getElementById("russiangamecover").style.display = "block";
          //  document.getElementById("russiangamecover").classList.add("gamecover_enbledisable")

          this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerFinalCards);
          this.shwnPrvsSteBuyDrawBtchips(res);
          this.ConfirmbuydealercardactivePopup = true;
          this.dealerShuffledCards = [...res.dealerFinalCards];
          if (res.insurance) {
            if (res.insuranceBetAmount > 0) {
              let convrInsurenceBet = this.topLable_rsp(res.insuranceBetAmount);
              this.setState((prevState) => ({
                storeinsurancechips: [
                  ...prevState.storeinsurancechips, { chip: this.ReturnChipBasedAmount(res.insuranceBetAmount), chipvalue: convrInsurenceBet, chipcolor1: "insuranceTextPortraitChip10" },
                ],
                insurencetbet: res.insuranceBetAmount
              }));
            }
          };

          this.setState({
            multibuttonsebledsble: true,
            disablemultibutton: true,
            // infoText: res.message,
            infoText: "",
            pokerranking: res.playerHand,
            pokerrankingdeler: res.dealerHand
          })
          confirmbuydealerPopupElement.className = "";
          confirmbuydealerPopupElement.style.display = "block";
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardLndsp");
          document.getElementById("alertTxt").innerHTML = res.message;
          document.getElementById("alertTxt").classList.add('alertTxtPortrait');
          document.getElementById("yes").classList.add("yesbutton_popup_portrait")
          document.getElementById("no").classList.add("nobutton_popup_portrait")
          document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerLndSpe")
          document.getElementById("rankinginfo").classList.add("pokerrankingPlayerLndsp")

        }
        else {

          this.setState({ betbutton: "REBET", buttontext: "REBETX2" })
          drawtextElement.textContent = "";
          buytextElement.textContent = "";

          betbutton.style.cssText = ""
          betbtntextElement.className = "";

          foldbutton.src = "";
          foldbutton.style.cssText = ""
          foldtextElement.className = "";

          confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardLndsp");
          confirmbuydealerPopupElement.style.display = "none";
          document.getElementById("yes").classList.remove("yesbutton_popup_portrait")
          document.getElementById("no").classList.remove("nobutton_popup_portrait")
          // this.setState({ infoText: res.message })

          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
          betbtntextElement.textContent = this.props.language.Rebet;
          betbtntextElement.classList.add('betLandscape');


          foldbutton.src = Foldbn;
          Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
          foldtextElement.textContent = this.props.language.RebetX2;
          foldtextElement.classList.add("foldtextLandscape");



        }
      }
    }

  };







  buyDealerCard(Text) {
    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }

    this.setState({ infoText: "" })

    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    const betbtntextElement = document.getElementById("betbtntext");
    const foldtextElement = document.getElementById("foldtext");


    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      // betbutton.style.position = "";
      // betbutton.style.width = "";
      // betbutton.style.transform = "";
      // betbtntextElement.textContent="";

      // foldbutton.src = "";
      // foldbutton.style.position = "";
      // foldbutton.style.width = "";
      // foldbutton.style.transform = "";
      // foldtextElement.textContent="";

    } else {


      // betbutton.src = "";
      // betbutton.style.cssText = "";
      // betbtntextElement.textContent="";
      // betbtntextElement.className="";


      // foldbutton.src = "";
      // foldbutton.style.cssText ="";
      // foldtextElement.textContent ="";
      // foldtextElement.className ="";
      this.setState({ bsbtn: true })
      document.getElementById("mobileAntiMaskCover").style.display = "block";




    }

    let wanttobuydelercrd;
    if ((Text == "YES") && (this.state.anteBetAmount + this.state.totalbet > this.props.balance)) {
      this.setState({
        betshigh_balnce: true,
        lowBalaceErrorInfo: `Insufficient Funds to place the Buy of ${this.state.anteBetAmount.toFixed(1)},Please DEPOSIT to continue playing.`
      });
      this.lowBalanceErrorInformationPopupanimation()


    } else {
      this.setState({ betshigh_balnce: null });

      if (Text == "YES") {
        wanttobuydelercrd = true;
        this.ConfirmbuydealercardactivePopup = true;
        if (this.state.mutesounds) {
          this.selectCard.pause();
        } else {
          this.selectCard.play();
        }

        if (window.innerWidth > window.innerHeight || window.innerWidth < window.innerHeight) {

          betbutton.src = "";
          betbutton.style.cssText = "";
          betbtntextElement.textContent = "";
          betbtntextElement.className = "";


          foldbutton.src = "";
          foldbutton.style.cssText = "";
          foldtextElement.textContent = "";
          foldtextElement.className = "";

        }

      } else {
        if (window.innerWidth > window.innerHeight || window.innerWidth < window.innerHeight) {

          betbutton.src = "";
          betbutton.style.cssText = "";
          betbtntextElement.textContent = "";
          betbtntextElement.className = "";


          foldbutton.src = "";
          foldbutton.style.cssText = "";
          foldtextElement.textContent = "";
          foldtextElement.className = "";

        }
        this.hidepopup_betshigh();
        wanttobuydelercrd = false;
        this.ConfirmbuydealercardactivePopup = false;
        if (this.state.mutesounds) {
          this.unselectCard.pause();
        } else {
          this.unselectCard.play();
        }
      }

      const body = {
        sessionId: sessionStorage.getItem("sessionId"),
        CN: "RUSSIAN_POKER_BET",
        object: {
          action: "BUY_DEALER_CARD",
          wantToBuyDealerACard: wanttobuydelercrd,
          gameId: this.props.gameId,
          playerId: Number(this.props.playerId),
          handId: Number(this.props.gameState.handId)
        },
      };

      // console.log(body);
      this.props.network.sendof(body);


    }

  }


  Insurenceactived = () => {


    // let totalInsuranceBtAt = 0;
    let totalInsuranceBtAt = this.state.insurencetbet;
    for (let k = 1; k <= this.chipVal.length; k++) {
      if (this.state.count === k) {
        totalInsuranceBtAt = this.state.insurencetbet + Number(this.chipVal[k - 1].val);
      }
    }

    if (this.state.totalbet + totalInsuranceBtAt > this.props.balance) {
      this.setState({
        betshigh_balnce: true,
        lowBalaceErrorInfo: `Insufficient Funds to place the Insurance of ${totalInsuranceBtAt.toFixed(1)},Please DEPOSIT to continue playing.`
      });
      this.lowBalanceErrorInformationPopupanimation();
    } else {
      this.setState({ betshigh_balnce: null });


      let betchipstoinsurence = document.getElementById("betchipsaddinsurence");
      document.getElementById("foldtext").classList.remove("insurenceopacity");

      this.setState({
        insurencebtn: false,
        disablemultibutton: false,
        buttontext: "insurence",
      });
      // this.setState({disablemultibutton:false})

      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {


        this.playPokerChipSound(this.pokerchipsnd);

        if (Number((this.state.insurencetbet + Number(this.chipVal[this.state.count - 1].val)).toFixed(2)) == this.state.insurenceMaxbetAmt) {
          document.getElementById("insurenceactive").src = "";
        }

        let totalInsuranceBetAmount = 0;
        if (Number((this.state.insurencetbet + Number(this.chipVal[this.state.count - 1].val)).toFixed(2)) > this.state.insurenceMaxbetAmt
          // || this.state.insurencetbet + Number(this.chipVal[this.state.count - 1].val) < this.state.anteBetAmount 
        ) {
          // document.getElementById("insurenceactive").src = "";
          // document.getElementById("insurenceactive").classList.remove("insurenceactivewindows");
          // document.getElementById("insurenceactive").classList.remove("insurenceblink");
          // document.getElementById("insurenceRussian").classList.remove("addpointer");
          this.setState({
            infoText: `You have a ${this.state.playerhand_status}! Bet limits for INSURANCE are ${this.state.insurenceMinbetAmt.toFixed(1)} - ${(this.state.insurenceMaxbetAmt).toFixed(1)}
            Press NO INSURANCE to continue without insurance.`
          })


        } else {

          if (document.getElementById("Foldbtn").classList.contains("insurencenodrop")) {
            // alert(".....added")
            document.getElementById("Foldbtn").classList.remove("insurencenodrop");
            document.getElementById("Foldbtn").classList.add("addpointer");
          }

          for (let k = 1; k <= this.chipVal.length; k++) {
            if (this.state.count === k) {
              totalInsuranceBetAmount = this.state.insurencetbet + Number(this.chipVal[k - 1].val);

            }

          }
          let insurencebetamount = this.topLable_rsp(totalInsuranceBetAmount)
          if (document.getElementById("betTextaddtoinsurence")) {
            gsap.from("#betTextaddtoinsurence", { zIndex: 25 })

          }

          this.setState({
            insurencebtn: false,
            disablemultibutton: false,
            buttontext: "insurence",
          });
          // this.setState({disablemultibutton:false})
          switch (this.state.count) {
            case 1:
              betchipstoinsurence.src = chip_10;
              this.setState({
                totalbet: this.state.totalbet + Number(this.chipVal[0].val),
                insurencetbet: this.state.insurencetbet + Number(this.chipVal[0].val),
                betdeductions: this.state.betdeductions + Number(this.chipVal[0].val)
              })
              this.clertime38 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chiptoinsurence">

                      <img
                        className="chiptoinsurence_windows"
                        src={this.ReturnChipBasedAmount(totalInsuranceBetAmount)}
                        alt="Chip 10"
                      />
                      <span className="insuranceTextwndwsChip10"> {insurencebetamount} </span>
                    </div>
                  ],
                }));
              }, 150);

              break;
            case 2:
              betchipstoinsurence.src = chip_25;
              this.setState({
                totalbet: this.state.totalbet + Number(this.chipVal[1].val),
                insurencetbet: this.state.insurencetbet + Number(this.chipVal[1].val),
                betdeductions: this.state.betdeductions + Number(this.chipVal[1].val)
              })
              this.clertime39 = setTimeout(() => {
                this.setState((prevState) => ({

                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chiptoinsurence">

                      <img
                        className="chiptoinsurence_windows"
                        src={this.ReturnChipBasedAmount(totalInsuranceBetAmount)}
                        alt="Chip 10"
                      />
                      <span className="insuranceText_windows">  {insurencebetamount} </span>
                    </div>
                  ],
                }));
              }, 150);

              break;
            case 3:
              betchipstoinsurence.src = chip_50;

              this.clertime40 = setTimeout(() => {
                this.setState((prevState) => ({

                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chiptoinsurence">

                      <img
                        className="chiptoinsurence_windows"
                        src={this.ReturnChipBasedAmount(totalInsuranceBetAmount)}
                        alt="Chip 10"
                      />
                      <span className="insuranceText_windows">{insurencebetamount} </span>
                    </div>
                  ],
                }));
              }, 150);
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                insurencetbet: prevState.insurencetbet + Number(this.chipVal[2].val),
                betdeductions: prevState.betdeductions + Number(this.chipVal[2].val)
              }));
              break;
            case 4:
              betchipstoinsurence.src = chip_100;
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                insurencetbet: prevState.insurencetbet + Number(this.chipVal[3].val),
                betdeductions: prevState.betdeductions + Number(this.chipVal[3].val)
              }));
              this.clertime41 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chiptoinsurence">
                      <img
                        className="chiptoinsurence_windows"
                        src={this.ReturnChipBasedAmount(totalInsuranceBetAmount)}
                        alt="Chip 10"
                      />
                      <span className="insuranceText_windows"> {insurencebetamount}</span>

                    </div>

                  ],
                }));
              }, 150);
              break;
            case 5:
              betchipstoinsurence.src = chip_500;
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                insurencetbet: prevState.insurencetbet + Number(this.chipVal[4].val),
                betdeductions: prevState.betdeductions + Number(this.chipVal[4].val)
              }));
              this.clertime42 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chiptoinsurence">

                      <img
                        className="chiptoinsurence_windows"
                        src={this.ReturnChipBasedAmount(totalInsuranceBetAmount)}
                        alt="Chip 10"
                      />
                      <span className="insuranceText_windows">{insurencebetamount} </span>
                    </div>
                  ],
                }));
              }, 150);
              break;
            case 6:
              betchipstoinsurence.src = chip_1000;
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[5].val),
                insurencetbet: prevState.insurencetbet + Number(this.chipVal[5].val),
                betdeductions: prevState.betdeductions + Number(this.chipVal[5].val)
              }));
              this.clertime42 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chiptoinsurence">

                      <img
                        className="chiptoinsurence_windows"
                        src={this.ReturnChipBasedAmount(totalInsuranceBetAmount)}
                        alt="Chip 10"
                      />
                      <span className="insuranceText_windows">{insurencebetamount} </span>
                    </div>
                  ],
                }));
              }, 150);
              break;

            default:
              break;
          }
          // betchipsinsurence.classList.add(betTextaddtoBetClass, "bet-text-addtoinsurence");
          Object.assign(betchipstoinsurence.style, porttraitejson.betchipstoinsurencewindows)



          var tl = gsap.timeline();
          tl.to(this.chipinsurenceref.current, {
            x: -966,
            y: -671,
            duration: 0.2,
            ease: "power2.inOut",
            zIndex: 12,
          });
          tl.to(this.chipinsurenceref.current, {
            opacity: 0,
            x: 0,
            y: 0,
            duration: 0.00001,
          });
          // tl.reverse();
          tl.to(this.chipinsurenceref.current, { opacity: 1, zIndex: -2 });
          tl.play();
        }

      }


      else {
        if (Number((this.state.insurencetbet + Number(this.chipVal[this.state.count - 1].val)).toFixed(2)) == this.state.insurenceMaxbetAmt) {
          document.getElementById("insurenceactive").src = "";
        }

        let totalInsuranceBetAmount = 0;
        if (window.innerHeight > window.innerWidth) {
          if (Number((this.state.insurencetbet + Number(this.chipVal[this.state.count - 1].val)).toFixed(2)) > this.state.insurenceMaxbetAmt
            // this.state.insurencetbet + Number(this.chipVal[this.state.count - 1].val) < this.state.anteBetAmount 
          ) {
            this.setState({
              infoText: `You have a ${this.state.playerhand_status}! Bet limits for INSURANCE are  ${this.state.insurenceMinbetAmt.toFixed(1)} - ${(this.state.insurenceMaxbetAmt).toFixed(1)}
            Press NO INSURANCE to continue without insurance.`,
              // disablemultibutton:true
            })
          } else {
            this.setState({
              // disablemultibutton:false
            })
            if (Number((this.state.insurencetbet + Number(this.chipVal[this.state.count - 1].val)).toFixed(2)) >= this.state.insurenceMinbetAmt) {
              const foldbutton = document.getElementById("Foldbtn");
              document.getElementById("foldtext").style.opacity = "1";
              foldbutton.style.opacity = "1"
            }
            for (let k = 1; k <= this.chipVal.length; k++) {
              if (this.state.count === k) {
                totalInsuranceBetAmount = this.state.insurencetbet + Number(this.chipVal[k - 1].val);
              }
            }

            // for (let k = 1; k <= this.chipVal.length; k++) {
            //   if (this.state.count === k) {
            //     if(this.state.insurencetbet > 0){

            //       totalInsuranceBetAmount = this.state.insurencetbet + Number(this.chipVal[k - 1].val);
            //     }else{
            //       totalInsuranceBetAmount = this.state.anteBetAmount;
            //     }
            //   }
            // }

            this.playPokerChipSound(this.pokerchipsnd);

            Object.assign(betchipstoinsurence.style, porttraitejson.betchipstobnsPortrait)

            let convrInsurenceBet = this.topLable_rsp(totalInsuranceBetAmount);


            switch (this.state.count) {
              case 1:
                // if(this.state.insurencetbet > 0){
                //   this.setState((prevState) => ({
                //     insurencetbet: prevState.insurencetbet + Number(this.chipVal[0].val),
                //     totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                //     betdeductions: prevState.betdeductions + Number(this.chipVal[0].val)
                //   }));
                // }else{
                //   this.setState((prevState) => ({
                //     insurencetbet: prevState.insurencetbet +totalInsuranceBetAmount,
                //     totalbet: prevState.totalbet +totalInsuranceBetAmount,
                //     betdeductions: prevState.betdeductions + totalInsuranceBetAmount
                //   }));

                // }
                // console.log(this.state.insurencetbet, totalInsuranceBetAmount);

                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[0].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[0].val)
                }));
                betchipstoinsurence.src = chip_10;
                this.clertime38 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips: [
                      ...prevState.storeinsurancechips, { chip: this.ReturnChipBasedAmount(Number(prevState.insurencetbet.toFixed(2))), chipvalue: convrInsurenceBet, chipcolor1: "insuranceTextPortraitChip10" },
                    ],

                  }));
                }, 150);

                break;
              case 2:
                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[1].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[1].val)
                }));

                betchipstoinsurence.src = chip_25;
                this.clertime39 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips: [
                      ...prevState.storeinsurancechips, { chip: this.ReturnChipBasedAmount(Number(prevState.insurencetbet.toFixed(2))), chipvalue: convrInsurenceBet, chipcolor1: null },
                    ],

                  }));
                }, 150);

                break;
              case 3:
                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[2].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[2].val)
                }));
                betchipstoinsurence.src = chip_50;
                this.clertime40 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips: [
                      ...prevState.storeinsurancechips, { chip: this.ReturnChipBasedAmount(Number(prevState.insurencetbet.toFixed(2))), chipvalue: convrInsurenceBet, chipcolor1: null },
                    ],

                  }));
                }, 150);
                break;
              case 4:
                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[3].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[3].val)
                }));
                betchipstoinsurence.src = chip_100;
                this.clertime41 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips: [
                      ...prevState.storeinsurancechips, { chip: this.ReturnChipBasedAmount(Number(prevState.insurencetbet.toFixed(2))), chipvalue: convrInsurenceBet, chipcolor1: null },
                    ],

                  }));
                }, 150);
                break;
              case 5:
                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[4].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[4].val)
                }));
                betchipstoinsurence.src = chip_500;
                this.clertime42 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips: [
                      ...prevState.storeinsurancechips, { chip: this.ReturnChipBasedAmount(Number(prevState.insurencetbet.toFixed(2))), chipvalue: convrInsurenceBet, chipcolor1: null },
                    ],

                  }));
                }, 150);
                break;
              case 6:
                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[5].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[5].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[5].val)
                }));
                betchipstoinsurence.src = chip_1000;
                this.clertime42 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips: [
                      ...prevState.storeinsurancechips, { chip: this.ReturnChipBasedAmount(Number(prevState.insurencetbet.toFixed(2))), chipvalue: convrInsurenceBet, chipcolor1: null },
                    ],

                  }));
                }, 150);
                break;

              default:
                break;
            }


            var tl = gsap.timeline();
            tl.to(this.chipinsurenceref.current, {
              x: -16,
              y: -1373,
              duration: 0.15,
              ease: "power2.inOut",
              zIndex: 15,
              autoAlpha: 1,
              opacity: 1,
              //   onStart: function() {
              //     betchipstoinsurence.style.zIndex = 5;
              //     document.getElementById('chiptoinsurence').style.visibility = 'visible';

              // }

            });
            tl.to(this.chipinsurenceref.current, {
              opacity: 0,
              x: 0,
              y: 0,
              duration: 0.00001,
            });

            tl.to(this.chipinsurenceref.current, { opacity: 0, zIndex: -2 });
            tl.play();

          }

        } else {

          if (Number((this.state.insurencetbet + Number(this.chipVal[this.state.count - 1].val)).toFixed(2)) > this.state.insurenceMaxbetAmt
            // || this.state.insurencetbet + Number(this.chipVal[this.state.count - 1].val) < this.state.anteBetAmount 
          ) {

            this.setState({
              infoText: `You have a ${this.state.playerhand_status}! Bet limits for INSURANCE are  ${this.state.insurenceMinbetAmt.toFixed(1)} - ${(this.state.insurenceMaxbetAmt).toFixed(1)}
            Press NO INSURANCE to continue without insurance.`
            })
          } else {

            if (Number((this.state.insurencetbet + Number(this.chipVal[this.state.count - 1].val)).toFixed(2)) >= this.state.insurenceMinbetAmt) {
              const foldbutton = document.getElementById("Foldbtn");
              document.getElementById("foldtext").style.opacity = "1";
              foldbutton.style.opacity = "1"
            }

            for (let k = 1; k <= this.chipVal.length; k++) {
              if (this.state.count === k) {
                totalInsuranceBetAmount = this.state.insurencetbet + Number(this.chipVal[k - 1].val);
              }
            }

            this.playPokerChipSound(this.pokerchipsnd);

            Object.assign(betchipstoinsurence.style, porttraitejson.betchipstobnsLndsp);

            var tl = gsap.timeline();
            tl.to(this.chipinsurenceref.current, {
              x: -1050,
              y: -175,
              duration: 0.15,
              ease: "power2.inOut",
              zIndex: 15,
              autoAlpha: 1
            });
            tl.to(this.chipinsurenceref.current, {
              autoAlpha: 0,
              x: 0,
              y: 0,
              duration: 0.00001,
            });

            tl.play();
            let convrInsurenceBet = this.topLable_rsp(totalInsuranceBetAmount);
            switch (this.state.count) {
              case 1:
                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[0].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[0].val)
                }));
                betchipstoinsurence.src = chip_10;
                this.clertime38 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips: [
                      ...prevState.storeinsurancechips, { chip: this.ReturnChipBasedAmount(Number(prevState.insurencetbet.toFixed(2))), chipvalue: convrInsurenceBet, chipcolor1: "insuranceTextPortraitChip10" },
                    ],

                  }));
                }, 150);

                break;
              case 2:
                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[1].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[1].val)
                }));

                betchipstoinsurence.src = chip_25;
                this.clertime39 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips: [
                      ...prevState.storeinsurancechips, { chip: this.ReturnChipBasedAmount(Number(prevState.insurencetbet.toFixed(2))), chipvalue: convrInsurenceBet, chipcolor1: null },
                    ],

                  }));
                }, 150);

                break;
              case 3:
                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[2].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[2].val)
                }));
                betchipstoinsurence.src = chip_50;
                this.clertime40 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips: [
                      ...prevState.storeinsurancechips, { chip: this.ReturnChipBasedAmount(Number(prevState.insurencetbet.toFixed(2))), chipvalue: convrInsurenceBet, chipcolor1: null },
                    ],

                  }));
                }, 150);
                break;
              case 4:
                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[3].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[3].val)
                }));
                betchipstoinsurence.src = chip_100;
                this.clertime41 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips: [
                      ...prevState.storeinsurancechips, { chip: this.ReturnChipBasedAmount(Number(prevState.insurencetbet.toFixed(2))), chipvalue: convrInsurenceBet, chipcolor1: null },
                    ],

                  }));
                }, 150);
                break;
              case 5:
                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[4].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[4].val)
                }));
                betchipstoinsurence.src = chip_500;
                this.clertime42 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips: [
                      ...prevState.storeinsurancechips, { chip: this.ReturnChipBasedAmount(Number(prevState.insurencetbet.toFixed(2))), chipvalue: convrInsurenceBet, chipcolor1: null },
                    ],

                  }));
                }, 150);
                break;
              case 6:
                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[5].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[5].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[5].val)
                }));
                betchipstoinsurence.src = chip_1000;
                this.clertime42 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips: [
                      ...prevState.storeinsurancechips, { chip: this.ReturnChipBasedAmount(Number(prevState.insurencetbet.toFixed(2))), chipvalue: convrInsurenceBet, chipcolor1: null },
                    ],

                  }));
                }, 150);
                break;

              default:
                break;
            }
          }
        }

      }


    }







  }




  claiminsurenceresp(res) {

    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {


      this.clertime43 = setTimeout(() => {
        document.getElementById("rankinginfodeler").classList.add("windowspokerrankingdeler");
        this.setState({
          pokerrankingdeler: res.dealerHand,
          // totalwinRussian: res.totalWinAmount
        })



      }, 2000)


      // const betbutton = document.getElementById("betbtn");
      // const foldbutton = document.getElementById("Foldbtn");

      setTimeout(() => {
        this.checkpayerordealerwins(res);

      }, 3500)

      this.revealDealercards(res);

    } else {
      this.setState({ pokerrankingdeler: res.dealerHand, bsbtn: true })

      // console.log("..........congratss", this.state.totalwinRussian);
      const rankinginfodelerElement = document.getElementById("rankinginfodeler");
      rankinginfodelerElement.style.cssText = "";
      const betbtntextElement = document.getElementById("betbtntext");
      const foldtextElement = document.getElementById("foldtext");
      document.getElementById("insurencetext").style.color = "";

      if (window.innerHeight > window.innerWidth) {
        rankinginfodelerElement.classList.add('pokerrankingdelerPortrait');
        switch (res.winner) {
          case "Dealer":

            this.ifDealerWinsMobilePortraitAction(res);
            break;

          // case `CONGRATULATIONS! You won FUN ${this.state.totalwinRussian}`: 
          case "Player":
            setTimeout(() => {
              this.ifPlayerWinsMobilePortraitAction(res);
            }, 3000)
            break;
          case "Dealer_no_hand":
            // case "Dealer doesn't have a hand. Would you like to replace the dealer's higher card for the cost equivalent of ANTE? Press either NO or YES.":
            this.setState({ disablemultibutton: false, infoText: res.message, });

            document.getElementById("betactive").src = "";
            document.getElementById("bettext").style.color = "";

            this.setState({ betbutton: "YES", buttontext: "NO" });
            betbutton.src = Betbtn;
            betbtntextElement.textContent = this.props.language.Yes;
            Object.assign(betbutton.style, porttraitejson.Betbtnportraite)


            foldbutton.src = Foldbn;
            foldtextElement.textContent = this.props.language.No;
            Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);

            betbtntextElement.classList.remove('noinsurencePortrait', 'betportraite');
            foldtextElement.classList.remove('insurencePortrait', 'foldtextportraite');

            betbtntextElement.classList.add("betportrait");
            foldtextElement.classList.add("foldtextportrait");
          default:
            break;
        }

      } else {
        rankinginfodelerElement.classList.add('pokerrankingdelerLndSpe');
        this.setState({ pokerrankingdeler: res.dealerHand })

        this.clertime7 = setTimeout(() => {
          this.checkPlayerOrDealerWinMblLndspe(res);
        }, 1000)
      }
    }
    this.revealDealercards(res);
  }

  Showclaiminsurenceresp(res) {

    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {


      // this.clertime43=setTimeout(() => {
      // document.getElementById("rankinginfo").classList.add("windowspokerranking");
      // document.getElementById("rankinginfodeler").classList.add("windowspokerrankingdeler");
      // this.setState({
      //   infoText: res.message, pokerrankingdeler: res.dealerHand, pokerranking: res.playerHand,
      //   totalwinRussian: res.totalWinAmount
      // })


      // }, 2000)


      // const betbutton = document.getElementById("betbtn");
      // const foldbutton = document.getElementById("Foldbtn");

      this.showcheckpayerordealerwins(res);

      // this.revealDealercards(res);

    }


  }

  cnfrmdelercardchipanim() {
    this.playPokerChipSound(this.pokerchipsnd);
    //alert("........drawcards")

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      let betexttodraw = document.getElementById("betTextaddtodraw");
      // gsap.to("#betTextaddtodraw", { opacity: 2, zIndex: 15 })
      let betchipsdraw = document.getElementById("betchipsaddtconfrm");

      let betTextaddtoBetClass = "bet-text-addtodraw20";
      this.setState((prevState) => ({
        totalbet: prevState.totalbet + prevState.anteBetAmount

      }));
      betexttodraw.textContent = this.topLable_rsp(this.state.anteBetAmount);
      betchipsdraw.src = this.ReturnChipBasedAmount(Number(this.state.anteBetAmount.toFixed(2)));
      switch (this.state.count) {
        case 1:

          // betchipsdraw.src = chip_10;
          betTextaddtoBetClass = "bet-text-addtodraw10";

          break;
        case 2:
          // betchipsdraw.src = chip_25;
          break;
        case 3:
          // betchipsdraw.src = chip_50;
          break;
        case 4:
          // betchipsdraw.src = chip_100;
          break;
        case 5:
          // betchipsdraw.src = chip_500;
          break;
        case 6:
          // betchipsdraw.src = chip_1000;
          break;

        default:
          break;
      }
      // this.clertime44 = setTimeout(() => {
      //   betexttodraw.classList.add(betTextaddtoBetClass, "bet-text-addtodraw");

      // }, 100)



      let t1 = gsap.timeline();
      t1.to("#betchipsaddtconfrm", {
        x: -1084,
        y: -598, duration: 0.3, opacity: 1,
        onComplete: () => {
          Object.assign(betchipsdraw.style, porttraitejson.betchipsdrawwindows)
          betexttodraw.classList.add(betTextaddtoBetClass, "bet-text-addtodraw");

        }

      });

      t1.play();


    }

  }
  drawbuybets() {

    this.playPokerChipSound(this.pokerchipsnd);
    //alert("........drawcards")

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {
      this.setState({ infoText: "" })
      let betexttodraw = document.getElementById("betTextaddtodraw");
      gsap.to("#betTextaddtodraw", { opacity: 2, zIndex: 15 })
      let betchipsdraw = document.getElementById("betchipsaddtodraw");




      let betTextaddtoBetClass = "";
      // this.setState((prevState) => ({
      //   totalbet: prevState.totalbet + prevState.anteBetAmount

      // }));
      let drwbetamnt
      // if (drawbwts > 0) {

      //   betexttodraw.textContent = this.topLable_rsp(this.state.anteBetAmount * drawbwts)
      //   drwbetamnt=(this.state.anteBetAmount * drawbwts)
      // }
      // else {
      betexttodraw.textContent = this.topLable_rsp(this.state.anteBetAmount)
      drwbetamnt = (this.state.anteBetAmount)


      // }
      switch (this.state.count) {
        case 1:
          betTextaddtoBetClass = "";
          betchipsdraw.src = this.ReturnChipBasedAmount(Number(drwbetamnt));
          betTextaddtoBetClass = "bet-text-addtodraw10";
          break;
        case 2:
          betTextaddtoBetClass = "";
          betchipsdraw.src = this.ReturnChipBasedAmount(Number(drwbetamnt));
          betTextaddtoBetClass = "bet-text-addtodraw20";

          break;
        case 3:
          betTextaddtoBetClass = "";
          betchipsdraw.src = this.ReturnChipBasedAmount(Number(drwbetamnt));
          betTextaddtoBetClass = "bet-text-addtodraw20";
          break;
        case 4:
          betTextaddtoBetClass = "";
          betchipsdraw.src = this.ReturnChipBasedAmount(Number(drwbetamnt));
          betTextaddtoBetClass = "bet-text-addtodraw20";
          break;
        case 5:
          betTextaddtoBetClass = "";
          betchipsdraw.src = this.ReturnChipBasedAmount(Number(drwbetamnt));
          betTextaddtoBetClass = "bet-text-addtodraw20";
          break;
        case 6:
          betTextaddtoBetClass = "";
          betchipsdraw.src = this.ReturnChipBasedAmount(Number(drwbetamnt));
          betTextaddtoBetClass = "bet-text-addtodraw20";
          break;

        default:
          break;
      }
      // this.clertime45 = setTimeout(() => {
      //   betexttodraw.classList.add(betTextaddtoBetClass, "bet-text-addtodraw");
      //   Object.assign(betchipsdraw.style, porttraitejson.betchipsdrawwindows)

      // }, 100)
      let t1 = gsap.timeline();
      // t1.to("#betchipsaddtodraw,betchipsaddtconfrm", {
      t1.to("#betchipsaddtodraw", {
        x: -1084,
        y: -598, duration: 0.2, opacity: 1,
        onComplete: () => {
          // betexttodraw.classList.add(betTextaddtoBetClass, "bet-text-addtodraw");
          betexttodraw.classList.add(betTextaddtoBetClass, "bet-text-addtodraw");
          Object.assign(betchipsdraw.style, porttraitejson.betchipsdrawwindows)

        }
      });

      t1.play();


    }
  }






  Confirmbuydealercard(txt) {


    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }
    this.wanttobuydelercrd = txt;

    if (txt === true) {
      this.playPokerChipSound(this.pokerchipsnd);
    } else {
      this.unselectCard = new Audio(unselectCard);
      if (this.state.mutesounds) {
        this.unselectCard.pause();
      } else {
        this.unselectCard.play();
      }
    }



    const body = {
      sessionId: sessionStorage.getItem("sessionId"),
      CN: "RUSSIAN_POKER_BET",
      object: {
        action: "CONFIRM_DEALER_CARD",
        wantToBuyDealerACard: txt,
        gameId: this.props.gameId,
        playerId: Number(this.props.playerId),
        handId: Number(this.props.gameState.handId)
      },
    };
    // console.log(body);
    this.props.network.sendof(body);

    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    betbutton.src = "";
    betbutton.style.cssText = "";
    document.getElementById("betbtntext").classList.remove("betwindows");
    document.getElementById("betbtntext").textContent = "";
    betbutton.style.position = "";
    betbutton.style.width = "";
    betbutton.style.transform = "";

    foldbutton.src = "";
    foldbutton.style.cssText = "";
    document.getElementById("foldtext").classList.remove("foldtextwindows");
    document.getElementById("foldtext").textContent = "";
    foldbutton.style.position = "";
    foldbutton.style.width = "";
    foldbutton.style.transform = "";



    const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popup");
    const yesbuttoElement = document.getElementById("yes");
    const nobuttonElement = document.getElementById("no")

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      if (txt === true) {

        this.setState((prevState) => ({
          betdeductions: prevState.betdeductions + prevState.anteBetAmount,
          // totalbet: prevState.totalbet + prevState.anteBetAmount,
          infoText: ""
        }));


        gsap.to("#betTextaddtodraw", { opacity: 4, zIndex: 15 })
        // let confirmtobuydealercard
        // if (txt == "YES") {
        // confirmtobuydealercard = true
        gsap.to(`#betchipsaddtodraw,betchipsaddtconfrm`, {
          x: -1084,
          y: -598,
          duration: 0.3,
          zIndex: 12,
        });
        this.cnfrmdelercardchipanim();

        // }
        // else {
        //   confirmtobuydealercard = false

        // }
        document.getElementById("alertTxt").innerHTML = "";
        document.getElementById("confirmbuydealer_popup").style.display = "none";
        document.getElementById("confirmbuydealer_popup").classList.remove("popup_cnfrmbuydealrcard");
        document.getElementById("yes").classList.remove("yesbutton_popup")
        document.getElementById("no").classList.remove("nobutton_popup")
      }
      else {
        this.setState({ infoText: "" })
        document.getElementById("alertTxt").innerHTML = "";
        document.getElementById("confirmbuydealer_popup").style.display = "none";
        document.getElementById("confirmbuydealer_popup").classList.remove("popup_cnfrmbuydealrcard");
        document.getElementById("yes").classList.remove("yesbutton_popup")
        document.getElementById("no").classList.remove("nobutton_popup")
      }
    } else {


      let betchipsdraw = document.getElementById("dealerChipsAddToBuy");
      let dealerBuyTextaddtoElement = document.getElementById("dealerChipsAddToBuyText");
      dealerBuyTextaddtoElement.className = "";
      let dealerBuyText = "bet-text-addtoBet20";

      betchipsdraw.src = this.ReturnChipBasedAmount(Number(this.state.anteBetAmount.toFixed(2)));
      switch (this.state.count) {
        case 1:
          // betchipsdraw.src = chip_10;
          dealerBuyText = "bet-text-addtoBet10";
          break;
        case 2:
          // betchipsdraw.src = chip_25;
          dealerBuyText = "bet-text-addtoBet20";
          break;
        case 3:
          // betchipsdraw.src = chip_50;
          dealerBuyText = "bet-text-addtoBet20";
          break;
        case 4:
          // betchipsdraw.src = chip_100;
          dealerBuyText = "bet-text-addtoBet20";

          break;
        case 5:
          // betchipsdraw.src = chip_500;
          dealerBuyText = "bet-text-addtoBet20";

          break;
        case 6:
          // betchipsdraw.src = chip_1000;
          dealerBuyText = "bet-text-addtoBet20";

          break;

        default:
          dealerBuyText = "bet-text-addtoBet20";
          break;
      }



      confirmbuydealerPopupElement.style.display = 'none';
      yesbuttoElement.classList.remove("yesbutton_popup_portrait");
      nobuttonElement.classList.remove("nobutton_popup_portrait");
      const confirmDelearcard = this.topLable_rsp(this.state.anteBetAmount);
      if (window.innerHeight > window.innerWidth) {
        confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardPortrait");
        if (txt === true) {
          document.getElementById("playerDealerCardContainer").style.zIndex = 20;
          dealerBuyTextaddtoElement.classList.add("dealerBuyCardtext", dealerBuyText);
          dealerBuyTextaddtoElement.textContent = confirmDelearcard;
          this.setState((prevState) => ({
            betdeductions: prevState.betdeductions + prevState.anteBetAmount,
            totalbet: prevState.totalbet + prevState.anteBetAmount,
            infoText: ""
          }));


          gsap.to(this.buyDealerCardDrawn.current, {
            // x: -190,
            x: -230,
            y: -1200,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 13,
            opacity: 1
          });

          gsap.to(dealerBuyTextaddtoElement, { zIndex: 14, width: "100%", opacity: 1, visibility: true })

        }


      } else {
        confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardLndsp");
        if (txt === true) {
          document.getElementById("playerDealerCardContainer").style.zIndex = 20;
          dealerBuyTextaddtoElement.classList.add("dealerBuyCardtextLndsp", dealerBuyText);
          dealerBuyTextaddtoElement.textContent = confirmDelearcard;
          this.setState((prevState) => ({
            betdeductions: prevState.betdeductions + prevState.anteBetAmount,
            totalbet: prevState.totalbet + prevState.anteBetAmount,
            infoText: ""
          }));


          gsap.to(this.buyDealerCardDrawn.current, {
            // x: -190,
            x: -1230,
            y: 0,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 13,
            autoAlpha: 1
          });

          gsap.to(dealerBuyTextaddtoElement, { zIndex: 14, width: "100%", opacity: 1, visibility: true })

        }




      }
    }

  }
  buyconfirmdealercardresp(res) {
    this.setState({ multibuttonsebledsble: false, disablemultibutton: false })
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    this.setState({ betbutton: "REBET", buttontext: "REBETX2", disBetBtn: 0, betBtnValue: 2, keyboardhandle: true })
    document.getElementById("drawtext").textContent = "";
    document.getElementById("buytext").textContent = "";
    betbutton.src = "";
    foldbutton.src = "";

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      this.insuranceActive = true;


      document.getElementById("betbtn").classList.remove("drwnodrop")
      document.getElementById("Foldbtn").classList.remove("drwnodrop")
      document.getElementById("foldtext").textContent = "";

      document.getElementById("betbtntext").textContent = "";


      if (res.dealerNewCardToReplace) {
        setTimeout(() => {
          this.checkpayerordealerwins(res);

        }, 3500)


      }


      else {

        this.checkpayerordealerwins(res)
      }


    }

    else {
      // this.setState({totalwinRussian: res.totalWinAmount, bsbtn:true,})
      this.setState({ bsbtn: true, })

      const betbtntextElement = document.getElementById("betbtntext");
      const foldtextElement = document.getElementById("foldtext")

      if (betbtntextElement) {
        betbtntextElement.classList.remove("betportrait");
        betbtntextElement.className = ""
        betbtntextElement.textContent = "";
      }


      if (foldtextElement) {
        foldtextElement.classList.remove("foldtextportrait");
        foldtextElement.className = "";
        foldtextElement.textContent = "";
      }

      betbutton.src = "";
      betbutton.style.cssText = "";
      betbutton.style.position = "";
      betbutton.style.width = "";
      betbutton.style.transform = "";


      foldbutton.src = "";
      foldbutton.style.cssText = "";
      foldbutton.style.position = "";
      foldbutton.style.width = "";
      foldbutton.style.transform = "";

      document.getElementById("yes").classList.remove("yesbutton_popup_portrait");
      document.getElementById("yes").className = "";
      document.getElementById("no").classList.remove("nobutton_popup_portrait");
      document.getElementById("no").className = "";
      // this.setState({ infoText: res.message })


      if (window.innerHeight > window.innerWidth) {
        // document.getElementById("confirmbuydealer_popup").classList.remove("popup_cnfrmbuydealrcard");
        switch (res.winner) {
          case "Dealer":
            setTimeout(() => {
              this.ifDealerWinsMobilePortraitAction(res);
            }, 2000)
            break;

          // case `CONGRATULATIONS! You won FUN ${res.totalWinAmount}`:
          case "Player":
            if (res.dealerNewCardToReplace) {

              setTimeout(() => {
                this.ifPlayerWinsMobilePortraitAction(res);
              }, 3000)
            } else {

              setTimeout(() => {
                this.ifPlayerWinsMobilePortraitAction(res);
              }, 1000)
            }
            break;
          default:
            break;

        }

      } else {
        if (res.dealerNewCardToReplace) {
          setTimeout(() => {
            this.checkPlayerOrDealerWinMblLndspe(res)
          }, 3000)
        } else {

          setTimeout(() => {
            this.checkPlayerOrDealerWinMblLndspe(res)
          }, 1000)
        }
      }
    }




    this.dealerUnsequenceDrawCards = [...res.dealerCards];
    this.dealerSequenceDrawFinalCards = [...res.dealerFinalCards];
    let cnt = this.dealerUnsequenceDrawCards.length;


    for (let i = 0; i < cnt; i++) {
      var b1 = this.dealerUnsequenceDrawCards[i];
      // console.log(this.dealerShuffledCards, b1)

      switch (i) {
        case 0:
          if (this.dealerShuffledCards[0] != b1) {
            this.dealerCardsContainer.current.dealerDrawcard("cardZero", b1, cnt);
          }
          break;
        case 1:
          if (this.dealerShuffledCards[1] != b1) {
            this.dealerCardsContainer.current.dealerDrawcard("cardOne", b1, cnt);
          }
          break;
        case 2:
          if (this.dealerShuffledCards[2] != b1) {
            this.dealerCardsContainer.current.dealerDrawcard("cardTwo", b1, cnt);
          }
          break;
        case 3:

          if (this.dealerShuffledCards[3] != b1) {
            this.dealerCardsContainer.current.dealerDrawcard("cardThree", b1, cnt);
          }
          break;
        case 4:
          if (this.dealerShuffledCards[4] != b1) {
            this.dealerCardsContainer.current.dealerDrawcard("cardFour", b1, cnt);
          }
          break;
        case 5:
          if (this.dealerShuffledCards[5] != b1) {
            this.dealerCardsContainer.current.dealerDrawcard("cardFive", b1, cnt);
          }
          break;
        default:
          break;
      }

    }



    setTimeout(() => {
      document.getElementById("playerDealerCardContainer").style.zIndex = 1;
      setTimeout(() => {
        this.setState({
          pokerrankingdeler: res.dealerHand,
        })
      }, 1000)


      for (let d = 0; d < res.dealerCards.length; d++) {
        if (res.dealerCards[d] !== res.dealerFinalCards[d]) {
          if (res.dealerNewCardToReplace) {
            this.suffleDealerCards(this.dealerSequenceDrawFinalCards);
            // this.shuffledPlayerCards = [...this.dealerSequenceDrawFinalCards];
          }
          break;
        }
      }
    }, 360 * cnt)




  }

  Showbuyconfirmdealercardresp(res) {

    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    this.setState({ betbutton: "REBET", buttontext: "REBETX2", disBetBtn: 0, betBtnValue: 2, keyboardhandle: true })
    document.getElementById("drawtext").textContent = "";
    document.getElementById("buytext").textContent = "";
    betbutton.src = "";
    foldbutton.src = "";

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {
      this.setState({ multibuttonsebledsble: false, disablemultibutton: false, infoText: res.message, totalwinRussian: res.totalWinAmount })
      this.setState({
        previousAnteBetAmount: res.anteBetAmount,
        anteBetAmount: res.anteBetAmount,

      })
      document.getElementById("rankinginfodeler").classList.add("windowspokerrankingdeler");
      document.getElementById("rankinginfo").classList.add("windowspokerranking");
      this.setState({ pokerrankingdeler: res.dealerHand, pokerranking: res.playerHand })
      // betbutton.src = "";
      // document.getElementById("betbtntext").classList.remove("betwindows");
      // betbutton.style.position = "";
      // betbutton.style.width = "";
      // betbutton.style.transform = "";

      // foldbutton.src = "";
      // document.getElementById("foldtext").classList.remove("foldtextwindows");
      // foldbutton.style.position = "";
      // foldbutton.style.width = "";
      // foldbutton.style.transform = "";
      // document.getElementById("confirmbuydealer_popup").classList.remove("popup_cnfrmbuydealrcard");
      document.getElementById("yes").classList.remove("yesbutton_popup")
      document.getElementById("no").classList.remove("nobutton_popup")
      this.setState({ infoText: res.message })
      this.setState({
        multibuttonsebledsble: false, disablemultibutton: false,
        infoText: res.message, totalwinRussian: res.totalWinAmount, pokerrankingdeler: res.dealerHand
      })


      document.getElementById("betbtn").classList.remove("drwnodrop")
      document.getElementById("Foldbtn").classList.remove("drwnodrop")
      document.getElementById("foldtext").textContent = "";
      document.getElementById("betbtntext").textContent = "";
      this.showcheckpayerordealerwins(res);

    }

    else {
      this.setState({ multibuttonsebledsble: false, disablemultibutton: false, })

      const betbtntextElement = document.getElementById("betbtntext");
      const foldtextElement = document.getElementById("foldtext")

      if (betbtntextElement) {
        betbtntextElement.classList.remove("betportrait");
        betbtntextElement.textContent = "";
      }
      if (foldtextElement) {
        foldtextElement.classList.remove("foldtextportrait");
        foldtextElement.textContent = "";
      }

      betbutton.style.cssText = "";
      betbutton.style.position = "";
      betbutton.style.width = "";
      betbutton.style.transform = "";

      foldbutton.src = "";

      foldbutton.style.cssText = "";
      foldbutton.style.position = "";
      foldbutton.style.width = "";
      foldbutton.style.transform = "";
      // document.getElementById("confirmbuydealer_popup").classList.remove("popup_cnfrmbuydealrcard");
      document.getElementById("yes").className = "";
      document.getElementById("no").className = "";
      this.setState({ infoText: res.message })
      if (window.innerHeight > window.innerWidth) {
        switch (res.winner) {
          case "Dealer":
          case "Player":
            this.showPlayerorDealerorFold(res)
            break;

          // case "Dealer_no_hand":
          //   this.dealerShuffledCards=[...res.dealerFinalCards]
          //   if(res.insurance){
          //     if(res.insuranceBetAmount>0){
          //     let convrInsurenceBet = this.topLable_rsp(res.insuranceBetAmount);
          //     this.setState((prevState) => ({
          //       storeinsurancechips:[
          //         ...prevState.storeinsurancechips, {chip:this.ReturnChipBasedAmount(res.insuranceBetAmount), chipvalue:convrInsurenceBet, chipcolor1:"insuranceTextPortraitChip10"},
          //       ],
          //       insurencetbet:res.insuranceBetAmount
          //     }));
          //   }
          //   };

          // if(res.action !== "BUY_DEALER_CARD"){
          //   this.showcardsAdBetsParamState(res,res.playerFinalCards,res.dealerFinalCards);
          //   this.shwnPrvsSteBuyDrawBtchips(res) 
          //   document.getElementById("betactive").src = "";
          //   document.getElementById("bettext").style.color = "";

          //   betbtntextElement.className ="";
          //   foldtextElement.className = "";
          //   document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerPortrait");        
          //   this.setState({ betbutton: "YES", buttontext: "NO", Yesbtn: 1, Nobtn: 1, keyboardhandle: true,
          //     infoText: res.message, pokerrankingdeler: res.dealerHand,pokerranking:res.playerHand,disablemultibutton: false 
          //    });

          //   betbtntextElement.classList.add("betportrait");
          //   betbtntextElement.textContent = this.props.language.Yes;
          //   betbutton.src = Betbtn;
          //   Object.assign(betbutton.style, porttraitejson.Betbtnportraite);

          //   foldtextElement.classList.add("foldtextportrait");
          //   foldbutton.src = Foldbn;
          //   Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);
          //   foldtextElement.textContent = this.props.language.No;
          // }
          // break;


          default:
            break;


        }

      } else {
        this.prevStatecheckPlayerOrDealerWinMblLndspe(res)
      }
    }

  }



  ifPlayerWinsMobilePortraitAction = (res) => {
    // this.playerWin = new Audio(playerWin);
    // this.playerWin.currentTime = 0;

    const winpopuprspelement = document.getElementById("winpopup_rsp");
    const winamtElement = document.getElementById("winamt_rsp");

    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    const betbtntextElement = document.getElementById("betbtntext");
    const foldtextElement = document.getElementById("foldtext");

    const anteText = document.querySelectorAll("#chipcenterposition span");
    const betText = document.querySelector("#betTextaddtoBet");
    const insuranceText = document.querySelectorAll("#chiptoinsurence span");

    const delaerChipAnimDiv = document.getElementById('delaerchipanim')
    const dealerchipElement = document.getElementById('dealerchip');


    if (Number(res.anteWinAmount) > Number(res.anteBetAmount)) {
      dealerchipElement.src = this.ReturnChipBasedAmount(Number(res.anteBetAmount));
      Object.assign(dealerchipElement.style, porttraitejson.dealerchipstoplayerPortrait)

      let t1 = gsap.timeline()
      t1.to("#dealerchip", {
        autoAlpha: 1,
        zIndex: 2,
        x: -75,
        y: 960,

        duration: 0.3,
        ease: "power2.inOut",
        onComplete: () => {
          gsap.set('#dealerchip', { opacity: 0, zIndex: -10 })
        },


      });
      t1.to("#dealerchip", {
        x: -75,
        y: 20,
        duration: 0.002,
        ease: "power2.inOut",
        autoAlpha: 0,
        zIndex: -10

      })
    }

    for (let b = 0; b < anteText.length; b++) {
      anteText[b].textContent = res.anteWinAmount;
    }
    // for(let b=0; b<insuranceText.length; b++){
    //   insuranceText[b].textContent = res.insuranceWinAmount;
    // }
    betText.textContent = res.winAmount;

    this.clertime52 = setTimeout(() => {
      // this.updateplayerbalnace();

      this.clertime53 = setTimeout(() => {
        this.clearActivesWeDid();
        // }, 1000);
      }, 3000);

      // console.log(this.state.anteBetAmount, this.state.bonusbetamount);

      this.setState((prevState) => ({
        isbonuspattable: false,
        // infoText: res.message,
        // totalwinRussian: res.totalWinAmount,
        previousAnteBetAmount: res.anteBetAmount,
        previousBonusbetamount: res.bonusBetAmount,
        insurencetbet: 0,
        anteBetAmount: 0,
        bonusbetamount: 0,
        prevStatestoreantebetchips: [...prevState.storeantebetchips],
        prevStatestorebonuschips: [...prevState.storebonuschips],
        storebonuschips: [],
        // storeinsurancechips: [],
      }));

      if (Number(res.bonusBetAmount) > 0) {
        if (this.state.storebonuschips.length === 0) {
          let convrBonusbet = this.topLable_rsp(res.bonusBetAmount);
          this.setState((prevState) => ({
            prevStatestorebonuschips: [...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(res.bonusBetAmount), chipvalue: convrBonusbet, chipcolor1: "insuranceTextPortraitChip10" },]
          }))
        }
      }







      delaerChipAnimDiv.className = "";
      dealerchipElement.src = "";
      dealerchipElement.className = "";

      this.resetAnimationChips = true;




      setTimeout(() => {
        this.playRemainingSounds(this.playerWin)

        // try{

        // if (this.state.mutesounds) {
        //   this.playerWin.pause();
        // } else {
        //   this.playerWin.play()

        // }
        // }catch(error){
        //   console.log(error)
        // }
        winpopuprspelement.classList.add("winPopupForMptSw");
        winamtElement.classList.add("winamtMpSw");
        this.setState({
          infoText: res.message,
          totalwinRussian: res.totalWinAmount,
        }
        )
      }, 1800)



      gsap.fromTo(winpopuprspelement,
        {
          autoAlpha: 0, scale: 0, duration: 0.5,

        },
        {
          autoAlpha: 1, scale: 1, duration: 0.5, delay: 1.8, rotate: 0, onStart: () => {
            setTimeout(() => {
              // winpopuprspelement.classList.add("winPopupForMptSw");
              // winamtElement.classList.add("winamtMpSw");
              // this.setState({
              //   infoText: res.message,
              //   totalwinRussian: res.totalWinAmount,
              // })
            }, 1500)

          }
        },
      )

      document.getElementById("betactive").src = "";
      document.getElementById("bettext").style.color = "";
      document.getElementById("betactive").classList.remove("betactive");

      // let t1 = gsap.timeline()
      gsap.to("#chipcenterposition", { x: -440, y: 925, duration: 0.5 });
      gsap.to("#betchipsaddtobet", {
        x: -455, y: -200, duration: 0.5, delay: 1, onComplete: () => {
          betText.textContent = Number((res.anteWinAmount + res.winAmount).toFixed(2));
        }
      });
      gsap.to("#betTextaddtoBet", { x: -430, y: -990, duration: 0.5, delay: 1 });

      // gsap.to(this.chipdrawref.current, { x: 102, y: -130, duration: 0.5, delay: 1 });

      // -------------------------------------------------------------------------------------------------
      // let t4 = gsap.timeline();
      // t4.to(this.chipdrawref.current, {
      //   // x: 360, y: -2060, duration: 0.2, zIndex: 12, ease: "power2.inOut", opacity: 1,
      //   x: 320, y: -2060, duration: 0.2, zIndex: 12, ease: "power2.inOut", opacity: 1,
      //   onComplete: () => {
      //     gsap.set(this.chipdrawref.current, { opacity: 0 });
      //   }
      // });
      // t4.to(this.chipdrawref.current, {
      //   x: 548, y: -36, duration: 0.001, zIndex: -2, opacity: 0,
      //   onComplete: () => {
      //     gsap.set(this.chipdrawref.current, { opacity: 1, delay: 2 });
      //   }
      // });

      // t4.play();

      if (res.insuranceWinAmount > 0) {

        for (let b = 0; b < insuranceText.length; b++) {
          insuranceText[b].textContent = res.insuranceWinAmount;
        }

        // gsap.to("#chiptoinsurence", { x: -430, y: 1130, duration: 0.5, delay: 1 });
        // gsap.to("#chiptoinsurence", { x: -430, y: -1090, duration: 0.5, delay: 1 });

        gsap.to("#chiptoinsurence img", { x: -430, y: 1130, duration: 0.5, delay: 1, autoAlpha: 1 });
        gsap.to("#chiptoinsurence span", {
          x: -430, y: 1130, duration: 0.5, delay: 1, autoAlpha: 1, onComplete: () => {
            for (let b = 0; b < insuranceText.length; b++) {
              insuranceText[b].textContent = Number((res.insuranceWinAmount + res.anteWinAmount + res.winAmount).toFixed(2));
            }
          }
        });
      } else {
        // gsap.to("#chiptoinsurence", { x: 15, y: -1700, duration: 0.3, zIndex: -2, opacity: 0 });
        // gsap.to("#chiptoinsurence", { x: 15, y: -2950, duration: 0.3, zIndex: -2, opacity: 0 });

        gsap.to("#chiptoinsurence img", {
          x: 0, y: -900, duration: 0.3, delay: 0.5, autoAlpha: 1, onComplete: () => {
            gsap.set("#chiptoinsurence img", { autoAlpha: 0, duration: 0.00001 })
          }
        });
        gsap.to("#chiptoinsurence span", {
          x: 0, y: -900, duration: 0.3, delay: 0.5, autoAlpha: 1, onComplete: () => {
            gsap.set("chiptoinsurence span", { autoAlpha: 0, duration: 0.00001 })
          }
        });
      }


      // if(this.wanttobuydelercrd === true){
      //    gsap.to(this.buyDealerCardDrawn.current, { x: -450, y: -280, duration: 0.5, delay: 1 });
      // }

      if (this.wanttobuydelercrd === true) {
        let t1 = gsap.timeline()
        t1.to(this.buyDealerCardDrawn.current, { x: -190, y: -2200, duration: 0.5 });
        t1.to(this.buyDealerCardDrawn.current, { opacity: 0, });
        t1.to(this.buyDealerCardDrawn.current, { opacity: 0, x: 0, y: 0, zIndex: -5 });
        t1.play();
      }

      this.setState({ betbutton: "REBET", buttontext: "REBETX2", disablemultibutton: false, bsbtn: true, betchipincrementbtn: false, betdecreamentbtn: false })




    }, 2000)

    this.resetPyrWnCpsAmn = true;

    setTimeout(() => {
      this.updateplayerbalnace();
      this.updateplayerbalnaceIfplayerWins()
    }, 6000)

    // this.clertime54 = setTimeout(() => {
    //   document.getElementById("mobileAntiMaskCover").style.display = "none";
    //   gsap.fromTo(winpopuprspelement, { scale: 1, duration: 0.5, rotate: 360 }, {
    //     autoAlpha: 0, scale: 0, duration: 0.5, onComplete: () => {
    //       winpopuprspelement.classList.remove("winPopupForMptSw");
    //       winamtElement.classList.remove("winamtMpSw");
    //     }
    //   })
    //   // this.updateplayerbalnace();
    //   this.setState({
    //     totalwinRussian: 0,
    //     totalbet: 0,
    //     infoText: this.props.language.Play_Njoy,
    //     storeantebetchips: [],
    //     storeinsurancechips: [],
    //   });

    //   betbutton.src = Betbtn;
    //   // Object.assign(betbutton.style, porttraitejson.Betbtnportraite);
    //   Object.assign(betbutton.style, this.state.betbuttoncss);

    //   betbtntextElement.classList.remove('noinsurencePortrait', 'betportraite');

    //   betbtntextElement.textContent = this.props.language.Rebet;


    //   // betbtntextElement.classList.add("betportrait");
    //   betbtntextElement.classList.add(`${this.state.betbtntextcss}`);

    //   // Object.assign(betbtntextElement.style, porttraitejson.betportraite);

    //   foldbutton.src = Foldbnwindows;
    //   // Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);
    //   Object.assign(foldbutton.style, this.state.foldbuttoncss);

    //   foldtextElement.classList.remove('insurencePortrait', 'foldtextportraite', 'foldtextportrait');
    //   foldtextElement.textContent = this.props.language.RebetX2;
    //   // foldtextElement.classList.add("rebettextportrait");
    //   foldtextElement.classList.add(`${this.state.foldbtntextcss}`);


    //   const hands = [
    //     "onepair", "Threekind", "aceking", "fullhse",
    //     "Straightflush", "frofkind", "twopair",
    //     "flush", "stright", "royalflush"
    //   ];
    //   hands.forEach(hand => {
    //     document.getElementById(hand).style.color = "";
    //   });


    //   if (this.resetAnimationChips) {
    //     if (document.getElementById("chipcenterposition")) {
    //       gsap.to("#chipcenterposition", { x: 13, y: 1200, duration: 0.00001, zIndex: -2, opacity: 0 });
    //     }

    //     if (document.getElementById("betchipsaddtobet")) {
    //       gsap.to("#betchipsaddtobet", { x: 1, y: 76, duration: 0.00001, zIndex: -2, opacity: 0 });
    //       gsap.to("#betTextaddtoBet", { x: 153, y: 185, duration: 0.00001, zIndex: -2, opacity: 0 });
    //     }

    //     // gsap.to("#chiptoinsurence" , {x:550, y: 145, duration:0.5,zIndex:-2,opacity:0});
    //     if (document.getElementById("chiptoinsurence")) {
    //       gsap.to("#chiptoinsurence", { x: 15, y: 1376, duration: 0.00001, zIndex: -2, opacity: 0 });
    //     };

    //     // gsap.to(this.chipdrawref.current, { x: 548, y: 140, duration: 0.5, delay: 1, zIndex: -5, opacity: 1 });
    //     gsap.to(this.chipdrawref.current, { x: 548, y: -36, duration: 0.00001, zIndex: -5, opacity: 0 });
    //     // if (this.playerWinBonus === true) {

    //     // if (document.getElementById("chip10centerbns")) {
    //     //   gsap.to("#chip10centerbns", { x: -170, y: 1200, duration: 0.00001, zIndex: -2, opacity: 1 });
    //     //   this.playerWinBonus = false;

    //     // }


    //     // }
    //     if (this.wanttobuydelercrd === true) {
    //       gsap.to(this.buyDealerCardDrawn.current, { x: 0, y: 0, duration: 0.00001, zIndex: -2, opacity: 0 });
    //     }
    //   }

    //   // }, 4000)
    // }, 8000)
    // }, 100000)



  }



  muteunmutesounds() {
    // this.getClickSound();

    this.selectCard.currentTime = 0;
    if (this.state.mutesounds) {
      this.selectCard.play();
    } else {
      this.selectCard.pause();
    }

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {
      document.getElementById('volumeRussian').classList.add("addpointer")
    }

    if (!this.state.mutesounds) {
      this.setState({ mutesounds: true })
      document.getElementById('volumeRussian').src = mutesnds;

    }
    else {

      this.setState({ mutesounds: false })
      document.getElementById('volumeRussian').src = volumeimg;

    }
  }


  Dealerbetstoplayer = (response) => {

    if (response.anteWinAmount > response.anteBetAmount) {


      let dealerchip = document.getElementById("dealerchip")

      switch (this.state.count) {

        case 1:
          dealerchip.src = chip_10;

          break;
        case 2:
          dealerchip.src = chip_25;

          break;
        case 3:
          dealerchip.src = chip_50;

          break;
        case 4:
          dealerchip.src = chip_100;

          break;
        case 5:
          dealerchip.src = chip_500;

          break;
        case 6:
          dealerchip.src = chip_1000;

          break;

        default:
          break;
      }
      Object.assign(dealerchip.style, porttraitejson.dealerchipstoplayer)
      let tl = gsap.timeline()
      tl.to(dealerchip, {
        x: -16,
        y: 622,
        duration: 0.2,
        opacity: 1,
        //  delay: 1.5,
        ease: "power2.inOut",
        onComplete: () => {
          gsap.set(dealerchip, { opacity: 0 })

        }
      });
      tl.to(dealerchip, {
        x: 31,
        y: 0,
        duration: 0.5,
        ease: "power2.inOut",

      });
      // tl.play();
    }

  }


  hidepopup_betshigh = () => {

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnce");
      if (document.getElementById("popupbet_greaterthanbalnce")) {
        gsap.to(popupbetGreaterthanBalnceElement, {
          scale: 0,
          duration: 1,
          rotate: 0,
          opacity: 0,
          ease: "power2.out",
          onComplete: () => {
            popupbetGreaterthanBalnceElement.className = "";
            popupbetGreaterthanBalnceElement.classList.add("popupbetshighMobileIntial");
          }

        }
        );

      }
    } else {
      this.setState({ betshigh_balnce: false })
      const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnce");
      if (document.getElementById("popupbet_greaterthanbalnce")) {
        gsap.to(popupbetGreaterthanBalnceElement, {
          scale: 0,
          duration: 1,
          rotate: 0,
          opacity: 0,
          ease: "power2.out",
          onComplete: () => {
            popupbetGreaterthanBalnceElement.className = "";
            popupbetGreaterthanBalnceElement.classList.add("popupbetshighMobileIntial");
          }

        }
        );

      }


    }






  }

  updatePlayerInfo() {

    this.setState({ isplayerinfoupdate: true })


    this.setState({
      betdeductions: 0
    });


    //  const rankinginfoElement = document.getElementById("rankinginfo");
    //  if (rankinginfoElement) {
    //   if (this.state.isMobilePortraitLndsp) {
    //      rankinginfoElement.style.left = "34%";
    //    }
    //  }

  }


  showAnteBetchips = () => {

    return (
      this.state.storeantebetchips.map((eachchip, index) => (
        <div key={index} id="chipcenterposition" className={`${this.state.antebetpdvcss}`}>
          <img
            className={`${this.state.antechpimgcss}`}
            src={eachchip.chip}
            alt="Chip"
          />
          <span className={`${eachchip.chipcolor1} ${this.state.antespntxtcss}`} >{eachchip.chipvalue}</span>
        </div>
      ))
    )
  }

  showBonusBetchips = () => {



    return (

      this.state.storebonuschips.map((eachchip, index) => (
        <div key={index} id="chip10centerbns" className={`${this.state.bonusdivcss}`}>
          <img
            className={`${this.state.bonusimgcss}`}
            src={eachchip.chip}
            alt="Chip"
          />
          {/* id="val_10" */}
          <span id={`bonusAmount${index}`} className={`${eachchip.chipcolor1} ${this.state.bonuspntxtcss}`} >{eachchip.chipvalue}</span>
        </div>
      ))
    )

  }

  showInsuranceBetChips = () => {

    return (
      this.state.storeinsurancechips.map((eachchip, index) => (
        <div key={index} id="chiptoinsurence" className={`${this.state.insurancedivcss}`}>
          <img
            className={`${this.state.insurancechipcss}`}
            src={eachchip.chip}
            alt="Chip"
          />
          {/* id="val_10" */}
          <span className={`${eachchip.chipcolor1} ${this.state.insurancetxtcss}`} >{eachchip.chipvalue}</span>
        </div>
      ))
    )



  }

  ShowHistory() {



    this.setState({ ShowHistory: !this.state.ShowHistory, ShowGamerules: false, ShowLimits: this.state.ShowLimits ? false : this.state.ShowLimits, ishistorytable: true }, () => {



      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {

        if (document.getElementById("histry_russian")) {
          document.getElementById("histry_russian")?.classList.remove("histry_russianportraite")

        }
        if (document.getElementById("exithistory")) {
          document.getElementById("exithistory")?.classList.remove("exihstrybuttonportraite")

        }
        if (document.getElementById("prevhistory")) {
          document.getElementById("prevhistory")?.classList.remove("showprevhsrytbleportraite")

        }
        if (document.getElementById("histry_body")) {
          document.getElementById("histry_body")?.classList.remove("histry_bodyportraite")
        }
        if (document.getElementById("heading_hstiry")) {
          document.getElementById("heading_hstiry")?.classList.remove("heading_hstiryportraite")

        }
        if (document.getElementById("histry_russian")) {
          document.getElementById("histry_russian")?.classList.remove("histry_russianlndspe")

        }
        if (document.getElementById("exithistory")) {
          document.getElementById("exithistory")?.classList.remove("exihstrybuttonlndspe")

        }
        if (document.getElementById("prevhistory")) {
          document.getElementById("prevhistory")?.classList.remove("showprevhsrytblelndspe")

        }
        if (document.getElementById("histry_body")) {
          document.getElementById("histry_body")?.classList.remove("histry_bodylndspe")

        }
        if (document.getElementById("heading_hstiry")) {
          document.getElementById("heading_hstiry")?.classList.remove("heading_hstirylndspe")

        }


        if (document.getElementById("histry_russian")) {
          document.getElementById("histry_russian").classList.add("histry_russian")

        }
        if (document.getElementById("exithistory")) {
          document.getElementById("exithistory").classList.add("exihstrybutton")

        }
        if (document.getElementById("prevhistory")) {
          document.getElementById("prevhistory").classList.add("showprevhsrytble")

        }
        if (document.getElementById("histry_body")) {
          document.getElementById("histry_body").classList.add("histry_body")

        }
        if (document.getElementById("heading_hstiry")) {
          document.getElementById("heading_hstiry").classList.add("heading_hstiry")

        }

        // document.getElementById("dealercards_hstry").classList.add("dealercards_hstry")
        // document.getElementById("dealerhand_hstry").classList.add("dealerhand_hstry")
        // document.getElementById("playercards_hstry").classList.add("playercards_hstry")
        // document.getElementById("playerhand_hstry").classList.add("playerhand_hstry")
        // document.getElementById("totalbet_histry").classList.add("totalbet_histry")
        // document.getElementById("totalwin_hstry").classList.add("totalwin_hstry")

      }
      else {

        if (window.innerHeight > window.innerWidth) {

          if (document.getElementById("histry_russian")) {
            document.getElementById("histry_russian")?.classList.remove("histry_russianlndspe")

          }
          if (document.getElementById("exithistory")) {
            document.getElementById("exithistory")?.classList.remove("exihstrybuttonlndspe")

          }
          if (document.getElementById("prevhistory")) {
            document.getElementById("prevhistory")?.classList.remove("showprevhsrytblelndspe")

          }
          if (document.getElementById("histry_body")) {
            document.getElementById("histry_body")?.classList.remove("histry_bodylndspe")

          }
          if (document.getElementById("heading_hstiry")) {
            document.getElementById("heading_hstiry")?.classList.remove("heading_hstirylndspe")

          }
          if (document.getElementById("histry_russian")) {
            document.getElementById("histry_russian")?.classList.remove("histry_russian")

          }
          if (document.getElementById("exithistory")) {
            document.getElementById("exithistory")?.classList.remove("exihstrybutton")

          }
          if (document.getElementById("prevhistory")) {
            document.getElementById("prevhistory")?.classList.remove("showprevhsrytble")

          }
          if (document.getElementById("histry_body")) {
            document.getElementById("histry_body")?.classList.remove("histry_body")

          }
          if (document.getElementById("heading_hstiry")) {
            document.getElementById("heading_hstiry").classList.add("heading_hstiry")

          }

          if (document.getElementById("histry_russian")) {
            document.getElementById("histry_russian").classList.add("histry_russianportraite")

          }
          if (document.getElementById("exithistory")) {
            document.getElementById("exithistory").classList.add("exihstrybuttonportraite")

          }
          if (document.getElementById("prevhistory")) {
            document.getElementById("prevhistory").classList.add("showprevhsrytbleportraite")

          }
          if (document.getElementById("histry_body")) {
            document.getElementById("histry_body").classList.add("histry_bodyportraite")

            if (document.getElementById("heading_hstiry")) {
              document.getElementById("heading_hstiry").classList.add("heading_hstiryportraite")

            }

          }


        }
        else {


          if (document.getElementById("histry_russian")) {
            document.getElementById("histry_russian")?.classList.remove("histry_russian")

          }
          if (document.getElementById("exithistory")) {
            document.getElementById("exithistory")?.classList.remove("exihstrybutton")

          }
          if (document.getElementById("prevhistory")) {
            document.getElementById("prevhistory")?.classList.remove("showprevhsrytble")

          }
          if (document.getElementById("histry_body")) {
            document.getElementById("histry_body")?.classList.remove("histry_body")

          }
          if (document.getElementById("heading_hstiry")) {
            document.getElementById("heading_hstiry")?.classList.remove("heading_hstiry")

          }

          if (document.getElementById("histry_russian")) {
            document.getElementById("histry_russian")?.classList.remove("histry_russianportraite")

          }
          if (document.getElementById("exithistory")) {
            document.getElementById("exithistory")?.classList.remove("exihstrybuttonportraite")

          }
          if (document.getElementById("prevhistory")) {
            document.getElementById("prevhistory")?.classList.remove("showprevhsrytbleportraite")

          }
          if (document.getElementById("histry_body")) {
            document.getElementById("histry_body")?.classList.remove("histry_bodyportraite")
          }
          if (document.getElementById("heading_hstiry")) {
            document.getElementById("heading_hstiry").classList.remove("heading_hstiryportraite")

          }


          if (document.getElementById("histry_russian")) {
            document.getElementById("histry_russian").classList.add("histry_russianlndspe")

          }
          if (document.getElementById("exithistory")) {
            document.getElementById("exithistory").classList.add("exihstrybuttonlndspe")

          }
          if (document.getElementById("prevhistory")) {
            document.getElementById("prevhistory").classList.add("showprevhsrytblelndspe")

          }
          if (document.getElementById("histry_body")) {
            document.getElementById("histry_body").classList.add("histry_bodylndspe")

          }
          if (document.getElementById("heading_hstiry")) {
            document.getElementById("heading_hstiry").classList.add("heading_hstirylndspe")

          }

        }

      }

    })


  }



  exithistory() {
    this.setState({ ShowHistory: false })

  }
  hideshowoptionmenu() {

    this.setState({ isoptionmenu: false, ShowLimits: false })
  }
  // animexit(){

  //   if(document.getElementById("exithistory")){
  //     document.getElementById("exithistory").classList.add("exithistory")

  //   }

  // }
  confirmfoldresp() {

  }


  confirmFoldactin(cnfrmtext) {
    if (this.state.mutesounds) {
      this.betrussiansnd.pause();
    }
    else {
      this.betrussiansnd.play();

    }
    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }
    const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popup");
    if (cnfrmtext === true) {
      let betexttodraw = document.getElementById("betchipsaddtodrawText");
      betexttodraw.classList.remove("bet-text-addtodraw", "bet-text-addtoinsurence10")
      const drawtextElement = document.getElementById("drawtext");
      const buytextElement = document.getElementById("buytext");
      const buybutton = document.getElementById("buybtn");
      const drawbutton = document.getElementById("drawbtn");
      buybutton.src = "";
      drawbutton.src = "";
      drawtextElement.textContent = "";
      buytextElement.textContent = "";

      document.getElementById("alertTxt").classList.remove("alertTxt");

      document.getElementById("alertTxt").innerHTML = "";
      document.getElementById("confirmbuydealer_popup").style.display = "none";
      document.getElementById("confirmbuydealer_popup").classList.remove("popup_cnfrmbuydealrcard");
      document.getElementById("yes").classList.remove("yesbutton_popup")
      document.getElementById("no").classList.remove("nobutton_popup")

      confirmbuydealerPopupElement.className = "";
      confirmbuydealerPopupElement.style.display = "none";
      confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardPortrait");
      document.getElementById("alertTxt").innerHTML = "";
      document.getElementById("alertTxt").classList.remove('alertTxtPortrait');
      document.getElementById("yes").classList.remove("yesbutton_popup_portrait")
      document.getElementById("no").classList.remove("nobutton_popup_portrait")



      confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardLndsp");
      document.getElementById("alertTxt").innerHTML = "";
      document.getElementById("alertTxt").classList.remove('alertTxtPortrait');
      document.getElementById("yes").classList.remove("yesbutton_popup_portrait")
      document.getElementById("no").classList.remove("nobutton_popup_portrait")

      const body = {
        sessionId: sessionStorage.getItem("sessionId"),

        CN: "RUSSIAN_POKER_BET",
        object: {
          action: "CONFIRM_FOLD",
          gameId: this.props.gameId,
          playerId: Number(this.props.playerId),
          handId: Number(this.props.gameState.handId)
        },
      };

      this.props.network.sendof(body);

    }

    else {
      if (this.drawReplaceCards === true) {
        this.showParamBetStatus(true);
      } else {
        this.showParamBetStatus(false);
      }
      document.getElementById("alertTxt").classList.remove("alertTxt");

      document.getElementById("alertTxt").innerHTML = "";
      document.getElementById("confirmbuydealer_popup").classList.remove("popup_cnfrmbuydealrcard");
      document.getElementById("yes").classList.remove("yesbutton_popup")
      document.getElementById("no").classList.remove("nobutton_popup")

      confirmbuydealerPopupElement.className = "";
      confirmbuydealerPopupElement.style.display = "none";
      confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardPortrait");
      document.getElementById("alertTxt").innerHTML = "";
      document.getElementById("alertTxt").classList.remove('alertTxtPortrait');
      document.getElementById("yes").classList.remove("yesbutton_popup_portrait")
      document.getElementById("no").classList.remove("nobutton_popup_portrait")



      confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardLndsp");
      document.getElementById("alertTxt").innerHTML = "";
      document.getElementById("alertTxt").classList.remove('alertTxtPortrait');
      document.getElementById("yes").classList.remove("yesbutton_popup_portrait")
      document.getElementById("no").classList.remove("nobutton_popup_portrait")

    }

  }




  ShowLimits() {
    this.setState({ ShowLimits: !this.state.ShowLimits, ShowGamerules: false, ShowHistory: this.state.ShowHistory ? false : this.state.ShowHistory }, () => {

      // exitLimits
      const exitLimitsElement = document.getElementById("exitLimits");
      // exitLimitsElement.className = "";
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {
        document.getElementById("Limits")?.classList.remove("LimitsPopup_landspe")
        document.getElementById("Limits")?.classList.remove("LimitsPopup_portraite")
        document.getElementById("Limits")?.classList.add("LimitsPopup");
        // exitLimitsElement.classList.add("exihstrybuttonportraite");
      }
      else {
        if (window.innerHeight > window.innerWidth) {
          document.getElementById("Limits")?.classList.remove("LimitsPopup")
          document.getElementById("Limits")?.classList.remove("LimitsPopup_landspe")
          document.getElementById("Limits")?.classList.add("LimitsPopup_portraite")
          // exitLimitsElement.classList.add("exihstrybuttonportraite");

        }
        else {

          document.getElementById("Limits")?.classList.remove("LimitsPopup")
          document.getElementById("Limits")?.classList.remove("LimitsPopup_portraite")
          document.getElementById("Limits")?.classList.add("LimitsPopup_landspe")
          // exitLimitsElement.classList.add("exihstrybuttonportraite");

        }
      }

    })

  }

  exitLimits = () => {
    this.setState({ ShowLimits: false })
  }
  Gamerules = () => {
    this.setState({ ShowGamerules: !this.state.ShowGamerules, ShowHistory: false, ShowLimits: false }, () => {

      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {

        // this.setState({ShowGamerules:true},()=>{


        document.getElementById("ShowGamerules")?.classList.remove("Gamerulescontainerport")
        document.getElementById("gameruleMainHeading")?.classList.remove("gameruleMainHeadingport")
        document.getElementById("unorderedlistitems")?.classList.remove("unorderedlistitemsport")
        document.getElementById("ShowGamerules")?.classList.remove("Gamerulescontainerlnd")
        document.getElementById("gameruleMainHeading")?.classList.remove("gameruleMainHeadinglnd")
        document.getElementById("unorderedlistitems")?.classList.remove("unorderedlistitemslnd")

        document.getElementById("ShowGamerules")?.classList.add("Gamerulescontainer")
        document.getElementById("gameruleMainHeading")?.classList.add("gameruleMainHeading")
        document.getElementById("unorderedlistitems")?.classList.add("unorderedlistitems")

        // })

      }
      else {
        if (window.innerHeight > window.innerWidth) {


          document.getElementById("ShowGamerules")?.classList.remove("Gamerulescontainer")
          document.getElementById("gameruleMainHeading")?.classList.remove("gameruleMainHeading")
          document.getElementById("unorderedlistitems")?.classList.remove("unorderedlistitems")
          document.getElementById("ShowGamerules")?.classList.remove("Gamerulescontainerlnd")
          document.getElementById("gameruleMainHeading")?.classList.remove("gameruleMainHeadinglnd")
          document.getElementById("unorderedlistitems")?.classList.remove("unorderedlistitemslnd")

          document.getElementById("ShowGamerules")?.classList.add("Gamerulescontainerport")
          document.getElementById("gameruleMainHeading")?.classList.add("gameruleMainHeadingport")
          document.getElementById("unorderedlistitems")?.classList.add("unorderedlistitemsport")



        }
        else {

          document.getElementById("ShowGamerules")?.classList.remove("Gamerulescontainer")
          document.getElementById("gameruleMainHeading")?.classList.remove("gameruleMainHeading")
          document.getElementById("unorderedlistitems")?.classList.remove("unorderedlistitems")
          document.getElementById("ShowGamerules")?.classList.remove("Gamerulescontainerport")
          document.getElementById("gameruleMainHeading")?.classList.remove("gameruleMainHeadingport")
          document.getElementById("unorderedlistitems")?.classList.remove("unorderedlistitemsport")
          document.getElementById("ShowGamerules")?.classList.add("Gamerulescontainerlnd")
          document.getElementById("gameruleMainHeading")?.classList.add("gameruleMainHeadinglnd")
          document.getElementById("unorderedlistitems")?.classList.add("unorderedlistitemslnd")



        }
      }
    })
  }

  closeMenuOption = () => {
    this.setState({
      isoptionmenu: false,
      ShowLimits: false,
      ShowHistory: false,
      ShowGamerules: false,
    })
  }

  renderTheBonusForView = () => {
    const { isbonuspattable } = this.state

    if (navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)) {
      return (
        <div className="grid">
          {/* 
        <table className="bonuspaytable" id="bonuspaytableInfo">
          <thead id="bonusInfoThead">
            <tr>
              <th id="heading_table" colSpan="2">BONUS PAY TABLE</th>
            </tr>
          </thead>
          <tbody id="bonusInfoTbody">
            {this.bonuspaytable.map((obj, i) => (
              <tr key={`card-${obj.k}`} id={`numbers${i}`}>
                <td id={`numrussian${i}`}>{obj.k}</td>
                <td id={`numbersvalrussian${i}`}>{obj.val}</td>
              </tr>
            ))}
          </tbody>
        </table> */}

          <table className="bonuspaytable" id="bonuspaytableInfo">
            <thead id="bonusInfoThead">
              <tr>
                <th id="heading_table" className="windowstablehead" colSpan="2">BONUS PAY TABLE</th>
              </tr>
            </thead>
            <tbody id="bonusInfoTbody">
              {this.bonuspaytable.map((obj, i) => {
                const parts = obj.val.split(" to ");
                console.log(parts)
                return (
                  <tr className="numbers" key={`card-${obj.k}`} id={`numbers${i}`}>
                    <td className="num" id={`numrussian${i}`}>{obj.k}</td>
                    <td className="numbersval" id={`numbersvalrussian${i}`}>
                      {parts[0]} <span className="span-to">to</span> {parts[1]}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

        </div>
      )
    } else {
      return (
        isbonuspattable ? (
          <div className="grid">

            <table className="bonuspaytable" id="bonuspaytableInfo">
              <thead id="bonusInfoThead">
                <tr>
                  <th id="heading_table" colSpan="2">BONUS PAY TABLE</th>
                </tr>
              </thead>
              <tbody id="bonusInfoTbody">
                {this.bonuspaytable.map((obj, i) => (
                  <tr key={`card-${obj.k}`} id={`numbers${i}`}>
                    <td id={`numrussian${i}`}>{obj.k}</td>
                    <td id={`numbersvalrussian${i}`}>{obj.val}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : ""
      )
    }
  }

  removehigltbonusclasses = (response) => {

    const highlightedElements = document.querySelectorAll('.bonuswinhglt, .bonuspayouthglt');


    highlightedElements.forEach(element => {
      element.classList.remove('bonuswinhglt', 'bonuspayouthglt');
    });

    this.bonuspaytable.map((obj, val) => {
      const bonushandplayer = document.getElementById(`numrussian${val}`);
      bonushandplayer.classList.add("num")
    });
    //   const bonushandplayerval = document.getElementById(`numbersvalrussian${val}`);



    //     let playerbonuhand = response.playerHand.split(" , ");

    //     if (bonushandplayer && playerbonuhand.includes(obj.k)) {
    //       if(bonushandplayer.classList.contains("bonuswinhglt")){
    //         bonushandplayer.classList.remove("bonuswinhglt")
    //         bonushandplayer.style.color="";
    //       }
    //       if(bonushandplayerval.classList.contains("bonuspayouthglt")){
    //         bonushandplayerval.classList.remove("bonuspayouthglt")
    //         bonushandplayerval.style.color="";
    //       }




    //       bonushandplayer.classList.add("num")



    //   }
    // });

  }

  showTableImage = () => {

    const pokertable = document.getElementById("pokertableim");
    const pokertablelnspe = document.getElementById("pokertablelandscape");
    const Russiantblewindows = document.getElementById("rsntblewindows");
    if (pokertable && pokertable) {
      if (pokertable.style?.cssText && pokertable?.src) {
        pokertable.style.cssText = "";
        pokertable.src = "";
      }
    }

    if (pokertablelnspe && pokertablelnspe) {
      if (pokertablelnspe.style?.cssText && pokertablelnspe?.src) {
        pokertablelnspe.style.cssText = "";
        pokertablelnspe.src = "";
      }

    }

    if (Russiantblewindows && Russiantblewindows) {
      if (Russiantblewindows.style?.cssText && Russiantblewindows?.src) {
        Russiantblewindows.style.cssText = "";
        Russiantblewindows.src = "";
      }

    }

    // if (navigator.userAgentData?.platform === "Windows" || navigator.userAgent.includes("Windows")) {
    //   if (Russiantblewindows) {
    //     Russiantblewindows.src = Rutablewindows;
    //     Object.assign(Russiantblewindows.style, porttraitejson.russtablewindows)
    //   }

    // 
    if (navigator.userAgentData?.platform === "Windows" || navigator.userAgent.includes("Windows")) {
      import("../../../../../assets/games/russianPoker/russiantblewindows.png").then((module) => {
        const Rutablewindows = module.default;
        if (Russiantblewindows) {
          Russiantblewindows.src = Rutablewindows;
          Object.assign(Russiantblewindows.style, porttraitejson.russtablewindows);
        }
      });
    }
    else {
      if (window.innerHeight > window.innerWidth) {
        if (pokertable) {

          pokertable.style.backgroundSize = "cover";

          import("../../../../../assets/games/russianPoker/AssetTable.png").then((module) => {
            const pokertableruss = module.default;
            pokertable.src = pokertableruss;
          });
          Object.assign(pokertable.style, porttraitejson.pokertable);
        }

      } else {
        if (pokertablelnspe) {
          Object.assign(pokertablelnspe.style, porttraitejson.pokertableland);
          import("../../../../../assets/games/russianPoker/landscapetable.png").then((module) => {
            const pokertablelandsp = module.default;
            pokertablelnspe.src = pokertablelandsp;
          });

        }

      }

    }


  }

  render() {

    const isWindows = navigator.userAgentData?.platform === "Windows" || navigator.userAgent.includes("Windows");
    const isPortrait = window.innerHeight > window.innerWidth;



    this.roundNumber = this.props.balance - this.state.betdeductions
    this.betAmount = (this.roundNumber)
    // this.state.balance_rsp = this.topLable_rsp(this.betAmount);
    // this.state.updateBet = this.topLable_rsp(this.state.totalbet)

    // let balance_rsp = this.topLable_rsp(this.betAmount);
    // let updateBet = this.topLable_rsp(this.state.totalbet)
    let balance_rsp = Number(this.betAmount.toFixed(2));
    let updateBet = Number(this.state.totalbet.toFixed(2));
    let popup_winamount = this.topLable_rsp(this.state.totalwinRussian)
    // console.log(this.props.balance, "     ",this.state.betdeductions,"         ",this.roundNumber,balance_rsp)


    const {
      heightOfLayer,
      widthOfLayer,
      dealerDistanceFromX,
      playerDistanceFromX,
      dealerPlayerDistanceFromY,
      isMobilePortraitLndsp,
      dealerDistanceFromY,
      PlayerDistanceFromY,
      pokerranking
    } = this.state;

    return (
      <Fragment>

        <div id="bacaratbgim" className="RP_MainDiv" onClick={() => {

          if (this.state.isoptionmenu === true) {
            this.closeMenuOption();
          }
        }}>

          <img src={this.showTableImage()} id="pokertableim" alt="" />
          <img src={this.showTableImage()} id="pokertablelandscape" alt="" />
          {/* <img src="" id="pokertableim" alt="" />
          <img src="" id="pokertablelandscape" alt="" /> */}

          <img id="optionrussian" src="" onClick={(e) => { e.preventDefault(); this.Showoptionsmenu(); }} alt="" />



          <img src="" id="russiantitle" alt="" />
          <img src="" id="balnceicon" alt="" />
          <span id="balance">{balance_rsp}</span>
          <img src="" id="beticon" alt="" />
          <span id="bets">{updateBet}</span>
          <img src="" id="exitbtn" alt="" />
          <img src="" id="winnericon" alt="" />
          <div id="playerDetails">
            <span>P ID:{this.props.playerId}</span>
            <span>ID:{this.props.gameState.handId}</span>
          </div>
          <div id="wins">
            <span id="winamount_rsp">{this.state.totalwinRussian}</span>
          </div>

          <div id="betcontainer" className="betpannelrussian">
            <button disabled={this.state.antebetcircle}>
              <img src="" id="betcircle"
                onClick={(e) => { e.preventDefault(); this.anteclick(); }}

                alt="" />
            </button>

            <button disabled={this.state.insurencebtn}>
              <img src="" id="insurenceRussian" onClick={(e) => { e.preventDefault(); this.Insurenceactived(); }} alt="" />
            </button>

            <button disabled={this.state.bsbtn}>
              <img src="" id="bonusRussian"
                onClick={(e) => { e.preventDefault(); this.BonusActivated(); }}
                alt="" />
            </button>
            <img src="" id="bnsactive" alt="" className={this.state.bnsactvglwcss} />
            <img src="" id="betactive" alt="" className={this.state.betactvglwcss} />
            <img src="" id="insurenceactive" alt="" className={this.state.insurenceactivecss} />
            <img src="" id="buydrawRussian" alt="" />
            <img src="" id="betRussian" alt="" />
            <span id="insurencetext">{this.props.language.Insurence}</span>
            <span id="bonustext">{this.props.language.Bonus}</span>
            <span id="bettext">{this.props.language.Bet}</span>
            <span id="buydrawtext">
              {this.props.language.Draw}
              <br />
              {`/${this.props.language.Buy}`}
            </span>
            <span id="antetext">{this.props.language.Ante}</span>
          </div>

          <div ref={this.buyDealerCardDrawn} id="dealerBuyCard">
            <button type="button" id="dealerChipsAddToBuyButton"> <img src="" id="dealerChipsAddToBuy" alt="" /></button>
            <span id="dealerChipsAddToBuyText"></span>
          </div>

          <div id="betpannelrussiancover" className={this.state.betpannelrussiancovercss}></div>
          <div id="betpannelinsurececover"></div>

          <div id="mobileAntiMaskCover" className={this.state.mobileAntiMaskCovercss}></div>


          <div className="player-dealer-card-container" id="playerDealerCardContainer">

            <div id="container_playerdealer">
              <h1 id="Dealer">
                DEALER
              </h1>
              <h2 id="Player">
                PLAYER
              </h2>
            </div>
            {/* <Stage width={window.innerWidth} height={window.innerHeight} > */}
            {/* <Stage width={2350} height={heightOfLayer}> */}
            <Stage width={widthOfLayer} height={heightOfLayer}
              style={{ width: `${widthOfLayer}px`, height: `${heightOfLayer}px` }}
              pixelRatio={1}
            >
              <Layer>
                <Group x={dealerDistanceFromX} y={dealerDistanceFromY} stroke="black">
                  <DealerCardsContainer ref={this.dealerCardsContainer} x={450} y={400} xPadding={18}
                    scaleX={this.state.scaleX} scaleY={this.state.scaleY}
                    isMobilePortrait={isMobilePortraitLndsp} activeScreen={this.state.activeScreen} mutesounds={this.state.mutesounds} />
                </Group>
                <Group x={playerDistanceFromX} y={PlayerDistanceFromY} stroke="black">
                  <Cards ref={this.cardsRef} x={450} y={400} xPadding={18} previousGamestateCardLength6={this.props.previousGamestateCardLength6}
                    scaleX={this.state.scaleX} scaleY={this.state.scaleY}
                    ratioed={1} hidecardPos={30} numberOfCardsToBeDraw={this.numberOfCardsToBeDraw}
                    isMobilePortrait={isMobilePortraitLndsp} mutesounds={this.state.mutesounds} activeScreen={this.state.activeScreen} />
                </Group>
              </Layer>

            </Stage>
          </div>


          {(this.state.isMobilePortraitLndsp === true) && (
            <div id="chipsstoredcontainer" ref={this.chipsarray} className={this.state.chipsstoredcontainercss}>
              {/* {this.state.storebetchips} */}
              {this.showAnteBetchips()}
              {this.showBonusBetchips()}
              {this.showInsuranceBetChips()}

            </div>

          )}

          <button disabled={this.state.drawbuttonhandle}>
            <img src="" id="drawbtn" onClick={(e) => { e.preventDefault(); this.drawCard(false); }} alt="" style={this.state.drawbtncss} />
          </button>
          <button disabled={this.state.disableBuyButton} type="button" onClick={(e) => { e.preventDefault(); this.BuyplayerCard(); }}>
            <img src="" id="buybtn" alt="" style={this.state.buybtncss} />
          </button>
          <button disabled={this.state.multibuttonsebledsble}>
            <img style={this.state.betbuttoncss} src="" id="betbtn"

              onClick={(e) => {
                e.preventDefault();

                if (this.state.betbutton === "Bet") {
                  this.RusssianpokerBet();
                } else if (this.state.betbutton === "DEAL") {
                  this.anteBet();
                } else if (this.state.betbutton === "REBET") {
                  this.anteclick(this.state.betbutton);
                } else if (
                  this.state.betbutton === "YES"
                ) {
                  this.buyDealerCard(this.state.betbutton);
                }
                else if (this.state.betbutton == "noinsurence") {
                  this.InsurenceClaim(this.state.betbutton);
                }
              }}
              alt="" />
          </button>

          <button disabled={this.state.disablemultibutton}>
            <img style={this.state.foldbuttoncss}
              src=""
              id="Foldbtn"
              onClick={(e) => {
                e.preventDefault();
                if (this.state.buttontext == "clear") {
                  this.clearbets();
                } else if (this.state.buttontext == "fold") {
                  this.FoldCards();
                } else if (this.state.buttontext == "insurence") {
                  this.InsurenceClaim(this.state.buttontext);
                } else if (this.state.buttontext == "NO") {
                  this.buyDealerCard(this.state.buttontext);
                } else if (this.state.buttontext == "REBETX2") {
                  this.anteclick(this.state.buttontext);
                }
              }
              }
              alt="" />
          </button>

          <img src="" id="infrmicon" onMouseDown={(e) => {
            e.preventDefault();
            this.ShowBonuspayouttable();
            this.HideBonuspayouttable();
          }}
            // onMouseLeave={(e) => {
            //   e.preventDefault();
            //   this.HideBonuspayouttable();
            // }}
            // onMouseOut={(e) => {
            //   e.preventDefault();
            //   this.HideBonuspayouttable();
            // }}
            alt="" />
          <img src="" id="volumeRussian" onClick={(e) => { e.preventDefault(); this.muteunmutesounds(); }} alt="" />
          <button disabled={this.state.betchipincrementbtn}>
            <img src="" id="betincrenet" onClick={(e) => { e.preventDefault(); this.IncreamentBetchips(); }} alt="" />
          </button>
          <button disabled={this.state.betdecreamentbtn}>

            <img src="" id="betdecrement"
              onClick={(e) => { e.preventDefault(); this.DecreamentBetchips(); }} alt="" />
          </button>
          <img src="" id="betchips" alt="" />
          {this.chipVal.map((obj, i) => (
            <span key={i} className={`valtext${i}`} id={`val${i}`}></span>
          ))}

          <img src="" ref={this.chipref} id={`betchipsaddtoante${this.state.idincrement}`} alt="" style={this.state.betchipstoantebetcss} />
          <img src="" id="betchipsaddtobonus" ref={this.chipbnsref} alt="" style={this.state.betchipstobonusbetcss} />
          <img src="" id="betchipsaddtoantecclick" ref={this.chiprefee} alt="" />



          <div ref={this.chipdrawref} id="betchipsaddtodrawContainer" className={this.state.buydrawcontainer}>
            <img src="" id="betchipsaddtodraw" alt="" className={this.state.drawchipcss} />
            <span id="betchipsaddtodrawText" className={this.state.drawchiptextcss}></span>
          </div>
          <div >
            <img src="" id="betchipsaddtconfrm" alt="" />
          </div>

          <span id="betTextaddtodraw"></span>

          <img src="" id="betchipsaddtobet" ref={this.chipbetref} alt="" style={this.state.betchiptobetbtncss} />
          <span id="betTextaddtoBet" className={this.state.betbtntxtcss}></span>
          <img src="" id="betchipsaddinsurence" ref={this.chipinsurenceref} alt="" style={this.state.betChipstoInsurance} />
          <div id="delaerchipanim">
            <img src="" id="dealerchip" alt="" />
          </div>
          <img src="" id="playerwinchip" alt="" />
          <img src="" id="userid" alt="" />
          <span id="useridtext">{this.props.user}</span>
          <img src="" id="Russianbgwindows" onClick={(e) => { e.preventDefault(); this.hidepopup_betshigh() }}

          // onMouseLeave={(e) => {
          //   e.preventDefault();
          //   this.hidepopup_betshigh();
          // }}
          // onMouseMove={(e) => {
          //   e.preventDefault();
          //   this.hidepopup_betshigh();
          // }}


          />
          <h1 id="paytable">PAY TABLE</h1>
          <img src={this.showTableImage()} id="rsntblewindows" alt="" />
          <h1 id="royalflush">
            Royal Flush-100 <span id="royalto">to</span> 1
          </h1>
          <h1 id="Straightflush">
            Straight Flush-50 <span id="strghtto">to</span> 1
          </h1>
          <h1 id="frofkind">
            Four of a kind-20 <span id="frkndto">to</span> 1
          </h1>
          <h1 id="fullhse">
            Full House-7 <span id="fullhseto">to</span> 1
          </h1>
          <h1 id="flush">
            Flush-5 <span id="flushto">to</span> 1
          </h1>
          <h1 id="stright">
            Straight-4 <span id="strightto">to</span> 1
          </h1>
          <h1 id="Threekind">
            Three Of a Kind-3 <span id="Threekindto">to</span> 1
          </h1>
          <h1 id="twopair">
            Two Pair-2 <span id="twopairto">to</span> 1
          </h1>
          <h1 id="onepair">
            One Pair-1 <span id="onepairto">to</span> 1
          </h1>
          <h1 id="aceking">
            Ace-King-1 <span id="acekingto">to</span> 1
          </h1>
          <h1 id="insurencepays">
            Insurance Pays <span id="insurencepaysto">1 : 1</span>
          </h1>



          <div id="landscapeInfo">
            <span id="inforussianland" className={this.state.inforussiancss}>{this.state.infoText}</span>
          </div>


          <span id="drawtext" className={this.state.drawbtntxtcss}></span>
          <span id="buytext" className={this.state.buybtntxtcss}></span>
          <span id="betbtntext" className={this.state.betbtntextcss}></span>
          <span id="foldtext" className={this.state.foldbtntextcss}></span>
          <span id="pokerinfotext"></span>
          <span id="bet_val"></span>
          <div id="topcardscontainer"></div>
        </div>

        {(this.state.isMobilePortraitLndsp === false) && (
          <div id="chipsstoredcontainer" ref={this.chipsarray} className={this.state.chipsstoredcontainercss}>
            {this.state.storebetchips}
          </div>

        )}


        {this.renderTheBonusForView()}


        <div id="infocontainer">
          <span id="inforussian" className={this.state.inforussiancss}>{this.state.infoText}</span>
        </div>


        <div id="rankinginfo">
          {pokerranking ? pokerranking : ""}
        </div>


        <div id="russianPokerDeck">
          <img id="russiandeck" src="" />
        </div>
        <div>
          <span id="rankinginfodeler">{this.state.pokerrankingdeler}</span>
        </div>

        <div id="anitecircleactive"></div>
        {this.state.isoptionmenu ? (
          <div id="optionsmenubutton">
            {/* <div id="optiontopmenu">
              <span id="optiontext">OPTIONS</span>
            </div> */}
            <div id="buttontscontainer">
              <button id="limits" onClick={(e) => {
                e.preventDefault();
                this.ShowLimits();
              }}>LIMITS</button>
              <button id="help" onClick={(e) => {
                e.preventDefault();
                this.Gamerules();
              }}>HELP</button>
              <button id="history"
                onClick={(e) => {
                  e.preventDefault();
                  this.ShowHistory()
                }}>HISTORY</button>
              {/* <button id="arrow">
                <img
                  // src={exitrussian}
                  src={popupExit}
                  id="exitmenu"
                  onClick={(e) => {
                    e.preventDefault();
                    this.hideshowoptionmenu();
                  }} /></button> */}

            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.ShowLimits ? (
          <div id="Limits">
            <span>MinBet:{this.props.minBetAmt}</span>
            <span>MaxBet:{this.props.maxBetAmt}</span>
            {/* <button id="exitLimits" onClick={(e) => {
              e.preventDefault();
              this.exitLimits()
            }}
            // onMouseOver={() => this.animexit()}
            >EXIT</button> */}
          </div>
        ) : ""}



        {this.state.ShowGamerules ? (



          <div id="ShowGamerules">
            <h1 id="gameruleMainHeading">GAME RULES</h1>
            <ul id="unorderedlistitems">

              <hr id="hrline" />
              {this.GameRules.map((val, key) => (
                <li>{val.name}</li>
              ))}
            </ul>
            {/* <button id="exitLimits" onClick={(e) => {
      e.preventDefault();
      this.exitLimits();
    }}
    // onMouseOver={() => this.animexit()}
    >EXIT</button> */}
          </div>

        ) : ""}

        {this.state.ShowHistory ? (
          <div id="prevhistory">
            <table id="histry_russian">
              <thead id="heading_hstiry">
                <tr>
                  <th>Hand Id</th>
                  <th>Dealer Cards</th>
                  <th>Dealer Hand</th>
                  <th>Player Cards</th>
                  <th>Player Hand</th>
                  <th>Ante BetAmount</th>
                  <th>Ante WinAmount</th>
                  <th>Bonus BetAmount</th>
                  <th>Bonus WinAmount</th>
                  <th>Bet Amount</th>
                  <th>Bet WinAmount</th>
                  <th>Insurance BetAmount</th>
                  <th>Insurance WinAmount</th>
                  <th>Total Bet</th>
                  <th>Total Win</th>
                </tr>
              </thead>

              <tbody id="histry_body">
                {this.props.gameState.history.map((obj, i) => (
                  <tr key={`card-${i}`} id={`${i}`}>
                    <td id="playerhand_hstry">{obj.result.handId}</td>
                    <td id="dealercards_hstry">
                      {console.log(obj.result.dealerFinalCards)}
                      {obj.result.dealerFinalCards.map((card_num, index) => {
                        const matchingCard = resultCards.find(res => res.rsc_val === card_num);
                        return (

                          <img
                            className="cardsimages"
                            key={index}
                            src={matchingCard ? matchingCard.src : ''}
                            alt={card_num}
                          />


                        );
                      })}
                    </td>
                    <td id="dealerhand_hstry">{obj.result.dealerHand}</td>
                    <td id="playercards_hstry">
                      {obj.result.playerFinalCards.map((card_num, index) => {
                        const matchingCard = resultCards.find(res => res.rsc_val === card_num);
                        return (
                          <img
                            className="cardsimages"
                            key={index}
                            src={matchingCard ? matchingCard.src : ''}
                            alt={card_num}
                          />
                        );
                      })}
                    </td>
                    <td id="playerhand_hstry">{obj.result.playerHand}</td>
                    <td id="totalbet_histry">{obj.result.anteBetAmount ? obj.result.anteBetAmount : 0}</td>
                    <td id="totalwin_hstry">{obj.result.anteWinAmount ? obj.result.anteWinAmount : 0}</td>
                    <td id="playerhand_hstry">{obj.result.bonusBetAmount ? obj.result.bonusBetAmount : 0}</td>
                    <td id="totalbet_histry">{obj.result.bonusWinAmount ? obj.result.bonusWinAmount : 0}</td>
                    <td id="totalwin_hstry">{obj.result.betAmount ? obj.result.betAmount : 0}</td>
                    <td id="playerhand_hstry">{obj.result.winAmount ? obj.result.winAmount : 0}</td>
                    <td id="totalbet_histry">{obj.result.insuranceBetAmount ? obj.result.insuranceBetAmount : 0}</td>
                    <td id="totalwin_hstry">{obj.result.insuranceWinAmount ? obj.result.insuranceWinAmount : 0}</td>
                    <td id="playerhand_hstry">{obj.result.totalBetAmount ? obj.result.totalBetAmount : 0}</td>
                    <td id="totalbet_histry">{obj.result.totalWinAmount ? obj.result.totalWinAmount : 0}</td>



                  </tr>
                ))}
              </tbody>
            </table>
            {/* <button id="exithistory" onClick={(e) => {
              e.preventDefault();
              this.exithistory()
            }}
            // onMouseOver={() => this.animexit()}
            >EXIT</button> */}
          </div>
        ) : null}

        <div id="russiangamecover">
        </div>
        <div id="waviy">
          {Title.map((im, k) => (
            <img style={{ '--i': k + 1 }} src={im.src} />
          ))}
        </div>
        <div id="crdscover"></div>
        <div id="buttons_cover"></div>
        <div id="glowonwinchip"></div>


        <div id="winpopup_rsp">
          <span id="winamt_rsp">{popup_winamount ? popup_winamount : ""}</span>
        </div>


        <div id="minmaxbets">
          <span>Min:{this.props.minBetAmt}</span>
          <span>Max:{this.props.maxBetAmt}</span>

        </div>

        <div id="popupbet_greaterthanbalnce">
          {(this.state.betshigh_balnce !== null) &&
            <button className="exit_popup" id="exitPopup" onClick={this.hidepopup_betshigh}><img src={popupExit} alt={popupExit} /></button>
          }
          <span id="textbets_high">
            <h4 id="attention">Attention!</h4>
            {this.state.lowBalaceErrorInfo}
          </span>


          {/* {this.state.betshigh_balnce ?
            <span id="textbets_high">
              <h4 id="attention">Attention!</h4>
              insufficient Funds to place the ANTE of FUN {this.state.totalbet},Please DEPOSIT to continue playing.
            </span> 
            : <span id="textbets_high">
              <h4 id="attention">Attention!</h4>
              insufficient Funds to follow up your ANTE of FUN {this.state.totalbet},with bet FUN {this.state.totalbet}*2 Please DEPOSIT to continue playing.
            </span>
          } */}




        </div>

        <div id="confirmbuydealer_popup" className="confirmbuydealerPopupcss">
          <span id="alertTxt" ></span>
          <button id="yes" onClick={(e) => {

            if (this.state.cmfrmdealeraction === 1) {
              e.preventDefault();
              this.Confirmbuydealercard(true)
            }
            else {
              this.confirmFoldactin(true);

            }

          }}>YES</button>
          <button id="no" ref={this.no}
            onClick={(e) => {
              if (this.state.cmfrmdealeractionNO === 1) {
                e.preventDefault();
                this.Confirmbuydealercard(false)
              }
              else {
                this.confirmFoldactin(false);

              }

            }}
          >NO</button>
        </div>








      </Fragment>
    );
  }

}



const mapStatesToProps = (state) => {
  return {
    language: state.languageObjs.languageObj
  }
}

export default connect(mapStatesToProps)(RussianPoker)


