import React from "react";
import "./code/css/app.css";

import AllTableGMBG from "./assets/Preloadimges/AllGamesimgs.jpg"
import AllOldGameBG from "./assets/Preloadimges/AllOldGamesimgs.jpg"
import AllTGBGM from "./assets/Preloadimges/AllGamesimgsMobile.jpg"
import AllOGBGM from "./assets/Preloadimges/AllOldGamesimgsMobile.jpg"

import loginBg from "./assets/login/loginbg.jpg";
import FunTarget from "./code/js/games/non_timer/funTarget/funTarget";
import FunCard from "./code/js/games/non_timer/funCard/funCard";
import FunTargetTimer from "./code/js/games/timer/funTarget_timer/funTargetTimer";
import FunSoratTimer from "./code/js/games/timer/funSorat_timer/funSoratTimer";
import FunMatkaTimer from "./code/js/games/timer/funMatka_timer/funMatkaTimer";
import FunMatka from "./code/js/games/non_timer/funMatka/funMatka";
import AmericanRouletteTimer from "./code/js/games/timer/americanRoulette_timer/americanRouletteTimer";
import EuropeanRouletteTimer from "./code/js/games/timer/europeanRoulette_timer/europeanRouletteTimer";
import DragonTigerTimer from "./code/js/games/timer/dragon_tiger_timer/dragon_tiger_Timer";
import MoneyWheelTimer from "./code/js/games/timer/money_wheel_timer/moneyWheel_timer";
import AndarBaharTimer from "./code/js/games/timer/andarBahar_timer/andarBaharTimer";
import KenoGame from "./code/js/games/non_timer/keno/keno";
import AmericanRoulette from "./code/js/games/non_timer/americanRoulette/americanRoulette";
import EuropeanRoulette from "./code/js/games/non_timer/europeanRoulette/europeanRoulette";
import RussianPoker from "./code/js/games/non_timer/RussianPoker/RussianPoker";
import TropicalPoker from "./code/js/games/non_timer/TropicalPoker/TropicalPoker";
import CarebbeanPoker from "./code/js/games/non_timer/CaribbeanPoker/Carebianpoker";
// import Baccarat from "./code/js/games/non_timer/CaribbeanPoker/Carebianpoker";
import Bingo from './code/js/games/non_timer/BINGO/bingo';
import JacksorBetter from "./code/js/games/non_timer/jacksorbetter/jacksorbetter";
import PayOutInfo from "./code/js/games/payoutInfo";
import errorAlert from "./assets/errorAlert.png"

import servicConnect from "../src/code/js/websocket/service";
import { Emitter } from "../src/code/js/websocket/eventEmiter";
import ForceLogin from "../src/code/js/login/forceLogin";
import ContextAPI from "./context_api";
import TripleChance from "./code/js/games/non_timer/tripleChance/tripleChance";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
  Link,
} from "react-router-dom";


import { withRouter } from "react-router-dom";
import exitSound from "./assets/sounds/exit.mp3";
import bet from "./assets/sounds/bet.mp3";

import HomePage from "./code/js/home/home";
import icon from "./assets/icon.svg";
import mobile_tilt from "./assets/mobile_tilt.svg";
import ic from "./assets/ic.svg";

import axios from "axios";
import { connect } from "react-redux";

import sparkleAnimation from "./code/js/games/sparkanim";

// import { fetchEnglish } from "./code/redux/actions";
// import { useSelector, useDispatch } from "react-redux";
// import { fetchEnglish, fetchTelugu, fetchBengali, fetchMarati, fetchHindi, fetchPunjabi, fetchGujarati } from "../../redux/actions";
// import { fetchEnglish, fetchTelugu } from "../../redux/actions";
import {
  fetchEnglish,
  fetchTelugu,
  fetchBengali,
  fetchMarati,
  fetchGujarati,
  fetchKannada,
  fetchTamil,
  fetchMalayalam,
  fetchPortuguese,
} from "./code/redux/actions";




class App extends React.Component {
  constructor(props) {

    super(props);
    this.FunTarget = React.createRef();
    this.FunMatka = React.createRef();
    this.FunTargetTimer = React.createRef();
    this.AmericanRoulette = React.createRef();
    this.AmericanRouletteTimer = React.createRef();
    this.EuropeanRoulette = React.createRef();
    this.FunMatkaTimer = React.createRef();
    this.FunSoratTimer = React.createRef();
    this.EuropeanRouletteTimer = React.createRef();
    this.FunCard = React.createRef();

    this.DragonTigerTimer = React.createRef();
    this.KenoGame = React.createRef();
    this.MoneyWheelTimer = React.createRef();
    this.AndarBaharTimer = React.createRef();
    this.RussianPoker = React.createRef();
    this.TropicalPoker = React.createRef();
    this.CarebbeanPoker = React.createRef();
    this.JacksorBetter = React.createRef();
    this.DeucesWild = React.createRef();
    this.JokerPoker = React.createRef();
    this.TensorBetter = React.createRef();
    this.Bingo = React.createRef();
    this.homeRef = React.createRef();
    this.PayOutInfo = React.createRef();
    this.connectionTo = new servicConnect();
    this.exitSound = new Audio(exitSound);
    this.loginSound = new Audio(bet);
    this.funcardIsActive = false;

    this.showLobby = false;
    this.showFunTarget = false;
    this.showFunTargetTimer = false;
    this.showFunSoratTimer = false;
    this.showFunMatkaTimer = false;
    this.showFunMatka = false;
    this.showFunCard = false;
    this.showAmericanRouletteTimer = false;
    this.showEuropeanRouletteTimer = false;
    this.showAmericanRoulette = false;
    this.showEuropeanRoulette = false;
    this.showDragonTiger = false;
    this.showMoneyWheel = false;
    this.showAndarBaharTimer = false;
    this.showRussianPoker = false;
    this.showTropicalPoker = false;
    this.showJacksorBetter = false;
    this.showDeucesWild = false;
    this.showJokerPoker = false;
    this.showTensorBetter = false;
    this.showCarebbeanPoker = false;
    this.showBingo = false;

    this.fixHight = window.innerHeight;
    this.cardGames = [28, 30, 33, 35, 36, 39, 40]
    this.PortraiteView = false
    console.log( window.innerWidth)
    if( window.innerWidth < 768){
      if(window.innerWidth < window.innerHeight ){
        this.PortraiteView = true
      }else{
        this.PortraiteView = false
      }
    }else{
      this.PortraiteView = false
    }

    this.positionsALLBG = {
      28: { x: 0, y: 0, IMG: AllTableGMBG, IMGM:AllTGBGM, spriteWidthBG: 33.3, spriteHeightBG: 50, PositionW: 420, PositionH: 310, GameName: "Russian Poker" },
      39: { x: 1, y: 0, IMG: AllTableGMBG, IMGM:AllTGBGM, spriteWidthBG: 33.3, spriteHeightBG: 50, PositionW: 420, PositionH: 310, GameName: "Tropical Stud Poker" },
      33: { x: 2, y: 0, IMG: AllTableGMBG, IMGM:AllTGBGM, spriteWidthBG: 33.3, spriteHeightBG: 50, PositionW: 420, PositionH: 310, GameName: "Caribean Poker" },
      29: { x: 3, y: 0, IMG: AllTableGMBG, IMGM:AllTGBGM, spriteWidthBG: 33.3, spriteHeightBG: 50, PositionW: 420, PositionH: 310, GameName: "Baccarat" },
      37: { x: 0, y: 1, IMG: AllTableGMBG, IMGM:AllTGBGM, spriteWidthBG: 33.3, spriteHeightBG: 50, PositionW: 420, PositionH: 310, GameName: "Oasis Poker" },
      35: { x: 1, y: 1, IMG: AllTableGMBG, IMGM:AllTGBGM, spriteWidthBG: 33.3, spriteHeightBG: 50, PositionW: 420, PositionH: 310, GameName: "Dueces wild" },
      30: { x: 2, y: 1, IMG: AllTableGMBG, IMGM:AllTGBGM, spriteWidthBG: 33.3, spriteHeightBG: 50, PositionW: 420, PositionH: 310, GameName: "Jacks Or Better" },
      40: { x: 3, y: 1, IMG: AllTableGMBG, IMGM:AllTGBGM, spriteWidthBG: 33.3, spriteHeightBG: 50, PositionW: 420, PositionH: 310, GameName: "Tens Or Better" },
      41: { x: 0, y: 2, IMG: AllTableGMBG, IMGM:AllTGBGM, spriteWidthBG: 33.3, spriteHeightBG: 50, PositionW: 420, PositionH: 310, GameName: "BlackJack" },
      42: { x: 1, y: 2, IMG: AllTableGMBG, IMGM:AllTGBGM, spriteWidthBG: 33.3, spriteHeightBG: 50, PositionW: 420, PositionH: 310, GameName: "PaiGow" },
      43: { x: 2, y: 2, IMG: AllTableGMBG, IMGM:AllTGBGM, spriteWidthBG: 33.3, spriteHeightBG: 50, PositionW: 420, PositionH: 310, GameName: "CasinoHoldem" },
      36: { x: 3, y: 2, IMG: AllTableGMBG, IMGM:AllTGBGM, spriteWidthBG: 33.3, spriteHeightBG: 50, PositionW: 420, PositionH: 310, GameName: "JokerPoker" },

      24: { x: 0, y: 0, IMG: AllOldGameBG, IMGM:AllOGBGM, spriteWidthBG: 33.3, spriteHeightBG: 33.3, PositionW: 420, PositionH: 415, GameName: "AndharBhar" },
      26: { x: 1, y: 0, IMG: AllOldGameBG, IMGM:AllOGBGM, spriteWidthBG: 33.3, spriteHeightBG: 33.3, PositionW: 420, PositionH: 415, GameName: "Keno" },
      25: { x: 2, y: 0, IMG: AllOldGameBG, IMGM:AllOGBGM, spriteWidthBG: 33.3, spriteHeightBG: 33.3, PositionW: 420, PositionH: 415, GameName: "Bingo" },
      23: { x: 3, y: 0, IMG: AllOldGameBG, IMGM:AllOGBGM, spriteWidthBG: 33.3, spriteHeightBG: 33.3, PositionW: 420, PositionH: 415, GameName: "Moneywheel" },
      20: { x: 0, y: 1, IMG: AllOldGameBG, IMGM:AllOGBGM, spriteWidthBG: 33.3, spriteHeightBG: 33.3, PositionW: 420, PositionH: 415, GameName: "Dragontiger" },
       1: { x: 1, y: 1, IMG: AllOldGameBG, IMGM:AllOGBGM, spriteWidthBG: 33.3, spriteHeightBG: 33.3, PositionW: 420, PositionH: 415, GameName: "Mini AndharBhar" },
      14: { x: 2, y: 1, IMG: AllOldGameBG, IMGM:AllOGBGM, spriteWidthBG: 33.3, spriteHeightBG: 33.3, PositionW: 420, PositionH: 415, GameName: "Matka" },
       7: { x: 3, y: 1, IMG: AllOldGameBG, IMGM:AllOGBGM, spriteWidthBG: 33.3, spriteHeightBG: 33.3, PositionW: 420, PositionH: 415, GameName: "Matka Timer" },
      12: { x: 0, y: 2, IMG: AllOldGameBG, IMGM:AllOGBGM, spriteWidthBG: 33.3, spriteHeightBG: 33.3, PositionW: 420, PositionH: 415, GameName: "European Roulette Timer" },
      13: { x: 1, y: 2, IMG: AllOldGameBG, IMGM:AllOGBGM, spriteWidthBG: 33.3, spriteHeightBG: 33.3, PositionW: 420, PositionH: 415, GameName: "European Roulette" },
      19: { x: 2, y: 2, IMG: AllOldGameBG, IMGM:AllOGBGM, spriteWidthBG: 33.3, spriteHeightBG: 33.3, PositionW: 420, PositionH: 415, GameName: "Sorat Timer" },
       6: { x: 3, y: 2, IMG: AllOldGameBG, IMGM:AllOGBGM, spriteWidthBG: 33.3, spriteHeightBG: 33.3, PositionW: 420, PositionH: 415, GameName: "American Roulette" },
       3: { x: 0, y: 3, IMG: AllOldGameBG, IMGM:AllOGBGM, spriteWidthBG: 33.3, spriteHeightBG: 33.3, PositionW: 420, PositionH: 415, GameName: "American Roulette Timer" },
       2: { x: 1, y: 3, IMG: AllOldGameBG, IMGM:AllOGBGM, spriteWidthBG: 33.3, spriteHeightBG: 33.3, PositionW: 420, PositionH: 415, GameName: "Target Timer" },
       5: { x: 2, y: 3, IMG: AllOldGameBG, IMGM:AllOGBGM, spriteWidthBG: 33.3, spriteHeightBG: 33.3, PositionW: 420, PositionH: 415, GameName: "Target" },
    };
    this.EnterGameId = null;

    this.state = {
      progressValue: 0,
      MainLoaderImg: true,
      loadedBGIMG: false,
      PortraiteViewChange: false,
      opacity: 1,
      caribbeanpayouts: null,
      kn_payouts: [],
      TP_payouts: [],
      showLoading: true,
      showLogin: false,
      alertText: "",
      forceLoignText: "",
      user: "",
      financialGoal: "",

      lobbyInfo: {},
      name: "",
      password: "",
      balance: 0,
      gameId: 0,
      playerId: 0,
      forceLog: false,

      funTargetGameState: null,
      moneyWheelGameState: null,
      americanRouletteGameState: null,
      EuropeanRouletteGameState: null,
      funTargetTimerGameState: null,
      AmericanRouletteTimerGameState: null,
      EuropeanRouletteTimerGameState: null,
      FunMatkaTimerGameState: null,
      funMatkaGameState: null,
      funSoratGameState: null,
      funCardGameState: null,
      dragonTigerGameState: null,
      andarBaharTimerGameState: {},
      KenoGameState: null,
      bingoGameState: null,
      russianPokerGameState: {},
      tropicalPokerGameState: {},
      jacksorBetterGameState: null,
      deucesWildGameState: null,
      jokerPokerGameState: null,
      tensorBetterGameState: null,
      CarebbeanPokerGameState: {},
      tripleChanceGameState: null,
      activeProfile: false,
      targetResult: {},
      kenoResult: {},
      AmericanRouletteTimerResult: {},
      funSoratResult: {},
      rouletteResult: {},
      EuropeanRouletteResult: {},
      funTargetTimerResult: {},
      funCardResult: {},
      dragonTigerResult: {},
      tripleChanceResult: {},
      EuropeanRouletteTimerTimerResult: {},
      FunMatkaTimerResult: {},
      funmatkaResult: {},
      moneyWheelResult: {},
      andarBaharTimerResult: {},
      russianPokerResult: {},
      tropicalPokerResult: {},
      CarebbeanPokerResult: {},
      myAccountResult: {},
      FunTargetTimeSend: 0,
      soratTimeSend: 0,
      AmericanTimeSend: 0,
      EuropeanTimeSend: 0,
      FunMatkaTimeSend: 0,
      dtt_TimeSend: 0,
      mwTimeSend: 0,
      AndarBaharTimeSend: 0,
      gameStateUpdate: false,
      FunTargetInfoText: "Play and Njoy!",
      FunCardInfoText: "Play and Njoy!",
      FunTargetTimerInfoText: "Play and Njoy!",
      FunSoratTimerInfoText: "Play and Njoy!",
      FunMatkaTimerInfoText: "Play and Njoy!",
      FunMatkaInfoText: "Play and Njoy!",
      AmericanRouletteTimerInfoText: "Play and Njoy!",
      AmericanRouletteInfoText: "Play and Njoy!",
      EuropeanRouletteTimerInfoText: "Play and Njoy!",
      EuropeanRouletteInfoText: "Play and Njoy!",
      MoneyWheelInfoText: "Play and Njoy!",
      KenoInfoText: "Play and Njoy!",
      DragonTigerInfoText: "Play and Njoy!",
      AndarBaharTimerInfoText: "Play and Njoy!",
      RussianPokerInfoText: "Play and Njoy!",
      TropicalPokerInfoText: "Play and Njoy!",
      JacksorBetterInfoText: "Play and Njoy!",
      DeucesWildInfoText: "Play and Njoy!",
      JokerPokerInfoText: "Play and Njoy!",
      TensorBetterInfoText: "Play and Njoy!",
      CarebbeanPokerInfoText: "Play and Njoy!",


      infoText: "Play and Njoy!",
      isPlayerBanned: false,
      popupMessage: "Common Popup",
      closePopup: false,
      error_code: "",
      gameUnderMaintanance: false,
      gameData: {},

      totalBet: 0,
      deviceBlocked: false,
      FunSoratState: "",
      AmericanRouletteTimerState: "",
      logBtnLoad: false,
      onFocus: undefined,
      TripleChanceHistory: "",
      DragHistory: "",
      isPlayerLogin: false,
      websocketLoader: true,
      logInBgLoader: true,
      // heightFix: false,
      heightFix: true,
      sessionIdMcasino: null,
      enterGameLoader: true,
      gameEntry: "in",

      face: "",
      currencyCode: "",

      fst_minBetLbl: "",
      fst_maxBetLbl: "",
      fst_minBet: 1,
      fst_maxBet: 500000,
      fst_innerLimits: "1-5000|1-5000|1-5000|1-5000|1-5000|1-5000",
      fst_chipValues: "1,5,10,25,50,100,500,1000,5000",

      ftt_minBetLbl: "",
      ftt_maxBetLbl: "",
      ftt_minBet: 1,
      ftt_maxBet: 500000,
      ftt_innerLimits: "1-5000|1-5000|1-5000|1-5000|1-5000|1-5000",
      ftt_chipValues: "1,5,10,25,50,100,500,1000,5000",

      art_minBetLbl: "",
      art_maxBetLbl: "",
      art_minBet: 1,
      art_maxBet: 500000,
      art_innerLimits: "1-5000|1-5000|1-5000|1-5000|1-5000|1-5000",
      art_chipValues: "1,5,10,25,50,100,500,1000,5000",

      ert_minBetLbl: "",
      ert_maxBetLbl: "",
      ert_minBet: 1,
      ert_maxBet: 500000,
      ert_innerLimits: "1-5000|1-5000|1-5000|1-5000|1-5000|1-5000",
      ert_chipValues: "1,5,10,25,50,100,500,1000,5000",

      fmt_minBetLbl: "",
      fmt_maxBetLbl: "",
      fmt_minBet: 1,
      fmt_maxBet: 500000,
      fmt_innerLimits: "1-5000|1-5000|1-5000|1-5000|1-5000|1-5000",
      fmt_chipValues: "1,5,10,25,50,100,500,1000,5000",

      fc_minBetLbl: "",
      fc_maxBetLbl: "",
      fc_minBet: 1,
      fc_maxBet: 500000,
      fc_innerLimits: "1-5000|1-5000|1-5000|1-5000|1-5000|1-5000",
      fc_chipValues: "1,5,10,25,50,100,500,1000,5000",

      ft_minBetLbl: "",
      ft_maxBetLbl: "",
      ft_minBet: 1,
      ft_maxBet: 500000,
      ft_innerLimits: "1-5000|1-5000|1-5000|1-5000|1-5000|1-5000",
      ft_chipValues: "1,5,10,25,50,100,500,1000,5000",

      ar_minBetLbl: "",
      ar_maxBetLbl: "",
      ar_minBet: 1,
      ar_maxBet: 500000,
      ar_innerLimits: "1-5000|1-5000|1-5000|1-5000|1-5000|1-5000",
      ar_chipValues: "1,5,10,25,50,100,500,1000,5000",

      er_minBetLbl: "",
      er_maxBetLbl: "",
      er_minBet: 1,
      er_maxBet: 500000,
      er_innerLimits: "1-5000|1-5000|1-5000|1-5000|1-5000|1-5000",
      er_chipValues: "1,5,10,25,50,100,500,1000,5000",

      fm_minBetLbl: "",
      fm_maxBetLbl: "",
      fm_minBet: 1,
      fm_maxBet: 500000,
      fm_innerLimits: "1-5000|1-5000|1-5000|1-5000|1-5000|1-5000",
      fm_chipValues: "1,5,10,25,50,100,500,1000,5000",

      dtt_minBetLbl: "",
      dtt_maxBetLbl: "",
      dtt_minBet: 1,
      dtt_maxBet: 500000,
      dtt_innerLimits: "10-5000|10-5000|10-5000",
      dtt_chipValues: "",

      mwt_minBetLbl: "",
      mwt_maxBetLbl: "",
      mwt_minBet: 1,
      mwt_maxBet: 500000,
      mwt_innerLimits: "1-1000|1-1000|1-1000|1-1000|1-1000|1-1000|1-1000",
      mwt_chipValues: "1,5,10,25,50,100,500,1000,5000",

      abt_minBetLbl: "",
      abt_maxBetLbl: "",
      abt_minBet: 1,
      abt_maxBet: 500000,
      abt_innerLimits: "1-5000|1-5000|1-5000|1-5000|1-5000|1-5000",
      abt_chipValues: "1,5,10,25,50,100,500,1000,5000",

      bng_minBetLbl: "",
      bng_maxBetLbl: "",
      bng_minBet: 1,
      bng_maxBet: 500000,
      bng_innerLimits: [["1", "5000"]],
      bng_chipValues: "1,5,10,25,50,100,500,1000,5000",

      rp_minBetLbl: "",
      rp_maxBetLbl: "",
      rp_minBet: 1,
      rp_maxBet: 500000,
      rp_innerLimits: "1-5000|1-5000|1-5000|1-5000|1-5000|1-5000",
      rp_chipValues: "1,5,10,25,50,100,500,1000,5000",

      cb_minBetLbl: "",
      cb_maxBetLbl: "",
      cb_minBet: 1,
      cb_maxBet: 500000,
      cb_innerLimits: "1-5000|1-5000|1-5000|1-5000|1-5000|1-5000",
      cb_chipValues: "1,5,10,25,50,100,500,1000,5000",

      tp_minBetLbl: "",
      tp_maxBetLbl: "",
      tp_minBet: 1,
      tp_maxBet: 500000,
      tp_innerLimits: "1-5000|1-5000|1-5000|1-5000|1-5000|1-5000",
      tp_chipValues: "1,5,10,25,50,100,500,1000,5000",


      jrb_minBetLbl: "",
      jrb_maxBetLbl: "",
      jrb_minBet: 1,
      jrb_maxBet: 500000,
      jrb_innerLimits: "1-5000|1-5000|1-5000|1-5000|1-5000|1-5000",
      jrb_chipValues: "1,5,10,25,50,100",
      // jrb_chipValues: "1,5,10,25,50,100,500,1000,5000",
      trb_minBetLbl: "",
      trb_maxBetLbl: "",
      trb_minBet: 1,
      trb_maxBet: 500000,
      trb_innerLimits: "1-5000|1-5000|1-5000|1-5000|1-5000|1-5000",
      trb_chipValues: "1,5,10,25,50,100",

      kn_innerLimits: "1-1000",
      kn_minBetLbl: "",
      kn_maxBetLbl: "",
      kn_minBet: 1,
      kn_maxBet: 500000,
      game_PayoutInfo: [],
      doubleUp_Base_Pay: [],
      doubleUp_PayoutInfo: []
    };
    this.spectatorCount = 0;
    this.handId = 0;
    this.isMobile = false;
    this.timeReload = 0;
    this.OutGameId = "";
    this.OutPlayerId = "";
    this.sendBody = {};
    this.timerGame = false;
    this.isPopupShown = false;
    this.systemOS = " ";

    this.gameEntryOutSide = false;
    this.saveURL = window.location.search;

    this.soratLoaded = true;
    this.receiveDataFromWs = false;
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.gameBoxMain = null;
    this.gbw = null;
    this.gbh = null;
    this.isIphone = false;

    this.gameList = [1, 2, 3, 5, 6, 7, 12, 13, 14, 19, 20, 23, 24, 25, 26, 28, 30, 33, 35, 36, 39, 40];

    // const dispatch = useDispatch(fetchEnglish(), fetchTelugu(), fetchMarati(), fetchBengali(), fetchHindi(), fetchPunjabi(), fetchGujarati());
    // const language = useSelector((state) => state.languageObjs.languageObj);

    if (
      /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      // alert("yes its mobile" + navigator.userAgent);
      this.isMobile = true;
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      // alert("yes its iphone " + navigator.userAgent);
      this.isIphone = true;
      this.isMobile = false;
    } else {
      this.isMobile = false;
    }

    console.log("*********************");
    console.log(this.props.language);
    console.log("*********************");
    this.loadBg();
    console.log(window.location.pathname);
    const queryString = window.location.search;
    console.log(queryString);

    const urlParams = new URLSearchParams(queryString);
    console.log(urlParams);
    if (urlParams.get("gameId")) {
      const gameId = urlParams.get("gameId");
      this.EnterGameId = Number(urlParams.get("gameId"));
      console.log(gameId);
      sessionStorage.setItem("gameId", gameId);
    }

    if (urlParams.get("playerToken")) {
      const playerToken = urlParams.get("playerToken");
      console.log(playerToken);
      sessionStorage.setItem("playerToken", playerToken);
    }

    if (urlParams.get("site")) {
      const site = urlParams.get("site");
      console.log(site);
      sessionStorage.setItem("siteId", site);
    }

    if (urlParams.get("lang")) {
      const lang = urlParams.get("lang");
      console.log(lang);
      sessionStorage.setItem("myLang", lang);

      switch (lang) {
        case "en":
        case "EN":
          this.props.fetchEnglish();
          break;
        case "te":
        case "TE":
          this.props.fetchTelugu();
          break;
        case "bn":
        case "BN":
          this.props.fetchBengali();
          break;
        case "mr":
        case "MR":
          this.props.fetchMarati();
          break;
        case "gu":
        case "GU":
          this.props.fetchGujarati();
          break;
        case "kn":
        case "KN":
          this.props.fetchKannada();
          break;
        case "ta":
        case "TA":
          this.props.fetchTamil();
          break;
        case "ml":
        case "ML":
          this.props.fetchMalayalam();
          break;
        case "pt":
        case "PT":
          this.props.fetchPortuguese();
          break;
        default:
          return this.props.fetchEnglish();
      }
    }

    this.connection = this.connection.bind(this);
    this.EnterGameId = JSON.parse(sessionStorage.getItem("gameId"))
  }
  componentDidMount() {
    this.EnterGameId = JSON.parse(sessionStorage.getItem("gameId"))

    console.log(this.EnterGameId)
    console.log(this.positionsALLBG)

    //this.enterGameFromOtherSite();
    console.log(this.saveURL);
    this.handleResize()
    window.addEventListener("resize", this.handleResize);
    this.preloadSparkAnim();

    // let gId = this.saveURL.split('?gameId=')
    // console.log(gId);

    // this.state.gameId = gId[1].split('&')[0];
    // console.log(this.state.gameId);

    // let lvl_2 = gId[1]
    // console.log(lvl_2)

    // let site_1 = lvl_2.split('site=')[1];
    // let site = site_1.split('&')[0];

    // console.log(site_1);
    // console.log(site);


    const activeGameId = sessionStorage.getItem('gameId');

    const activeStatus = this.cardGames.includes(Number(activeGameId));
    console.log(activeStatus, activeGameId, typeof activeGameId);

    window.addEventListener(
      "orientationchange",
      function () {
        if (window.screen.orientation.angle == 90) {
          document.getElementById("isMobileTrue").style.display = "block";

          // if (sessionStorage.getItem('gameId') != 28) {  // For Russion Poker, change game id number.
          if (!activeStatus) {
            document.getElementById('isMobileTrue').style.display = 'block';
          } else {
            document.getElementById('isMobileTrue').style.display = 'none';
          }
        } else {
          document.getElementById("isMobileTrue").style.display = "none";
        }
      },
      false
    );

    if (this.isMobile && window.screen.orientation.angle == 90) {
      document.getElementById("isMobileTrue").style.display = "block";

      // if (sessionStorage.getItem('gameId') != 28) {  // For Russion Poker, change game id number.
      if (!activeStatus) {
        document.getElementById('isMobileTrue').style.display = 'block';
      } else {
        document.getElementById('isMobileTrue').style.display = 'none';
      }


      // if(sessionStorage.getItem('gameId') != 5)  {
      //   document.getElementById('isMobileTrue').style.display = 'block';
      // }else{
      //   document.getElementById('isMobileTrue').style.display = 'none';
      // }
    } else {
      document.getElementById("isMobileTrue").style.display = "none";
    }

    document.getElementById("awayPopup").style.display = "none";
    this.connection();
    var appVersion = navigator.appVersion;
    const myArray = appVersion.slice(5, 20);
    if (this.isMobile) {
      this.myArray1 = myArray.slice(7, 14);
      if (String(this.myArray1) != "Android") {
        this.systemOS = "IOS";
      } else {
        this.systemOS = "Android";
      }
    } else {
      this.myArray2 = myArray.replace("NT", "");
      this.systemOS = this.myArray2;
    }

    console.log("systemOS " + this.systemOS);
    this.resize();

    window.addEventListener('online', () => {
      // window.location.reload();
      // const body = {
      //   sessionId: sessionStorage.getItem("sessionId"),
      //   CN: "GET_GAME_STATE",
      //   object: {
      //     gameId: sessionStorage.getItem("gameId"),
      //     playerId: Number(sessionStorage.getItem("playerId")),
      //     // handId:this.handId,

      //   },
      // };
      // this.connectionTo.sendof(body);
      // const activeGameId = sessionStorage.getItem('gameId');
      // this.gameOpenHandler(Number(activeGameId));
      // this.refreshGame()
      this.connection();
    })


  }

  preloadSparkAnim() {
    let loadedSparkImages = 0;
    // Preload each image
    for (let n = 1; n <= 100; n++) {
      let spark_img = new Image();
      if (loadedSparkImages !== 100) {
        loadedSparkImages++;
        spark_img.onload = loadedSparkImages;
        spark_img.onerror = loadedSparkImages; // Call imageLoaded even if there's an error loading the image
        const sparkImageName = `sparkanimation${n}`;
        spark_img.src = sparkleAnimation[sparkImageName];
        // console.log(spark_img.src);
      }
    }
  }
  handleResize = () => {
    // alert(this.state.gameId);
    const activeGameId = sessionStorage.getItem('gameId');

    const activeStatus = this.cardGames.includes(Number(activeGameId));
    console.log(activeStatus, activeGameId, typeof activeGameId);

   if( window.innerWidth < 768){
      if(window.innerWidth < window.innerHeight ){
        this.PortraiteView = true
      }else{
        this.PortraiteView = false
      }
    }else{
      this.PortraiteView = false
    }

    this.setState({
      PortraiteViewChange: true
    })

    // if (this.state.gameId == 28) {
    if (activeStatus) {
      this.resize();
    }
  };

  resize = () => {
    // alert(">..........resize")
    let windHt;
    let windWd;
    this.gbw = 2350;
    this.gbh = 1250;
    windHt = window.innerHeight;
    windWd = window.innerWidth;

    let marg_left = ((windHt * this.gbw) / this.gbh - windWd) / 2;
    let marg_top = ((windWd * this.gbh) / this.gbw - windHt) / 2;
    let lndscp_1 = (windHt / this.gbw) * this.gbh;
    let lndscp_2 = ((lndscp_1 - windWd) / 2) * -1;
    let marg_left_lndscp = lndscp_1 + lndscp_2;

    let checkWid = (windHt * this.gbw) / this.gbh;
    this.gameBox = document.getElementById("gameBoxMain");
    const activeGameId = sessionStorage.getItem('gameId');

    console.log('this.state.gameId', this.state.gameId, "activeGameId ", activeGameId);

    // const activeStatus = this.cardGames.includes(Number(this.state.gameId));
    const activeStatus = this.cardGames.includes(Number(activeGameId));


    // if ((this.isMobile || this.isIphone) && this.state.gameId != 28) {
    if ((this.isMobile || this.isIphone) && !activeStatus) {
      // alert("**********----------------------***************")
      this.gameBox.style.transformOrigin = "left top";
      this.gameBox.style.marginLeft = marg_left_lndscp + "px";
      this.gameBox.style.transform = "scale(" + windHt / this.gbw + ") rotate(90deg)";

    } else {
      // if (this.state.gameId != 28) {
      if (!activeStatus) {


        if (windWd > windHt && windWd < this.gameBox.clientWidth &&
          windHt < this.gameBox.clientHeight && windWd > checkWid) {
          // alert('one')
          this.gameBox.style.transform = "scale(" + windHt / this.gbh + ")";
          this.gameBox.style.marginLeft = marg_left * -1 + "px";
        } else if (windWd < windHt && windWd < this.gameBox.clientWidth &&
          windHt < this.gameBox.clientHeight) {
          // alert('two')
          this.gameBox.style.transform = "scale(" + windWd / this.gbw + ")";
          this.gameBox.style.marginTop = marg_top * -1 + "px";
        } else {
          // alert('three')
          this.gameBox.style.transformOrigin = "left top";
          this.gameBox.style.transform = "scale(" + windWd / this.gbw + ")";
          this.gameBox.style.marginTop = marg_top * -1 + "px";
        }
      }
    };

    console.log(navigator.userAgentData);

    console.log('window', window)
    console.log('window.width', window.innerWidth)
    console.log('window.height', window.innerHeight)

    // if (this.state.gameId == 28) {
    if (activeStatus) {
      if (navigator.userAgentData && navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") {

        // alert("app.js window");
        switch (Number(activeGameId)) {
          case 30:
          case 35:
          case 36:
          case 40:
            this.gameBox.style.height = "1250px";
            this.gameBox.style.width = "2350px";
            break;
          default:
            // return null;  
            break;

        }
        // if(Number(activeGameId) == 30 || Number(activeGameId) == 40){
        //   this.gameBox.style.height = "1250px";
        //   this.gameBox.style.width = "2350px";
        // }

        // if (windWd > windHt && windWd < this.gameBox.clientWidth &&
        //   windHt < this.gameBox.clientHeight && windWd > checkWid) {
        //   // alert('one')
        //   this.gameBox.style.transform = "scale(" + windHt / this.gbh + ")";
        //   this.gameBox.style.marginLeft = marg_left * -1 + "px";
        // } else if (windWd < windHt && windWd < this.gameBox.clientWidth &&
        //   windHt < this.gameBox.clientHeight) {
        //   // alert('two')
        //   this.gameBox.style.transform = "scale(" + windWd / this.gbw + ")";
        //   this.gameBox.style.marginTop = marg_top * -1 + "px";
        // } else {
        //   // alert('three')
        //   this.gameBox.style.transformOrigin = "left top";
        //   this.gameBox.style.transform = "scale(" + windWd / this.gbw + ")";
        //   this.gameBox.style.marginTop = marg_top * -1 + "px";
        // }




        if (windWd > windHt && windWd < this.gameBox.clientWidth && windHt < this.gameBox.clientHeight && windWd > checkWid) {
          // Landscape Mode with constraints
          this.gameBox.style.transform = `scale(${windHt / this.gbh})`;
          this.gameBox.style.marginLeft = `${-marg_left}px`;
          this.gameBox.style.marginTop = "0px";
        }
        else if (windWd < windHt && windWd < this.gameBox.clientWidth && windHt < this.gameBox.clientHeight) {
          // Portrait Mode with constraints
          this.gameBox.style.transform = `scale(${windWd / this.gbw})`;
          this.gameBox.style.marginTop = `${-marg_top}px`;
          this.gameBox.style.marginLeft = "0px";
        }
        else {
          // Fallback for other scenarios (larger screens)
          this.gameBox.style.transformOrigin = "left top";
          this.gameBox.style.transform = `scale(${windWd / this.gbw})`;
          this.gameBox.style.marginTop = `${-marg_top}px`;
          this.gameBox.style.marginLeft = "0px";
        }

        this.fixHight = window.innerHeight;
        const mainWindowElement = document.getElementById("mainWindow")
        if (mainWindowElement) {
          mainWindowElement.style.height = this.fixHight + "px"
        }

      } else {
        this.fixHight = window.innerHeight;
        const mainWindowElement = document.getElementById("mainWindow")
        if (mainWindowElement) {
          mainWindowElement.style.height = this.fixHight + "px"
        }

        if (window.innerWidth > window.innerHeight) {
          const originalHeight = 1250;
          const originalWidth = 2350;
          // alert("mobile landScape app.js");
          // Landscape mode

          const scaleLandscapeWidth = windWd / originalWidth; // Scale based on width 
          const scaleLandscapeHeight = windHt / originalHeight; // Scale based on height 
          const scaleLandscape = Math.min(scaleLandscapeWidth, scaleLandscapeHeight); // Use the smaller scale to fit the content

          const margLeftLandscape = (windWd - originalWidth * scaleLandscape) / 2; // Calculate margin-left to center 
          const margTopLandscape = (windHt - originalHeight * scaleLandscape) / 2; // Calculate margin-top to center vertically 

          // Apply styles dynamically
          this.gameBox.style.transformOrigin = "left top";
          this.gameBox.style.transform = `scale(${scaleLandscape})`;
          this.gameBox.style.marginLeft = `${margLeftLandscape}px`;
          this.gameBox.style.marginTop = `${margTopLandscape}px`;
          this.gameBox.style.height = `${originalHeight}px`;
          this.gameBox.style.width = `${originalWidth}px`;


          // this.gameBox.style.transform = "scale(" + windWd / 2350 + "," + windHt / 1250 + ")";
          // this.gameBox.style.marginLeft = "8px";
          // this.gameBox.style.marginTop = "-4px";
          // this.gameBox.style.height = "1250px";
          // this.gameBox.style.width = "2350px";

        } else {
          // Portrait mode

          const originalWidth = 1250;
          const originalHeight = 2350;

          const scalePortraitWidth = windWd / originalWidth; // Scale based on width  
          const scalePortraitHeight = windHt / originalHeight; // Scale based on height  
          const scalePortrait = Math.min(scalePortraitWidth, scalePortraitHeight); // Use the smaller scale to fit the content

          const margLeftPortrait = (windWd - originalWidth * scalePortrait) / 2; // Calculate margin-left to center  
          const margTopPortrait = (windHt - originalHeight * scalePortrait) / 2; // Calculate margin-top to center vertically 

          // Apply styles dynamically
          this.gameBox.style.transformOrigin = "left top";
          this.gameBox.style.transform = `scale(${scalePortrait})`;
          this.gameBox.style.marginLeft = `${margLeftPortrait}px`;
          this.gameBox.style.marginTop = `${margTopPortrait}px`;
          this.gameBox.style.height = `${originalHeight}px`;
          this.gameBox.style.width = `${originalWidth}px`;


          // // alert("mobile portrait Scape app.js");
          // this.gameBox.style.transformOrigin = "left top";
          // this.gameBox.style.transform = "scale(" + windWd / 1250 + "," + windHt / 2350 + ")";
          // // this.gameBox.style.transform = "scale(" + windWd / 1250 + "," + windHt / 2350 + ")";
          // // this.gameBox.style.marginLeft =  "4%";
          // this.gameBox.style.marginLeft = "1%";
          // this.gameBox.style.marginTop = "0%";
          // this.gameBox.style.width = "1250px";
          // this.gameBox.style.height = "2350px";




        }
      }


    }



  };

  enterGameFromOtherSite = () => {
    console.log(window.location.pathname);
    let profileNm;
    let balance;
    let site;
    let lang;

    console.log(this.saveURL.indexOf("profile"));
    if (this.saveURL.indexOf("profile") == -1) {
      this.setState({
        enterGameLoader: true,
        heightFix: true,
        gameEntry: "out",
      });

      const queryString = window.location.search;
      console.log(queryString);

      const urlParams = new URLSearchParams(queryString);
      if (urlParams.get("gameId")) {
        this.state.gameId = urlParams.get("gameId");
        console.log(this.state.gameId);
      }
      if (urlParams.get("playerToken")) {
        profileNm = urlParams.get("playerToken");
        console.log(profileNm);
      }

      if (urlParams.get("balance")) {
        balance = urlParams.get("balance");
        console.log(balance);
      }

      if (urlParams.get("site")) {
        site = urlParams.get("site");
      }

      if (urlParams.get("lang")) {
        lang = urlParams.get("lang");
        console.log(lang);
      }

      let indSessionId = sessionStorage.getItem("indieSessionId");
      console.log(indSessionId);
      console.log(this.systemOS);

      this.sendBody = {
        operatorId: "10.10.20.68",
        playerToken: profileNm,
        balance: balance,
        // currency: plr_crncy,
        system: this.systemOS,
        gameId: Number(this.state.gameId),
        site: site,
        lang: lang,
        webSocketSessionId: indSessionId,
      };
      console.log(this.sendBody);
      // this.axiosHit();

      //Testing
      /*
      this.setState({
        playerId: event.data.playerId
      })

      this.state.playerId = event.data.playerId;

      const body = {
        CN: "ENTER_GAME",
        object: {
          playerId: event.data.playerId,
          gameId: myGameId,
        },
        sessionId: event.data.sessionId,
      };
      this.connectionTo.sendof(body);
      this.body = {};
      

      const body2 = {
        sessionId: event.data.sessionId,
        CN: "GET_GAME_STATE",
        object: {
          gameId: myGameId,
          playerId: event.data.playerId,
        },
      };
      this.connectionTo.sendof(body2);
      console.log(body2);
      this.body2 = {};

      const body3 = {
        sessionId: event.data.sessionId,
        CN: "GET_PLAYER_INFO",
        object: {
          gameId: myGameId,
          playerId: event.data.playerId,
        },
      };
      this.connectionTo.sendof(body3);
      console.log(body3);
      this.body3 = {};

      */

      //Testing
    }
  };

  axiosHit = () => {
    // alert('axios')
    axios({
      // url: "https://indicasino.czargaming.com/rest/indiCasino/api/authenticate",
      // url: "https://indicasino.undertesting.com/rest/indiCasino/api/authenticate",
      // url: "https://devp4m.czargaming.com/betby/rest/indiCasino/api/authentication",
      // url: "https://gearupcasino.undertesting.com/rest/indiCasino/api/authenticate",
      // url: "https://mcasino.czargaming.com/rest/indiCasino/api/authentication",
      // url: "http://10.10.20.72:9999/authenticate",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
      },
      data: this.sendBody,
    })
      .then((res) => {
        if (res.data.data.sessionId) {
          // this.gameEntryOutSide = true;
          this.state.playerId = res.data.data.playerId;
          this.state.sessionIdMcasino = res.data.data.sessionId;
          sessionStorage.setItem("sessionId", res.data.data.sessionId);
          console.log(this.state.playerId);
          console.log(sessionStorage.getItem("sessionId"));

          const body = {
            CN: "ENTER_GAME",
            object: {
              playerId: res.data.data.playerId,
              gameId: this.state.gameId,
            },
            sessionId: sessionStorage.getItem("sessionId"),
          };
          console.log(body);
          this.connectionTo.sendof(body);
        } else {
          this.setState({
            enterGameLoader: true,
          });
          console.log("authentication faild ");
        }
        // alert(  window.location )

        sessionStorage.setItem("reloadUrl", window.location);
      })
      .catch((err) => {
        console.log(err);
        alert("authentication failed");
      });
  };

  update() {
    if (!this.doVisualUpdates) {
      // console.log("this.update------------------");
    }
  }

  connection() {
    this.connectionTo.connection();
    Emitter.on(
      this.connectionTo.OnReceivingData,
      this.onReceivingData.bind(this)
    );
    Emitter.on(
      this.connectionTo.ConnectionEstd,
      this.ConnectionEstd.bind(this)
    );
    Emitter.on(
      this.connectionTo.ReceiveNoConnection,
      this.ReceiveNoConnection.bind(this)
    );
    Emitter.on(
      this.connectionTo.webSocketClosed,
      this.webSocketClosed.bind(this)
    );
  }

  webSocketClosed = () => {
    this.closeAllGames();
    // this.componentStateHandler("logOutSuccess", {})
    console.log("app js connection closed ");

    this.setState({ websocketLoader: true });
    setTimeout(() => {
      // window.location.reload();
    }, 1000);
  };
  ConnectionEstd = (data) => {

    // document.getElementById("infoIcon").style.display = "none";
    console.log(data.currentTarget.url);
    if (data.type == "open") {
      this.setState({ websocketLoader: false });
    }
    console.log(this.state.websocketLoader);

    let gId = data.currentTarget.url.split('?gameId=')
    console.log(gId);

    this.state.gameId = gId[1].split('&')[0];
    this.setState({
      gameId: gId[1].split('&')[0]
    })
    console.log(this.state.gameId);
    this.resize();


  };
  ReceiveNoConnection = (eve) => {
    if (eve.type == "error") {
      this.setState({ websocketLoader: true });
      setTimeout(() => {
        // window.location.reload();
      }, 2000);
    } else {
      this.setState({ websocketLoader: false });
    }
  };

  convertNumber(chngAmt) {
    let newAmt = Number(Number(chngAmt).toFixed(2));
    if (newAmt >= 1000 && newAmt <= 999999) {
      newAmt = Number((newAmt / 1000).toFixed(2)) + "K";
    } else if (newAmt >= 1000000 && newAmt <= 999999999) {
      newAmt = Number((newAmt / 1000).toFixed(2)) + "M";
    } else if (newAmt > 999999999) {
      newAmt = Number((newAmt / 1000).toFixed(2)) + "B";
    }
    return newAmt;
  }


  PreLoaderEnterGameCheck(event) {
    console.log(event)
    console.log(this.state.progressValue)
    if (event) {
      this.setState({ enterGameLoader: true });
    } else {
      let intervalPogressValue = setInterval(() => {
        console.log(this.state.progressValue)
        if (this.state.progressValue < 100) {
          this.setState({
            progressValue: this.state.progressValue + 1,
          });
        } else {
          console.log("clear")
          clearInterval(intervalPogressValue);
        }
      }, 40);

      setTimeout(() => {
        this.setState({
          MainLoaderImg: false,
          enterGameLoader: false
        })
        clearInterval(intervalPogressValue);
        console.log(this.state.progressValue)
      }, 3000)
    }

  }


  onReceivingData = (event) => {
    console.log(event);
    let showGame = true;
    if (event.status == 401) {
      this.setState({ popupMessage: event.msg });
      this.setState({ closePopup: true });
      this.setState({ error_code: "game.under.maintenance" });
    } else {
      //let myGameId =  13;
      let gameId = sessionStorage.getItem("gameId");
      console.log(gameId);

      this.setState({ gameId: sessionStorage.getItem("gameId") })
      // console.log(this.gameList);

      for (let k = 0; k < this.gameList.length; k++) {
        if (this.gameList[k] == gameId) {
          showGame = true;
          break;
        } else {
          showGame = false;
        }
      }
      console.log(showGame);
      console.log(event.data);

      if (showGame) {
        if (event.data && event.data.sessionId) {
          console.log(event.data.sessionId);
          sessionStorage.setItem("indieSessionId", event.data.sessionId);
          sessionStorage.setItem("sessionId", event.data.sessionId);
          sessionStorage.setItem("playerId", event.data.playerId);

          //Testing
          let myGameId = gameId;
          this.setState({
            playerId: event.data.playerId,
          });

          this.state.playerId = event.data.playerId;

          let body = {
            CN: "ENTER_GAME",
            object: {
              playerId: event.data.playerId,
              gameId: myGameId,
            },
            sessionId: event.data.sessionId,
          };
          this.connectionTo.sendof(body);
          // this.body = {};
          body = {};

          let body2 = {
            sessionId: event.data.sessionId,
            CN: "GET_GAME_STATE",
            object: {
              gameId: myGameId,
              playerId: event.data.playerId,
              // handId: this.handId


            },
          };
          this.connectionTo.sendof(body2);
          console.log(body2);
          // this.body2 = {};
          body2 = {};

          let body3 = {
            sessionId: event.data.sessionId,
            CN: "GET_PLAYER_INFO",
            object: {
              gameId: myGameId,
              playerId: event.data.playerId,
            },
          };
          this.connectionTo.sendof(body3);
          console.log(body3);
          // this.body3 = {};
          body3 = {};
        }
        if (this.receiveDataFromWs == false) {
          this.enterGameFromOtherSite();
          this.receiveDataFromWs = true;
        }
        this.setState({
          websocketLoader: false,
        });

        if (event != null && this.state.websocketLoader == false) {
          switch (event["commandName"]) {
            case "GAME_STATE":
              this.setState({ gameId: event.gameId });
              let id = sessionStorage.getItem("sessionId");
              let playerId = sessionStorage.getItem("playerId");
              this.setState({ activeProfile: false });
              if (event.timer != 0) {
                this.timerGame = true;
                this.getTimer(playerId, id);
              } else {
                this.timerGame = false;
              }
              /*********************  UPDATING GAME STATE WHEN GAME IS LOADED  ******************/

              switch (Number(event.gameId)) {
                case 1:
                  this.setState({ funCardGameState: event, heightFix: true });
                  if (this.state.gameEntry == "out" && this.state.funCardGameState !== null) {
                    this.gameEntryOutSide = true;
                    // this.setState({ enterGameLoader: false, });
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true, });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  this.props.history.push("/miniAndarBahar");
                  break;
                case 2:
                  this.setState({ funTargetTimerGameState: event, heightFix: true });
                  if (this.state.gameEntry == "out" && this.state.funTargetTimerGameState !== null) {
                    this.gameEntryOutSide = true;
                    // this.setState({ enterGameLoader: false });
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  this.props.history.push("/targetTimer");
                  break;
                case 3:
                  this.setState({ AmericanRouletteTimerGameState: event, heightFix: true });
                  if (this.AmericanRouletteTimer.current !== null) {
                    this.AmericanRouletteTimer.updateGameState();
                  }
                  if (this.state.gameEntry == "out" && this.state.AmericanRouletteTimerGameState !== null) {
                    this.gameEntryOutSide = true;
                    // this.setState({ enterGameLoader: false });
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  this.props.history.push("/americanRouletteTimer");
                  break;
                case 5:
                  this.setState({ funTargetGameState: event, heightFix: true });
                  if (this.FunTarget && this.FunTarget.current) {
                    this.FunTarget.updateGameState();
                  }
                  if (this.state.gameEntry == "out" && this.state.funTargetGameState !== null) {
                    this.gameEntryOutSide = true;
                    // this.setState({ enterGameLoader: false });
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  this.props.history.push("/targetNonTimer");
                  break;
                case 6:
                  this.setState({ americanRouletteGameState: event, heightFix: true });
                  if (this.AmericanRoulette.current !== null) {
                    this.AmericanRoulette.updateGameState();
                  }
                  if (this.state.gameEntry == "out" && this.state.americanRouletteGameState !== null) {
                    this.gameEntryOutSide = true;
                    // this.setState({ enterGameLoader: false });
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  this.props.history.push("/americanRoulettenon");
                  break;
                case 7:
                  this.setState({ FunMatkaTimerGameState: event, heightFix: true });
                  console.log("FMT Game state Update");
                  console.log(this.FunMatkaTimer);
                  console.log(this.FunMatkaTimer.updateGameState);
                  if (this.FunMatkaTimer && this.FunMatkaTimer.updateGameState) {
                    this.FunMatkaTimer.updateGameState();
                  }
                  if (
                    this.state.gameEntry == "out" && this.state.FunMatkaTimerGameState !== null) {
                    this.gameEntryOutSide = true;
                    // this.setState({ enterGameLoader: false });
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  this.props.history.push("/matkatimer");
                  break;
                case 12:
                  this.setState({ EuropeanRouletteTimerGameState: event, heightFix: true });
                  if (this.EuropeanRouletteTimer.current !== null) {
                    this.EuropeanRouletteTimer.updateGameState();
                  }
                  if (this.state.gameEntry == "out" && this.state.EuropeanRouletteTimerGameState !== null) {
                    this.gameEntryOutSide = true;
                    // this.setState({ enterGameLoader: false });
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  this.props.history.push("/europeanRouletteTimer");
                  break;
                case 13:
                  console.log("European Roulette Game State");
                  this.setState({ EuropeanRouletteGameState: event, heightFix: true });
                  if (this.EuropeanRoulette.current !== null) {
                    console.log("European Update GameState: " + this.EuropeanRoulette.updateGameState());
                    this.EuropeanRoulette.updateGameState();
                  }
                  if (this.state.gameEntry == "out" && this.state.EuropeanRouletteGameState !== null) {
                    this.gameEntryOutSide = true;
                    // this.setState({ enterGameLoader: false });
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  this.props.history.push("/europeanRoulette");
                  break;
                case 14:
                  this.setState({ funMatkaGameState: event, heightFix: true });
                  if (this.state.gameEntry == "out" && this.state.funMatkaGameState !== null) {
                    this.gameEntryOutSide = true;
                    // this.setState({ enterGameLoader: false });
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  this.props.history.push("/matkaNonTimer");
                  break;
                case 19:
                  console.log(event);
                  this.setState({ funSoratGameState: event, heightFix: true });
                  if (this.FunSoratTimer.current !== null) {
                    this.FunSoratTimer.updateGameState();
                  }
                  if (this.state.gameEntry == "out" && this.state.funSoratGameState !== null) {
                    this.gameEntryOutSide = true;
                    // this.setState({ enterGameLoader: false });
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  console.log(this.state.funSoratGameState);
                  if (this.FunSoratTimer.current !== null) {
                    this.FunSoratTimer.historyUpdate();
                  }
                  this.props.history.push("/soratTimer");
                  break;
                case 20:
                  console.log("dragonTigerTimer Game State: " + event);
                  console.log(event);
                  let historyTest = [
                    {
                      handId: 1,
                      results: [1, 2, 1, 0, 0, 3, 2],
                    },
                    {
                      handId: 1,
                      results: [0, 2, 1, 1, 0, 3, 2],
                    },
                    {
                      handId: 1,
                      results: [1, 2, 1, 0, 0, 3, 2],
                    },
                    {
                      handId: 1,
                      results: [0, 2, 1, 1, 0, 3, 2],
                    },
                    {
                      handId: 1,
                      results: [0, 2, 1, 1, 0, 3, 2],
                    },
                  ];
                  this.setState({ dragonTigerGameState: event, heightFix: true, dragonTigerHistoy: event.history });
                  if (this.DragonTigerTimer.current !== null) {
                    this.DragonTigerTimer.update_DT_GameState(event);
                  }
                  console.log("Dragon History");
                  console.log(event.history);
                  console.log(this.state.dragonTigerHistoy);
                  if (this.state.gameEntry == "out" && this.state.dragonTigerGameState !== null) {
                    this.gameEntryOutSide = true;
                    // this.setState({ enterGameLoader: false });
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  this.props.history.push("/dragonTiger");
                  break;
                case 22:
                  this.setState({ heightFix: true });

                  this.setState({ tripleChanceGameState: event });
                  this.setState({ TripleChance: event.history });
                  console.log("TripleChance History");
                  console.log(event.history);
                  console.log(this.state.TripleChanceHistory);

                  this.props.history.push("/tripleChance");
                  break;
                case 23:
                  this.setState({ moneyWheelGameState: event, heightFix: true });
                  console.log(this.state.moneyWheelGameState);
                  if (this.MoneyWheelTimer.current !== null) {
                    this.MoneyWheelTimer.historyUpdate(event);
                  }
                  if (this.state.gameEntry == "out" && this.state.moneyWheelGameState !== null) {
                    console.log("Enter Into Money Wheel Game");
                    this.gameEntryOutSide = true;
                    // this.setState({ enterGameLoader: false });
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  // this.state.enterGameLoader = false;
                  this.props.history.push("/moneyWheel");
                  break;
                case 24:
                  console.log("Andar Bahar Game State: ");
                  console.log(event);
                  this.setState({ andarBaharTimerGameState: event, heightFix: true });
                  if (this.AndarBaharTimer && this.showAndarBaharTimer) {
                    this.AndarBaharTimer.updateHistory(event.history);
                  }
                  if (this.state.gameEntry == "out" && this.state.andarBaharTimerGameState != null) {
                    this.gameEntryOutSide = true;
                    // this.setState({ enterGameLoader: false });
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  this.props.history.push("/andarBaharTimer");
                  break;
                case 25:
                  console.log("Bingo Game State: ");
                  console.log(event);
                  this.setState({ bingoGameState: event, heightFix: true });
                  if (this.Bingo.current !== null) {
                    this.Bingo.updateGameState();
                  }

                  if (this.state.gameEntry == "out" && this.state.bingoGameState != null) {
                    this.gameEntryOutSide = true;
                    // this.setState({ enterGameLoader: false });
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  this.props.history.push("/bingo");
                  break;
                case 26:
                  console.log("Keno Game State: " + event);
                  console.log(event);
                  this.setState({ KenoGameState: event, heightFix: true });
                  if (this.KenoGame.current !== null) {
                    this.KenoGame.updateGameState();
                  }

                  if (
                    this.state.gameEntry == "out" && this.state.KenoGameState !== null) {
                    this.gameEntryOutSide = true;
                    // this.setState({ enterGameLoader: false });
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  this.props.history.push("/Keno");
                  break;
                case 28:
                  // alert("...123")
                  console.log("Russian Poker Game State: ");
                  console.log(event);
                  this.setState({
                    russianPokerGameState: event,
                    heightFix: true,
                  });
                  console.log(this.state.russianPokerGameState);
                  console.log(this.state.gameEntry);
                  if (this.state.gameEntry == "out") {
                    this.gameEntryOutSide = true;
                    // this.setState({ enterGameLoader: false });
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  // this.gameEntryOutSide = true;

                  this.props.history.push("/russianPoker");


                  break;

                case 30:
                  console.log("Jacks or Better Game State: ");
                  console.log(event);
                  this.setState({ jacksorBetterGameState: event, heightFix: true });
                  if (this.JacksorBetter && this.showJacksorBetter) {
                    this.JacksorBetter.updateHistory(event.history);
                  }
                  if (this.state.gameEntry == "out" && this.state.jacksorBetterGameState != null) {
                    this.gameEntryOutSide = true;
                    // this.setState({ enterGameLoader: false });
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  this.props.history.push("/jacksrbetter");
                  break;
                case 33:

                  console.log("CarebbeanPoker Game State: ");
                  console.log(event);
                  this.setState({
                    CarebbeanPokerGameState: event,
                    heightFix: true,
                  });

                  if (this.state.gameEntry == "out") {
                    this.gameEntryOutSide = true;
                    // setTimeout(() => {
                    //   this.setState({ enterGameLoader: false });
                    // }, 3000)
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  // this.gameEntryOutSide = true;

                  this.props.history.push("/CarebbeanPoker");
                  break;

                case 35:
                  // console.log("DeucesWild Game State: ");
                  // console.log(event);
                  this.setState({ deucesWildGameState: event, heightFix: true });
                  if (this.DeucesWild && this.showDeucesWild) {
                    this.DeucesWild.updateHistory(event.history);
                  }
                  if (this.state.gameEntry == "out" && this.state.deucesWildGameState != null) {
                    this.gameEntryOutSide = true;
                    // this.setState({ enterGameLoader: false });
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  this.props.history.push("/deuceswild");
                  break;

                case 36:
                  // console.log("JokerPoker Game State: ");
                  // console.log(event);
                  this.setState({ jokerPokerGameState: event, heightFix: true });
                  if (this.JokerPoker && this.showJokerPoker) {
                    this.JokerPoker.updateHistory(event.history);
                  }
                  if (this.state.gameEntry == "out" && this.state.jokerPokerGameState != null) {
                    this.gameEntryOutSide = true;
                    // this.setState({ enterGameLoader: false });
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  this.props.history.push("/jokerpoker");
                  break;

                case 39:
                  // alert("...123")
                  console.log("Tropical Poker Game State: ");
                  console.log(event);

                  this.setState({
                    tropicalPokerGameState: event,
                    heightFix: true,
                  });

                  console.log(this.state.tropicalPokerGameState);
                  console.log(this.state.gameEntry);

                  if (this.state.gameEntry == "out") {
                    this.gameEntryOutSide = true;
                    // setTimeout(() => {
                    //   this.setState({ enterGameLoader: false });
                    // }, 3000)
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  // this.gameEntryOutSide = true;
                  console.log(".................///////////................")

                  this.props.history.push("/tropicalPoker");
                  console.log(".................///////////................")
                  console.log(".................///////////................")
                  break;

                case 40:
                  console.log("Tens or Better Game State: ");
                  console.log(event);
                  this.setState({ tensorBetterGameState: event, heightFix: true });
                  if (this.TensorBetter && this.showTensorBetter) {
                    this.TensorBetter.updateHistory(event.history);
                  }
                  if (this.state.gameEntry == "out" && this.state.tensorBetterGameState != null) {
                    this.gameEntryOutSide = true;
                    // this.setState({ enterGameLoader: false });
                    this.PreLoaderEnterGameCheck(false)
                  } else {
                    // this.setState({ enterGameLoader: true });
                    this.PreLoaderEnterGameCheck(true)
                  }
                  this.props.history.push("/tensrbetter");
                  break;
                default:
                  break;
              }


              console.log("Game State Event Response: " + event.response);
              if (event.response) {
                console.log("Receive Command: " + event.response.commandName.action);
                switch (event.response.commandName) {
                  case "TARGET_RESULT":
                    if (this.FunTarget.current !== null) {
                      this.FunTarget.updateGameState();
                    }
                    break;
                  case "FUN_MATKA_RESULT":
                    if (Number(this.state.gameId) == 14) {
                      this.setState({ funMatkaGameState: event });
                    } else {
                      this.getTimer(playerId, id);
                    }
                    break;
                  case "ROULETTE_RESULT":
                    if (Number(this.state.gameId) == 6) {
                      this.setState({ americanRouletteGameState: event });
                      if (this.AmericanRoulette.current !== null) {
                        this.AmericanRoulette.updateGameState();
                      }
                    } else if (Number(this.state.gameId) == 13) {
                      this.setState({ EuropeanRouletteGameState: event });
                      if (this.EuropeanRoulette.current !== null) {
                        this.EuropeanRoulette.updateGameState();
                      }
                    }
                    break;
                  case "FUN_TARGET_RESULT":
                    this.getTimer(playerId, id);
                    break;
                  case "FUN_ROULETTE_RESULT":
                    console.log("Game Number: " + Number(this.state.gameId));
                    this.getTimer(playerId, id);

                    if (Number(this.state.gameId) == 3) {
                      if (this.AmericanRouletteTimer.current !== null) {
                        this.AmericanRouletteTimer.updateGameState();
                      }
                    }
                    break;
                  case "SORAT_RESULT":
                    console.log("Funsorat Game Result");
                    this.getTimer(playerId, id);
                    break;

                  case "ANDAR_BAHAR_RESULT":
                    console.log("ANDAR_BAHAR_RESULT");
                    if (Number(event.takeAmount) > 0) {
                      this.setState({ andarBaharTimerResult: event.response });
                    }
                    break;
                  case "DRAGON_TIGER_RESULT":
                    console.log("DRAGON_TIGER_RESULT");
                    this.getTimer(playerId, id);
                    console.log(this.DragonTigerTimer.current);
                    if (this.DragonTigerTimer.current != null) {
                      if (this.showDragonTiger) {
                        this.setState({ dragonTigerGameState: event });
                      }
                    }

                    break;
                  case "MONEY_WHEEL_RESULT":
                    if (this.MoneyWheelTimer.current !== null) {
                      if (this.showMoneyWheel) {
                        this.setState({ moneyWheelGameState: event });
                      }
                    }
                    if (Number(event.takeAmount) > 0) {
                      this.setState({ moneyWheelResult: event });
                    }
                    break;

                  case "TRIPLE_CHANCE_RESULT":
                    console.log("TRIPLE_CHANCE_RESULT");
                    this.setState({ tripleChanceGameState: event });
                    console.log("App.js: tripleChanceGameState : " + this.state.tripleChanceGameState);
                    if (event) {
                      this.TripleChance.current.tripleChanceGameState();
                    }
                    break;

                  default:
                    break;
                }

              }

              if (!this.state.activeProfile && !this.state.gameStateUpdate) {
                if (this.state.gameId == 1) {

                  // if (this.funcardIsActive)


                  this.gameLobbyOpen(this.state.gameId);
                } else {
                  this.gameLobbyOpen(this.state.gameId);
                }
              }
              break;
            case "UPDATED_PLAYER_INFO":
              switch (Number(this.state.gameId)) {
                case 28:
                  this.RussianPoker.UpdateplayerinfoPlayerWins();
                  break
                case 33:
                  this.CarebbeanPoker.UpdateplayerinfoPlayerWins();
              }
              break
            default:
              break;
            case "PLAYER_INFO":
              this.setState({
                balance: event["balance"],
                user: event["loginName"],
              });
              sessionStorage.setItem("playerId", event["playerId"]);
              sessionStorage.setItem("username", event["loginName"]);
              let name = sessionStorage.getItem("username");
              let playerid = sessionStorage.getItem("playerId");
              let type = "loginSuccess";
              let res = { name: name, playerId: playerid };
              if (this.state.activeProfile) {
                this.componentStateHandler(type, res);
                //this.getGameLobby();
              }
              console.log("Update Player Info: Game ID: " + typeof this.state.gameId);
              switch (Number(this.state.gameId)) {

                case 1:
                  console.log(this.state.gameId);
                  this.FunCard.updatePlayerInfo(); //current
                  console.log(this.FunCard);
                  if (this.FunCard != null) {
                    // console.log(this.FunCard.current);
                    // console.log(this.FunCard.current.updateMessage);
                    this.FunCard.updateMessage();
                  }

                  break;
                case 2:
                  console.log(this.FunTargetTimer);
                  console.log(this.FunTargetTimer.updatePlayerInfo);
                  if (this.FunTargetTimer.updatePlayerInfo) {
                    this.FunTargetTimer.updatePlayerInfo();
                  }
                  break;
                case 3:
                  this.AmericanRouletteTimer.updatePlayerInfo();
                  break;
                case 5:
                  this.FunTarget.updatePlayerInfo();
                  break;
                case 6:
                  this.AmericanRoulette.updatePlayerInfo();
                  break;
                case 7:
                  this.FunMatkaTimer.updatePlayerInfo();
                  break;
                case 12:
                  this.EuropeanRouletteTimer.updatePlayerInfo();
                  break;
                case 13:
                  this.EuropeanRoulette.updatePlayerInfo();
                  break;
                case 14:
                  this.FunMatka.updatePlayerInfo();
                  break;
                case 19:
                  // this.FunSoratTimer.updatePlayerInfo(event["balance"]);
                  break;
                case 20:
                  console.log(this.DragonTigerTimer);
                  console.log(this.DragonTigerTimer.current);

                  if (this.DragonTigerTimer) {
                    this.DragonTigerTimer.dragonTigerPlayerInfo();
                  }
                  break;
                case 22:
                  // console.log(this.MoneyWheelTimer);
                  // if (this.MoneyWheelTimer) {
                  //   this.MoneyWheelTimer.updatePlayerInfo();
                  // }
                  break;
                case 23:
                  console.log(this.MoneyWheelTimer);
                  if (this.MoneyWheelTimer) {
                    this.MoneyWheelTimer.updatePlayerInfo();
                  }
                  break;
                case 24:
                  // this.AndarBaharTimer.updatePlayerInfo();
                  break;
                case 25:
                  this.Bingo.updatePlayerInfo();
                  break;
                case 26:
                  if (this.KenoGame) {
                    this.KenoGame.updatePlayerInfo();

                  }
                  break;
                case 28:
                  if (this.RussianPoker) {
                    this.RussianPoker.updatePlayerInfo();
                  }
                  break;
                case 30:
                  console.log(this.JacksorBetter)
                  if (this.JacksorBetter) {
                    this.JacksorBetter.updatePlayerInfo(event);
                  }
                  break;
                case 33:
                  if (this.CarebbeanPoker) {
                    this.CarebbeanPoker.updatePlayerInfo(event);
                  }

                  break;
                case 35:
                  console.log(this.DeucesWild)
                  if (this.DeucesWild) {
                    this.DeucesWild.updatePlayerInfo(event);
                  }
                  break;
                case 36:
                  // console.log(this.JokerPoker)
                  if (this.JokerPoker) {
                    this.JokerPoker.updatePlayerInfo(event);
                  }
                  break;

                case 39:
                  if (this.TropicalPoker) {
                    this.TropicalPoker.updatePlayerInfo();
                  }
                  break;


                case 40:
                  console.log(this.TensorBetter)
                  if (this.TensorBetter) {
                    this.TensorBetter.updatePlayerInfo(event);
                  }
                  break;
                default:
                  console.log(this.state.gameId)
                  break;
              }

            case "ERROR_INFO":
              switch (event["errorCode"]) {
                case "system.internalError":
                  // this.setState({ infoText: event["errorMessage"] });
                  // this.componentStateHandler("logOutSuccess", event.errorMessage);
                  switch (Number(gameId)) {
                    case 1:
                      if (this.FunCard && this.showFunCard) {
                        this.setState({
                          FunCardInfoText: this.props.language.InternalError,
                        });
                        this.FunCard.updateMessage();
                      }
                      break;
                    case 2:
                      if (this.FunTargetTimer && this.showFunTargetTimer) {
                        this.setState({
                          FunTargetTimerInfoText:
                            this.props.language.InternalError,
                        });
                        this.FunTargetTimer.updateMessage();
                      }
                      break;
                    case 3:
                      if (
                        this.AmericanRouletteTimer &&
                        this.showAmericanRouletteTimer
                      ) {
                        this.setState({
                          AmericanRouletteTimerInfoText:
                            this.props.language.InternalError,
                        });
                        this.AmericanRouletteTimer.updateMessage();
                      }
                      break;
                    case 5:
                      if (this.FunTarget && this.showFunTarget) {
                        this.setState({
                          FunTargetInfoText: this.props.language.InternalError,
                        });
                        this.FunTarget.updateMessage();
                      }
                      break;
                    case 6:
                      if (this.AmericanRoulette && this.showAmericanRoulette) {
                        this.setState({
                          AmericanRouletteInfoText:
                            this.props.language.InternalError,
                        });
                        this.AmericanRoulette.updateMessage();
                      }
                      break;
                    case 7:
                      if (this.FunMatkaTimer && this.showFunMatkaTimer) {
                        this.setState({
                          FunMatkaTimerInfoText:
                            this.props.language.InternalError,
                        });
                        this.FunMatkaTimer.updateMessage();
                      }
                      break;
                    case 12:
                      if (
                        this.EuropeanRouletteTimer &&
                        this.showEuropeanRouletteTimer
                      ) {
                        this.setState({
                          EuropeanRouletteTimerInfoText:
                            this.props.language.InternalError,
                        });
                        this.EuropeanRouletteTimer.updateMessage();
                      }

                      break;
                    case 13:
                      if (this.EuropeanRoulette && this.showEuropeanRoulette) {
                        this.setState({
                          EuropeanRouletteInfoText:
                            this.props.language.InternalError,
                        });
                        this.EuropeanRoulette.updateMessage();
                      }
                      break;
                    case 14:
                      if (
                        this.FunMatka &&
                        this.FunMatka.current &&
                        this.showFunMatka
                      ) {
                        this.setState({
                          FunMatkaInfoText: this.props.language.InternalError,
                        });
                        this.FunMatka.updateMessage();
                      }
                      break;
                    case 19:
                      if (this.FunSoratTimer && this.showFunSoratTimer) {
                        this.setState({
                          FunSoratTimerInfoText:
                            this.props.language.InternalError,
                        });
                        this.FunSoratTimer.updateMessage();
                      }
                      break;
                    case 20:
                      if (this.DragonTigerTimer && this.showDragonTiger) {
                        this.setState({
                          DragonTigerInfoText:
                            this.props.language.InternalError,
                        });
                        this.DragonTigerTimer.updateMessage(
                          "system.internalError"
                        );
                      }
                      break;
                    case 23:
                      if (this.MoneyWheelTimer && this.showMoneyWheel) {
                        this.setState({
                          MoneyWheelInfoText: this.props.language.InternalError,
                        });
                        this.MoneyWheelTimer.updateMessage();
                      }
                      break;
                    case 24:
                      if (this.AndarBaharTimer && this.showAndarBaharTimer) {
                        this.setState({
                          AndarBaharTimerInfoText:
                            this.props.language.InternalError,
                        });
                        this.AndarBaharTimer.updateMessage();
                      }
                      break;
                    case 25:
                      if (this.Bingo && this.showBingo) {
                        this.setState({ BingoInfoText: this.props.language.InternalError });
                        this.Bingo.updateMessage();
                      }
                      break;
                    case 26:
                      if (this.KenoGame && this.showKeno) {
                        this.setState({
                          KenoInfoText: this.props.language.InternalError,
                        });
                        this.KenoGame.updateDoubleupInfo(event);
                      }
                      break;
                    case 28:
                      if (this.RussianPoker && this.showRussianPoker) {
                        this.setState({
                          RussianPokerInfoText:
                            this.props.language.InternalError,
                        });
                      }
                      break;
                    case 33:
                      if (this.CarebbeanPoker && this.showCarebbeanPoker) {
                        this.setState({
                          CarebbeanPokerInfoText:
                            this.props.language.InternalError,
                        });
                      }
                      break;



                    case 30:
                      if (this.JacksorBetter && this.showJacksorBetter) {
                        this.setState({
                          JacksorBetterInfoText:
                            this.props.language.InternalError,
                        });
                      }
                      break;
                    // case 33:
                    // if (this.CarebbeanPoker && this.showCarebbeanPoker) {
                    //   this.setState({
                    //     CarebbeanPokerInfoText:
                    //       this.props.language.InternalError,
                    //   });
                    // }
                    // break;
                    case 35:
                      if (this.DeucesWild && this.showDeucesWild) {
                        this.setState({
                          DeucesWildInfoText:
                            this.props.language.InternalError,
                        });
                      }
                      break;
                    case 36:
                      if (this.JokerPoker && this.showJokerPoker) {
                        this.setState({
                          JokerPokerInfoText:
                            this.props.language.InternalError,
                        });
                      }
                      break;

                    case 39:
                      if (this.TropicalPoker && this.showTropicalPoker) {
                        this.setState({
                          TropicalPokerInfoText:
                            this.props.language.InternalError,
                        });
                      }
                      break;

                    case 40:
                      if (this.TensorBetter && this.showTensorBetter) {
                        this.setState({
                          TensorBetterInfoText:
                            this.props.language.InternalError,
                        });
                      }
                      break;
                    default:
                      break;
                  }
                  break;
                case "game.bet.rejected.insufficientFunds":
                case "game.bet.rejected.alreadyPlaced":
                  console.log(event["errorMessage"]);
                  // alert(event["errorMessage"])
                  // this.setState({ infoText: event["errorMessage"] });
                  switch (event.gameId) {
                    case 1:
                      if (this.FunCard && this.showFunCard) {
                        this.setState({
                          FunCardInfoText: this.props.language.AlreadyBetPlaced,
                        });
                      }
                      break;
                    case 2:
                      if (this.FunTargetTimer && this.showFunTargetTimer) {
                        this.setState({
                          FunTargetTimerInfoText:
                            this.props.language.AlreadyBetPlaced,
                        });
                      }
                      break;
                    case 3:
                      if (
                        this.AmericanRouletteTimer &&
                        this.showAmericanRouletteTimer
                      ) {
                        this.setState({
                          AmericanRouletteTimerInfoText:
                            this.props.language.AlreadyBetPlaced,
                        });
                      }
                      break;
                    case 5:
                      if (this.FunTarget && this.showFunTarget) {
                        this.setState({
                          FunTargetInfoText:
                            this.props.language.AlreadyBetPlaced,
                        });
                      }
                      break;
                    case 6:
                      if (this.AmericanRoulette && this.showAmericanRoulette) {
                        this.setState({
                          AmericanRouletteInfoText:
                            this.props.language.AlreadyBetPlaced,
                        });
                      }
                      break;
                    case 7:
                      if (this.FunMatkaTimer && this.showFunMatka) {
                        this.setState({
                          FunMatkaTimerInfoText:
                            this.props.language.AlreadyBetPlaced,
                        });
                      }
                      break;
                    case 12:
                      if (
                        this.EuropeanRouletteTimer &&
                        this.showEuropeanRouletteTimer
                      ) {
                        this.setState({
                          EuropeanRouletteTimerInfoText:
                            this.props.language.AlreadyBetPlaced,
                        });
                      }
                      break;
                    case 13:
                      if (this.EuropeanRoulette && this.showEuropeanRoulette) {
                        this.setState({
                          EuropeanRouletteInfoText:
                            this.props.language.AlreadyBetPlaced,
                        });
                      }
                      break;
                    case 14:
                      if (
                        this.FunMatka &&
                        this.FunMatka.current &&
                        this.showFunMatka
                      ) {
                        this.setState({
                          FunMatkaInfoText:
                            this.props.language.AlreadyBetPlaced,
                        });
                      }
                      break;
                    case 19:
                      if (this.FunSoratTimer && this.showFunSoratTimer) {
                        this.setState({
                          FunSoratTimerInfoText:
                            this.props.language.AlreadyBetPlaced,
                        });
                      }
                      break;
                    case 20:
                      if (this.DragonTigerTimer && this.showDragonTiger) {
                        this.setState({
                          DragonTigerInfoText:
                            this.props.language.AlreadyBetPlaced,
                        });
                      }
                      break;
                    case 23:
                      if (this.MoneyWheelTimer && this.showMoneyWheel) {
                        this.setState({
                          MoneyWheelInfoText:
                            this.props.language.AlreadyBetPlaced,
                        });
                      }
                      break;
                    case 24:
                      if (this.AndarBaharTimer && this.showAndarBaharTimer) {
                        this.setState({
                          AndarBaharTimerInfoText:
                            this.props.language.AlreadyBetPlaced,
                        });
                      }
                      break;
                    case 25:
                      if (this.Bingo && this.showBingo) {
                        this.setState({ BingoTimerInfoText: this.props.language.AlreadyBetPlaced });
                      }
                      break;
                    case 26:
                      console.log(this.KenoGame + "  ***   " + this.showKeno);
                      if (this.KenoGame && this.showKeno) {
                        this.setState({
                          KenoInfoText: this.props.language.AlreadyBetPlaced,
                        });
                      }
                      break;

                    case 28:
                      if (this.RussianPoker && this.showRussianPoker) {
                        this.setState({
                          RussianPokerInfoText:
                            this.props.language.AlreadyBetPlaced,
                        });
                      }
                      break;
                    case 30:
                      if (this.JacksorBetter && this.showJacksorBetter) {
                        this.setState({
                          JacksorBetterInfoText:
                            this.props.language.AlreadyBetPlaced,
                        });
                      }
                      break;

                    // case 33:
                    //   if (this.CarebbeanPoker && this.showCarebbeanPoker) {
                    //     this.setState({
                    //       CarebbeanPokerInfoText:
                    //     });
                    //   }
                    case 33:
                      if (this.CarebbeanPoker && this.showCarebbeanPoker) {
                        this.setState({
                          CarebbeanPokerInfoText:
                            this.props.language.AlreadyBetPlaced,
                        });
                      }
                      break;

                    case 35:
                      if (this.DeucesWild && this.showDeucesWild) {
                        this.setState({
                          DeucesWildInfoText:
                            this.props.language.AlreadyBetPlaced,
                        });
                      }
                      break;
                    case 36:
                      if (this.JokerPoker && this.showJokerPoker) {
                        this.setState({
                          JokerPokerInfoText:
                            this.props.language.AlreadyBetPlaced,
                        });
                      }
                      break;

                    case 39:
                      if (this.TropicalPoker && this.showTropicalPoker) {
                        this.setState({
                          TropicalPokerInfoText:
                            this.props.language.AlreadyBetPlaced,
                        });
                      }
                      break;

                    case 40:
                      if (this.TensorBetter && this.showTensorBetter) {
                        this.setState({
                          TensorBetterInfoText:
                            this.props.language.AlreadyBetPlaced,
                        });
                      }
                      break;
                    default:
                      break;
                  }
                  this.componentStateHandler(
                    "logOutSuccess",
                    event.errorMessage
                  );
                  break;
                case "player.session.expired":
                  this.setState({ popupMessage: event["reason"] });
                  this.setState({ closePopup: true });
                  this.setState({ error_code: "player.session.expired" });
                  localStorage.removeItem("sessionId");
                  localStorage.removeItem("username");
                  localStorage.removeItem("playerId");
                  break;
                default:
                  break;
              }

              break;
            case "SESSION_CLOSED":
              this.sessionClosed = true;
              this.setState({
                popupMessage: event.ackMessage,
                closePopup: true,
                error_code: event.ackCode,
              });
              switch (this.state.gameId) {
                case 23:
                  if (this.MoneyWheelTimer.current != null) {
                    this.MoneyWheelTimer.gameSessionClosed();
                  }
                  this.showMoneyWheel = false;
                  break;
                case 26:
                  this.KenoGame.gameSessionClosed();
                  this.showKeno = false;
                  break;
                default:
                  break;
              }
              break;

            case "ACKNOWLEDGE":
              // console.log("Acknowledge: 1");
              //console.log("Acknowledge: Ack: " + event);
              console.log(event);
              console.log(event.ackMessage);
              this.setState({
                gameId: event.gameId,
                gameData: event,
              });
              if (!event.ackMessage || event.ackMessage == undefined) {
                // this.getGameState();
              }
              switch (event.gameId) {
                case 1:
                  if (event.minBet) {
                    this.setState({
                      fc_minBetLbl: this.convertNumber(event.minBet),
                      fc_maxBetLbl: this.convertNumber(event.maxBet),
                      fc_minBet: event.minBet,
                      fc_maxBet: event.maxBet,
                      fc_innerLimits: event.innerLimits,
                      fc_chipValues: event.chipValues,
                    });
                  }
                  break;
                case 2:
                  if (event.minBet) {
                    this.setState({
                      ftt_minBetLbl: this.convertNumber(event.minBet),
                      ftt_maxBetLbl: this.convertNumber(event.maxBet),
                      ftt_minBet: event.minBet,
                      ftt_maxBet: event.maxBet,
                      ftt_innerLimits: event.innerLimits,
                      ftt_chipValues: event.chipValues,
                    });
                  }
                  break;
                case 3:
                  if (event.minBet) {
                    this.setState({
                      art_minBetLbl: this.convertNumber(event.minBet),
                      art_maxBetLbl: this.convertNumber(event.maxBet),
                      art_minBet: event.minBet,
                      art_maxBet: event.maxBet,
                      art_innerLimits: event.innerLimits,
                      art_chipValues: event.chipValues,
                    });
                  }
                  break;
                case 5:
                  if (event.minBet) {
                    this.setState({
                      ft_minBetLbl: this.convertNumber(event.minBet),
                      ft_maxBetLbl: this.convertNumber(event.maxBet),
                      ft_minBet: event.minBet,
                      ft_maxBet: event.maxBet,
                      ft_innerLimits: event.innerLimits,
                      ft_chipValues: event.chipValues,
                    });
                  }

                  break;
                case 6:
                  if (event.minBet) {
                    this.setState({
                      ar_minBetLbl: this.convertNumber(event.minBet),
                      ar_maxBetLbl: this.convertNumber(event.maxBet),
                      ar_minBet: event.minBet,
                      ar_maxBet: event.maxBet,
                      ar_innerLimits: event.innerLimits,
                      ar_chipValues: event.chipValues,
                    });
                  }
                  break;
                case 7:
                  if (event.minBet) {
                    this.setState({
                      fmt_minBetLbl: this.convertNumber(event.minBet),
                      fmt_maxBetLbl: this.convertNumber(event.maxBet),
                      fmt_minBet: event.minBet,
                      fmt_maxBet: event.maxBet,
                      fmt_innerLimits: event.innerLimits,
                      fmt_chipValues: event.chipValues,
                    });
                  }
                  break;
                case 12:
                  if (event.minBet) {
                    this.setState({
                      ert_minBetLbl: this.convertNumber(event.minBet),
                      ert_maxBetLbl: this.convertNumber(event.maxBet),
                      ert_minBet: event.minBet,
                      ert_maxBet: event.maxBet,
                      ert_innerLimits: event.innerLimits,
                      ert_chipValues: event.chipValues,
                    });
                  }
                  break;
                case 13:
                  if (event.minBet) {
                    this.setState({
                      er_minBetLbl: this.convertNumber(event.minBet),
                      er_maxBetLbl: this.convertNumber(event.maxBet),
                      er_minBet: event.minBet,
                      er_maxBet: event.maxBet,
                      er_innerLimits: event.innerLimits,
                      er_chipValues: event.chipValues,
                    });
                  }
                  break;
                case 14:
                  if (event.minBet) {
                    this.setState({
                      fm_minBetLbl: this.convertNumber(event.minBet),
                      fm_maxBetLbl: this.convertNumber(event.maxBet),
                      fm_minBet: event.minBet,
                      fm_maxBet: event.maxBet,
                      fm_innerLimits: event.innerLimits,
                      fm_chipValues: event.chipValues,
                    });
                  }
                  console.log("FM Min Bet amount  " + this.state.fm_minBetLbl);
                  console.log("FM Max Bet amount  " + this.state.fm_maxBetLbl);
                  break;
                case 19:
                  if (event.minBet) {
                    this.setState({
                      fst_minBetLbl: this.convertNumber(event.minBet),
                      fst_maxBetLbl: this.convertNumber(event.maxBet),
                      fst_minBet: event.minBet,
                      fst_maxBet: event.maxBet,
                      fst_innerLimits: event.innerLimits,
                      fst_chipValues: event.chipValues,
                    });
                  }
                  break;
                case 20:
                  if (event.minBet) {
                    this.setState({
                      dtt_minBetLbl: this.convertNumber(event.minBet),
                      dtt_maxBetLbl: this.convertNumber(event.maxBet),
                      dtt_minBet: event.minBet,
                      dtt_maxBet: event.maxBet,
                      dtt_innerLimits: event.innerLimits,
                      dtt_chipValues: event.chipValues,
                    });
                  }
                  break;

                case 23:
                  if (event.minBet) {
                    this.setState({
                      mwt_minBetLbl: this.convertNumber(event.minBet),
                      mwt_maxBetLbl: this.convertNumber(event.maxBet),
                      mwt_minBet: event.minBet,
                      mwt_maxBet: event.maxBet,
                      mwt_innerLimits: event.innerLimits,
                      mwt_chipValues: event.chipValues,
                    });
                  }
                  break;
                case 24:
                  if (event.minBet) {
                    this.setState({
                      abt_minBetLbl: this.convertNumber(event.minBet),
                      abt_maxBetLbl: this.convertNumber(event.maxBet),
                      abt_minBet: event.minBet,
                      abt_maxBet: event.maxBet,
                      abt_innerLimits: event.innerLimits,
                      abt_chipValues: event.chipValues,
                    });
                  }
                  break;
                case 25:
                  if (event.minBet) {
                    this.setState({
                      bng_minBetLbl: this.convertNumber(event.minBet),
                      bng_maxBetLbl: this.convertNumber(event.maxBet),
                      bng_minBet: event.minBet,
                      bng_maxBet: event.maxBet,
                      bng_innerLimits: event.innerLimits,
                      bng_chipValues: event.chipValues,
                    });
                  }
                  break;

                case 26:
                  if (event.minBet) {
                    this.setState({
                      kn_minBetLbl: this.convertNumber(event.minBet),
                      kn_maxBetLbl: this.convertNumber(event.maxBet),
                      kn_minBet: event.minBet,
                      kn_maxBet: event.maxBet,
                    });
                  }
                  console.log(event.minBet);
                  console.log(event.maxBet);
                  console.log(this.state.kn_minBet);
                  console.log(this.state.kn_maxBet);
                  console.log(this.state.kn_minBetLbl);
                  console.log(this.state.kn_minBetLbl);
                  this.setState({
                    kn_payouts: event.kenoPayouts,
                  });
                  break;

                case 28:
                  if (event.minBet) {
                    this.setState({
                      rp_minBetLbl: this.convertNumber(event.minBet),
                      rp_maxBetLbl: this.convertNumber(event.maxBet),
                      rp_minBet: event.minBet,
                      rp_maxBet: event.maxBet,
                      rp_innerLimits: event.innerLimits,
                      rp_chipValues: event.chipValues,
                    });
                  }
                  break;

                case 30:
                case 35:
                case 36:
                  if (event.minBet) {
                    this.setState({
                      jrb_minBetLbl: this.convertNumber(event.minBet),
                      jrb_maxBetLbl: this.convertNumber(event.maxBet),
                      jrb_minBet: event.minBet,
                      jrb_maxBet: event.maxBet,
                      jrb_innerLimits: event.innerLimits,
                      jrb_chipValues: event.chipValues ? event.chipValues : this.state.jrb_chipValues,
                    });
                  }
                  break;


                case 33:
                  if (event.minBet) {
                    this.setState({
                      cb_minBetLbl: this.convertNumber(event.minBet),
                      cb_maxBetLbl: this.convertNumber(event.maxBet),
                      cb_minBet: event.minBet,
                      cb_maxBet: event.maxBet,
                      cb_innerLimits: event.innerLimits,
                      cb_chipValues: event.chipValues,

                    });
                    this.setState({ caribbeanpayouts: event.payoutInfos })
                  }
                  break;

                case 39:
                  if (event.minBet) {
                    this.setState({
                      tp_minBetLbl: this.convertNumber(event.minBet),
                      tp_maxBetLbl: this.convertNumber(event.maxBet),
                      tp_minBet: event.minBet,
                      tp_maxBet: event.maxBet,
                      tp_innerLimits: event.innerLimits,
                      tp_chipValues: event.chipValues,
                    });
                  }
                  this.setState({
                    TP_payouts: event.payoutInfos,
                  });
                  break


                case 40:
                  if (event.minBet) {
                    this.setState({
                      trb_minBetLbl: this.convertNumber(event.minBet),
                      trb_maxBetLbl: this.convertNumber(event.maxBet),
                      trb_minBet: event.minBet,
                      trb_maxBet: event.maxBet,
                      trb_innerLimits: event.innerLimits,
                      trb_chipValues: event.chipValues ? event.chipValues : this.state.trb_chipValues,
                    });
                  }
                  break;

                default:
                  break;

              }
              if (event.payoutInfos) {
                // alert('1111')
                console.log(" Game Payout Information: ")
                console.log(event.payoutInfos)
                // this.setState({game_PayoutInfo: event.payoutInfos })
                console.log(event.payoutInfos.length)
                if (event.payoutInfos.length > 0) {
                  // alert("2")
                  this.setState({ game_PayoutInfo: event.payoutInfos })
                }
              }
              switch (event.ackCode) {
                case "game.under.maintenance":
                  console.log("gameUnderMaintanance");
                  console.log(this.state.gameId);
                  this.setState({ gameUnderMaintanance: true });
                  this.state.gameUnderMaintanance = true;

                  if (this.state.gameId == 1) {
                    if (this.funcardIsActive) {
                      console.log(event.ackMessage);
                      this.showGameMaintanance(event.ackMessage);
                    }
                  } else {
                    this.showGameMaintanance(event.ackMessage);
                  }

                  break;
                case "device.blocked":
                  console.log("deviceBlocked");
                  this.setState({ deviceBlocked: true, gameData: event });
                  this.showDeviceBlocked(this.state.gameData);
                  break;
                case "exception.occured":
                  // case "Exception Occured":
                  document.getElementById("errorPopup").style.display = "block";
                  document.getElementById("errorPopup").innerHTML =
                    "Technical error occured. Please re-open the Game";
                  // document.getElementById('errorPopup').innerHTML = 'event.ackMessage';
                  this.gameCloseHandler();
                  break;

                case "player.session.closed":
                  console.log("Player Session Closed");
                  this.showDeviceBlocked(event.ackMessage);
                  break;
                case "game.timer":
                  console.log(this.state.gameId);
                  var gamesec = event.ackMessage;
                  var addvalue = Number(gamesec) + 500;
                  var divide = addvalue / 1000;
                  var secondsIn = Math.round(divide);

                  switch (this.state.gameId) {
                    case 2:
                      //console.log(secondsIn);
                      this.setState({ FunTargetTimeSend: secondsIn });
                      try {
                        this.FunTargetTimer.funTargetTimerGetTime();
                      } catch (e) {
                        console.log(e);
                      }
                      break;
                    case 3:
                      this.setState({ AmericanTimeSend: secondsIn });
                      try {
                        if (this.AmericanRouletteTimer) {
                          this.AmericanRouletteTimer.AmericanRouletteTimerGetTime();
                        }
                      } catch (e) {
                        console.log(e);
                      }
                      break;
                    case 7:
                      if (this.showFunMatkaTimer) {
                        console.log(this.showFunMatkaTimer);

                        this.setState({ FunMatkaTimeSend: secondsIn });
                        try {
                          if (this.FunMatkaTimer) {
                            this.FunMatkaTimer.FunMatkaTimerGetTime();
                          }
                        } catch (e) {
                          console.log(e);
                        }
                      } else {
                      }
                      break;
                    case 12:
                      this.setState({ EuropeanTimeSend: secondsIn });
                      try {
                        this.EuropeanRouletteTimer.EuropeanRouletteTimerGetTime();
                      } catch (e) {
                        console.log(e);
                      }
                      break;
                    case 19:
                      this.setState({ soratTimeSend: secondsIn });
                      try {
                        if (this.FunSoratTimer) {
                          this.FunSoratTimer.funSoratTimerGetTime();
                        }
                      } catch (e) {
                        console.log(e);
                      }
                      break;
                    case 20:
                      if (this.showDragonTiger) {
                        this.setState({ dtt_TimeSend: secondsIn });
                        try {
                          if (this.DragonTigerTimer) {
                            console.log("dtt_TimeSend : " + this.state.dtt_TimeSend);
                            this.DragonTigerTimer.dt_GetTime();
                          }
                        } catch (e) {
                          console.log(e);
                        }
                      }
                      break;
                    case 23:
                      console.log(this.showMoneyWheel);
                      if (this.showMoneyWheel) {
                        this.setState({ mwTimeSend: secondsIn });
                        try {
                          if (this.MoneyWheelTimer && this.MoneyWheelTimer.moneyWheelTimerGetTime != undefined) {
                            console.log(this.MoneyWheelTimer.moneyWheelTimerGetTime + "  *****   " + this.state.mwTimeSend);
                            this.MoneyWheelTimer.moneyWheelTimerGetTime();
                          }
                        } catch (e) {
                          console.log(e);
                        }
                      }
                      break;
                    case 24:
                      this.setState({ AndarBaharTimeSend: secondsIn });
                      try {
                        if (this.AndarBaharTimer) {
                          this.AndarBaharTimer.andarBaharTimer_GetTime();
                        }
                      } catch (e) {
                        console.log(e);
                      }
                      break;

                    default:
                      this.getGameState();
                      this.setState({ infoText: event.ackMessage });
                      break;
                  }
                  //console.log("Ack Message: " + event.ackMessage);
                  break;
                case "player.is.banned":
                  this.setState({ isPlayerBanned: true, gameData: event });
                  console.log(" ********************   Player Banned   ********************************");
                  console.log(this.state.isPlayerBanned);
                  console.log(this.state.gameData);
                  console.log(this.state.gameId);
                  console.log(" ********************   Player Banned   ********************************");
                  this.playerBanned(this.state.gameData);
                  break;
                case "game.bet.rejected.takeAmount":
                case "game.bet.accepted":
                case "game.bet.invalid":
                case "game.bet.rejected":
                  this.setState({ infoText: event.ackMessage });
                  this.setState({ error_code: event.ackCode });
                  let updateMsgTxt;

                  if (event.ackCode == "game.bet.accepted") {
                    let BetAmt = event.ackMessage.split("is")[1];
                    BetAmt = BetAmt.replace(",", "");
                    // let newAmt = this.convertNumber(BetAmt);
                    updateMsgTxt = this.props.language.BetAccepted + BetAmt;
                    // this.updatePlayerBalance();
                  }
                  if (event.ackMessage == "Bet Rejected!! Time Over") {
                    updateMsgTxt = this.props.language.BetRejTimeOver;
                  } else if (
                    event.ackMessage == "Bets Rejected !! Due to {0}"
                  ) {
                    updateMsgTxt = this.props.language.InvalidBet;
                  } else if (event.ackMessage == "Bet Rejected") {
                    updateMsgTxt = this.props.language.BetRej;
                  } else if (
                    event.ackMessage ==
                    "Bet rejected!!.You have already placed BET for this Round."
                  ) {
                    updateMsgTxt = this.props.language.AlreadyBetPlaced;
                  }

                  switch (event.gameId) {
                    case 1:
                      if (this.FunCard) {
                        this.setState({ FunCardInfoText: updateMsgTxt });
                        this.FunCard.updateMessage();
                      }
                      break;
                    case 2:
                      if (this.FunTargetTimer) {
                        this.setState({ FunTargetTimerInfoText: updateMsgTxt });
                        this.FunTargetTimer.updateMessage();
                      }
                      break;
                    case 3:
                      if (this.AmericanRouletteTimer) {
                        this.setState({ AmericanRouletteTimerInfoText: updateMsgTxt });
                        this.AmericanRouletteTimer.updateMessage();
                      }
                      break;
                    case 5:
                      if (this.FunTarget) {
                        this.setState({ FunTargetInfoText: updateMsgTxt });
                        this.FunTarget.updateMessage();
                      }
                      break;
                    case 6:
                      if (this.AmericanRoulette) {
                        this.setState({
                          AmericanRouletteInfoText: updateMsgTxt,
                        });
                        this.AmericanRoulette.updateMessage();
                      }
                      break;
                    case 7:
                      if (this.FunMatkaTimer) {
                        this.setState({ FunMatkaTimerInfoText: updateMsgTxt });
                        this.FunMatkaTimer.updateMessage();
                      }
                      break;
                    case 12:
                      if (this.EuropeanRouletteTimer) {
                        this.setState({
                          EuropeanRouletteTimerInfoText: updateMsgTxt,
                        });
                        this.EuropeanRouletteTimer.updateMessage();
                      }
                      break;
                    case 13:
                      if (this.EuropeanRoulette) {
                        this.setState({
                          EuropeanRouletteInfoText: updateMsgTxt,
                        });
                        this.EuropeanRoulette.updateMessage();
                      }
                      break;
                    case 14:
                      if (this.FunMatka) {
                        this.setState({ FunMatkaInfoText: updateMsgTxt });
                        this.FunMatka.updateMessage();
                      }
                      break;
                    case 19:
                      if (this.FunSoratTimer) {
                        this.setState({ FunSoratTimerInfoText: updateMsgTxt });
                        this.FunSoratTimer.updateMessage();
                      }
                      break;
                    case 20:
                      if (this.DragonTigerTimer && this.showDragonTiger) {
                        this.setState({ DragonTigerInfoText: updateMsgTxt });
                        this.DragonTigerTimer.updateMessage(event.ackCode);
                      }
                      break;
                    case 23:
                      if (this.MoneyWheelTimer && this.showMoneyWheel) {
                        this.setState({ MoneyWheelInfoText: updateMsgTxt });
                        this.MoneyWheelTimer.updateMessage(event.ackCode);
                      }
                      break;
                    case 24:
                      if (this.AndarBaharTimer) {
                        this.setState({ AndarBaharTimerInfoText: updateMsgTxt });
                        this.AndarBaharTimer.updateMessage();
                      }
                      break;
                    case 25:
                      if (this.Bingo) {
                        this.setState({ BingoInfoText: updateMsgTxt });
                        this.Bingo.updateMessage(updateMsgTxt);
                      }
                      break;

                    case 26:
                      if (
                        this.KenoGame &&
                        this.KenoGame.current &&
                        this.showKeno
                      ) {
                        this.setState({ KenoInfoText: updateMsgTxt });
                        this.KenoGame.current.updateMessage();
                      }
                      break;
                    case 28:
                      if (this.RussianPoker) {
                        this.setState({ RussianPokerInfoText: updateMsgTxt });
                        this.RussianPoker.updateMessage();
                      }
                      break;

                    case 30:
                      if (this.JacksorBetter) {
                        this.setState({ JacksorBetterInfoText: updateMsgTxt });
                        this.JacksorBetter.updateMessage();
                      }
                      break;
                    // case 33:
                    //   if (this.CarebbeanPoker) {
                    //     this.setState({ CarebbeanPokerInfoText: updateMsgTxt });
                    //     this.CarebbeanPoker.updateMessage();
                    //   }
                    //   break;
                  }
                  break;

                case 33:
                  if (this.CarebbeanPoker) {
                    this.setState({ CarebbeanPokerInfoText: updateMsgTxt });
                    this.CarebbeanPoker.updateMessage();
                  }
                  break;
                case 35:
                  if (this.DeucesWild) {
                    this.setState({ DeucesWildInfoText: updateMsgTxt });
                    this.DeucesWild.updateMessage();
                  }
                  break;
                case 36:
                  if (this.JokerPoker) {
                    this.setState({ JokerPokerInfoText: updateMsgTxt });
                    this.JokerPoker.updateMessage();
                  }
                  break;

                case 39:
                  if (this.TropicalPoker) {
                    this.setState({ TropicalPokerInfoText: updateMsgTxt });
                    this.TropicalPoker.updateMessage();
                  }
                  break;

                case 40:
                  if (this.TensorBetter) {
                    this.setState({ TensorBetterInfoText: updateMsgTxt });
                    this.TensorBetter.updateMessage();
                  }
                  break;



                default:
                  break;
              }
              break;
            case "LOGIN_SESSION":
              sessionStorage.setItem("playerId", event["playerId"]);
              this.state.playerId = event["playerId"];
              sessionStorage.setItem("sessionId", event["sessionId"]);
              this.onLoginSuccess(
                sessionStorage.getItem("playerId"),
                sessionStorage.getItem("sessionId")
              );
              this.setState({ activeProfile: true });
              break;
            // case "SESSION_EXIST":
            //   alert("------2")
            //   console.log("Session Exitst");
            //   localStorage.removeItem("sessionId");
            //   localStorage.removeItem("playerId");
            //   if (event["playerId"] == 0) {
            //     this.setState({
            //       forceLog: true,
            //       forceLoignText: event["message"],
            //       alertText: "",
            //     });
            //   }
            //   break;
            case "LOGIN_FAILED":
              // let type = "loginError";
              // let res = { error: event['reason'] };
              this.setState({ popupMessage: event["reason"] });
              this.setState({ closePopup: true });
              this.setState({ error_code: "LOGIN_FAILED" });

              console.log("close this popup");
              //this.componentStateHandler("loginError", { error: event["reason"] });
              break;
            case "LOBBY_INFO":
              // console.log("LOBBY_INFO");
              //console.log(event);
              this.setState({ lobbyInfo: event });
              if (this.homeRef && this.homeRef.current != null) {
                this.homeRef.current.updateGames();
              }
              break;

            case "LOGOUT_INFO":
              // alert("Re-opening...");

              setTimeout(() => {
                // if (this.state.popupMessage === "Successfully Logged Out" ||
                //   this.state.popupMessage === "Your game session has ended or is active in another window. Restart the game to regain access.") {
                // alert("games are closed")
                this.closeAllGames();
                this.setState({
                  popupMessage: "Your game session has ended or is active in another window. Restart the game to regain access.",
                  closePopup: true,
                  websocketLoader: false

                });

                // }
              }, 1000)

              // setTimeout(() => {
              //   this.setState({ 
              //     popupMessage: "Session Expired Reload The Game",
              //     closePopup: true

              //    });

              //    window.open(event.url)
              //    window.location.href = "https://riv7.czargaming.com/home"; // Opens in the same tab
              // }, 500);


              // this.connection();
              // let Errtype = "loginError";
              // let resMsg = { error: event["message"] };
              // this.componentStateHandler(Errtype, resMsg);
              // localStorage.removeItem("sessionId");
              // localStorage.removeItem("username");
              // localStorage.removeItem("playerId");
              break;

            case "EXIT_GAME":
            case "SESSION_EXIST":
              // this.gameOpenHandler()
              // this.setState({ closePopup: true ,error_code: "Session Expired"});
              setTimeout(() => {
                // window.location.href = "https://riv7.czargaming.com/home"; // Opens in the same tab
                // this.props.history.goBack();  
                // this.props.history.goBack();  
                // this.setState({ closePopup: false, error_code: ""});

              }, 2000)

          }

          if (event["commandName"] == "TARGET_RESULT" && event.gameId == 5) {
            //console.log(event);
            this.setState({ targetResult: event });
            let id = sessionStorage.getItem("sessionId");
            let playerId = sessionStorage.getItem("playerId");
            if (event && this.FunTarget) {
              this.FunTarget.funTargetGetwheelresp();
              // this.onLoginSuccess(playerId, id);
            }
          }
          if (
            event["commandName"] == "FUN_MATKA_RESULT" &&
            event.gameId == 14
          ) {
            //console.log(event);
            this.setState({ funmatkaResult: event });
            let id = sessionStorage.getItem("sessionId");
            let playerId = sessionStorage.getItem("playerId");
            //console.log(event);
            //console.log(this.FunMatka);
            //console.log(this.FunMatka.current.funMatkaGetwheelresp);
            if (event && this.FunMatka) {
              this.FunMatka.funMatkaGetwheelresp();
              // this.onLoginSuccess(playerId, id);
            }
          }
          if (event["commandName"] == "ROULETTE_RESULT" && event.gameId == 6) {
            console.log("American Roulette Non Timer Game: ");
            console.log(event);

            this.setState({ rouletteResult: event });
            let id = sessionStorage.getItem("sessionId");
            let playerId = sessionStorage.getItem("playerId");
            if (event && this.AmericanRoulette) {
              this.AmericanRoulette.americanRoulleteGetwheelresp();
            }
          }
          if (event["commandName"] == "ROULETTE_RESULT" && event.gameId == 13) {
            //console.log(event);
            this.setState({ EuropeanRouletteResult: event });
            let id = sessionStorage.getItem("sessionId");
            let playerId = sessionStorage.getItem("playerId");
            if (event && this.EuropeanRoulette) {
              this.EuropeanRoulette.europeanRoulleteGetwheelresp();
            }
          }
          if (
            event["commandName"] == "FUN_TARGET_RESULT" &&
            event.gameId == 2
          ) {
            this.setState({ funTargetTimerResult: event });
            let id = sessionStorage.getItem("sessionId");
            let playerId = sessionStorage.getItem("playerId");
            if (event && this.FunTargetTimer) {
              this.FunTargetTimer.funTargetTimerGetwheelresp();
            }
          }
          if (event["commandName"] == "FUN_ROULETTE_RESULT" && event.gameId == 3) {
            console.log("FUN_ROULETTE_RESULT: 568");
            console.log("Game Number: " + Number(this.state.gameId));
            this.setState({ AmericanRouletteTimerResult: event });
            let id = sessionStorage.getItem("sessionId");
            let playerId = sessionStorage.getItem("playerId");
            if (event && this.AmericanRouletteTimer) {
              this.AmericanRouletteTimer.americanRoulleteTimerGetwheelresp();
              // this.onLoginSuccess(playerId, id);
            }
          }
          if (event["commandName"] == "FUN_MATKA_RESULT" && event.gameId == 7) {
            //console.log(event);
            this.setState({ FunMatkaTimerResult: event });
            let id = sessionStorage.getItem("sessionId");
            let playerId = sessionStorage.getItem("playerId");
            if (event && this.FunMatkaTimer) {
              this.FunMatkaTimer.funMatkaTimerGetwheelresp();
              // this.onLoginSuccess(playerId, id);
            }
          }
          if (event["commandName"] == "SORAT_RESULT" && event.gameId == 19) {
            this.setState({ funSoratResult: event });
            let id = sessionStorage.getItem("sessionId");
            let playerId = sessionStorage.getItem("playerId");
            if (event && this.FunSoratTimer) {
              this.FunSoratTimer.funSoratTimerGetwheelresp();
              // this.onLoginSuccess(playerId, id);
            }
          }
          if (
            event["commandName"] == "FUN_ROULETTE_RESULT" &&
            event.gameId == 12
          ) {
            this.setState({ EuropeanRouletteTimerResult: event });
            let id = sessionStorage.getItem("sessionId");
            let playerId = sessionStorage.getItem("playerId");
            if (event && this.EuropeanRouletteTimer) {
              this.EuropeanRouletteTimer.europeanRoulleteTimerGetwheelresp();
              // this.onLoginSuccess(playerId, id);
            }
          }
          if (event["commandName"] == "FUN_CARD_RESULT" && event.gameId == 1) {
            this.setState({ funCardResult: event });
            let id = sessionStorage.getItem("sessionId");
            let playerId = sessionStorage.getItem("playerId");
            if (event && this.FunCard) {
              this.FunCard.funCardGetResp();
              console.log("Funcard Result Received");
              // this.onLoginSuccess(playerId, id);
            }
          }
          if (
            event["commandName"] == "DRAGON_TIGER_RESULT" &&
            event.gameId == 20 &&
            this.showDragonTiger
          ) {
            console.log("dragonTigerResult: ");
            // console.log(event);
            console.log(this.DragonTigerTimer);
            console.log(DragonTigerTimer);
            console.log(this.DragonTigerTimer.current);
            this.setState({ dragonTigerResult: event });
            if (event && this.DragonTigerTimer && this.showDragonTiger) {
              console.log("--------------------------  Dragon Tiger Result  ------------------------------");
              this.DragonTigerTimer.dragonTigerResults(event);
            }
          }
          if (
            event["commandName"] == "MONEY_WHEEL_RESULT" &&
            event.gameId == 23 &&
            this.showMoneyWheel
          ) {
            console.log("MoneyWheelTimer Result: ");
            console.log(event);
            this.setState({ moneyWheelResult: event });
            if (event && this.MoneyWheelTimer && this.showMoneyWheel) {
              this.MoneyWheelTimer.moneyWheelGetwheelresp();
            }
          }
          if (
            event["commandName"] == "RUSSIAN_POKER_RESULT" &&
            event.gameId == 28 &&
            this.showRussianPoker
          ) {
            // console.log("Russian Poker Result: ");
            // console.log(event);
            // this.setState({ russianPokerResult: event });
            // if (event && this.RussianPoker && this.showRussianPoker) {
            //   this.RussianPoker.russianPokerresp(event); // RP add in code.
            // }

            switch (event.action) {
              case "PARAM_BET":
                if (event && this.showRussianPoker) {
                  this.RussianPoker.rpAnteResultRes(event);
                  this.setState({ RpInfoText: event.message });
                }
                break;
              case "BET":
                if (event && this.showRussianPoker) {
                  // this.RussianPoker.rpResultRes(event);
                  this.RussianPoker.russianPokerresp(event);

                  this.setState({ RpInfoText: event.message });
                }

                break;
              case "FOLD":
                if (event && this.showRussianPoker) {
                  this.RussianPoker.FoldHandresp(event);
                  this.setState({ RpInfoText: event.message });

                }

                break;
              case "CONFIRM_FOLD":
                if (event && this.showRussianPoker) {
                  this.RussianPoker.FoldHandresp(event);
                  this.setState({ RpInfoText: event.message });

                }

                break;
              case "REPLACE_CARDS":
                if (event && this.showRussianPoker) {
                  this.RussianPoker.rpReplaceCards(event);
                  this.setState({ RpInfoText: event.message });
                }

                break;
              case "BUY_PLAYER_CARD":
                if (event && this.showRussianPoker) {
                  this.RussianPoker.buyplayercardresp(event);
                  this.setState({ RpInfoText: event.message });

                }
                break;
              case "BUY_DEALER_CARD":
                if (event && this.showRussianPoker) {
                  this.RussianPoker.buydealercardResponse(event);
                  this.setState({ RpInfoText: event.message });
                }
                break;
              case "CONFIRM_DEALER_CARD":
                if (event && this.showRussianPoker) {
                  this.RussianPoker.buyconfirmdealercardresp(event);
                  this.setState({ RpInfoText: event.message });
                }
                break;
              case "INSURANCE":
                if (event && this.showRussianPoker) {
                  this.RussianPoker.claiminsurenceresp(event);
                  this.setState({ RpInfoText: event.message });
                }

              default:
                break;
            }
            // }
          }
          if (
            event["commandName"] == "CARIBBEAN_POKER_RESULT" &&
            event.gameId == 33 &&
            this.showCarebbeanPoker
          ) {
            // console.log("Russian Poker Result: ");
            // console.log(event);
            // this.setState({ russianPokerResult: event });
            // if (event && this.RussianPoker && this.showRussianPoker) {
            //   this.RussianPoker.russianPokerresp(event); // RP add in code.
            // }

            switch (event.action) {
              case "PARAM_BET":
                if (event && this.showCarebbeanPoker) {
                  this.CarebbeanPoker.Carebbeananterespnce(event);
                  this.setState({ CpInfoText: event.message });
                }
                break;
              case "BET":
                if (event && this.showCarebbeanPoker) {

                  this.CarebbeanPoker.CarebbeanBetreapnce(event);

                  this.setState({ CpInfoText: event.message });
                }

                break;
              case "FOLD":

                if (event && this.showCarebbeanPoker) {



                  this.CarebbeanPoker.FoldHandrespCaribbean(event);
                  this.setState({ CpInfoText: event.message });

                }

                break;
              case "CONFIRM_FOLD":
                if (event && this.showCarebbeanPoker) {
                  this.CarebbeanPoker.FoldHandrespCaribbean(event);
                  this.setState({ RpInfoText: event.message });

                }
                break;
              default:
                break;
            }
          }

          if (
            event["commandName"] == "TROPICAL_STUD_POKER_RESULT" &&
            event.gameId == 39 &&
            this.showTropicalPoker
          ) {
            // console.log("Russian Poker Result: ");
            // console.log(event);
            // this.setState({ russianPokerResult: event });
            // if (event && this.RussianPoker && this.showRussianPoker) {
            //   this.RussianPoker.russianPokerresp(event); // RP add in code.
            // }

            switch (event.action) {
              case "PARAM_BET":
                if (event && this.showTropicalPoker) {
                  this.TropicalPoker.TPAnteResultRes(event);
                  this.setState({ RpInfoText: event.message });
                }
                break;
              case "BET":
                if (event && this.showTropicalPoker) {
                  this.TropicalPoker.russianPokerrespTP(event);
                  this.setState({ RpInfoText: event.message });
                }

                break;
              case "FOLD":
                if (event && this.showTropicalPoker) {
                  this.TropicalPoker.FoldHandresp(event);
                  this.setState({ RpInfoText: event.message });

                }

                break;
              case "CONFIRM_FOLD":
                if (event && this.showTropicalPoker) {
                  this.TropicalPoker.FoldHandresp(event);
                  this.setState({ RpInfoText: event.message });

                }

                break;
              case "REPLACE_CARDS":
                if (event && this.showTropicalPoker) {
                  this.TropicalPoker.TPReplaceCards(event, 'REPLACE_CARDS');
                  this.setState({ RpInfoText: event.message });
                }

                break;
              case "REDRAW_CARDS":
                if (event && this.showTropicalPoker) {
                  this.TropicalPoker.TPReplaceCards(event, 'REDRAW_CARDS');
                  this.setState({ RpInfoText: event.message });
                }

                break;

              // case "BUY_PLAYER_CARD":
              //   if (event && this.showTropicalPoker) {
              //     this.TropicalPoker.buyplayercardresp(event);
              //     this.setState({ RpInfoText: event.message });

              //   }
              //   break;
              // case "BUY_DEALER_CARD":
              //   if (event && this.showTropicalPoker) {
              //     this.TropicalPoker.buydealercardResponse(event);
              //     this.setState({ RpInfoText: event.message });
              //   }
              //   break;

              // case "CONFIRM_DEALER_CARD":
              //   if (event && this.showTropicalPoker) {
              //     this.TropicalPoker.buyconfirmdealercardresp(event);
              //     this.setState({ RpInfoText: event.message });
              //   }
              //   break;

              // case "INSURANCE":
              //   if (event && this.showTropicalPoker) {
              //     this.TropicalPoker.claiminsurenceresp(event);
              //     this.setState({ RpInfoText: event.message });
              //   }

              // default:
              //   break;
            }
            // }
          }



          if (event["commandName"] === "JACKS_OR_BETTER_RESULT" && event.gameId == 30 && this.showJacksorBetter) {
            switch (event.action) {
              case "PARAM_BET":
                if (event && this.showJacksorBetter) {
                  this.JacksorBetter.dealBetResultRes(event);
                  this.setState({ JacksorBetterInfoText: event.message });
                }
                break;
              case "KEEP_CARDS":
                if (event && this.showJacksorBetter) {
                  // this.RussianPoker.rpResultRes(event);
                  this.JacksorBetter.drawBetResult(event);

                  this.setState({ JacksorBetterInfoText: event.message });
                }

                break;
              case "STOP_AUTO_PLAY":
                if (event && this.showJacksorBetter) {
                  // this.RussianPoker.rpResultRes(event);
                  this.JacksorBetter.stopAutoPlayResult(event);

                  this.setState({ JacksorBetterInfoText: event.message });
                }

              default:
                break;
            }


          }
          if (event["commandName"] === "DEUCES_WILD_RESULT" && event.gameId == 35 && this.showDeucesWild) {
            switch (event.action) {
              case "PARAM_BET":
                if (event && this.showDeucesWild) {
                  this.DeucesWild.dealBetResultRes(event);
                  this.setState({ DeucesWildInfoText: event.message });
                }
                break;
              case "KEEP_CARDS":
                if (event && this.showDeucesWild) {
                  // this.RussianPoker.rpResultRes(event);
                  this.DeucesWild.drawBetResult(event);

                  this.setState({ DeucesWildInfoText: event.message });
                }

                break;
              case "STOP_AUTO_PLAY":
                if (event && this.showDeucesWild) {
                  // this.RussianPoker.rpResultRes(event);
                  this.DeucesWild.stopAutoPlayResult(event);
                  this.setState({ DeucesWildInfoText: event.message });
                }

              default:
                break;
            }


          }
          if (event["commandName"] === "JOKER_POKER_RESULT" && event.gameId == 36 && this.showJokerPoker) {
            switch (event.action) {
              case "PARAM_BET":
                if (event && this.showJokerPoker) {
                  this.JokerPoker.dealBetResultRes(event);
                  this.setState({ JokerPokerInfoText: event.message });
                }
                break;
              case "KEEP_CARDS":
                if (event && this.showJokerPoker) {
                  // this.RussianPoker.rpResultRes(event);
                  this.JokerPoker.drawBetResult(event);

                  this.setState({ JokerPokerInfoText: event.message });
                }

                break;
              case "STOP_AUTO_PLAY":
                if (event && this.showJokerPoker) {
                  // this.RussianPoker.rpResultRes(event);
                  this.JokerPoker.stopAutoPlayResult(event);
                  this.setState({ JokerPokerInfoText: event.message });
                }

              default:
                break;
            }


          }



          if (event["commandName"] === "TENS_OR_BETTER_RESULT" && event.gameId == 40 && this.showTensorBetter) {
            switch (event.action) {
              case "PARAM_BET":
                if (event && this.showTensorBetter) {
                  this.TensorBetter.dealBetResultRes(event);
                  this.setState({ TensorBetterInfoText: event.message });
                }
                break;
              case "KEEP_CARDS":
                if (event && this.showTensorBetter) {
                  // this.RussianPoker.rpResultRes(event);
                  this.TensorBetter.drawBetResult(event);

                  this.setState({ TensorBetterInfoText: event.message });
                }

                break;
              case "STOP_AUTO_PLAY":
                if (event && this.showTensorBetter) {
                  // this.RussianPoker.rpResultRes(event);
                  this.TensorBetter.stopAutoPlayResult(event);

                  this.setState({ TensorBetterInfoText: event.message });
                }

              default:
                break;
            }


          }
          if (
            event["commandName"] == "TRIPLE_CHANCE_RESULT" &&
            event.gameId == 22
          ) {
            console.log("TripleChance Result: " + event);
            this.setState({ tripleChanceResult: event });
            let id = sessionStorage.getItem("sessionId");
            let playerId = sessionStorage.getItem("playerId");
            if (event) {
              this.TripleChance.current.tripleChanceResult();
              // this.onLoginSuccess(playerId, id);
            }
          }
          if (
            event["commandName"] == "ANDAR_BAHAR_RESULT" && event.gameId == 24) {
            //console.log(event);
            this.setState({ andarBaharTimerResult: event });
            let id = sessionStorage.getItem("sessionId");
            let playerId = sessionStorage.getItem("playerId");
            if (this.AndarBaharTimer && this.showAndarBaharTimer) {
              this.AndarBaharTimer.andarBaharTimerGetResp();
            }
          }
          console.log(event["commandName"] + "  ***   " + event.gameId + " ***  " + this.showBingo)
          if (event["commandName"] == "BINGO_RESULT" && event.gameId == 25 && this.showBingo) {
            console.log("Bingo Result: ");
            console.log(event);
            this.setState({ BingoResult: event, bng_takeAmount: event.winAmount });
            if (this.Bingo && this.showBingo) {
              this.Bingo.funBingoResultRes(event);
            }

          }
          if (event["commandName"] == "KENO_RESULT" && event.gameId == 26) {
            console.log("kenoResult: ");
            this.setState({ kenoResult: event, resultNo: event.resultNo });

            if (event) {
              this.KenoGame.kenoResults(event);
            }
          }

          if (event.type == "close") {
            this.setState({ lobbyInfo: event });
            let sid = sessionStorage.getItem("sessionId");
            let playerId = sessionStorage.getItem("playerId");

            const body = {
              CN: "EXIT_GAME",
              object: {
                playerId: Number(playerId),
                gameId: this.state.gameId,
              },
              sessionId: sid,
            };
            this.connectionTo.sendof(body);
            this.body = {};

            this.showLobby = true;
            this.closeAllGames();
            this.setState({
              gameStateUpdate: false,
              deviceBlocked: false,
            });
            console.log("this is routing to home ----------------------------");
            this.props.history.push("/home");
            this.componentStateHandler(
              "logOutSuccess",
              this.state.gameData.ackCode
            );
          }
        }
      } else {
        // alert("Invalid Game Invocation.");
        // this.setState({ popupMessage: "Invalid Game Invocation." });
        // this.setState({ closePopup: true });
        // this.setState({ error_code: "game.under.maintenance" });
      }
    }
  };

  showGameMaintanance(msgTxt) {
    console.log(msgTxt);
    this.showLobby = true;
    this.setState({
      popupMessage: this.props.language.GameMainenanceMsg,
      closePopup: true,
      errTitle: this.props.language.GameMainenance,
      popCloseCode: "Game Under Mainenance",
      error_code: "game.under.maintenance",
      popupBtnName: this.props.language.Ok,
      gameUnderMaintanance: false,
      gameStateUpdate: false,
    });
  }

  showDeviceBlocked() {
    this.showLobby = true;
    this.setState({ deviceBlocked: false, gameStateUpdate: false });

    console.log(this.state.gameData.ackMessage);
    this.setState({ popupMessage: this.state.gameData.ackMessage });
    this.setState({ closePopup: true });
    this.setState({ error_code: "device.is.blocked" });
    // this.componentStateHandler("logOutSuccess", this.state.gameData.ackCode);
  }

  updatePlayerBalance() {
    const body = {
      CN: "GET_PLAYER_INFO",
      object: {
        gameId: Number(this.state.gameId),
        playerId: Number(this.state.playerId),
      },
      sessionId: sessionStorage.getItem("sessionId"),
    };

    this.connectionTo.sendof(body);
    this.body = {};
  }

  takeAmountResult() {
    let body = {
      sessionId: sessionStorage.getItem("sessionId"),
      CN: "TAKE_AMOUNT",
      object: {
        gameId: this.state.gameId,
        playerId: Number(this.state.playerId),
      },
    };
    this.connectionTo.sendof(body);
    // this.body = {};
    body = {};
  }
  forcedLoginCheck = (text) => {
    console.log(text);
    let body = {
      sessionId: "",
      CN: "FORCE_LOGIN",
      object: {
        //system: "Windows 10",
        system: this.systemOS,
        physicalAdd: "28-F1-0E-3B-7F-A0",
        loginName: this.state.name,
        password: this.state.password,
      },
    };
    this.connectionTo.sendof(body);
    // this.body = {};
    body = {};
    this.setState({ forceLog: false });
  };
  gameLobbyOpen(game) {

    console.log("Game ID: " + game);
    this.showLobby = false;
    switch (game) {
      case 1:
        // alert("..........."+this.funcardIsActive)
        // if (this.funcardIsActive) {
        this.showFunCard = true;
        // }
        break;
      case 2:
        this.showFunTargetTimer = true;
        break;
      case 3:
        this.showAmericanRouletteTimer = true;
        break;
      case 5:
        this.showFunTarget = true;
        break;
      case 6:
        this.showAmericanRoulette = true;
        break;
      case 7:
        this.showFunMatkaTimer = true;
        break;
      case 12:
        this.showEuropeanRouletteTimer = true;
        break;
      case 13:
        this.showEuropeanRoulette = true;
        break;
      case 14:

        this.showFunMatka = true;
        break;
      case 19:
        this.showFunSoratTimer = true;
        break;
      case 20:
        this.showDragonTiger = true;
        break;
      case 23:
        this.showMoneyWheel = true;
        break;
      case 24:
        this.showAndarBaharTimer = true;
        break;
      case 25:
        this.showBingo = true;
        break;
      case 26:
        this.showKeno = true;
        break;
      case 28:
        this.showRussianPoker = true;
        break;
      case 33:
        this.showCarebbeanPoker = true;
        break;

      case 30:
        this.showJacksorBetter = true;
        break;
      // case 33:
      //   this.showCarebbeanPoker = true;
      //   break;

      case 35:
        this.showDeucesWild = true;
        break;
      case 36:
        this.showJokerPoker = true;
        break;
      case 39:
        this.showTropicalPoker = true;
        break;

      case 40:
        this.showTensorBetter = true;
        break;




      default:
        break;
    }

    if (this.state.game_PayoutInfo.length > 0) {
      console.log(this.PayOutInfo)
      this.PayOutInfo.setPayoutData(this.state.game_PayoutInfo, game)
    }

  }

  getGameLobby = () => {
    let id = sessionStorage.getItem("sessionId");
    let playerId = sessionStorage.getItem("playerId");

    let body = {
      CN: "GET_LOBBY_INFO",
      object: {
        playerId: Number(playerId),
      },
      sessionId: id,
    };
    this.connectionTo.sendof(body);
    // this.body = {};
    body = {};
  };

  onLoginSuccess = (playerid, sessionid) => {
    let body = {
      CN: "GET_PLAYER_INFO",
      object: {
        gameId: Number(this.state.gameId),
        playerId: Number(playerid),
      },
      sessionId: sessionid,
    };

    this.connectionTo.sendof(body);
    this.setState({ isPlayerLogin: true });
    body = {};
    // this.body = {};

    let body2 = {
      CN: "GET_LOBBY_INFO",
      object: {
        playerId: Number(playerid),
      },
      sessionId: sessionid,
    };

    this.connectionTo.sendof(body2);
    body2 = {};
    // this.body2 = {};
  };

  getTimer(playerid, sessionid) {
    let body = {
      sessionId: sessionid,
      CN: "GET_GAME_TIMER",
      object: {
        gameId: Number(this.state.gameId),
        playerId: this.state.playerId,
      },
    };
    this.connectionTo.sendof(body);
    console.log(body);
    body = {};
    // this.body = {};
  }

  componentStateHandler(action, value) {

    if (action != "loaded") {
      //this.loginSound.play();
    }
    //console.log(action);
    console.log("State handler: " + action + "  --------  " + value);
    switch (action) {
      case "loaded":
        // document.getElementById("infoIcon").style.display = "none";
        this.setState({ showLoading: false, showLogin: false });
        break;
      case "showLog":
        this.setState({ showLogin: true });
        break;
      case "hideLog":
        this.setState({ showLogin: false });
        break;
      case "loginFormSubmission":
        // document.getElementById("infoIcon").style.display = "none";
        this.setState({
          showLoading: false,
          showLogin: true,
          logBtnLoad: true,
          alertText: "Logging In Please Wait...",
          user: "",
          playerId: "",
        });
        break;
      case "loginError":
        this.setState({
          showLoading: false,
          showLogin: true,
          opacity: 1,
          logBtnLoad: false,
          alertText: value.error,
          user: "",
          playerId: "",
        });
        break;
      case "SessionExitst":
        this.setState({
          showLoading: false,
          showLogin: true,
          opacity: 1,
          logBtnLoad: false,
          alertText: value.error,
          user: "",
          playerId: "",
        });
        break;
      case "SessionTimeOut":
        console.log(value.error);
        console.log(this.state.gameId);

        switch (this.state.gameId) {
          case 20:
            if (this.state.gameId == 20 && this.DragonTigerTimer) {
              // this.DragonTigerTimer.exitGame();
            }
            //this.showDragonTiger = false;
            break;
          case 23:
            if (this.MoneyWheelTimer.current != null) {
              this.MoneyWheelTimer.gameSessionClosed();
            }
            this.showMoneyWheel = false;
            break;
          case 24:
            if (this.AndarBaharTimer.current != null) {
              this.AndarBaharTimer.gameSessionClosed();
            }
            this.showAndarBaharTimer = false;
            break;
          case 25:
            if (this.Bingo.current != null) {
              this.Bingo.gameSessionClosed();
            }
            this.showBingo = false;
            break;
          case 26:
            this.KenoGame.gameSessionClosed();
            this.showKeno = false;
            break;
          default:
            break;
        }
        this.setState({
          opacity: 1,
          logBtnLoad: false,
          alertText: this.props.language.Session_TimeOut,
        });
        break;
      case "loginSuccess":
        this.exitSound.play();

        this.showLobby = true;
        this.state.user = value.name;
        console.log("----> " + this.state.user);
        this.setState({
          showLogin: false,
          opacity: 1,
          logBtnLoad: false,
          alertText: "",
          user: value.name,
          playerId: value.playerId,
        });
        break;
      case "logOutSuccess":
        this.exitSound.play();
        this.getLogOutAction();

        this.showLobby = false;
        this.setState({
          showLogin: true,
          opacity: 1,
          logBtnLoad: false,
          alertText: value.error,
          user: "",
          playerId: "",
          isPlayerLogin: false,
        });
        // this.connection();
        break;
      case "loginDetails":
        this.setState({ name: value.username, password: value.password });
        //console.log(this.state.name + " " + this.state.password);
        break;

      default:
        break;
    }
  }

  getLogOutAction() {
    this.closeAllGames();

    let id = sessionStorage.getItem("sessionId");
    let playerId = sessionStorage.getItem("playerId");

    const body = {
      sessionId: id,
      CN: "LOGOUT",
      object: {
        playerId: playerId,
      },
    };
    this.connectionTo.sendof(body);
    this.body = {};
  }

  playerBanned() {
    console.log("playerBanned");
    console.log(this.state.gameData);
    // this.gameCloseHandler();
    // this.componentStateHandler("logOutSuccess", this.state.gameData.ackCode);
    this.setState({ isPlayerBanned: false });
    // alert(this.state.gameData.ackMessage);

    this.setState({ popupMessage: this.state.gameData.ackMessage });
    this.setState({ closePopup: true });
    this.setState({ error_code: "player.is.banned" });
  }

  updateSpectatorCount_fn(betAmount, handId) {
    console.log("spectatorCount Fun: betAmt:  " + betAmount + "    HandId: " + handId);
    if (betAmount == 0) {
      this.spectatorCount++;
      //console.log("spectatorCount Fun: spectatorCount:  " + this.spectatorCount);
      if (this.spectatorCount >= 3) {
        //ADD_AS_SPECTATOR
        let sid = sessionStorage.getItem("sessionId");
        let playerId = sessionStorage.getItem("playerId");
        const body = {
          CN: "ADD_AS_SPECTATOR",
          object: {
            playerId: Number(playerId),
            gameId: this.state.gameId,
            handId: handId,
            spectatorCount: this.spectatorCount,
            //system:"Windows 10",
            system: this.systemOS,
            physicalAdd: "c22555eff0043d5d",
          },
          sessionId: sid,
        };
        this.connectionTo.sendof(body);
        this.body = {};

        //console.log("Finding Spectator ");
        //console.log(body);
        this.spectatorCount = 0;
      }
    } else {
      this.spectatorCount = 0;
    }
  }
  sendBetRequests(body) {
    this.connectionTo.sendof(body);
  }

  gameCloseHandler(game) {
    window.parent.close();

    if (this.state.gameId == 1) {

      this.funcardIsActive = false;
    }

    if (this.state.gameId != 0) {
      let sid = sessionStorage.getItem("sessionId");
      let playerId = sessionStorage.getItem("playerId");
      const body = {
        CN: "EXIT_GAME",
        object: {
          playerId: Number(playerId),
          gameId: this.state.gameId,
        },
        sessionId: sid,
      };
      console.log(this.state.gameId);
      console.log(body);
      this.connectionTo.sendof(body);
      this.body = {};

      const body2 = {
        CN: "GET_LOBBY_INFO",
        object: {
          playerId: sessionStorage.getItem("playerId"),
        },
        sessionId: sessionStorage.getItem("sessionId"),
      };
      this.body2 = {};
    }

    this.closeAllGames();
    this.showLobby = true;
    this.setState({
      gameStateUpdate: false,
    });
  }

  gameOpenHandler(gameid) {
    this.exitSound.play();
    let sid = sessionStorage.getItem("sessionId");
    let playerId = sessionStorage.getItem("playerId");
    const body = {
      CN: "ENTER_GAME",
      object: {
        playerId: Number(playerId),
        gameId: gameid,
      },
      sessionId: sid,
    };
    this.connectionTo.sendof(body);
    this.body = {};
  }
  gamstateHandleMethod() {
    this.getGameState();
  }

  getGameState() {

    const body = {
      sessionId: sessionStorage.getItem("sessionId"),
      CN: "GET_GAME_STATE",
      object: {
        gameId: this.state.gameId,
        playerId: Number(this.state.playerId),
        // handId:this.handId,

      },
    };
    this.connectionTo.sendof(body);
    console.log(body);
    this.body = {};
    if (this.state.gameId == 1) {
      this.funcardIsActive = true;
    }
  }
  refreshGame() {
    // alert( sessionStorage.getItem('reloadUrl') )
    window.open(sessionStorage.getItem("reloadUrl"), "_self");
    // this.connection.close();
    // window.location.reload();
    // this.componentDidMount();
    // this.state.websocketLoader = true;
    // let url = window.location.search
    // console.log(this.saveURL)
    // console.log(window.location)
    // console.log(url)
    // document.getElementById('awayPopup').style.display = 'none';
    // this.receiveDataFromWs = false;
    // this.connection()
  }
  closeWin() {


    // this.props.history.goBack();  
    // this.props.history.goBack();  
    // this.setState({ closePopup: false, error_code: ""});

    window.close();
    document.getElementById("errorPopup").style.display = "block";
    document.getElementById("errorPopupIn").innerHTML =
      "Technical error occured. Please re-open the Game";
    this.setState({ closePopup: false, alertText: "" });
    console.log(this.state.error_code);
    if (this.state.error_code == "LOGIN_FAILED") {
      console.log("error_code");
      // this.setState({password:" "});
    }
    if (
      this.state.error_code == "player.is.banned" ||
      this.state.error_code == "device.is.blocked"
    ) {
      // this.componentStateHandler("logOutSuccess", this.state.error_code);
      let id = sessionStorage.getItem("sessionId");
      let playerId = sessionStorage.getItem("playerId");
      const body = {
        sessionId: id,
        CN: "LOGOUT",
        object: {
          playerId: playerId,
        },
      };
      this.connectionTo.sendof(body);
      this.body = {};

      this.showLobby = false;
      this.setState({
        showLogin: true,
        opacity: 1,
        logBtnLoad: false,
        user: "",
        playerId: "",
        alertText: "",
      });
    }
    if (this.state.error_code == "game.under.maintenance") {
      this.gameCloseHandler();
    }

    if (this.state.error_code == "session.closed") {
      this.gameCloseHandler();

      this.showLobby = false;
      this.setState({
        showLogin: true,
        opacity: 1,
        logBtnLoad: false,
        user: "",
        playerId: "",
        alertText: "",
      });
    }


  }

  openPopup() {
    this.setState({ closePopup: true });
    console.log("close this popup");
  }

  closeAllGames() {
    this.showFunTarget = false;
    this.showFunTargetTimer = false;
    this.showFunSoratTimer = false;
    this.showFunMatkaTimer = false;
    this.showFunMatka = false;
    this.showFunCard = false;
    this.showAmericanRouletteTimer = false;
    this.showEuropeanRouletteTimer = false;
    this.showAmericanRoulette = false;
    this.showEuropeanRoulette = false;
    this.showMoneyWheel = false;
    this.showKeno = false;
    this.showDragonTiger = false;
    this.showRussianPoker = false;

    this.showTropicalPoker = false;

    this.showJacksorBetter = false;
    this.showDeucesWild = false;
    this.showJokerPoker = false;
    this.showTensorBetter = false;
    this.showCarebbeanPoker = false;
  }

  loadBg() {
    this.LogImg = new window.Image();
    this.LogImg.src = loginBg;
    this.LogImg.addEventListener("load", this.loadImg);
  }
  loadImg = () => {
    this.setState({
      logInBgLoader: false,
    });
    console.log(this.state.logInBgLoader);
  };

  handleImageLoad = () => {
    console.log("image Loaded complted ..........................................................")
    this.setState({ loadedBGIMG: true });
  };

  render() {
    console.log(this.PortraiteView)
    return (
      <React.Fragment>
        <div>
          <div
            id="mainWindow"
            className="fgaLoginMain"
            style={{
              height: `${this.state.heightFix ? this.fixHight + "px" : "auto"}`,
              overflow: `${this.state.heightFix ? "hidden" : "auto"}`,
            }}
          >
            <div className="gameBoxMain" id="gameBoxMain">
              {this.gameEntryOutSide ? (
                <div className="fd">
                  <div className={this.state.closePopup ? "popupWin block" : "popupWin none"} >
                    {/* <div className="pop_text"> */}
                    <div className="session_closed">
                      <img className="errorPopup_session_closed" src={errorAlert} alt="errorPopup" />
                      <div className="fd session_closed_text_container">{this.state.popupMessage}</div>
                      {/* <div className="fd">
                        <button
                          onClick={(e) => this.closeWin()}
                          className="btn_1 m_t_15"
                        >
                          {" "}
                          OK{" "}
                        </button>
                      </div> */}
                    </div>
                  </div>
                  <div className="appMain">
                    <ContextAPI.Provider value={this.state}>
                      {/**************************** BEFORE LOGIN SECTION START ******************/}
                      <div
                        className={
                          this.state.forceLog
                            ? "coverLog showCover"
                            : "coverLog hideCover"
                        }
                      ></div>
                      {/**************************** BEFORE LOGIN SECTION END ******************/}



                      <Switch>
                        <Route exact path="/">
                          <HomePage
                            websocketLoader={this.state.websocketLoader}
                            ref={this.homeRef}
                            isPlayerLogin={this.state.isPlayerLogin}
                            games={this.state.lobbyInfo}
                            balance={this.state.balance}
                            network={this.connectionTo}
                            user={this.state.user}
                            playerId={this.state.playerId}
                            action={this.componentStateHandler.bind(this)}
                            gameOpenHandler={this.gameOpenHandler.bind(this)}
                          ></HomePage>
                        </Route>
                        <Route exact path="/home">
                          <HomePage
                            websocketLoader={this.state.websocketLoader}
                            ref={this.homeRef}
                            isPlayerLogin={this.state.isPlayerLogin}
                            games={this.state.lobbyInfo}
                            balance={this.state.balance}
                            network={this.connectionTo}
                            user={this.state.user}
                            playerId={this.state.playerId}
                            action={this.componentStateHandler.bind(this)}
                            gameOpenHandler={this.gameOpenHandler.bind(this)}
                          ></HomePage>
                        </Route>
                        {/* <Route exact path="/PayOutInfo">
                             <PayOutInfo
                    payOutChild={ref => (this.PayOutInfo = ref)}
                    ref={this.PayOutInfo}>
                    isMobile={this.isMobile}
                 </PayOutInfo>
                             </Route> */}
                        <Route exact path="/targetNonTimer">
                          <FunTarget
                            childRef={(ref) => (this.FunTarget = ref)}
                            gamstateHandle={this.gamstateHandleMethod.bind(
                              this
                            )}
                            user={this.state.user}
                            ref={this.FunTarget}
                            targetResult={this.state.targetResult}
                            gameState={this.state.funTargetGameState}
                            action={this.gameCloseHandler.bind(this)}
                            game_maintanance={this.showGameMaintanance.bind(
                              this
                            )}
                            player_banned={this.playerBanned.bind(this)}
                            device_blocked={this.showDeviceBlocked.bind(this)}
                            isMobile={this.isMobile}
                            network={this.connectionTo}
                            balance={this.state.balance}
                            // balance={400}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            infoText={this.state.FunTargetInfoText}
                            isPlayerBanned={this.state.isPlayerBanned}
                            isdeviceBlocked={this.state.deviceBlocked}
                            gameUnderMaintanance={
                              this.state.gameUnderMaintanance
                            }
                            gameEntry={this.state.gameEntry}
                            ////onFocus={this.state.onFocus}
                            minBetLbl={this.state.ft_minBetLbl}
                            maxBetLbl={this.state.ft_maxBetLbl}
                            minBetAmt={this.state.ft_minBet}
                            maxBetAmt={this.state.ft_maxBet}
                            ft_innerLimits={this.state.ft_innerLimits}
                            ft_chipValues={this.state.ft_chipValues}
                            game_PayoutInfo={this.state.game_PayoutInfo}
                          ></FunTarget>
                        </Route>
                        <Route exact path="/miniAndarBahar">
                          <FunCard
                            childRef={(ref) => (this.FunCard = ref)}
                            funCardGameState={this.state.funCardGameState}
                            network={this.connectionTo}
                            ref={this.FunCard}
                            action={this.gameCloseHandler.bind(this)}
                            game_maintanance={this.showGameMaintanance.bind(
                              this
                            )}
                            player_banned={this.playerBanned.bind(this)}
                            device_blocked={this.showDeviceBlocked.bind(this)}
                            gamstateHandle={this.gamstateHandleMethod.bind(
                              this
                            )}
                            balance={this.state.balance}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            targetResult={this.state.funCardResult}
                            userName={this.state.user}
                            infoText={this.state.FunCardInfoText}
                            isPlayerBanned={this.state.isPlayerBanned}
                            isdeviceBlocked={this.state.deviceBlocked}
                            gameUnderMaintanance={
                              this.state.gameUnderMaintanance
                            }
                            gameEntry={this.state.gameEntry}
                            // //onFocus={this.state.onFocus}
                            minBetLbl={this.state.fc_minBetLbl}
                            maxBetLbl={this.state.fc_maxBetLbl}
                            minBetAmt={this.state.fc_minBet}
                            maxBetAmt={this.state.fc_maxBet}
                            fc_innerLimits={this.state.fc_innerLimits}
                            fc_chipValues={this.state.fc_chipValues}
                            game_PayoutInfo={this.state.game_PayoutInfo}
                          ></FunCard>
                        </Route>
                        <Route exact path="/targetTimer">
                          <FunTargetTimer
                            childRef={(ref) => (this.FunTargetTimer = ref)}
                            gamstateHandle={this.gamstateHandleMethod.bind(
                              this
                            )}
                            timesend={this.state.FunTargetTimeSend}

                            user={this.state.user}
                            ref={this.FunTargetTimer}
                            targetResult={this.state.funTargetTimerResult}
                            gameState={this.state.funTargetTimerGameState}
                            action={this.gameCloseHandler.bind(this)}
                            game_maintanance={this.showGameMaintanance.bind(
                              this
                            )}
                            player_banned={this.playerBanned.bind(this)}
                            device_blocked={this.showDeviceBlocked.bind(this)}
                            spectatorCount={this.updateSpectatorCount_fn.bind(
                              this
                            )}
                            isMobile={this.isMobile}
                            network={this.connectionTo}
                            balance={this.state.balance}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            infoText={this.state.FunTargetTimerInfoText}
                            isPlayerBanned={this.state.isPlayerBanned}
                            isdeviceBlocked={this.state.deviceBlocked}
                            gameUnderMaintanance={
                              this.state.gameUnderMaintanance
                            }
                            gameEntry={this.state.gameEntry}
                            isIphone={this.isIphone}
                            // //onFocus={this.state.onFocus}

                            minBetLbl={this.state.ftt_minBetLbl}
                            maxBetLbl={this.state.ftt_maxBetLbl}
                            minBetAmt={this.state.ftt_minBet}
                            maxBetAmt={this.state.ftt_maxBet}
                            ftt_innerLimits={this.state.ftt_innerLimits}
                            ftt_chipValues={this.state.ftt_chipValues}
                            game_PayoutInfo={this.state.game_PayoutInfo}
                          ></FunTargetTimer>
                        </Route>
                        <Route exact path="/soratTimer">
                          <FunSoratTimer
                            childRef={(ref) => (this.FunSoratTimer = ref)}
                            timesend={this.state.soratTimeSend}
                            user={this.state.user}
                            ref={this.FunSoratTimer}
                            targetResult={this.state.funSoratResult}
                            gameState={this.state.funSoratGameState}
                            action={this.gameCloseHandler.bind(this)}
                            game_maintanance={this.showGameMaintanance.bind(
                              this
                            )}
                            player_banned={this.playerBanned.bind(this)}
                            device_blocked={this.showDeviceBlocked.bind(this)}
                            spectatorCount={this.updateSpectatorCount_fn.bind(
                              this
                            )}
                            isMobile={this.isMobile}
                            network={this.connectionTo}
                            balance={this.state.balance}
                            // balance={400}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            gamstateHandle={this.gamstateHandleMethod.bind(
                              this
                            )}
                            infoText={this.state.FunSoratTimerInfoText}
                            isPlayerBanned={this.state.isPlayerBanned}
                            isdeviceBlocked={this.state.deviceBlocked}
                            FunSoratState={this.state.FunSoratState}
                            gameUnderMaintanance={
                              this.state.gameUnderMaintanance
                            }
                            gameEntry={this.state.gameEntry}
                            isIphone={this.isIphone}
                            minBetLbl={this.state.fst_minBetLbl}
                            maxBetLbl={this.state.fst_maxBetLbl}
                            minBetAmt={this.state.fst_minBet}
                            maxBetAmt={this.state.fst_maxBet}
                            fst_innerLimits={this.state.fst_innerLimits}
                            fst_chipValues={this.state.fst_chipValues}
                            game_PayoutInfo={this.state.game_PayoutInfo}
                          // //onFocus={this.state.onFocus}
                          ></FunSoratTimer>
                        </Route>

                        <Route exact path="/matkatimer">
                          <FunMatkaTimer
                            childRef={(ref) => (this.FunMatkaTimer = ref)}
                            ref={this.FunMatkaTimer}
                            timesend={this.state.FunMatkaTimeSend}
                            user={this.state.user}
                            targetResult={this.state.FunMatkaTimerResult}
                            gameState={this.state.FunMatkaTimerGameState}
                            gamstateHandle={this.gamstateHandleMethod.bind(
                              this
                            )}
                            action={this.gameCloseHandler.bind(this)}
                            game_maintanance={this.showGameMaintanance.bind(
                              this
                            )}
                            player_banned={this.playerBanned.bind(this)}
                            device_blocked={this.showDeviceBlocked.bind(this)}
                            spectatorCount={this.updateSpectatorCount_fn.bind(
                              this
                            )}
                            isMobile={this.isMobile}
                            network={this.connectionTo}
                            balance={this.state.balance}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            infoText={this.state.FunMatkaTimerInfoText}
                            isPlayerBanned={this.state.isPlayerBanned}
                            isdeviceBlocked={this.state.deviceBlocked}
                            gameUnderMaintanance={
                              this.state.gameUnderMaintanance
                            }
                            gameEntry={this.state.gameEntry}
                            isIphone={this.isIphone}
                            minBetLbl={this.state.fmt_minBetLbl}
                            maxBetLbl={this.state.fmt_maxBetLbl}
                            minBetAmt={this.state.fmt_minBet}
                            maxBetAmt={this.state.fmt_maxBet}
                            fmt_innerLimits={this.state.fmt_innerLimits}
                            fmt_chipValues={this.state.fmt_chipValues}
                            game_PayoutInfo={this.state.game_PayoutInfo}
                          //onFocus={this.state.onFocus}
                          ></FunMatkaTimer>
                        </Route>
                        <Route exact path="/matkaNonTimer">
                          <FunMatka
                            childRef={(ref) => (this.FunMatka = ref)}
                            gamstateHandle={this.gamstateHandleMethod.bind(
                              this
                            )}
                            user={this.state.user}
                            ref={this.FunMatka}
                            targetResult={this.state.funmatkaResult}
                            gameState={this.state.funMatkaGameState}
                            action={this.gameCloseHandler.bind(this)}
                            game_maintanance={this.showGameMaintanance.bind(
                              this
                            )}
                            player_banned={this.playerBanned.bind(this)}
                            device_blocked={this.showDeviceBlocked.bind(this)}
                            isMobile={this.isMobile}
                            network={this.connectionTo}
                            balance={this.state.balance}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            infoText={this.state.FunMatkaInfoText}
                            isdeviceBlocked={this.state.deviceBlocked}
                            isPlayerBanned={this.state.isPlayerBanned}
                            gameUnderMaintanance={
                              this.state.gameUnderMaintanance
                            }
                            gameEntry={this.state.gameEntry}
                            //onFocus={this.state.onFocus}
                            minBetLbl={this.state.fm_minBetLbl}
                            maxBetLbl={this.state.fm_maxBetLbl}
                            minBetAmt={this.state.fm_minBet}
                            maxBetAmt={this.state.fm_maxBet}
                            fm_innerLimits={this.state.fm_innerLimits}
                            fm_chipValues={this.state.fm_chipValues}
                            game_PayoutInfo={this.state.game_PayoutInfo}
                          ></FunMatka>
                        </Route>
                        <Route exact path="/americanRouletteTimer">
                          <AmericanRouletteTimer
                            childRef={(ref) =>
                              (this.AmericanRouletteTimer = ref)
                            }
                            timesend={this.state.AmericanTimeSend}
                            user={this.state.user}
                            ref={this.AmericanRouletteTimer}
                            targetResult={
                              this.state.AmericanRouletteTimerResult
                            }
                            gameState={
                              this.state.AmericanRouletteTimerGameState
                            }
                            gamstateHandle={this.gamstateHandleMethod.bind(
                              this
                            )}
                            action={this.gameCloseHandler.bind(this)}
                            game_maintanance={this.showGameMaintanance.bind(
                              this
                            )}
                            player_banned={this.playerBanned.bind(this)}
                            device_blocked={this.showDeviceBlocked.bind(this)}
                            spectatorCount={this.updateSpectatorCount_fn.bind(
                              this
                            )}
                            isMobile={this.isMobile}
                            network={this.connectionTo}
                            balance={this.state.balance}
                            // balance={70000.299}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            infoText={this.state.AmericanRouletteTimerInfoText}
                            isPlayerBanned={this.state.isPlayerBanned}
                            isdeviceBlocked={this.state.deviceBlocked}
                            gameUnderMaintanance={
                              this.state.gameUnderMaintanance
                            }
                            AmericanRouletteTimerState={
                              this.state.AmericanRouletteTimerState
                            }
                            gameEntry={this.state.gameEntry}
                            isIphone={this.isIphone}
                            //onFocus={this.state.onFocus}
                            minBetLbl={this.state.art_minBetLbl}
                            maxBetLbl={this.state.art_maxBetLbl}
                            minBetAmt={this.state.art_minBet}
                            maxBetAmt={this.state.art_maxBet}
                            art_innerLimits={this.state.art_innerLimits}
                            art_chipValues={this.state.art_chipValues}
                            game_PayoutInfo={this.state.game_PayoutInfo}
                          ></AmericanRouletteTimer>
                        </Route>
                        <Route exact path="/americanRoulettenon">
                          <AmericanRoulette
                            childRef={(ref) => (this.AmericanRoulette = ref)}
                            user={this.state.user}
                            ref={this.AmericanRoulette}
                            targetResult={this.state.rouletteResult}
                            gameState={this.state.americanRouletteGameState}
                            gamstateHandle={this.gamstateHandleMethod.bind(
                              this
                            )}
                            action={this.gameCloseHandler.bind(this)}
                            game_maintanance={this.showGameMaintanance.bind(
                              this
                            )}
                            player_banned={this.playerBanned.bind(this)}
                            device_blocked={this.showDeviceBlocked.bind(this)}
                            isMobile={this.isMobile}
                            network={this.connectionTo}
                            balance={this.state.balance}
                            //  balance={70.22}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            infoText={this.state.AmericanRouletteInfoText}
                            isPlayerBanned={this.state.isPlayerBanned}
                            gameUnderMaintanance={
                              this.state.gameUnderMaintanance
                            }
                            isdeviceBlocked={this.state.deviceBlocked}
                            error_code={this.state.error_code}
                            gameEntry={this.state.gameEntry}
                            //onFocus={this.state.onFocus}

                            minBetLbl={this.state.ar_minBetLbl}
                            maxBetLbl={this.state.ar_maxBetLbl}
                            minBetAmt={this.state.ar_minBet}
                            maxBetAmt={this.state.ar_maxBet}
                            ar_innerLimits={this.state.ar_innerLimits}
                            ar_chipValues={this.state.ar_chipValues}
                            game_PayoutInfo={this.state.game_PayoutInfo}
                          ></AmericanRoulette>
                        </Route>
                        <Route exact path="/europeanRouletteTimer">
                          <EuropeanRouletteTimer
                            timesend={this.state.EuropeanTimeSend}
                            user={this.state.user}
                            childRef={(ref) =>
                              (this.EuropeanRouletteTimer = ref)
                            }
                            ref={this.EuropeanRouletteTimer}
                            targetResult={
                              this.state.EuropeanRouletteTimerResult
                            }
                            gameState={
                              this.state.EuropeanRouletteTimerGameState
                            }
                            gamstateHandle={this.gamstateHandleMethod.bind(
                              this
                            )}
                            action={this.gameCloseHandler.bind(this)}
                            game_maintanance={this.showGameMaintanance.bind(
                              this
                            )}
                            player_banned={this.playerBanned.bind(this)}
                            device_blocked={this.showDeviceBlocked.bind(this)}
                            spectatorCount={this.updateSpectatorCount_fn.bind(
                              this
                            )}
                            isMobile={this.isMobile}
                            network={this.connectionTo}
                            balance={this.state.balance}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            infoText={this.state.EuropeanRouletteTimerInfoText}
                            isPlayerBanned={this.state.isPlayerBanned}
                            isdeviceBlocked={this.state.deviceBlocked}
                            gameUnderMaintanance={
                              this.state.gameUnderMaintanance
                            }
                            gameEntry={this.state.gameEntry}
                            isIphone={this.isIphone}
                            //onFocus={this.state.onFocus}

                            minBetLbl={this.state.ert_minBetLbl}
                            maxBetLbl={this.state.ert_maxBetLbl}
                            minBetAmt={this.state.ert_minBet}
                            maxBetAmt={this.state.ert_maxBet}
                            ert_innerLimits={this.state.ert_innerLimits}
                            ert_chipValues={this.state.ert_chipValues}
                            game_PayoutInfo={this.state.game_PayoutInfo}
                          ></EuropeanRouletteTimer>
                        </Route>

                        <Route exact path="/europeanRoulette">
                          <EuropeanRoulette
                            childRef={(ref) => (this.EuropeanRoulette = ref)}
                            user={this.state.user}
                            ref={this.EuropeanRoulette}
                            targetResult={this.state.EuropeanRouletteResult}
                            gameState={this.state.EuropeanRouletteGameState}
                            gamstateHandle={this.gamstateHandleMethod.bind(
                              this
                            )}
                            action={this.gameCloseHandler.bind(this)}
                            game_maintanance={this.showGameMaintanance.bind(
                              this
                            )}
                            player_banned={this.playerBanned.bind(this)}
                            device_blocked={this.showDeviceBlocked.bind(this)}
                            isMobile={this.isMobile}
                            network={this.connectionTo}
                            balance={this.state.balance}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            infoText={this.state.EuropeanRouletteInfoText}
                            error_code={this.state.error_code}
                            isdeviceBlocked={this.state.deviceBlocked}
                            gameUnderMaintanance={
                              this.state.gameUnderMaintanance
                            }
                            isPlayerBanned={this.state.isPlayerBanned}
                            gameEntry={this.state.gameEntry}
                            //onFocus={this.state.onFocus}
                            minBetLbl={this.state.er_minBetLbl}
                            maxBetLbl={this.state.er_maxBetLbl}
                            minBetAmt={this.state.er_minBet}
                            maxBetAmt={this.state.er_maxBet}
                            er_innerLimits={this.state.er_innerLimits}
                            er_chipValues={this.state.er_chipValues}
                            game_PayoutInfo={this.state.game_PayoutInfo}
                          ></EuropeanRoulette>
                        </Route>
                        <Route exact path="/dragonTiger">
                          <DragonTigerTimer
                            childRef={(ref) => (this.DragonTigerTimer = ref)}
                            ref={this.DragonTigerTimer}
                            user={this.state.user}
                            targetResult={this.state.dragonTigerResult}
                            history={this.state.DragHistory}
                            dt_timeSend={this.state.dtt_TimeSend}
                            gameState={this.state.dragonTigerGameState}
                            gamstateHandle={this.gamstateHandleMethod.bind(
                              this
                            )}
                            action={this.gameCloseHandler.bind(this)}
                            game_maintanance={this.showGameMaintanance.bind(
                              this
                            )}
                            player_banned={this.playerBanned.bind(this)}
                            device_blocked={this.showDeviceBlocked.bind(this)}
                            bet_request={this.sendBetRequests.bind(this)}
                            isMobile={this.isMobile}
                            network={this.connectionTo}
                            balance={this.state.balance}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            infoText={this.state.DragonTigerInfoText}
                            error_code={this.state.error_code}
                            isdeviceBlocked={this.state.deviceBlocked}
                            gameUnderMaintanance={
                              this.state.gameUnderMaintanance
                            }
                            isPlayerBanned={this.state.isPlayerBanned}
                            gameEntry={this.state.gameEntry}
                            minBetLbl={this.state.dtt_minBetLbl}
                            maxBetLbl={this.state.dtt_maxBetLbl}
                            minBetAmt={this.state.dtt_minBet}
                            maxBetAmt={this.state.dtt_maxBet}
                            dt_innerLimits={this.state.dtt_innerLimits}
                            dtt_chipValues={this.state.dtt_chipValues}
                            game_PayoutInfo={this.state.game_PayoutInfo}
                          ></DragonTigerTimer>
                        </Route>
                        <Route exact path="/Keno">
                          <KenoGame
                            childRef={(ref) => (this.KenoGame = ref)}
                            user={this.state.user}
                            ref={this.KenoGame}
                            balance={this.state.balance}
                            targetResult={this.state.kenoResult}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            gameState={this.state.KenoGameState}
                            gamstateHandle={this.gamstateHandleMethod.bind(
                              this
                            )}
                            action={this.gameCloseHandler.bind(this)}
                            game_maintanance={this.showGameMaintanance.bind(
                              this
                            )}
                            player_banned={this.playerBanned.bind(this)}
                            device_blocked={this.showDeviceBlocked.bind(this)}
                            isMobile={this.isMobile}
                            network={this.connectionTo}
                            infoText={this.state.KenoInfoText}
                            error_code={this.state.error_code}
                            isdeviceBlocked={this.state.deviceBlocked}
                            gameUnderMaintanance={
                              this.state.gameUnderMaintanance
                            }
                            isPlayerBanned={this.state.isPlayerBanned}
                            gameEntry={this.state.gameEntry}
                            resultNo={this.state.resultNo}
                            minBetLbl={this.state.kn_minBetLbl}
                            maxBetLbl={this.state.kn_maxBetLbl}
                            minBetAmt={this.state.kn_minBet}
                            maxBetAmt={this.state.kn_maxBet}
                            kn_innerLimits={this.state.kn_innerLimits}
                            kn_chipValues={this.state.kn_chipValues}
                            resultCard={this.state.resultCard}
                            bonusAmt={this.state.bonusAmt}
                            dupwinAmt={this.state.dupwinAmt}
                            starCount={this.state.starCount}
                            kn_payouts={this.state.kn_payouts}
                            game_PayoutInfo={this.state.game_PayoutInfo}
                          ></KenoGame>
                        </Route>
                        <Route exact path="/andarBaharTimer">
                          <AndarBaharTimer
                            childRefAB={(ref) => (this.AndarBaharTimer = ref)}
                            user={this.state.user}
                            ref={this.AndarBaharTimer}
                            ab_timeSend={this.state.AndarBaharTimeSend}
                            andarBaharTimerResult={this.state.andarBaharTimerResult}
                            gamstateHandle={this.gamstateHandleMethod.bind(this)}
                            action={this.gameCloseHandler.bind(this)}
                            game_maintanance={this.showGameMaintanance.bind(this)}
                            player_banned={this.playerBanned.bind(this)}
                            device_blocked={this.showDeviceBlocked.bind(this)}
                            spectatorCount={this.updateSpectatorCount_fn.bind(this)}
                            isMobile={this.isMobile}
                            network={this.connectionTo}
                            balance={this.state.balance}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            infoText={this.state.AndarBaharTimerInfoText}
                            error_code={this.state.error_code}
                            isdeviceBlocked={this.state.deviceBlocked}
                            gameUnderMaintanance={this.state.gameUnderMaintanance}
                            isPlayerBanned={this.state.isPlayerBanned}
                            minBetLbl={this.state.abt_minBetLbl}
                            maxBetLbl={this.state.abt_maxBetLbl}
                            minBetAmt={this.state.abt_minBet}
                            maxBetAmt={this.state.abt_maxBet}
                            gameState={this.state.andarBaharTimerGameState}
                            gameEntry={this.state.gameEntry}
                            abt_innerLimits={this.state.abt_innerLimits}
                            abt_chipValues={this.state.abt_chipValues}
                            game_PayoutInfo={this.state.game_PayoutInfo}
                          ></AndarBaharTimer>
                        </Route>
                        <Route exact path="/moneyWheel">
                          <MoneyWheelTimer
                            childRef={(ref) => (this.MoneyWheelTimer = ref)}
                            gamstateHandle={this.gamstateHandleMethod.bind(this)}
                            user={this.state.user}
                            ref={this.MoneyWheelTimer}
                            moneywheelResult={this.state.moneyWheelResult}
                            gameState={this.state.moneyWheelGameState}
                            action={this.gameCloseHandler.bind(this)}
                            game_maintanance={this.showGameMaintanance.bind(this)}
                            player_banned={this.playerBanned.bind(this)}
                            device_blocked={this.showDeviceBlocked.bind(this)}
                            isMobile={this.isMobile}
                            network={this.connectionTo}
                            balance={this.state.balance}
                            // balance={4}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            infoText={this.state.infoText}
                            isPlayerBanned={this.state.isPlayerBanned}
                            isdeviceBlocked={this.state.deviceBlocked}
                            gameUnderMaintanance={this.state.gameUnderMaintanance}
                            mw_timesend={this.state.mwTimeSend}
                            minBetLbl={this.state.mwt_minBetLbl}
                            maxBetLbl={this.state.mwt_maxBetLbl}
                            minBetAmt={this.state.mwt_minBet}
                            maxBetAmt={this.state.mwt_maxBet}
                            mwt_innerLimits={this.state.mwt_innerLimits}
                            mwt_chipValues={this.state.mwt_chipValues}
                            game_PayoutInfo={this.state.game_PayoutInfo}
                          ></MoneyWheelTimer>
                        </Route>

                        <Route exact path="/bingo">
                          <Bingo
                            childRef={ref => (this.Bingo = ref)}
                            user={this.state.user}
                            ref={this.Bingo}
                            BingolResult={this.state.BingoResult}
                            gameState={this.state.bingoGameState}
                            gamstateHandle={this.gamstateHandleMethod.bind(this)}
                            action={this.gameCloseHandler.bind(this)}
                            game_maintanance={this.showGameMaintanance.bind(this)}
                            player_banned={this.playerBanned.bind(this)}
                            device_blocked={this.showDeviceBlocked.bind(this)}
                            isMobile={this.isMobile}
                            network={this.connectionTo}
                            balance={this.state.balance}

                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            infoText={this.state.BingoInfoText}
                            error_code={this.state.error_code}
                            isdeviceBlocked={this.state.deviceBlocked}
                            gameUnderMaintanance={this.state.gameUnderMaintanance}
                            isPlayerBanned={this.state.isPlayerBanned}
                            minBetLbl={this.state.bng_minBetLbl}
                            maxBetLbl={this.state.bng_maxBetLbl}
                            minBetAmt={this.state.bng_minBet}
                            maxBetAmt={this.state.bng_maxBet}
                            bingo_innerLimits={this.state.bng_innerLimits}
                            resultCard={this.state.resultCard}
                            bonusAmt={this.state.bonusAmt}
                            dupwinAmt={this.state.dupwinAmt}
                            starCount={this.state.starCount}
                            bngo_payouts={this.state.bngo_payouts}
                            bng_chipValues={this.state.bng_chipValues}
                            game_PayoutInfo={this.state.game_PayoutInfo}
                          // bngo_dup_payOut={this.dup_payOut.bind(this)}
                          />
                        </Route>




                        <Route exact path="/russianPoker">
                          <RussianPoker
                            key={this.RussianPoker}
                            childRef={(ref) => (this.RussianPoker = ref)}
                            ref={this.RussianPoker}
                            user={this.state.user}
                            gamstateHandle={this.gamstateHandleMethod.bind(
                              this
                            )}
                            russianPokerResult={this.state.russianPokerResult}
                            gameState={this.state.russianPokerGameState}
                            action={this.gameCloseHandler.bind(this)}
                            game_maintanance={this.showGameMaintanance.bind(
                              this
                            )}
                            player_banned={this.playerBanned.bind(this)}
                            device_blocked={this.showDeviceBlocked.bind(this)}
                            // isMobile={this.isMobile}
                            network={this.connectionTo}
                            balance={this.state.balance}
                            // balance={1000}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            rp_chipValues={this.state.rp_chipValues}
                            minBetAmt={this.state.rp_minBet}
                            maxBetAmt={this.state.rp_maxBet}
                            minBetLbl={this.state.rp_minBetLbl}
                            maxBetLbl={this.state.rp_maxBetLbl}
                            previousGamestateCardLength6={this.state.russianPokerGameState}

                          // infoText={this.state.infoText}
                          // isPlayerBanned={this.state.isPlayerBanned}
                          // isdeviceBlocked={this.state.deviceBlocked}
                          // gameUnderMaintanance={this.state.gameUnderMaintanance}
                          ></RussianPoker>
                        </Route>

                        <Route exact path="/CarebbeanPoker">
                          <CarebbeanPoker
                            key={this.CarebbeanPoker}
                            childRef={(ref) => (this.CarebbeanPoker = ref)}
                            ref={this.CarebbeanPoker}
                            user={this.state.user}
                            gamstateHandle={this.gamstateHandleMethod.bind(
                              this
                            )}
                            CarebbeanPokerResult={this.state.CarebbeanPokerResult}
                            gameState={this.state.CarebbeanPokerGameState}
                            action={this.gameCloseHandler.bind(this)}
                            game_maintanance={this.showGameMaintanance.bind(
                              this
                            )}
                            player_banned={this.playerBanned.bind(this)}
                            device_blocked={this.showDeviceBlocked.bind(this)}
                            // isMobile={this.isMobile}
                            network={this.connectionTo}
                            balance={this.state.balance}
                            //balance={100}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            cb_chipValues={this.state.cb_chipValues}
                            minBetAmt={this.state.cb_minBet}
                            maxBetAmt={this.state.cb_maxBet}
                            minBetLbl={this.state.cb_minBetLbl}
                            maxBetLbl={this.state.cb_maxBetLbl}
                            payoutInfoscrbn={this.state.caribbeanpayouts}
                            game_PayoutInfo={this.state.game_PayoutInfo}
                          // previousGamestateCardLength6={ this.state.russianPokerGameState}

                          // infoText={this.state.infoText}
                          // isPlayerBanned={this.state.isPlayerBanned}
                          // isdeviceBlocked={this.state.deviceBlocked}
                          // gameUnderMaintanance={this.state.gameUnderMaintanance}
                          ></CarebbeanPoker>
                        </Route>

                        <Route exact path="/tropicalPoker">

                          <TropicalPoker
                            key={this.TropicalPoker}
                            childRef={(ref) => (this.TropicalPoker = ref)}
                            ref={this.TropicalPoker}
                            user={this.state.user}
                            gamstateHandle={this.gamstateHandleMethod.bind(
                              this
                            )}
                            tropicalPokerResult={this.state.tropicalPokerResult}
                            gameState={this.state.tropicalPokerGameState}
                            action={this.gameCloseHandler.bind(this)}
                            game_maintanance={this.showGameMaintanance.bind(
                              this
                            )}
                            player_banned={this.playerBanned.bind(this)}
                            device_blocked={this.showDeviceBlocked.bind(this)}
                            // isMobile={this.isMobile}
                            network={this.connectionTo}
                            balance={this.state.balance}
                            // balance={50}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            tp_chipValues={this.state.tp_chipValues}
                            minBetAmt={this.state.tp_minBet}
                            maxBetAmt={this.state.tp_maxBet}

                            minBetLbl={this.state.tp_minBetLbl}
                            maxBetLbl={this.state.tp_maxBetLbl}
                            previousGamestateCardLength6={this.state.tropicalPokerGameState}
                            // infoText={this.state.infoText}
                            // isPlayerBanned={this.state.isPlayerBanned}
                            // isdeviceBlocked={this.state.deviceBlocked}
                            // gameUnderMaintanance={this.state.gameUnderMaintanance}
                            TP_payouts={this.state.TP_payouts}
                          ></TropicalPoker>
                        </Route>

                        <Route exact path="/jacksrbetter">
                          <JacksorBetter
                            ref={this.JacksorBetter}
                            childRef={(ref) => (this.JacksorBetter = ref)}
                            user={this.state.user}
                            // // JacksorBetterResult={this.state.JacksorBetterResult}
                            gameState={this.state.jacksorBetterGameState}
                            gamstateHandle={this.gamstateHandleMethod.bind(this)}
                            // action={this.gameCloseHandler.bind(this)}
                            // game_maintanance={this.showGameMaintanance.bind(this)}
                            // player_banned={this.playerBanned.bind(this)}
                            // device_blocked={this.showDeviceBlocked.bind(this)}
                            isMobile={this.isMobile}
                            network={this.connectionTo}
                            balance={this.state.balance}
                            // balance={5}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            // infoText={this.state.JacksorBetterInfoText}
                            // error_code={this.state.error_code}
                            // isdeviceBlocked={this.state.deviceBlocked}
                            // gameUnderMaintanance={this.state.gameUnderMaintanance}
                            // isPlayerBanned={this.state.isPlayerBanned}
                            // minBetLbl={this.state.jrb_minBetLbl}
                            // maxBetLbl={this.state.jrb_maxBetLbl}
                            // minBetAmt={this.state.jrb_minBet}
                            // maxBetAmt={this.state.jrb_maxBet}
                            // jrb_innerLimits={this.state.bng_innerLimits}
                            // // jrb_payouts={this.state.jrb_payouts}
                            jrb_chipValues={this.state.jrb_chipValues}
                            game_PayoutInfo={this.state.game_PayoutInfo}
                          />
                        </Route>
                        <Route exact path="/deuceswild">
                          <JacksorBetter
                            ref={this.DeucesWild}
                            childRef={(ref) => (this.DeucesWild = ref)}
                            user={this.state.user}
                            gameState={this.state.deucesWildGameState}
                            gamstateHandle={this.gamstateHandleMethod.bind(this)}
                            // action={this.gameCloseHandler.bind(this)}
                            // game_maintanance={this.showGameMaintanance.bind(this)}
                            // player_banned={this.playerBanned.bind(this)}
                            // device_blocked={this.showDeviceBlocked.bind(this)}
                            isMobile={this.isMobile}
                            network={this.connectionTo}
                            balance={this.state.balance}
                            // balance={5}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            // infoText={this.state.JacksorBetterInfoText}
                            // error_code={this.state.error_code}
                            // isdeviceBlocked={this.state.deviceBlocked}
                            // gameUnderMaintanance={this.state.gameUnderMaintanance}
                            // isPlayerBanned={this.state.isPlayerBanned}
                            // minBetLbl={this.state.jrb_minBetLbl}
                            // maxBetLbl={this.state.jrb_maxBetLbl}
                            // minBetAmt={this.state.jrb_minBet}
                            // maxBetAmt={this.state.jrb_maxBet}
                            // jrb_innerLimits={this.state.bng_innerLimits}
                            // // jrb_payouts={this.state.jrb_payouts}
                            jrb_chipValues={this.state.jrb_chipValues}
                            game_PayoutInfo={this.state.game_PayoutInfo}
                          />
                        </Route>
                        <Route exact path="/jokerpoker">
                          <JacksorBetter
                            ref={this.JokerPoker}
                            childRef={(ref) => (this.JokerPoker = ref)}
                            user={this.state.user}
                            gameState={this.state.jokerPokerGameState}
                            gamstateHandle={this.gamstateHandleMethod.bind(this)}

                            isMobile={this.isMobile}
                            network={this.connectionTo}
                            balance={this.state.balance}
                            // balance={5}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}

                            jrb_chipValues={this.state.jrb_chipValues}
                            game_PayoutInfo={this.state.game_PayoutInfo}
                          />
                        </Route>

                        <Route exact path="/tensrbetter">
                          <JacksorBetter
                            ref={this.TensorBetter}
                            childRef={(ref) => (this.TensorBetter = ref)}
                            user={this.state.user}
                            // // JacksorBetterResult={this.state.JacksorBetterResult}
                            gameState={this.state.tensorBetterGameState}
                            gamstateHandle={this.gamstateHandleMethod.bind(this)}
                            // action={this.gameCloseHandler.bind(this)}
                            // game_maintanance={this.showGameMaintanance.bind(this)}
                            // player_banned={this.playerBanned.bind(this)}
                            // device_blocked={this.showDeviceBlocked.bind(this)}
                            isMobile={this.isMobile}
                            network={this.connectionTo}
                            balance={this.state.balance}
                            // balance={5}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            // infoText={this.state.JacksorBetterInfoText}
                            // error_code={this.state.error_code}
                            // isdeviceBlocked={this.state.deviceBlocked}
                            // gameUnderMaintanance={this.state.gameUnderMaintanance}
                            // isPlayerBanned={this.state.isPlayerBanned}
                            // minBetLbl={this.state.jrb_minBetLbl}
                            // maxBetLbl={this.state.jrb_maxBetLbl}
                            // minBetAmt={this.state.jrb_minBet}
                            // maxBetAmt={this.state.jrb_maxBet}
                            // jrb_innerLimits={this.state.bng_innerLimits}
                            // // jrb_payouts={this.state.jrb_payouts}
                            jrb_chipValues={this.state.trb_chipValues}
                            game_PayoutInfo={this.state.game_PayoutInfo}
                          />
                        </Route>
                        <Route exact path="/tripleChance">
                          <TripleChance
                            ref={this.TripleChance}
                            user={this.state.user}
                            history={this.state.TripleChanceHistory}
                            tripleChanceGameState={
                              this.state.tripleChanceGameState
                            }
                            action={this.gameCloseHandler.bind(this)}
                            isMobile={this.isMobile}
                            network={this.connectionTo}
                            balance={this.state.balance}
                            playerId={this.state.playerId}
                            gameId={this.state.gameId}
                            targetResult={this.state.tripleChanceResult}
                          ></TripleChance>
                        </Route>
                      </Switch>
                      <PayOutInfo
                        childRef={(ref) => (this.PayOutInfo = ref)}
                        ref={this.PayOutInfo}
                      />
                      {this.state.forceLog && (
                        <ForceLogin
                          forceData={{
                            log: this.state.alertText,
                            forcedLoginCheck: this.forcedLoginCheck.bind(this),
                          }}
                        />
                      )}
                    </ContextAPI.Provider>
                  </div>
                </div>
              ) : null}
            </div>
          </div>


          {this.state.MainLoaderImg ? (
            // {(this.state.MainLoaderImg || this.state.websocketLoader || this.state.enterGameLoader && this.state.gameEntry == "out") ? (
            <div className="MainLoadercontainer">
              <div className={`Loadercontainer ${this.state.loadedBGIMG ? "loaded" : ""}`}
                style={{
                  backgroundImage: `url(${this.PortraiteView?(this.positionsALLBG[this.EnterGameId]).IMGM:(this.positionsALLBG[this.EnterGameId]).IMG})`,
                  // backgroundImage: `url(${(this.positionsALLBG[this.EnterGameId]).IMG})`,
                  backgroundSize: `${(this.positionsALLBG[this.EnterGameId]).PositionW}% ${(this.positionsALLBG[this.EnterGameId]).PositionH}% `,
                  backgroundPosition: `${(this.positionsALLBG[this.EnterGameId]).x * (this.positionsALLBG[this.EnterGameId]).spriteWidthBG}% ${(this.positionsALLBG[this.EnterGameId]).y * (this.positionsALLBG[this.EnterGameId]).spriteHeightBG}%`,
                }}
              />
              <img src={(this.positionsALLBG[this.EnterGameId]).IMG} alt="Hidden Loader" className="hidden" onLoad={this.handleImageLoad} />
            </div>
          ) : null}

          {this.state.websocketLoader ? (

            // <div className="socketLoader">
            //   <div className="ind_loader">
            //     <div className="box_1Logo">
            //       <img src={icon} />
            //       <img src={ic} />
            //     </div>
            //   </div>
            // </div>
            
            <div className="MainLoadercontainer" >
              <div className={`Loadercontainer ${this.state.loadedBGIMG ? "loaded" : ""}`}
                style={{
                  backgroundImage: `url(${this.PortraiteView?(this.positionsALLBG[this.EnterGameId]).IMGM:(this.positionsALLBG[this.EnterGameId]).IMG})`,
                  backgroundSize: `${(this.positionsALLBG[this.EnterGameId]).PositionW}% ${(this.positionsALLBG[this.EnterGameId]).PositionH}% `,
                  backgroundPosition: `${(this.positionsALLBG[this.EnterGameId]).x * (this.positionsALLBG[this.EnterGameId]).spriteWidthBG}% ${(this.positionsALLBG[this.EnterGameId]).y * (this.positionsALLBG[this.EnterGameId]).spriteHeightBG}%`,
                }}
              />
              <img src={(this.positionsALLBG[this.EnterGameId]).IMG} alt="Hidden Loader" className="hidden" onLoad={this.handleImageLoad} />
              
              <div className="loading_bar">
                <div className="loading-fill"></div>
              </div>
            </div>

          ) : null}

          {this.state.enterGameLoader && this.state.gameEntry == "out" ? (

            // <div className="socketLoader">
            //   <div className="ind_loader">
            //     <div className="box_1Logo">
            //       <img src={icon} />
            //       <img src={ic} />
            //     </div>
            //   </div>
            // </div>

            <div className="MainLoadercontainer">
              <div className={`Loadercontainer ${this.state.loadedBGIMG ? "loaded" : ""}`}
                style={{
                  backgroundImage: `url(${this.PortraiteView?(this.positionsALLBG[this.EnterGameId]).IMGM :(this.positionsALLBG[this.EnterGameId]).IMG})`,
                  backgroundSize: `${(this.positionsALLBG[this.EnterGameId]).PositionW}% ${(this.positionsALLBG[this.EnterGameId]).PositionH}% `,
                  backgroundPosition: `${(this.positionsALLBG[this.EnterGameId]).x * (this.positionsALLBG[this.EnterGameId]).spriteWidthBG}% ${(this.positionsALLBG[this.EnterGameId]).y * (this.positionsALLBG[this.EnterGameId]).spriteHeightBG}%`,
                }}
              />
              <img src={(this.positionsALLBG[this.EnterGameId]).IMG} alt="Hidden Loader" className="hidden" onLoad={this.handleImageLoad} />
              <div className="containerBaritem">
                <div className="progress2">
                  <div className="progress-bar2" style={{ width: `${this.state.progressValue}%` }}></div>
                </div>
                <div className="loader">{this.state.progressValue}%</div>
              </div>
            </div>
          ) : null}

          <div className="awayPopup" id="awayPopup">
            <div className="popupTooLong">
              <div className="fd m_b_5 bold clr_1"> You are in Timer game </div>
              You were away from game for too long <br></br>
              Please re-open the Game
              <div className="fd btn_iosPopup">
                <button className="" onClick={(e) => this.refreshGame()}>
                  {" "}
                  Reconnect{" "}
                </button>
                <button className="" onClick={(e) => this.closeWin()}>
                  {" "}
                  Close window{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="errorPopup" id="errorPopup">
            <div className="popupTooLong" id="errorPopupIn"></div>
          </div>
          <div
            onClick={this.forcedLoginCheck}
            style={{
              zIndex: 300,
              position: "absolute",
              top: "40%",
              right: "40%",
              color: "#ffffff",
              fontSize: "24px",
            }}
          >
            {this.state.alertText}
          </div>
        </div>
        <div className="isMobileTrue" id="isMobileTrue">
          <div className="isMob">
            <img src={mobile_tilt} />
            Please rotate your screen to portrait mode <br></br>
            or off auto screen rotate
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStatesToProps = (state) => {
  // console.log(state.languageObjs.languageObj)
  // console.log(state.languageObjs.languageError)
  return {
    language: state.languageObjs.languageObj,
    noLanguage: state.languageObjs.languageError,
  };
};

const mapDispatch = (dispatch) => {
  return {
    fetchEnglish: () => dispatch(fetchEnglish()),
    fetchTelugu: () => dispatch(fetchTelugu()),
    fetchBengali: () => dispatch(fetchBengali()),
    fetchMarati: () => dispatch(fetchMarati()),
    fetchGujarati: () => dispatch(fetchGujarati()),
    fetchKannada: () => dispatch(fetchKannada()),
    fetchTamil: () => dispatch(fetchTamil()),
    fetchMalayalam: () => dispatch(fetchMalayalam()),
    fetchPortuguese: () => dispatch(fetchPortuguese()),
  };
};
// export default withRouter(App);
export default withRouter(connect(mapStatesToProps, mapDispatch)(App));
